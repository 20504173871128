import { createSlice } from "@reduxjs/toolkit";
import { getNumberPerPage } from "../../helpers/functions";
import { apiCallBegan } from "../actions/apiActions";
const url = "companies";

const slice = createSlice({
  name: "companies",
  initialState: {
    list: [],
    selectedGroup: null,
    typeOfCompany: null,
    search: "",
    page: 0,
    per_page: 0,
    total_pages: 0,
    maxPage: 0,
    total_records: 0,
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
    deActivated: null,
    selectedCompany: [],
  },
  reducers: {
    resetAll: (companies, action) => {
      companies.isLoading = false;
      companies.error = null;
      companies.success = null;
      companies.loadingOver = null;
      companies.deActivated = null;
    },
    requestStarted: (companies, action) => {
      companies.isLoading = true;
      companies.error = null;
      companies.isRequestSent = true;
    },
    requestedFailed: (companies, action) => {
      companies.error = action.payload;
      companies.isLoading = false;
    },

    companyAdded: (companies, action) => {
      companies.success = "New Company Added";
      companies.list.push(action.payload);
      companies.isLoading = false;
    },
    companyUpdated: (companies, action) => {
      const index = companies.list.findIndex(
        (company) => company._id === action.payload._id
      );
      companies.list[index] = action.payload;
      companies.success = "Company Updated";
      companies.isLoading = false;
    },

    companiesDeActivated: (companies, action) => {
      const { modifiedCount, word } = action.payload;
      companies.success =
        modifiedCount + " companies De-activated successfully";
      companies.deActivated = word;
      companies.isLoading = false;
    },

    companiesLoaded: (state, action) => {
      state.list = state.list.concat(action.payload.result);
      state.isLoading = false;
      state.page = action.payload.page;
      state.per_page = action.payload.per_page;
      state.total_pages = action.payload.total_pages;
      state.total_records = action.payload.total_records;
      state.selectedGroup = action.payload.selected_group;
      state.typeOfCompany = action.payload.typeOfCompany;
      state.search = action.payload.search;
      state.maxPage = state.page > state.maxPage ? state.page : state.maxPage;
    },

    companiesReLoaded: (state, action) => {
      state.list = action.payload.result;
      state.isLoading = false;
      state.page = action.payload.page;
      state.per_page = action.payload.per_page;
      state.total_pages = action.payload.total_pages;
      state.total_records = action.payload.total_records;
      state.selectedGroup = action.payload.selected_group;
      state.typeOfCompany = action.payload.typeOfCompany;
      state.search = action.payload.search;
      state.maxPage = 0;
    },

    companyLoaded: (state, action) => {
      state.list.push(action.payload);
      state.isLoading = false;
    },
  },
});

export const {
  companyAdded,
  companiesLoaded,
  companyLoaded,
  companiesReLoaded,
  requestedFailed,
  requestStarted,
  companyUpdated,
  companiesDeActivated,
  resetAll,
} = slice.actions;
export default slice.reducer;

export const getCompanies = (params = {}) => {
  params.per_page = getNumberPerPage();

  if (params.search === "" || params.search === null) delete params.search;
  if (params.group === "all" || params.group === null) delete params.group;
  if (params.type === "all" || params.type === null) delete params.type;

  let query = new URLSearchParams(params);

  // // Reload the data if no page is provided
  let resultsReceived = params.page
    ? companiesLoaded.type
    : companiesReLoaded.type;

  return apiCallBegan({
    url: `${url}?${query}`,
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: resultsReceived,
    onError: requestedFailed.type,
  });
};

export const getOneCompany = (id) => {
  return apiCallBegan({
    url: `${url}/${id}`,
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: companyLoaded.type,
    onError: requestedFailed.type,
  });
};

export const addCompany = (data) =>
  apiCallBegan({
    url,
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: companyAdded.type,
    onError: requestedFailed.type,
  });

export const updateCompany = (id, data) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "put",
    data,
    onStart: requestStarted.type,
    onSuccess: companyUpdated.type,
    onError: requestedFailed.type,
  });

export const deActivateCompanies = (word) =>
  apiCallBegan({
    url: `${url}/de-activate/${word}`,
    method: "put",
    onStart: requestStarted.type,
    onSuccess: companiesDeActivated.type,
    onError: requestedFailed.type,
  });
