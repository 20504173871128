import React, { useEffect, useState, Fragment } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import {
  Box,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Slide,
  Snackbar,
  Tab,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  getOneDirectory,
  resetAll,
} from "../../../redux/reducers/directoriesReducer.js";
import { Alert } from "@material-ui/lab";
import { useParams } from "react-router-dom";
import GeneraleSettings from "./components/GeneraleSettings";
import ListingStructureSettings from "./components/ListingStructureSettings";
import EmailsProviderSettings from "./components/EmailsProviderSettings";
import Features from "./components/features/index.js";
import Prompts from "./components/Prompts/index.js";
import SettingsPopUp from "./components/Settings";
import { getTabNumber, updateTabNumber } from "../../../helpers/functions.js";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TransitionLeft = (props) => {
  return <Slide {...props} direction="left" />;
};

const LabTabs = ({ directory }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("1");
  const { isLoading } = useSelector((state) => state.entities.directories);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    updateTabNumber("directory_settings_tab", newValue);
  };

  useEffect(() => {
    const tabNumber = getTabNumber("directory_settings_tab");
    if (tabNumber) setValue(tabNumber.toString());
  }, []);

  return (
    <Box
      sx={{ typography: "body1" }}
      style={{ position: "relative", height: "100%" }}
    >
      <TabContext value={value} style={{ height: "100%" }}>
        <Box style={{ display: "flex" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              orientation={"vertical"}
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab variant="h6" label="Details" value="1" />
              <Tab variant="h6" label="Listing Structure" value="2" />
              <Tab variant="h6" label="Emails Provider" value="3" />
              <Tab variant="h6" label="Settings" value="4" />
              <Tab variant="h6" label="Features" value="5" />
              <Tab variant="h6" label="Prompts" value="6" />
            </TabList>
          </Box>

          <Box style={{ width: "100%" }}>
            <TabPanel value="1">
              <GeneraleSettings
                directory={directory}
                dispatch={dispatch}
                isLoading={isLoading}
              />
            </TabPanel>
            <TabPanel value="2">
              <ListingStructureSettings
                directory={directory}
                dispatch={dispatch}
                isLoading={isLoading}
              />
            </TabPanel>
            <TabPanel value="3">
              <EmailsProviderSettings
                directory={directory}
                dispatch={dispatch}
                isLoading={isLoading}
              />
            </TabPanel>
            <TabPanel value="4">
              <SettingsPopUp
                directory={directory}
                dispatch={dispatch}
                isLoading={isLoading}
              />
            </TabPanel>
            <TabPanel value="5">
              <Features
                directory={directory}
                dispatch={dispatch}
                isLoading={isLoading}
              />
            </TabPanel>
            <TabPanel value="6">
              <Prompts
                directory={directory}
                dispatch={dispatch}
                isLoading={isLoading}
              />
            </TabPanel>
          </Box>
        </Box>
      </TabContext>
    </Box>
  );
};

const Settings = () => {
  const [directory, setDirectory] = useState(null);
  const [notification, setNotification] = useState(false);
  let { id } = useParams();
  const { success, error, list } = useSelector(
    (state) => state.entities.directories
  );
  const dispatch = useDispatch();
  const handleNotificationClose = () => {
    setNotification(false);
    dispatch(resetAll());
  };

  useEffect(() => {
    if (success || error) {
      setNotification(true);
    }
  }, [success, error]);

  useEffect(() => {
    if (id && list.length) {
      const found = list.find((item) => item._id === id);
      if (!found) dispatch(getOneDirectory(id));
      else setDirectory(found);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, list]);

  return (
    <Fragment>
      {notification === true && (
        <Snackbar
          open={notification}
          autoHideDuration={5000}
          onClose={handleNotificationClose}
          TransitionComponent={TransitionLeft}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleNotificationClose}
            severity={success ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {success || error}
          </Alert>
        </Snackbar>
      )}
      <Helmet title="Directory Settings" />
      <Typography variant="h3" gutterBottom display="inline">
        Directory Settings {directory && `: ${directory.name}`}
      </Typography>
      <Divider my={6} />
      <Grid
        container
        spacing={6}
        style={{ height: "96%", position: "relative" }}
      >
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent style={{ height: "100%" }}>
              {directory && <LabTabs directory={directory} />}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Settings;
