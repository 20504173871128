import React from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import { Button as MuiButton, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { getUserByToken, signOut } from "../../redux/reducers/authReducer";
import { updateToken } from "../../helpers/functions";
import { useHistory } from "react-router-dom";

const Spacer = styled.div(spacing);
const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function Page404() {
  const dispatch = useDispatch();
  const history = useHistory();
  let { error } = useSelector((state) => state.entities.submissions);
  let { admin_area } = useSelector((state) => state.entities.auth.data);

  const handleClick = () => {
    if (admin_area && admin_area.token) {
      updateToken(admin_area.token);
      dispatch(getUserByToken());
      setTimeout(() => {
        history.push("/users");
      }, 3000);
    } else dispatch(signOut());
  };

  return (
    <Wrapper>
      <Helmet title="400  No Campaigns" />
      <center>
        <img src="/static/img/logo/noProject.svg" alt="notDevice" width="40%" />
      </center>
      <Spacer mb={18} />
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Not Working.
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        {error}
      </Typography>

      <Button
        style={{ backgroundColor: "#6C63FF" }}
        variant="contained"
        color="secondary"
        mt={2}
        onClick={handleClick}
      >
        Return to website
      </Button>
    </Wrapper>
  );
}

export default Page404;
