import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { Close as CloseIcon } from "@material-ui/icons";
import * as global from "../../../constants/index";

import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Chip,
  CardContent,
  CircularProgress,
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import ChipList from "../../customComponent/Chips";
import { makeStyles } from "@material-ui/core/styles";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { useSelector, useDispatch } from "react-redux";
import {
  addMessage,
  updateMessage,
} from "../../../redux/reducers/messagesReducer";
import { getSpinFromText } from "../../../redux/reducers/settingsReducer";
import { syncChannelFilter } from "../../../helpers/functions";
import {
  getAllProfiles,
  getProfiles,
} from "../../../redux/reducers/profilesReducer";
const Alert = styled(MuiAlert)(spacing);

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },
  dialogWrapper: {
    position: "absolute",
    maxWidth: "40rem",
    // top: theme.spacing(5)
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },

  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  h5: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  controlInput: {
    backgroundColor: "#F9F9FC",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
  },
  shrink: {
    display: "none",
  },
  inputSelect: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    backgroundColor: "#f5f6fa",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
}));

const Popup = ({ open, setOpen, handleClose, message }) => {
  const classes = useStyles();
  const [profilesList, setProfilesList] = useState([]);
  const [selectProfiles, setSelectProfiles] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [channelId, setChannelId] = useState(syncChannelFilter());
  const [selectApproach, setSelectedApproach] = useState(null);
  const [selectTone, setSelectedTone] = useState(null);
  const [updatedMessage, setUpdatedMessage] = useState(message?.body || "");
  const dispatch = useDispatch();

  const { success, error, isLoading } = useSelector(
    (state) => state.entities.messages
  );
  const profiles = useSelector((state) => state.entities.profiles);
  const spinText = useSelector((state) => state.entities.settings.spinText);
  const { TONE_LIST, APPROACHES_LIST } = useSelector(
    (state) => state.entities.settings.setting
  );

  const initialValues = {
    subject: message ? message.subject : "",
    label: message ? message.label : "",
    type: 1,
    body: updatedMessage,
  };

  useEffect(() => {
    if (message) {
      let MessageProfiles = profiles.list_all.filter((item) =>
        message.profiles.includes(item._id)
      );
      setSelectProfiles(MessageProfiles);
    }

    setUpdatedMessage(message?.body);
    setSelectedApproach(message?.approach);
    setSelectedTone(message?.tone);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    let list = profiles.list_all.filter(
      (item) =>
        (selectApproach === item.approach &&
          selectTone === item.tone &&
          channelId === item.channel) ||
        (!item.approach && !item.tone && channelId === item.channel)
    );
    setProfilesList(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectApproach, selectTone, channelId, profiles.list_all]);

  const validationSchema = Yup.object().shape({
    label: Yup.string().required("Required"),
    subject: Yup.string().required("Required"),
    body: Yup.string().required("Required"),
  });

  const handleSubmit = async (values) => {
    const data = {
      label: values.label,
      subject: values.subject,
      level: values.type,
      body: values.body,
      approach: selectApproach,
      tone: selectTone,
      profiles: selectProfiles,
    };

    if (message) {
      // dispatch update
      let id = message._id;
      dispatch(updateMessage(id, data));
      setOpen(false);

      // get updated profiles list
      dispatch(getAllProfiles());
      dispatch(getProfiles());
    } else {
      //dispatch add
      dispatch(addMessage(data));
      setOpen(false);
    }
  };

  const getSpinText = (text) => {
    dispatch(getSpinFromText({ text }));
  };

  const addTags = (e, tag, values) => {
    e.preventDefault();
    // initialValues.message += ` ${} `;
    setUpdatedMessage(values.body + global.MESSAGES_TAGS[tag]);
    // add focus
  };

  const addSpin = (e, values) => {
    e.preventDefault();
    setUpdatedMessage(values.body + "{word1|word2|word3|word4|word5}");
    // add focus
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogWrapper }}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Box style={{ display: "flex", float: "right" }}>
          <Button
            style={{ color: "#868695" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </Box>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
          }) => (
            <>
              <CardContent>
                {success && (
                  <Alert severity="success" my={3}>
                    New Message has been created successfully!
                  </Alert>
                )}
                {error && (
                  <Alert severity="error" my={3}>
                    Error: {error}
                  </Alert>
                )}
                {isLoading ? (
                  <Box display="flex" justifyContent="center" my={6}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <Grid item md={12} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Label*:
                        </Typography>
                        <TextField
                          InputProps={{ classes: { input: classes.input } }}
                          inputlabelprops={{ shrink: false }}
                          name="label"
                          placeholder="Label"
                          value={values.label}
                          error={Boolean(touched.label && errors.label)}
                          fullWidth
                          helpertext={touched.label && errors.label}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>

                      <Grid item md={6} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Approach*:
                        </Typography>
                        <ChipList
                          list={APPROACHES_LIST || []}
                          selected={selectApproach}
                          setSelected={setSelectedApproach}
                        />
                      </Grid>

                      <Grid item md={6} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Tone*:
                        </Typography>
                        <ChipList
                          list={TONE_LIST || []}
                          selected={selectTone}
                          setSelected={setSelectedTone}
                        />
                      </Grid>

                      <Grid item md={6} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Subject*:
                        </Typography>
                        <TextField
                          InputProps={{ classes: { input: classes.input } }}
                          inputlabelprops={{ shrink: false }}
                          name="subject"
                          placeholder="Subject"
                          value={values.subject}
                          error={Boolean(touched.subject && errors.subject)}
                          fullWidth
                          helpertext={touched.subject && errors.subject}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>

                      <Grid item md={6} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Type*:
                        </Typography>
                        <FormControl fullWidth>
                          <InputLabel
                            // shrink={false}
                            classes={{ shrink: classes.shrink }}
                            style={{ margin: "-7px 10px", zIndex: 1 }}
                            id="demo-simple-select-label"
                          >
                            Access Level
                          </InputLabel>
                          <Select
                            classes={{ root: classes.inputSelect }}
                            inputlabelprops={{ shrink: false }}
                            MenuProps={{
                              classes: { paper: classes.paperSelect },
                              variant: "menu",
                            }}
                            // defaultValue={1}
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.type}
                            error={Boolean(touched.project && errors.project)}
                            fullWidth
                            helpertext={touched.project && errors.project}
                            onBlur={handleBlur}
                            onChange={handleChange("type")}
                            my={2}
                          >
                            <MenuItem value={1}>First contact</MenuItem>
                            <MenuItem value={2}>Follow up</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={12} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Message*:
                        </Typography>
                        <TextField
                          inputlabelprops={{ shrink: false }}
                          placeholder="Message"
                          InputProps={{
                            classes: {
                              input: classes.inputTextarea,
                              root: classes.controlInput,
                            },
                          }}
                          name="message"
                          value={values.body}
                          error={Boolean(touched.body && errors.body)}
                          fullWidth
                          helpertext={touched.body && errors.body}
                          onBlur={handleBlur}
                          onChange={handleChange("body")}
                          variant="outlined"
                          type="text"
                          multiline
                          rows={4}
                          rowsMax={6}
                          my={2}
                        />
                        {spinText && (
                          <Typography
                            variant="h5"
                            style={{
                              backgroundColor: "#f0ffff",
                              marginTop: "10px",
                            }}
                            classes={{
                              h5: classes.h5,
                            }}
                          >
                            {spinText}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      md={12}
                      style={{ padding: "9px 0px", marginTop: "10px" }}
                    >
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Aliases:
                      </Typography>
                      <ChipList
                        list={profilesList}
                        selected={selectProfiles}
                        label={"url"}
                        value={"email"}
                        setSelected={setSelectProfiles}
                        multiple={true}
                        // disabled={false}
                      />
                    </Grid>

                    <Box>
                      {Object.keys(global.MESSAGES_TAGS).map((key, index) => {
                        return (
                          <Chip
                            key={index}
                            style={{
                              marginRight: "5px",
                              marginTop: "10px",
                              cursor: "pointer",
                            }}
                            label={key}
                            onClick={(e) => {
                              addTags(e, key, values);
                            }}
                          />
                        );
                      })}
                    </Box>
                    <Box>
                      <Chip
                        style={{
                          marginRight: "5px",
                          marginTop: "10px",
                          cursor: "pointer",
                        }}
                        label={"Add Spin"}
                        onClick={(e) => {
                          addSpin(e, values);
                        }}
                      />
                    </Box>

                    <Box
                      style={{
                        float: "right",
                        margin: "40px 0",
                      }}
                    >
                      <Button
                        style={{ marginRight: "15px", marginTop: "10px" }}
                        // type="outlined"
                        variant="outlined"
                        color="primary"
                        mt={3}
                        onClick={() => {
                          getSpinText(values.body);
                        }}
                      >
                        Spin
                      </Button>
                      <Button
                        style={{ marginRight: "15px", marginTop: "10px" }}
                        // type="outlined"
                        variant="outlined"
                        color="primary"
                        mt={3}
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{
                          marginTop: "10px",
                          backgroundColor: "#6a74c9",
                          color: "white",
                        }}
                        type="submit"
                        variant="contained"
                        mt={4}
                      >
                        {message ? "Update" : "Add New"}
                      </Button>
                    </Box>
                  </form>
                )}
              </CardContent>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
