import React, { useState, useEffect, Fragment, forwardRef } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Snackbar,
  Slide,
  FormControl,
} from "@material-ui/core";
import AlertDialog from "../customComponent/AlertDialog";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import Loading from "../customComponent/Loading";
import { Reload } from "../customComponent/Reload";
import {
  getDateTimeFormate,
  getDomainFromURL,
  getNumberPerPage,
  updateNbrPerPage,
} from "../../helpers/functions";
import { TableName } from "../customComponent/TableName";
import { Layout } from "react-feather";
import { ROWS_PER_PAGE_OPTIONS } from "../../constants";
import MySelect from "../customComponent/MySelect";
import {
  deleteConversion,
  getConversions,
  resetAll,
} from "../../redux/reducers/conversionsReducer";
import DatePicker from "../customComponent/DateRangePicker";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "action", alignment: "left", label: "Action", sort: false },
  { id: "createdAt", alignment: "left", label: "Created At", sort: true },
  { id: "organization", alignment: "left", label: "Organization", sort: false },
  { id: "alias", alignment: "center", label: "Alias", sort: true },
  { id: "actions", alignment: "right", label: "Actions", sort: false },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontSize: "16px",
            }}
          >
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let EnhancedTableToolbar = () => {
  return (
    <Toolbar>
      <TableName title="Events List" Component={Layout} />
    </Toolbar>
  );
};

function EnhancedTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("customer");
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState([]);
  const [conversionToDelete, setConversionToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();

  const {
    list,
    page: serverPage,
    total_records,
    maxPage,
    selectedChannel,
    selectedProfile,
    selectedDate,
  } = useSelector((state) => state.entities.conversions);
  const rowsPerPage = getNumberPerPage();

  useEffect(() => {
    if (maxPage === 0) setPage(0);
  }, [maxPage]);

  useEffect(() => {
    setPage(serverPage - 1);
  }, [serverPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > maxPage - 1) {
      dispatch(
        getConversions({
          channel: selectedChannel,
          profile: selectedProfile,
          date: selectedDate,
          page: newPage + 1,
        })
      );
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const per_page = event.target.value;
    dispatch(
      getConversions({
        channel: selectedChannel,
        profile: selectedProfile,
        date: selectedDate,
        per_page,
      })
    );
    updateNbrPerPage(per_page);
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(list, list.length - page * rowsPerPage);

  const handleDeleteConversion = (conversion_id) => {
    setConversionToDelete(conversion_id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogStatus = (open) => {
    if (!open) {
      setConversionToDelete(null);
    }
    setDeleteDialogOpen(open);
  };

  return (
    <Box>
      {conversionToDelete && (
        <AlertDialog
          title="Confirm Removing the Conversion?!"
          description="Removing the Conversion will remove also any Stats calculated in aliases."
          id={conversionToDelete}
          setOpen={handleDeleteDialogStatus}
          open={deleteDialogOpen}
          dispatchOnAgree={deleteConversion}
        />
      )}
      <Paper>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={list.length}
            />
            <TableBody>
              {stableSort(list, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell
                        style={{
                          fontSize: "14px",
                        }}
                        align="left"
                      >
                        {row.action}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                        }}
                        align="left"
                      >
                        {getDateTimeFormate(row.createdAt)}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                        }}
                        align="left"
                      >
                        {row.channel?.name}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                        }}
                        align="left"
                      >
                        {getDomainFromURL(row.profile?.url)}
                      </TableCell>

                      <TableCell padding="none" align="right">
                        <Box mr={2}>
                          <Tooltip title="Delete Conversion">
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              onClick={() => handleDeleteConversion(row._id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={total_records}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

function ConversionList() {
  const [notification, setNotification] = useState(false);
  const {
    error,
    success,
    isLoading,
    selectedDate,
    selectedChannel,
    selectedProfile,
  } = useSelector((state) => state.entities.conversions);
  const { list_all: profiles } = useSelector(
    (state) => state.entities.profiles
  );
  const [profiles_list, setProfilesList] = useState(profiles);

  const dispatch = useDispatch();
  useEffect(() => {
    if (success || error) {
      setNotification(true);
    }
  }, [success, error]);

  useEffect(() => {
    const list = profiles.filter(
      (item) =>
        !selectedChannel ||
        selectedChannel === "all" ||
        selectedChannel === item.channel
    );
    setProfilesList(list);
  }, [profiles, selectedChannel]);

  const handleNotificationClose = () => {
    setNotification(false);
    dispatch(resetAll());
  };

  const loadConversions = () => {
    dispatch(
      getConversions({
        channel: selectedChannel,
        profile: selectedProfile,
        date: selectedDate,
      })
    );
  };
  return (
    <Fragment>
      {(success || error) && (
        <Snackbar
          open={notification}
          autoHideDuration={5000}
          onClose={handleNotificationClose}
          TransitionComponent={TransitionLeft}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleNotificationClose}
            severity={success ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {success || error}
          </Alert>
        </Snackbar>
      )}
      <Helmet title="Events" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Events
          </Typography>
        </Grid>
        <Grid item>
          <Box display="flex" align="center">
            <Reload loading={isLoading} action={loadConversions} />
            <FormControl style={{ marginRight: ".5rem" }}>
              <DatePicker
                data={{ channel: selectedChannel, profile: selectedProfile }}
                toDispatch={getConversions}
              />
            </FormControl>
            <FormControl style={{ marginRight: ".5rem" }}>
              <MySelect
                selected={selectedProfile}
                defaultLabel="All Aliases"
                defaultValue="all"
                list={profiles_list}
                label="url"
                value="_id"
                valueHolder="profile"
                data={{ channel: selectedChannel, date: selectedDate }}
                toDispatch={getConversions}
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {isLoading ? <Loading /> : <EnhancedTable />}
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default ConversionList;
