import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert as MuiAlert } from "@material-ui/lab";
// import { spacing } from "@material-ui/system";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import Chip from "../../customComponent/Chips";

import {
  addChannel,
  updateChannel,
} from "../../../redux/reducers/channelReducer";

const Alert = styled(MuiAlert)(spacing);

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },

  dialogWrapper: {
    position: "absolute",
    maxWidth: "50rem",
    // top: theme.spacing(5)
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  startICon: {
    margin: 0,
  },
  chips: {
    height: "20px",
    borderRadius: "0px",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  ButtonApprove: {
    backgroundColor: "#23CC94",
    color: "white",
    width: "6rem",
    margin: "12px 39px",
  },
  description: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  h5: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
    // backgroundColor: "white"
  },
  shrink: {
    display: "none",
  },
  inputSelect: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    backgroundColor: "#f5f6fa",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
}));

const validationSchema = Yup.object().shape({
  number: Yup.number().required("Organization Number is required.."),
  name: Yup.string().required("Organization name is required"),
  costPercentage: Yup.number(),
});

const Popup = ({ open, setOpen, channel }) => {
  const [selectProjects, setSelectProjects] = useState([]);
  const dispatch = useDispatch();

  let projects = useSelector((state) => state.entities.projects);

  useEffect(() => {
    if (channel) {
      // in update
      let channelProjects = channel.projects.map((project) => ({
        _id: project._id,
        name: project.name,
      }));

      setSelectProjects(channelProjects);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    number: channel ? channel.number : "",
    name: channel ? channel.name : "",
    costPercentage: channel ? channel.costPercentage : "",
    selectProjects,
  };

  const classes = useStyles();

  const handleSubmit = ({ number, name, costPercentage }) => {
    let listOfProjects = selectProjects.map((item) => item._id);

    const data = {
      number,
      name,
      costPercentage,
      projects: listOfProjects,
    };

    if (channel) {
      // dispatch update
      dispatch(updateChannel(channel._id, data));
      setOpen(false);
    } else {
      // dispatch add
      dispatch(addChannel(data));
      setOpen(false);
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogWrapper }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h6" gutterBottom>
          {channel ? "Update Organization" : "New Organization"}
        </Typography>
        <div style={{ display: "flex", float: "right" }}>
          <Button
            style={{ color: "#868695", marginTop: "-40px" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            status,
          }) => (
            <>
              <CardContent>
                <Typography variant="body2" gutterBottom>
                  {channel ? "Update" : "Create a new"} organization and start
                  connecting with business across the country
                </Typography>
                {projects.success && (
                  <Alert severity="success" my={3}>
                    {channel
                      ? "Organization has been updated successfully!"
                      : "New organization has been created successfully!"}
                  </Alert>
                )}
                {projects.error && (
                  <Alert severity="error" my={3}>
                    Error: {projects.error}
                  </Alert>
                )}

                <form onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid item md={12} style={{ padding: "9px 0" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Organization Number*:
                      </Typography>
                      <TextField
                        name="number"
                        placeholder="Organization Number"
                        InputProps={{ classes: { input: classes.input } }}
                        inputlabelprops={{ shrink: false }}
                        value={values.number}
                        error={Boolean(touched.number && errors.number)}
                        fullWidth
                        helpertext={touched.number && errors.number}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>

                    <Grid item md={12} style={{ padding: "9px 0" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Name*:
                      </Typography>
                      <TextField
                        name="name"
                        placeholder="Name"
                        InputProps={{ classes: { input: classes.input } }}
                        inputlabelprops={{ shrink: false }}
                        value={values.name}
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helpertext={touched.name && errors.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>

                    <Grid item md={12} style={{ padding: "9px 0" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Cost Percentage:
                      </Typography>
                      <TextField
                        name="costPercentage"
                        placeholder="Organization Cost Percentage"
                        InputProps={{ classes: { input: classes.input } }}
                        inputlabelprops={{ shrink: false }}
                        value={values.costPercentage}
                        error={Boolean(
                          touched.costPercentage && errors.costPercentage
                        )}
                        fullWidth
                        helpertext={
                          touched.costPercentage && errors.costPercentage
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>

                    <Grid item md={12} style={{ padding: "9px 0px" }}>
                      <Chip
                        list={projects.allProjects}
                        selected={values.selectProjects}
                        placeholder={"Campaigns"}
                        label={"name"}
                        setSelected={setSelectProjects}
                        multiple={true}
                      />
                    </Grid>
                  </Grid>

                  <Button
                    style={{
                      marginTop: "10px",
                      backgroundColor: "#6a74c9",
                      color: "white",
                    }}
                    type="submit"
                    variant="contained"
                    mt={4}
                  >
                    {channel ? "Update" : "Add New"}
                  </Button>
                </form>
              </CardContent>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
