import React, { useState, useEffect, Fragment, forwardRef } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import {
  getProfiles,
  updateProfile,
} from "../../redux/reducers/profilesReducer";
import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Snackbar,
  Slide,
  FormControlLabel,
  Switch,
  FormControl,
  TextField,
} from "@material-ui/core";
import Popup from "./components/Popup";
import PopupStats from "./components/PopupStats";
import ProfileChare from "./components/profileChart";
import { resetAll, deleteProfile } from "../../redux/reducers/profilesReducer";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  BarChartOutlined as StatsIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import Loading from "../customComponent/Loading";
import { Reload } from "../customComponent/Reload";
import {
  getDateFormate,
  getDomainFromURL,
  getNumberPerPage,
  updateNbrPerPage,
} from "../../helpers/functions";
import { TableName } from "../customComponent/TableName";
import { Copy, Layout } from "react-feather";
import { ROWS_PER_PAGE_OPTIONS } from "../../constants";
import NumberFormat from "react-number-format";
import MySelect from "../customComponent/MySelect";
import DatePicker from "../customComponent/DateRangePicker";
import ArchiveDialog from "../customComponent/AlertDialog";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "status", alignment: "left", label: "Status", sort: false },
  { id: "createdAt", alignment: "left", label: "Created At", sort: true },
  // { id: "email", alignment: "left", label: "Email", sort: true },
  // { id: "sender", alignment: "left", label: "Sender", sort: true },
  // { id: "phone", alignment: "left", label: "Phone", sort: true },
  // { id: "address", alignment: "left", label: "Address", sort: true },
  { id: "url", alignment: "left", label: "URL", sort: true },
  { id: "issues", alignment: "left", label: "Issues", sort: false },
  { id: "events", alignment: "left", label: "Events", sort: false },
  // { id: "city", alignment: "left", label: "City", sort: true },
  // { id: "state", alignment: "left", label: "State", sort: true },
  // { id: "zip", alignment: "left", label: "Zip", sort: true },
  { id: "message", alignment: "center", label: "Message", sort: true },
  { id: "actions", alignment: "right", label: "Actions", sort: false },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontSize: "16px",
            }}
          >
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let EnhancedTableToolbar = () => {
  return (
    <Toolbar>
      <TableName title="Aliases List" Component={Layout} />
    </Toolbar>
  );
};

function EnhancedTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("customer");
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const rowsPerPage = getNumberPerPage();
  // this needed for edit dialog
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [profileToEdit, setProfileToEdit] = useState(null);
  const [statsToEdit, setStatsToShow] = useState(null);
  const [statsDialogOpen, setStatsDialogOpen] = useState(false);
  const [profileToDuplicate, setProfileToDuplicate] = useState(null);
  const [profileToDelete, setProfileToDelete] = useState(false);
  const [deleteProfileOpen, setDeleteProfileOpen] = useState(false);

  const {
    page: serverPage,
    total_records,
    maxPage,
    list: ProfilesList,
    selectedChannel,
    selectedProject,
    selectedApproach,
    selectedTone,
    selectedDate,
  } = useSelector((state) => state.entities.profiles);

  useEffect(() => {
    setPage(serverPage - 1);
  }, [serverPage]);

  useEffect(() => {
    if (maxPage === 0) setPage(0);
  }, [maxPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > maxPage - 1) {
      dispatch(
        getProfiles({
          channel: selectedChannel,
          project: selectedProject,
          approach: selectedApproach,
          tone: selectedTone,
          date: selectedDate,
          page: newPage + 1,
        })
      );
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let per_page = event.target.value;
    updateNbrPerPage(per_page);
    setPage(0);
    dispatch(
      getProfiles({
        channel: selectedChannel,
        project: selectedProject,
        approach: selectedApproach,
        tone: selectedTone,
        date: selectedDate,
        per_page,
      })
    );
  };

  const handleEditProject = (profile) => {
    setProfileToEdit(profile);
    dispatch(resetAll());
    setEditDialogOpen(true);
  };

  const handleDuplicateProfile = (profile) => {
    setProfileToDuplicate(true);
    setProfileToEdit(profile);
    dispatch(resetAll());
    setEditDialogOpen(true);
  };

  const handleEditDialogStatus = (open) => {
    if (!open) {
      setProfileToEdit(null);
      setProfileToDuplicate(false);
    }
    setEditDialogOpen(open);
  };

  const handleShowDialogStats = (open) => {
    if (!open) {
      setStatsToShow(null);
    }
    setStatsDialogOpen(open);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(ProfilesList, ProfilesList.length - page * rowsPerPage);

  const handleProjectStatus = (profile_id, status) => {
    dispatch(updateProfile(profile_id, status));
  };

  const handleDeleteProfile = (profile) => {
    setDeleteProfileOpen(true);
    setProfileToDelete(profile);
  };

  return (
    <Box>
      {profileToDelete && deleteProfileOpen && (
        <ArchiveDialog
          title="Confirm Deleting Profile?!"
          description={`This Profile "${profileToDelete.email}" will force deleting from your database`}
          id={profileToDelete._id}
          setOpen={setDeleteProfileOpen}
          open={deleteProfileOpen}
          dispatchOnAgree={deleteProfile}
        />
      )}
      {profileToEdit && (
        <Popup
          open={editDialogOpen}
          setOpen={handleEditDialogStatus}
          profile={profileToEdit}
          duplicate={!!profileToDuplicate}
        />
      )}
      {statsToEdit && (
        <PopupStats
          open={statsDialogOpen}
          setOpen={handleShowDialogStats}
          stats={statsToEdit}
        />
      )}
      <Paper>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={ProfilesList.length}
            />
            <TableBody>
              {stableSort(ProfilesList, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const { status } = row;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell style={{ fontSize: "14px" }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row.status}
                              onChange={(event) =>
                                handleProjectStatus(row._id, {
                                  status: !status,
                                })
                              }
                            />
                          }
                        />
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "14px" }}
                        align="left"
                        onClick={() => handleEditProject(row)}
                      >
                        {getDateFormate(row.createdAt)}
                      </TableCell>
                      <TableCell style={{ fontSize: "14px" }} align="left">
                        <a
                          href={row.url}
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: "none" }}
                        >
                          {getDomainFromURL(row.url)}
                        </a>
                      </TableCell>
                      {/* <TableCell style={{ fontSize: "14px" }} align="left">
                        <NumberFormat
                          value={
                            row.stats ? row.stats["Submissions Success"] : 0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                        - ({row.stats ? row.stats["Submissions Success"] : 0}%)
                      </TableCell> */}
                      <TableCell style={{ fontSize: "14px" }} align="left">
                        <NumberFormat
                          value={row.spams ? row.spams.length : 0}
                          displayType={"text"}
                        />
                      </TableCell>
                      <TableCell style={{ fontSize: "14px" }} align="left">
                        {row.tracking && Object.keys(row.tracking).length
                          ? Object.keys(row.tracking).map((tracked, index) => {
                              return (
                                <Box key={index}>
                                  {index !== 0 && <Divider />}
                                  <NumberFormat
                                    value={row.tracking[tracked]}
                                    displayType={"text"}
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    prefix={tracked + ": "}
                                  />
                                </Box>
                              );
                            })
                          : "-"}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          color: !row.messages[0]?.label ? "red" : "blue",
                        }}
                        align="left"
                      >
                        {row.messages[0]?.label || "*Not Assigned"}
                      </TableCell>
                      <TableCell padding="none" align="right">
                        <Box mr={2}>
                          <Tooltip title="Show Stats">
                            <IconButton
                              aria-label="details"
                              color="primary"
                              onClick={() => {
                                handleShowDialogStats(true);
                                setStatsToShow(row.stats);
                              }}
                            >
                              <StatsIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Duplicate Alias">
                            <IconButton
                              aria-label="details"
                              color="primary"
                              onClick={() => handleDuplicateProfile(row)}
                            >
                              <Copy />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Update Alias">
                            <IconButton
                              aria-label="details"
                              color="primary"
                              onClick={() => handleEditProject(row)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete Alias">
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              onClick={() => handleDeleteProfile(row)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={total_records}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

function ProfileList() {
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState(false);
  const {
    error,
    success,
    isLoading,
    selectedTone,
    selectedApproach,
    selectedChannel,
    selectedMessage,
    selectedProject,
    selectedDate,
    // randomStats,
  } = useSelector((state) => state.entities.profiles);
  const { allProjects: projects } = useSelector(
    (state) => state.entities.projects
  );
  const { all: messages } = useSelector((state) => state.entities.messages);
  const { TONE_LIST, APPROACHES_LIST } = useSelector(
    (state) => state.entities.settings.setting
  );

  const [projects_list, setProjectsList] = useState(projects);
  const [messages_list, setMessagesList] = useState(messages);

  useEffect(() => {
    const list = projects.filter(
      (item) =>
        !selectedChannel ||
        selectedChannel === "all" ||
        selectedChannel === item.channel
    );
    setProjectsList(list);
  }, [projects, selectedChannel]);

  useEffect(() => {
    const project = projects.find((item) => item._id === selectedProject);
    const list = messages.filter(
      (item) =>
        (!selectedChannel ||
          selectedChannel === "all" ||
          selectedChannel === item.channel) &&
        (!selectedProject ||
          selectedProject === "all" ||
          project?.messages?.includes(item._id.toString()))
    );
    setMessagesList(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, selectedProject, selectedChannel]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (success || error) {
      setNotification(true);
      // dispatch(getUsers());
    }
  }, [success, error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleNotificationClose = () => {
    setNotification(false);
    dispatch(resetAll());
  };

  const loadProfiles = () => {
    dispatch(
      getProfiles({
        tone: selectedTone,
        approach: selectedApproach,
        channel: selectedChannel,
        project: selectedProject,
        date: selectedDate,
      })
    );
  };
  return (
    <Fragment>
      {(success || error) && (
        <Snackbar
          open={notification}
          autoHideDuration={5000}
          onClose={handleNotificationClose}
          TransitionComponent={TransitionLeft}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleNotificationClose}
            severity={success ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {success || error}
          </Alert>
        </Snackbar>
      )}
      <Helmet title="Aliases" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Aliases
          </Typography>
        </Grid>
        <Grid item>
          <Box display="flex" align="center">
            <Reload loading={isLoading} action={loadProfiles} />
            <FormControl style={{ marginRight: ".5rem" }}>
              <DatePicker
                data={{
                  project: selectedProject,
                  tone: selectedTone,
                  approach: selectedApproach,
                  channel: selectedChannel,
                }}
                toDispatch={getProfiles}
              />
            </FormControl>
            <FormControl style={{ marginRight: ".5rem" }}>
              <MySelect
                selected={selectedProject}
                defaultLabel="All Campaigns"
                defaultValue="all"
                list={projects_list}
                label="name"
                value="_id"
                valueHolder="project"
                data={{
                  channel: selectedChannel,
                  message: selectedMessage,
                  tone: selectedTone,
                  approach: selectedApproach,
                  date: selectedDate,
                }}
                toDispatch={getProfiles}
              />
            </FormControl>
            <FormControl style={{ marginRight: ".5rem" }}>
              <MySelect
                selected={selectedMessage}
                defaultLabel="All Messages"
                defaultValue="all"
                list={messages_list}
                label="label"
                value="_id"
                valueHolder="message"
                data={{
                  channel: selectedChannel,
                  project: selectedProject,
                  tone: selectedTone,
                  approach: selectedApproach,
                  date: selectedDate,
                }}
                toDispatch={getProfiles}
              />
            </FormControl>
            <FormControl style={{ marginRight: ".5rem" }}>
              <MySelect
                selected={selectedApproach}
                defaultLabel="All Approaches"
                defaultValue="all"
                list={APPROACHES_LIST || []}
                valueHolder="approach"
                data={{
                  channel: selectedChannel,
                  project: selectedProject,
                  message: selectedMessage,
                  tone: selectedTone,
                  date: selectedDate,
                }}
                toDispatch={getProfiles}
              />
            </FormControl>
            <FormControl style={{ marginRight: ".5rem" }}>
              <MySelect
                selected={selectedTone}
                defaultLabel="All Tones"
                defaultValue="all"
                list={TONE_LIST || []}
                valueHolder="tone"
                data={{
                  channel: selectedChannel,
                  project: selectedProject,
                  message: selectedMessage,
                  approach: selectedApproach,
                  date: selectedDate,
                }}
                toDispatch={getProfiles}
              />
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              style={{ backgroundColor: "#6a74c9", color: "white" }}
            >
              <AddIcon />
              Add New
            </Button>
            {open && (
              <Popup open={open} setOpen={setOpen} handleClose={handleClose} />
            )}
          </Box>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {isLoading ? <Loading /> : <EnhancedTable />}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <></>
        ) : (
          <>
            <Divider my={6} /> <ProfileChare />
          </>
        )}
      </Grid>
    </Fragment>
  );
}

export default ProfileList;
