import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import Gravatar from "react-gravatar";
import ProfileEdit from "./../pages/members/ProfileUser";
import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  Snackbar,
  Slide,
} from "@material-ui/core";
import { resetAll, signOut } from "../redux/reducers/authReducer";
import { Alert } from "@material-ui/lab";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

function UserDropdown() {
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [openUserEdit, setOpenUserEdit] = useState(false);
  const [notification, setNotification] = useState(false);
  const { user, password_change } = useSelector(
    (state) => state.entities.auth.data
  );
  const { success, error } = useSelector((state) => state.entities.auth);

  const dispatch = useDispatch();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleOpenUserEdit = () => {
    setOpenUserEdit(true);
  };

  const handleCloseUserEdit = () => {
    setOpenUserEdit(false);
  };

  useEffect(() => {
    if (error || success) setNotification(true);
    if (!password_change && user) setOpenUserEdit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, success, user]);

  const handleNotificationClose = () => {
    dispatch(resetAll());
    setNotification(false);
  };

  return (
    <Fragment>
      {openUserEdit && (
        <ProfileEdit
          open={openUserEdit}
          setOpen={setOpenUserEdit}
          handleClose={handleCloseUserEdit}
        />
      )}
      {notification && (
        <Snackbar
          open={notification}
          autoHideDuration={5000}
          onClose={handleNotificationClose}
          TransitionComponent={TransitionLeft}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleNotificationClose}
            severity={success ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {success || error}
          </Alert>
        </Snackbar>
      )}

      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
        >
          <Gravatar
            email={user ? user.email : ""}
            size={40}
            mask="rounded"
            default="monsterid"
            protocol="https://"
            style={{ borderRadius: "50%" }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
        style={{ marginTop: "40px" }}
      >
        <MenuItem onClick={handleOpenUserEdit}>Update Password</MenuItem>
        <MenuItem onClick={() => dispatch(signOut())}>Sign out</MenuItem>
      </Menu>
    </Fragment>
  );
}

export default UserDropdown;
