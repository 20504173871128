import { createSlice } from "@reduxjs/toolkit";
import { getNumberPerPage } from "../../helpers/functions";
import { apiCallBegan } from "../actions/apiActions";
const url = "conversions";

const slice = createSlice({
  name: "conversions",
  initialState: {
    list: [],
    events_list: [],
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
    selectedChannel: null,
    selectedProfile: null,
    selectedDate: null,
    event_lead: null,
    page: 0,
    per_page: 0,
    total_pages: 0,
    maxPage: 0,
    total_records: 0,
  },
  reducers: {
    resetAll: (conversions, action) => {
      conversions.isLoading = false;
      conversions.error = null;
      conversions.success = null;
    },
    requestStarted: (conversions, action) => {
      conversions.isLoading = true;
      conversions.error = null;
      conversions.isRequestSent = true;
    },
    requestedFailed: (conversions, action) => {
      conversions.error = action.payload;
      conversions.isLoading = false;
    },
    loadConversionsList: (state, action) => {
      state.page = action.payload.page;
      state.per_page = action.payload.per_page;
      state.total_pages = action.payload.total_pages;
      state.total_records = action.payload.total_records;
      state.list = state.list.concat(action.payload.result);
      state.selectedChannel = action.payload.selectedChannel;
      state.selectedProfile = action.payload.selectedProfile;
      state.selectedDate = action.payload.selectedDate;
      state.maxPage = state.page > state.maxPage ? state.page : state.maxPage;
      state.isLoading = false;
    },
    reLoadConversionsList: (state, action) => {
      state.page = action.payload.page;
      state.per_page = action.payload.per_page;
      state.total_pages = action.payload.total_pages;
      state.total_records = action.payload.total_records;
      state.list = action.payload.result;
      state.selectedChannel = action.payload.selectedChannel;
      state.selectedProfile = action.payload.selectedProfile;
      state.selectedDate = action.payload.selectedDate;
      state.maxPage = 0;
      state.isLoading = false;
    },
    conversionDeleted: (conversions, action) => {
      const index = conversions.list.findIndex(
        (conversion) => conversion._id === action.payload._id
      );
      conversions.list.splice(index, 1);
      conversions.isLoading = false;
      conversions.success = "Event Deleted";
    },
    eventsListLoaded: (conversions, action) => {
      conversions.events_list = action.payload;
      conversions.event_lead = conversions.events_list[0]?.value || null;
    },
    channelChanged: (conversions, action) => {
      conversions.selectedChannel = action.payload;
    },
    eventLeadChanged: (conversions, action) => {
      conversions.event_lead = action.payload;
    },
  },
});

export const {
  resetAll,
  profileAdded,
  requestStarted,
  channelChanged,
  requestedFailed,
  eventLeadChanged,
  loadProfilesList,
  eventsListLoaded,
  conversionDeleted,
  loadConversionsList,
  reLoadConversionsList,
} = slice.actions;
export default slice.reducer;

export const getConversions = (params = {}) => {
  if (!params.per_page) params.per_page = getNumberPerPage();

  delete params.channel;

  if (params.profile === null || params.profile === "all")
    delete params.profile;

  if (params.startDate === null) {
    delete params.date;
  } else {
    if (params.date && params.date.startDate)
      params.startDate = params.date.startDate;
    if (params.date && params.date.endDate)
      params.endDate = params.date.endDate;
    delete params.date;
  }

  //Generate a URL query based on the object we got
  let query = new URLSearchParams(params);

  let resultsReceived = params.page
    ? loadConversionsList.type
    : reLoadConversionsList.type;

  return apiCallBegan({
    url: `${url}?${query}`,
    onStart: requestStarted.type,
    onSuccess: resultsReceived,
    onError: requestedFailed.type,
  });
};

export const deleteConversion = (_id) =>
  apiCallBegan({
    url: `${url}/`,
    method: "DELETE",
    onStart: requestStarted.type,
    onSuccess: conversionDeleted.type,
    onError: requestedFailed.type,
    data: { _id },
  });

export const getEventsList = () =>
  apiCallBegan({
    url: `${url}/events`,
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: eventsListLoaded.type,
    onError: requestedFailed.type,
  });
