import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { getUserByToken } from "../redux/reducers/authReducer";
import { dispatchAdminModules, getToken } from "../helpers/functions";
import LoadingPage from "../pages/auth/LoadingPage";

// For routes that can only be accessed by authenticated users
function LoginGuard({ children }) {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.entities.auth.data);

  let token = getToken();

  if (token && !user) {
    // we have token but no data
    // lets take the user to loading page to wait for the response
    dispatch(getUserByToken());
    return <LoadingPage />;
  }

  if (token && user) {
    if (user.membership_role === "user") {
      // dispatch user store
      return <Redirect to="/members-area" />;
    } else {
      //dispatch admin stuff
      dispatchAdminModules(dispatch);
      return <Redirect to="/dashboard" />;
    }
  }

  return children;
}

export default LoginGuard;
