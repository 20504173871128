import React, { useState, useEffect, Fragment, forwardRef } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  FormControlLabel,
  Switch,
  Snackbar,
  Slide,
  FormControl,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import Popup from "./components/Popup";
import { useDispatch, useSelector } from "react-redux";
import {
  resetAll,
  getMessages,
  deleteMessage,
  updateMessage,
} from "../../redux/reducers/messagesReducer";
import { eventLeadChanged } from "../../redux/reducers/conversionsReducer";
import {
  Add as AddIcon,
  Done as DoneIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  // FilterList as FilterListIcon,
} from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import Loading from "../customComponent/Loading";

import { MessageSquare } from "react-feather";
import { TableName } from "../customComponent/TableName";
import { ROWS_PER_PAGE_OPTIONS } from "../../constants";
import MySelect from "../customComponent/MySelect";
import {
  getShortDateFormate,
  getNumberPerPage,
  updateNbrPerPage,
} from "../../helpers/functions";
import Message from "./components/Message";
import Actions from "./components/Actions";
import ArchiveDialog from "../customComponent/AlertDialog";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "status", alignment: "center", label: "Status", sort: false },
  { id: "date", alignment: "center", label: "Date", sort: true },
  { id: "name", alignment: "center", label: "Name", sort: true },
  { id: "campaign", alignment: "center", label: "Campaign", sort: true },
  { id: "alias", alignment: "center", label: "Alias", sort: true },
  { id: "submits", alignment: "center", label: "Submits", sort: true },
  { id: "traffic", alignment: "center", label: "Traffic", sort: true },
  { id: "tcr", alignment: "center", label: "CTR", sort: false },
  { id: "cvr", alignment: "center", label: "CVR", sort: false },
  // { id: "cpl", alignment: "center", label: "CPL", sort: false },
  { id: "message", alignment: "center", label: "Message", sort: false },
  { id: "winner", alignment: "right", label: "", sort: false },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontSize: "16px",
            }}
          >
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let EnhancedTableToolbar = () => {
  const dispatch = useDispatch();
  const { events_list, event_lead } = useSelector(
    (stats) => stats.entities.conversions
  );
  // const [showList, setShowList] = useState(false);
  // const [selectedEvent, setSelectedEvent] = useState(null);

  return (
    <Toolbar>
      <TableName title="Messages List" Component={MessageSquare} />
      <Spacer />
      <Tooltip title="Choose Lead">
        <FormControl style={{ marginRight: "4rem" }}>
          <MySelect
            selected={event_lead}
            defaultLabel="Events"
            defaultValue={event_lead || "all"}
            list={events_list}
            valueHolder="project"
            label="label"
            value="value"
            func={(event) => {
              dispatch(eventLeadChanged(event));
            }}
            dispatch={eventLeadChanged}
          />
        </FormControl>
      </Tooltip>
    </Toolbar>
  );
};

function EnhancedTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("customer");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [messageToEdit, setMessageToEdit] = useState(null);
  const [messageToDelete, setMessageToDelete] = useState(false);
  const [deleteMessageOpen, setDeleteMessageOpen] = useState(false);

  const {
    list,
    maxPage,
    page: serverPage,
    total_records,
    selectedTone,
    selectedApproach,
    selectedProject,
    selectedChannel,
    selectedDate,
  } = useSelector((state) => state.entities.messages);
  const { event_lead } = useSelector((state) => state.entities.conversions);
  const rowsPerPage = getNumberPerPage();

  const dispatch = useDispatch();

  useEffect(() => {
    if (maxPage === 0) setPage(0);
  }, [maxPage]);

  useEffect(() => {
    setPage(serverPage - 1);
  }, [serverPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectees = list.map((n) => n.id);
      setSelected(newSelectees);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > maxPage - 1) {
      dispatch(
        getMessages({
          page: newPage + 1,
          tone: selectedTone,
          approach: selectedApproach,
          project: selectedProject,
          channel: selectedChannel,
          date: selectedDate,
        })
      );
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let per_page = event.target.value;
    dispatch(
      getMessages({
        per_page,
        tone: selectedTone,
        approach: selectedApproach,
        project: selectedProject,
        channel: selectedChannel,
        date: selectedDate,
      })
    );
    updateNbrPerPage(per_page);
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, list.length - page * rowsPerPage);

  const handleMessageStatus = (id, data) => {
    dispatch(updateMessage(id, data));
  };

  const handleOpenDialog = (message) => {
    setMessageToEdit({ ...message });
    dispatch(resetAll());
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteMessage = (message) => {
    setDeleteMessageOpen(true);
    setMessageToDelete(message);
  };

  // let winnerValue = Math.max(
  //   ...list.map((o) => o.actionsStats[event_lead] || 0)
  // );
  // console.log(winnerValue);
  // let index = list.indexOf((o) => o.actionsStats[event_lead] === winnerValue);
  // console.log(index);
  // console.log(list[index]);

  return (
    <div>
      {messageToDelete && deleteMessageOpen && (
        <ArchiveDialog
          title="Confirm Deleting Message?!"
          description={`This message "${messageToDelete.label}" will force deleting from your database`}
          id={messageToDelete._id}
          setOpen={setDeleteMessageOpen}
          open={deleteMessageOpen}
          dispatchOnAgree={deleteMessage}
        />
      )}
      {messageToEdit && (
        <Popup
          open={dialogOpen}
          setOpen={setDialogOpen}
          message={messageToEdit}
          handleClose={handleClose}
        />
      )}
      <Paper>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={list.length}
            />
            <TableBody>
              {stableSort(list, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);

                  let leadTypeValue = row.actionsStats[event_lead] || 0;
                  let leadValue = row.traffic
                    ? ((leadTypeValue * 100) / row.traffic).toFixed(2)
                    : 0;

                  // Add Remove button to message - if the message does not have any submits and not added to ant campaign and doesn't have any events.
                  let showDeleteButton =
                    row.submissionsCount < 1 &&
                    row.projects.length < 1 &&
                    Object.keys(row.actionsStats).length < 1;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row._id}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={JSON.parse(row.status)}
                              onChange={(event) =>
                                handleMessageStatus(row._id, {
                                  status: !JSON.parse(row.status),
                                })
                              }
                            />
                          }
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        align="left"
                        onClick={() => handleOpenDialog(row)}
                      >
                        {getShortDateFormate(row.createdAt)}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        align="left"
                        onClick={() => handleOpenDialog(row)}
                      >
                        {row.label}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        align="center"
                        onClick={() => handleOpenDialog(row)}
                      >
                        {row.projects?.length ? (
                          <DoneIcon style={{ color: "green" }} />
                        ) : (
                          <CloseIcon style={{ color: "red" }} />
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        align="center"
                        onClick={() => handleOpenDialog(row)}
                      >
                        {row.profiles?.length ? (
                          <DoneIcon style={{ color: "green" }} />
                        ) : (
                          <CloseIcon style={{ color: "red" }} />
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        align="center"
                        onClick={() => handleOpenDialog(row)}
                      >
                        {row.submissionsCount}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        align="center"
                        onClick={() => handleOpenDialog(row)}
                      >
                        {row.traffic}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        align="center"
                        onClick={() => handleOpenDialog(row)}
                      >
                        {row.ctr}%
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        align="center"
                        onClick={() => handleOpenDialog(row)}
                      >
                        {leadValue}%
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: 200,
                        }}
                      >
                        <Message message={row} key={row._id} />
                      </TableCell>
                      <TableCell padding="none" align="right">
                        <Box mr={2}>
                          {showDeleteButton ? (
                            <Tooltip title="Delete Alias">
                              <IconButton
                                aria-label="delete"
                                color="primary"
                                onClick={() => handleDeleteMessage(row)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={total_records}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function MessagesList() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState(false);

  const { error, success, isLoading, list } = useSelector(
    (state) => state.entities.messages
  );

  useEffect(() => {
    if (success || error) {
      setNotification(true);
      // dispatch(getUsers());
    }
  }, [success, error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleNotificationClose = () => {
    setNotification(false);
    dispatch(resetAll());
  };

  return (
    <Fragment>
      {(success || error) && (
        <Snackbar
          open={notification}
          autoHideDuration={5000}
          onClose={handleNotificationClose}
          TransitionComponent={TransitionLeft}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleNotificationClose}
            severity={success ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {success || error}
          </Alert>
        </Snackbar>
      )}
      <Helmet title="Messages" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Messages
          </Typography>
        </Grid>
        <Grid item style={{ display: "flex" }}>
          <Actions />
          <Button
            variant="contained"
            style={{ backgroundColor: "#6a74c9", color: "white" }}
            onClick={handleClickOpen}
          >
            <AddIcon />
            New message
          </Button>
          {open && (
            <Popup
              open={open}
              setOpen={setOpen}
              message={null}
              handleClose={handleClose}
            />
          )}
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {isLoading && !list.length ? <Loading /> : <EnhancedTable />}
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default MessagesList;
