import React, { useState } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";

import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider as MuiDivider,
  TextField,
  CardContent,
  Box,
  Chip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DetailsSharp } from "@material-ui/icons";

import { spacing } from "@material-ui/system";

import { sendNotWorking } from "../../../redux/reducers/submissionsReducer";
import { sendProfileSpam } from "../../../redux/reducers/profilesReducer";
const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles((theme) => ({
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  input1: {
    backgroundColor: "#F9F9FC",
  },
  h5: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  shrink: {
    display: "none",
  },
  controlInput: {
    backgroundColor: "#F9F9FC",
  },
}));

const Popup = ({ open, setOpen, handleClose }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState({
    message: "",
  });

  const { data } = useSelector((state) => state.entities.submissions);

  const handleChange = (e) => {
    setInitialValues({ ...initialValues, [e.target.name]: e.target.value });
  };

  const handleSendMessage = () => {
    if (initialValues.message.includes("Spam Detected")) {
      dispatch(
        sendProfileSpam({
          profile: data.profile._id,
          url: data.url,
        })
      );
    } else {
      dispatch(
        sendNotWorking({
          _id: data.submission_id,
          success: false,
          message: initialValues.message,
        })
      );
    }
    setOpen(false);
  };

  const addTags = (e, message) => {
    e.preventDefault();
    setInitialValues({ message });
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogwrapper }}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <DetailsSharp
              style={{ width: "22px", color: "#6320EE", marginLeft: "10px" }}
            />
            <Typography
              variant="h4"
              style={{ fontSize: "16px", fontWeight: "700" }}
            >
              Send message
            </Typography>
          </div>
          <Button
            style={{ color: "#868695" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent style={{ marginTop: "-18px" }}>
        <Divider my={2} />

        <CardContent>
          <Grid container spacing={6}>
            <Grid item md={12} style={{ padding: "9px" }}>
              <Typography variant="h5" classes={{ h5: classes.h5 }}>
                Message Body*:
              </Typography>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.input1,
                    root: classes.controlInput,
                  },
                  style: { width: "32.5rem" },
                }}
                inputlabelprops={{ shrink: false }}
                multiline
                rows={5}
                rowsMax={15}
                name="message"
                placeholder="Message"
                value={initialValues.message}
                fullWidth
                onChange={handleChange}
                type="message"
                variant="outlined"
                my={2}
              />
            </Grid>

            <Box>
              {data.MessagesFailedSubmit?.map((message) => {
                return (
                  <Chip
                    style={{
                      marginRight: "5px",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                    label={message}
                    onClick={(e) => {
                      addTags(e, message);
                    }}
                  />
                );
              })}
            </Box>
          </Grid>

          <div style={{ float: "right", margin: "20px 0" }}>
            <Button
              style={{ backgroundColor: "#6A74C9" }}
              type="submit"
              variant="contained"
              color="primary"
              mt={3}
              onClick={handleSendMessage}
            >
              Submit
            </Button>
          </div>
        </CardContent>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
