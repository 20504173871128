import { createSlice } from "@reduxjs/toolkit";
import { getNumberPerPage } from "../../helpers/functions";
import { apiCallBegan } from "../actions/apiActions";
const url = "profiles";

const slice = createSlice({
  name: "profiles",
  initialState: {
    list: [],
    list_all: [],
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
    selectedApproach: null,
    selectedTone: null,
    selectedChannel: null,
    selectedProject: null,
    selectedMessage: null,
    selectedDate: null,
    chartStats: {},
    page: 0,
    per_page: 0,
    total_pages: 0,
    maxPage: 0,
    total_records: 0,
  },
  reducers: {
    resetAll: (profiles, action) => {
      profiles.isLoading = false;
      profiles.error = null;
      profiles.success = null;
    },
    requestStarted: (profiles, action) => {
      profiles.isLoading = true;
      profiles.error = null;
      profiles.isRequestSent = true;
    },
    requestedFailed: (profiles, action) => {
      profiles.error = action.payload;
      profiles.isLoading = false;
    },
    loadProfilesList: (profiles, action) => {
      profiles.list = action.payload.list;
      profiles.selectedApproach = action.payload.selectedApproach;
      profiles.selectedTone = action.payload.selectedTone;
      profiles.selectedMessage = action.payload.selectedMessage;
      profiles.selectedChannel = action.payload.selectedChannel;
      profiles.selectedProject = action.payload.selectedProject;
      profiles.selectedDate = action.payload.selectedDate;
      profiles.isLoading = false;

      profiles.page = action.payload.page;
      profiles.per_page = action.payload.per_page;
      profiles.total_pages = action.payload.total_pages;
      profiles.total_records = action.payload.total_records;
      profiles.maxPage =
        profiles.page > profiles.maxPage ? profiles.page : profiles.maxPage;
    },
    loadProfilesListAll: (profiles, action) => {
      profiles.list_all = action.payload;
      profiles.isLoading = false;
    },
    loadProfilesChartStats: (profiles, action) => {
      profiles.chartStats = action.payload.data;
      profiles.selectedProject = action.payload.selectedProject;
      profiles.isLoading = false;
    },
    profileAdded: (profiles, action) => {
      profiles.success = "New Alias Added";
      profiles.list.unshift(action.payload);
      profiles.list_all.push({
        _id: action.payload._id,
        email: action.payload.email,
        url: action.payload.url,
        channel: action.payload.channel._id,
        approach: action.payload.approach,
        tone: action.payload.tone,
      });
      profiles.isLoading = false;
    },
    profileUpdated: (profiles, action) => {
      const index = profiles.list.findIndex(
        (profile) => profile._id === action.payload._id
      );
      const indexAll = profiles.list_all.findIndex(
        (profile) => profile._id === action.payload._id
      );
      profiles.list[index] = action.payload;
      profiles.list_all[indexAll] = action.payload;
      profiles.success = "Alias Updated";
      profiles.isLoading = false;
    },
    profileTrackerDeleted: (profiles, action) => {
      const index = profiles.list.findIndex(
        (profile) => profile._id === action.payload._id
      );
      profiles.list[index].tracking = action.payload.tracking;
      profiles.success = "Tracking Updated";
      profiles.isLoading = false;
    },
    profileTrackerUpdated: (profiles, action) => {
      const index = profiles.list.findIndex(
        (profile) => profile._id === action.payload._id
      );
      profiles.list[index].tracking = action.payload.tracking;
      profiles.success = "Tracking Updated";
      profiles.isLoading = false;
    },
    profileDeleted: (profiles, action) => {
      const index = profiles.list.findIndex(
        (profile) => profile._id === action.payload._id
      );
      const indexAll = profiles.list_all.findIndex(
        (profile) => profile._id === action.payload._id
      );
      profiles.list.splice(index, 1);
      profiles.list_all.splice(indexAll, 1);
      profiles.isLoading = false;
      profiles.success = "Alias Deleted";
    },
    channelChanged: (messages, action) => {
      messages.selectedChannel = action.payload;
    },
    profileSpammed: (state, action) => {
      state.success = "Spam Profile Reported";
    },
  },
});

export const {
  resetAll,
  profileAdded,
  profileSpammed,
  channelChanged,
  requestStarted,
  profileDeleted,
  profileUpdated,
  requestedFailed,
  loadProfilesList,
  loadProfilesListAll,
  profileTrackerDeleted,
  profileTrackerUpdated,
  loadProfilesChartStats,
} = slice.actions;
export default slice.reducer;

export const getProfiles = (params = {}) => {
  params.per_page = getNumberPerPage();

  delete params.channel;

  if (params.project === null || params.project === "all")
    delete params.project;
  if (params.approach === null || params.approach === "all")
    delete params.approach;
  if (params.tone === null || params.tone === "all") delete params.tone;
  if (params.message === null || params.message === "all")
    delete params.message;
  if (params.startDate === null) {
    delete params.date;
  } else {
    if (params.date && params.date.startDate)
      params.startDate = params.date.startDate;
    if (params.date && params.date.endDate)
      params.endDate = params.date.endDate;
    delete params.date;
  }

  //Generate a URL query based on the object we got
  let query = new URLSearchParams(params);

  return apiCallBegan({
    url: `${url}?${query}`,
    onStart: requestStarted.type,
    onSuccess: loadProfilesList.type,
    onError: requestedFailed.type,
  });
};

export const getProfilesChartStats = (params = {}) => {
  if (
    params.channel === "undefined" ||
    params.channel === null ||
    params.channel === "all"
  )
    delete params.channel;
  if (params.project === null || params.project === "all")
    delete params.project;
  if (params.startDate === null) {
    delete params.date;
  } else {
    if (params.date && params.date.startDate)
      params.startDate = params.date.startDate;
    if (params.date && params.date.endDate)
      params.endDate = params.date.endDate;
    delete params.date;
  }

  //Generate a URL query based on the object we got
  let query = new URLSearchParams(params);
  return apiCallBegan({
    url: `${url}/stats?${query}`,
    onStart: requestStarted.type,
    onSuccess: loadProfilesChartStats.type,
    onError: requestedFailed.type,
  });
};

export const getAllProfiles = () =>
  apiCallBegan({
    url: `${url}/all`,
    onStart: requestStarted.type,
    onSuccess: loadProfilesListAll.type,
    onError: requestedFailed.type,
  });

export const addProfile = (data) =>
  apiCallBegan({
    url,
    method: "POST",
    data,
    onStart: requestStarted.type,
    onSuccess: profileAdded.type,
    onError: requestedFailed.type,
  });

export const updateProfile = (id, data) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "PUT",
    /* The payload that is being sent to the server. */
    data,
    onStart: requestStarted.type,
    onSuccess: profileUpdated.type,
    onError: requestedFailed.type,
  });

export const deleteProfile = (id) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "DELETE",
    onStart: requestStarted.type,
    onSuccess: profileDeleted.type,
    onError: requestedFailed.type,
  });

export const deleteProfileTracking = (data) =>
  apiCallBegan({
    url: `${url}/tracking`,
    method: "DELETE",
    data,
    onStart: requestStarted.type,
    onSuccess: profileTrackerDeleted.type,
    onError: requestedFailed.type,
  });

export const updateProfileTracking = (data) =>
  apiCallBegan({
    url: `${url}/tracking`,
    method: "PUT",
    data,
    onStart: requestStarted.type,
    onSuccess: profileTrackerUpdated.type,
    onError: requestedFailed.type,
  });

export const sendProfileSpam = (data) =>
  apiCallBegan({
    url: `${url}/spams`,
    method: "PUT",
    data,
    onStart: requestStarted.type,
    onSuccess: profileSpammed.type,
    onError: requestedFailed.type,
  });
