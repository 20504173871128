import React, { useState } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";

import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider as MuiDivider,
  TextField,
  CardContent,
  InputAdornment,
  ButtonBase,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DetailsSharp } from "@material-ui/icons";
import { Copy } from "react-feather";
import { spacing } from "@material-ui/system";

import { setSnack } from "../../../redux/reducers/snackbarReducer";

const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },

  dialogwrapper: {
    position: "absolute",
    maxWidth: "40rem",
    // top: theme.spacing(5)
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  startICon: {
    margin: 0,
  },
  chips: {
    height: "20px",
    borderRadius: "0px",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  ButtonApprove: {
    backgroundColor: "#23CC94",
    color: "white",
    width: "6rem",
    margin: "12px 39px",
  },
  description: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  input1: {
    backgroundColor: "#F9F9FC",
  },
  h5: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
    // backgroundColor: "white"
  },
  shrink: {
    display: "none",
  },
  controlInput: {
    backgroundColor: "#F9F9FC",
  },
}));

const Popup = ({ open, setOpen, handleClose }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let { data } = useSelector((state) => state.entities.submissions);

  const [initialValues, setInitialValues] = useState({
    channelName: data.channelName,
    subject: data.message.subject,
    name: data.profile.sender,
    email: data.profile.email,
    url: data.profile.url,
    phoneNumber: data.profile.phone,
    zip: data.profile.zip,
    state: data.profile.state,
    address: data.profile.address,
    city: data.profile.city,
    message: data.message.body,
  });

  const handleChange = (e) => {
    setInitialValues({ ...initialValues, [e.target.name]: e.target.value });
  };
  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    setOpen(false);
    dispatch(setSnack(true));
    setTimeout(() => dispatch(setSnack(false)), 2500);
  };

  const CopyButton = ({ value }) => (
    <InputAdornment
      position="right"
      style={{
        maxHeight: "none",
        height: "auto",
        marginTop: "-1px",
        marginRight: "-1px",
        marginBottom: "-1px",
      }}
    >
      <ButtonBase
        size="small"
        style={{
          textTransform: "none",
          padding: "7px 6px",
          backgroundColor: "rgb(189 189 189)",
          marginRight: "-0.8rem",
        }}
        onClick={() => copyToClipboard(value)}
      >
        <Copy />
      </ButtonBase>
    </InputAdornment>
  );

  return (
    <Dialog
      classes={{ paper: classes.dialogwrapper }}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <DetailsSharp
              style={{ width: "22px", color: "#6320EE", marginLeft: "10px" }}
            />
            <Typography
              variant="h4"
              style={{ fontSize: "16px", fontWeight: "700" }}
            >
              Campaign Details
            </Typography>
          </div>
          <Button
            style={{ color: "#868695" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent style={{ marginTop: "-18px" }}>
        <Divider my={2} />

        <>
          <CardContent>
            <Grid container spacing={6}>
              <Grid item md={6} style={{ padding: "9px" }}>
                <Typography variant="h5" classes={{ h5: classes.h5 }}>
                  Company Name*:
                </Typography>
                <TextField
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                    readOnly: true,
                    endAdornment: (
                      <CopyButton value={initialValues.channelName} />
                    ),
                  }}
                  inputlabelprops={{ shrink: false }}
                  name="channelName"
                  placeholder="Company Name"
                  value={initialValues.channelName}
                  fullWidth
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  my={2}
                />
              </Grid>

              <Grid item md={6} style={{ padding: "9px" }}>
                <Typography variant="h5" classes={{ h5: classes.h5 }}>
                  Subject*:
                </Typography>
                <TextField
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                    readOnly: true,
                    endAdornment: <CopyButton value={initialValues.subject} />,
                  }}
                  inputlabelprops={{ shrink: false }}
                  name="subject"
                  placeholder="Subject"
                  value={initialValues.subject}
                  fullWidth
                  onChange={handleChange}
                  type="subject"
                  variant="outlined"
                  my={2}
                />
              </Grid>
            </Grid>

            <Grid container spacing={6} style={{ marginTop: "10px" }}>
              <Grid item md={4} style={{ padding: "9px" }}>
                <Typography variant="h5" classes={{ h5: classes.h5 }}>
                  Name*:
                </Typography>
                <TextField
                  InputProps={{
                    classes: { input: classes.input },
                    readOnly: true,
                    endAdornment: <CopyButton value={initialValues.name} />,
                  }}
                  inputlabelprops={{ shrink: false }}
                  name="name"
                  placeholder="Name"
                  value={initialValues.name}
                  fullWidth
                  onChange={handleChange}
                  type="name"
                  variant="outlined"
                  my={2}
                />
              </Grid>
              <Grid item md={4} style={{ padding: "9px" }}>
                <Typography variant="h5" classes={{ h5: classes.h5 }}>
                  Email*:
                </Typography>
                <TextField
                  InputProps={{
                    classes: { input: classes.input },
                    readOnly: true,
                    endAdornment: <CopyButton value={initialValues.email} />,
                  }}
                  inputlabelprops={{ shrink: false }}
                  name="email"
                  placeholder="Email"
                  value={initialValues.email}
                  fullWidth
                  onChange={handleChange}
                  type="email"
                  variant="outlined"
                  my={2}
                  rea
                />
              </Grid>
              <Grid item md={4} style={{ padding: "9px" }}>
                <Typography variant="h5" classes={{ h5: classes.h5 }}>
                  Domain*:
                </Typography>
                <TextField
                  InputProps={{
                    classes: { input: classes.input },
                    readOnly: true,
                    endAdornment: <CopyButton value={initialValues.url} />,
                  }}
                  inputlabelprops={{ shrink: false }}
                  name="domain"
                  placeholder="Domain"
                  value={initialValues.url}
                  fullWidth
                  variant="outlined"
                  my={2}
                  rea
                />
              </Grid>
            </Grid>

            <Grid container spacing={6} style={{ marginTop: "10px" }}>
              <Grid item md={4} style={{ padding: "9px" }}>
                <Typography variant="h5" classes={{ h5: classes.h5 }}>
                  Phone Number*:
                </Typography>
                <TextField
                  InputProps={{
                    classes: { input: classes.input },
                    readOnly: true,
                    endAdornment: (
                      <CopyButton value={initialValues.phoneNumber} />
                    ),
                  }}
                  inputlabelprops={{ shrink: false }}
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={initialValues.phoneNumber}
                  fullWidth
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  my={2}
                />
              </Grid>
              <Grid item md={4} style={{ padding: "9px" }}>
                <Typography variant="h5" classes={{ h5: classes.h5 }}>
                  ZIP*:
                </Typography>
                <TextField
                  InputProps={{
                    classes: { input: classes.input },
                    readOnly: true,
                    endAdornment: <CopyButton value={initialValues.zip} />,
                  }}
                  inputlabelprops={{ shrink: false }}
                  name="zip"
                  placeholder="Zip"
                  value={initialValues.zip}
                  fullWidth
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  my={2}
                />
              </Grid>
              <Grid item md={4} style={{ padding: "9px" }}>
                <Typography variant="h5" classes={{ h5: classes.h5 }}>
                  State*:
                </Typography>
                <TextField
                  InputProps={{
                    classes: { input: classes.input },
                    readOnly: true,
                    endAdornment: <CopyButton value={initialValues.state} />,
                  }}
                  inputlabelprops={{ shrink: false }}
                  name="state"
                  placeholder="State"
                  value={initialValues.state}
                  fullWidth
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  my={2}
                />
              </Grid>
            </Grid>

            <Grid container spacing={6} style={{ marginTop: "10px" }}>
              <Grid item md={6} style={{ padding: "9px" }}>
                <Typography variant="h5" classes={{ h5: classes.h5 }}>
                  Address*:
                </Typography>
                <TextField
                  InputProps={{
                    classes: { input: classes.input },
                    readOnly: true,
                    endAdornment: <CopyButton value={initialValues.address} />,
                  }}
                  inputlabelprops={{ shrink: false }}
                  name="address"
                  placeholder="Address"
                  value={initialValues.address}
                  fullWidth
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  my={2}
                />
              </Grid>
              <Grid item md={6} style={{ padding: "9px" }}>
                <Typography variant="h5" classes={{ h5: classes.h5 }}>
                  City*:
                </Typography>
                <TextField
                  InputProps={{
                    classes: { input: classes.input },
                    readOnly: true,
                    endAdornment: <CopyButton value={initialValues.city} />,
                  }}
                  inputlabelprops={{ shrink: false }}
                  name="city"
                  placeholder="City"
                  value={initialValues.city}
                  fullWidth
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  my={2}
                />
              </Grid>
            </Grid>

            <Grid container spacing={6} style={{ marginTop: "10px" }}>
              <Grid item md={6} style={{ padding: "9px" }}>
                <Typography variant="h5" classes={{ h5: classes.h5 }}>
                  Message Body*:
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => copyToClipboard(initialValues.message)}
                >
                  Copy Message
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={6} style={{ marginTop: "10px" }}>
              <Grid item md={6} style={{ padding: "9px" }}>
                <TextField
                  InputProps={{
                    classes: {
                      input: classes.input1,
                      root: classes.controlInput,
                    },
                    readOnly: true,
                    style: { width: "35.5rem" },
                  }}
                  inputlabelprops={{ shrink: false }}
                  multiline
                  rows={3}
                  rowsMax={6}
                  name="message"
                  placeholder="Message"
                  value={initialValues.message}
                  fullWidth
                  onChange={handleChange}
                  type="message"
                  variant="outlined"
                  my={2}
                />
              </Grid>
            </Grid>

            <div style={{ float: "right", margin: "20px 0" }}>
              <Button
                style={{ backgroundColor: "#6A74C9" }}
                type="submit"
                variant="contained"
                color="primary"
                mt={3}
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </CardContent>
        </>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
