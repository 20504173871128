import {
  Button as MuiButton,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import { updateDirectory } from "../../../../../redux/reducers/directoriesReducer";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/styles";
import MySelect from "../../../../customComponent/MySelect";

const Button = styled(MuiButton)(spacing);

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },
  dialogWrapper: {
    position: "absolute",
    maxWidth: "50rem",
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  startICon: {
    margin: 0,
  },
  chips: {
    height: "20px",
    borderRadius: "0px",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  ButtonApprove: {
    backgroundColor: "#23CC94",
    color: "white",
    width: "6rem",
    margin: "12px 39px",
  },
  description: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  h5: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
  },
  shrink: {
    display: "none",
  },
  inputSelect: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    backgroundColor: "#f5f6fa",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
}));

const engines = [
  "text-ada-001",
  "text-babbage-001",
  "text-curie-001",
  "text-davinci-003",
];

const faqsNumbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

const publishing_times = [
  { label: "1min", value: "60" },
  { label: "5min", value: "300" },
  { label: "10min", value: "600" },
  { label: "15min", value: "900" },
  { label: "30min", value: "1800" },
  { label: "1h", value: "3600" },
  { label: "2h", value: "7200" },
  { label: "3h", value: "10800" },
  { label: "4h", value: "14400" },
  { label: "5h", value: "18000" },
  { label: "6h", value: "21600" },
  // { label: "7h", value: "25200" },
  // { label: "8h", value: "28800" },
  // { label: "9h", value: "32400" },
  // { label: "10h", value: "36000" },
  // { label: "11h", value: "39600" },
  { label: "12h", value: "43200" },
  // { label: "13h", value: "46800" },
  // { label: "14h", value: "50400" },
  // { label: "15h", value: "54000" },
  // { label: "16h", value: "57600" },
  // { label: "17h", value: "61200" },
  // { label: "18h", value: "64800" },
  // { label: "19h", value: "68400" },
  // { label: "20h", value: "72000" },
  // { label: "21h", value: "75600" },
  // { label: "22h", value: "79200" },
  // { label: "23h", value: "82800" },
  { label: "24h", value: "86400" },
];

const GeneraleSettings = ({ directory, dispatch, isLoading = false }) => {
  const classes = useStyles();
  const [data, setData] = useState(directory || {});

  const [publishing_time, set_publishing_time] = useState(
    publishing_times[0].value
  );
  const [engine, set_engine] = useState(engines[2]);
  const [nbrFaqs, set_nbrFaqs] = useState(faqsNumbers[0]);
  const [publish_allowed, set_publish_allowed] = useState(false);
  const [email_verification, set_email_verification] = useState(false);
  const [scrape_services, set_scrape_services] = useState(false);
  const [scrape_data_from_website, set_scrape_data_from_website] = useState(
    false
  );

  useEffect(() => {
    setData(directory);
    set_engine(directory?.settings?.engine);
    set_nbrFaqs(directory?.settings?.nbrFaqs);
    set_publishing_time(directory?.settings?.publishing_time);
    set_publish_allowed(directory?.settings?.publish_allowed);
    set_email_verification(directory?.settings?.email_verification);
    set_scrape_services(directory?.settings?.scrape_services);
    set_scrape_data_from_website(directory?.settings?.scrape_data_from_website);
  }, [directory]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let UpdateData = {
      _id: data._id,
      settings: {
        engine,
        nbrFaqs,
        publish_allowed,
        email_verification,
        scrape_services,
        publishing_time,
        scrape_data_from_website,
      },
    };
    dispatch(updateDirectory(UpdateData));
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Grid item md={12} style={{}}>
        <Typography variant="h5" classes={{ h5: classes.h5 }}>
          Publishing Time:
        </Typography>
        <MySelect
          selected={publishing_time}
          list={publishing_times}
          label="label"
          value="value"
          func={set_publishing_time}
        />
      </Grid>
      <Grid item md={12} style={{ marginTop: "1rem" }}>
        <Typography variant="h5" classes={{ h5: classes.h5 }} style={{}}>
          Engine Use:
        </Typography>
        <MySelect selected={engine} list={engines} func={set_engine} />
      </Grid>
      <Grid item md={12} style={{ marginTop: "1rem" }}>
        <Typography variant="h5" classes={{ h5: classes.h5 }} style={{}}>
          Number of Faqs:
        </Typography>
        <MySelect selected={nbrFaqs} list={faqsNumbers} func={set_nbrFaqs} />
      </Grid>
      <Grid item md={12} style={{ display: "flex", marginTop: "1rem" }}>
        <Grid item md={12} style={{ padding: "9px 0", position: "relative" }}>
          <Grid item md={12} style={{ display: "flex" }}>
            <Typography variant="h5" classes={{ h5: classes.h5 }}>
              Publish Allowed:
            </Typography>
            <FormControlLabel
              style={{ position: "absolute", right: "0", marginTop: "-10px" }}
              control={
                <Switch
                  checked={publish_allowed}
                  onChange={(event) => {
                    set_publish_allowed(!publish_allowed);
                  }}
                />
              }
            />
          </Grid>
        </Grid>
        <Grid item md={12} style={{ padding: "9px 0", position: "relative" }}>
          <Grid item md={12} style={{ display: "flex" }}>
            <Typography variant="h5" classes={{ h5: classes.h5 }}>
              Email Verification:
            </Typography>
            <FormControlLabel
              style={{ position: "absolute", right: "0", marginTop: "-10px" }}
              control={
                <Switch
                  checked={email_verification}
                  onChange={(event) => {
                    set_email_verification(!email_verification);
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12} style={{ display: "flex" }}>
        <Grid item md={12} style={{ padding: "9px 0", position: "relative" }}>
          <Grid item md={12} style={{ display: "flex" }}>
            <Typography variant="h5" classes={{ h5: classes.h5 }}>
              Scrape Services:
            </Typography>
            <FormControlLabel
              style={{ position: "absolute", right: "0", marginTop: "-10px" }}
              control={
                <Switch
                  checked={scrape_services}
                  onChange={(event) => {
                    set_scrape_services(!scrape_services);
                  }}
                />
              }
            />
          </Grid>
        </Grid>
        <Grid item md={12} style={{ padding: "9px 0", position: "relative" }}>
          <Grid item md={12} style={{ display: "flex" }}>
            <Typography variant="h5" classes={{ h5: classes.h5 }}>
              Scrape Data From Company Website:
            </Typography>
            <FormControlLabel
              style={{ position: "absolute", right: "0", marginTop: "-10px" }}
              control={
                <Switch
                  checked={scrape_data_from_website}
                  onChange={(event) => {
                    set_scrape_data_from_website(!scrape_data_from_website);
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Button
        variant="contained"
        type="submit"
        style={{
          backgroundColor: "#6a74c9",
          color: "white",
          width: "8vw",
          position: "absolute",
          right: "0",
          bottom: "0",
        }}
      >
        {isLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Save changes"
        )}
      </Button>
    </form>
  );
};

export default GeneraleSettings;
