import React, { useState, useEffect, Fragment, forwardRef } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import {
  updateGroup,
  resetAll,
  generateKeywords,
  deleteGroup,
  getGroups,
  getOneGroup,
  groupIsLoading,
  resetGroupKeywords,
  resetGroupFiltered,
} from "../../redux/reducers/groupsReducer";
import { Chip, InputBase, Snackbar } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import AlertDialog from "../customComponent/AlertDialog";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  Slide,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  FormControlLabel,
  Typography,
  Switch,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";
import {
  getComparator,
  getNumberPerPage,
  stableSort,
  updateNbrPerPage,
} from "../../helpers/functions";
import { Alert as MuiAlert } from "@material-ui/lab";
import {
  HelpOutline as InfoIcon,
  Add as AddIcon,
  Edit as EditIcon,
  MergeType as MergeIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import { darken } from "polished";
import { spacing } from "@material-ui/system";
import GroupPopup from "./components/GroupPopup";
import Loading from "../customComponent/Loading";
import { getCompanies } from "../../redux/reducers/companiesReducer";
import { Reload } from "../customComponent/Reload";
import { Globe, Search as SearchIcon } from "react-feather";
import { TableName } from "../customComponent/TableName";
import NumberFormat from "react-number-format";
import MergeGroup from "./components/MergeGroup";
import { updateSettings } from "../../redux/reducers/settingsReducer";
import { ROWS_PER_PAGE_OPTIONS } from "../../constants";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Search = styled.div`
  border-radius: 4px;
  border: 1px solid #6320ee;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  margin-right: 2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  width: 30rem;
  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 100%;
  }
`;

const ButtonTable = styled.div`
  display: flex;
  align-items: center;
`;

const headCells = [
  { id: "status", alignment: "left", label: "Status", sort: false },
  {
    id: "scraping",
    alignment: "left",
    label: "Scraping",
    sort: false,
    tooltip: "Generate Description",
  },
  {
    id: "filtered",
    alignment: "left",
    label: "Filtered",
    sort: false,
    tooltip: "Filtered Companies",
  },
  { id: "name", alignment: "left", label: "Name", sort: true },
  {
    id: "keywords",
    alignment: "left",
    label: "Keywords",
    tooltip: "Keywords / Keywords Scraped",
    sort: true,
  },
  {
    id: "filtered_stats",
    alignment: "left",
    label: "Filtered Stats",
    tooltip: "Companies / Emails / Filtered",
    sort: true,
  },
  { id: "actions", alignment: "right", label: "Actions", sort: false },
];
function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontSize: "16px",
            }}
          >
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {headCell.tooltip ? (
                  <Tooltip title={headCell.tooltip}>
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                ) : (
                  ""
                )}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let EnhancedTableToolbar = () => {
  const { search, statusType } = useSelector((state) => state.entities.groups);
  const [searchGroup, setSearchGroup] = useState(search);
  const { GROUP_SEARCH_TAGS } = useSelector(
    (state) => state.entities.settings.setting
  );
  const dispatch = useDispatch();

  const handleChange = (e) => {
    let search = e.target.value;

    setSearchGroup(search);
    let data = {
      search: search.trim(),
    };

    if (e.keyCode === 13) {
      dispatch(getGroups(data));
      if (!GROUP_SEARCH_TAGS.includes(search.trim()) && search.trim().length)
        addSearchTag(search);
    }
  };

  useEffect(() => {
    setSearchGroup(search);
  }, [search]);

  const addSearchTag = (tag) => {
    dispatch(
      updateSettings({ GROUP_SEARCH_TAGS: [...GROUP_SEARCH_TAGS, tag] })
    );
  };

  const handleClickButton = (status) => {
    dispatch(getGroups({ statusType: status }));
  };

  return (
    <Toolbar>
      <TableName title="Groups List" Component={Globe} />
      <Spacer />
      <Grid item>
        <ButtonTable>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <Input
              placeholder="Search Groups"
              value={searchGroup}
              onChange={handleChange}
              onKeyUp={handleChange}
              inputRef={(input) => input && input.focus()}
            />
          </Search>

          <Grid mx={12} item>
            <Button
              variant="contained"
              color="default"
              onClick={() => handleClickButton("active")}
              style={{
                marginRight: "1rem",
                backgroundColor: statusType === "active" ? "#27ae60" : "",
                color: statusType === "active" ? "white" : "",
              }}
            >
              Active
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="default"
              onClick={() => handleClickButton("archived")}
              style={{
                marginRight: "1rem",
                backgroundColor: statusType === "archived" ? "#27ae60" : "",
                color: statusType === "archived" ? "white" : "",
              }}
            >
              Inactive
            </Button>
          </Grid>
        </ButtonTable>
      </Grid>
    </Toolbar>
  );
};

const EnhancedTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("customer");
  const [page, setPage] = useState(0);

  const rowsPerPage = getNumberPerPage();
  const [notificationMessage, setNotificationMessage] = useState(null);
  // this needed for edit dialog
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [groupToEdit, setGroupToEdit] = useState(null);
  const [generatingKeywords, setGeneratingKeywords] = useState(null);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [groupToReset, setGroupToReset] = useState(null);
  const [groupToResetFilter, setGroupToResetFilter] = useState(null);
  const [
    resetGroupKeywordsDialogOpen,
    setResetGroupKeywordsDialogOpen,
  ] = useState(false);
  const [
    resetGroupFilteredDialogOpen,
    setResetGroupFilteredDialogOpen,
  ] = useState(false);
  const [groupToMerge, setGroupToMerge] = useState(null);
  const [mergeDialogOpen, setMergeDialogOpen] = useState(false);

  const handleDeleteGroup = (group_id) => {
    setGroupToDelete(group_id);
    setDeleteDialogOpen(true);
  };

  const {
    search,
    statusType,
    page: serverPage,
    total_records,
    maxPage,
    list: groupsList,
    success,
    groupAdded,
  } = useSelector((state) => state.entities.groups);

  useEffect(() => {
    if (groupAdded) {
      let { _id, keywords } = groupAdded;
      setGeneratingKeywords(_id);
      dispatch(generateKeywords({ group: _id, keywords }));
    } else {
      setGeneratingKeywords(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    if (maxPage === 0) setPage(0);
  }, [maxPage]);

  useEffect(() => {
    setPage(serverPage - 1);
  }, [serverPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    let data = {
      page: newPage + 1,
      statusType,
    };

    if (search !== "") data.search = search;

    if (newPage > maxPage - 1) {
      dispatch(getGroups(data));
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let per_page = event.target.value;
    let data = {
      per_page,
      statusType,
    };

    if (search !== "") data.search = search;

    updateNbrPerPage(per_page);
    setPage(0);
    dispatch(getGroups(data));
  };

  const emptyRows =
    rowsPerPage - Math.min(groupsList, groupsList.length - page * rowsPerPage);

  const handleGroupStatus = (group_id, data) => {
    dispatch(updateGroup(group_id, data));
  };

  const handleEditDialogStatus = (open) => {
    if (!open) {
      setGroupToEdit(null);
    }
    setEditDialogOpen(open);
  };

  const handleMergeGroup = (group) => {
    setGroupToMerge(group);
    setMergeDialogOpen(true);
  };

  const handleRefreshGroup = (group) => {
    dispatch(groupIsLoading({ _id: group._id }));
    dispatch(getOneGroup(group._id));
  };

  const handleEditGroup = (group) => {
    // setGroupToEdit(group);
    // dispatch(resetAll());
    // setEditDialogOpen(true);
    history.push(`/groups/${group._id}/settings`);
  };

  const handleGenerate = (group, keywords) => {
    setGeneratingKeywords(group);
    dispatch(generateKeywords({ group, keywords }));
  };

  const handleResetGroupKeywords = (group) => {
    setGroupToReset(group._id);
    setResetGroupKeywordsDialogOpen(true);
  };

  const handleResetGroupFiltered = (group) => {
    setGroupToResetFilter(group._id);
    setResetGroupFilteredDialogOpen(true);
  };

  const handleDeleteDialogStatus = (open) => {
    if (!open) {
      setGroupToDelete(null);
    }
    setDeleteDialogOpen(open);
  };

  const handleResetDialogStatus = (open) => {
    if (!open) {
      setGroupToReset(null);
    }
    setResetGroupKeywordsDialogOpen(open);
  };

  const handleResetFilteredDialogStatus = (open) => {
    if (!open) {
      setGroupToResetFilter(null);
    }
    setResetGroupFilteredDialogOpen(open);
  };

  const handleCompaniesByGroup = (group) => {
    dispatch(getCompanies({ group }));
    history.push("/companies");
  };

  const handleNotificationMessageClose = () => {
    setNotificationMessage(false);
    if (!groupAdded) dispatch(resetAll());
  };

  return (
    <Box>
      {groupToDelete && (
        <AlertDialog
          title="Confirm Removing the group?!"
          description="Removing the group will remove also any generated keyword under this group."
          id={groupToDelete}
          setOpen={handleDeleteDialogStatus}
          open={deleteDialogOpen}
          dispatchOnAgree={deleteGroup}
        />
      )}

      {groupToReset && (
        <AlertDialog
          title="Confirm Reset Group's Keywords?!"
          description="Resetting the group's keywords will restart scrapping all keywords of group."
          id={groupToReset}
          setOpen={handleResetDialogStatus}
          open={resetGroupKeywordsDialogOpen}
          dispatchOnAgree={resetGroupKeywords}
        />
      )}

      {groupToResetFilter && (
        <AlertDialog
          title="Confirm Reset Group's Filtered?!"
          description="Resetting the group's Filtered will restart filtering all companies of group."
          id={groupToResetFilter}
          setOpen={handleResetFilteredDialogStatus}
          open={resetGroupFilteredDialogOpen}
          dispatchOnAgree={resetGroupFiltered}
        />
      )}

      {groupToEdit && (
        <GroupPopup
          open={editDialogOpen}
          setOpen={handleEditDialogStatus}
          group={groupToEdit}
        />
      )}

      {groupToMerge && (
        <MergeGroup
          open={mergeDialogOpen}
          setOpen={handleMergeGroup}
          group={groupToMerge}
        />
      )}

      {notificationMessage && (
        <Snackbar
          open={notificationMessage}
          autoHideDuration={5000}
          onClose={handleNotificationMessageClose}
          TransitionComponent={TransitionLeft}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleNotificationMessageClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            {notificationMessage}
          </Alert>
        </Snackbar>
      )}
      <Paper>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={groupsList.length}
            />

            <TableBody>
              {stableSort(groupsList, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .filter(
                  (item) =>
                    item.status === (statusType === "active") || search !== ""
                )
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const { status } = row;

                  let keywordsScrapedPercent =
                    row.stats?.nonScrapedKeywords > 0
                      ? (
                          (row.stats?.scrapedKeywords * 100) /
                          row.stats?.number_all_keywords
                        ).toFixed(2)
                      : 100;

                  let keywordsStats = "No Keywords";
                  if (
                    row.stats?.nonScrapedKeywords === 0 &&
                    row.stats?.scrapedKeywords
                  )
                    keywordsStats = "Completed";
                  else if (row.stats?.scrapedKeywords) {
                    // `${row.stats?.scrapedKeywords}/${row.stats?.number_all_keywords}` +
                    keywordsStats = false;

                    // `${(row.stats?.scrapedKeywords > 0 &&
                    //    - (${keywordsScrapedPercent}%)`);
                  }

                  let totalKeywords =
                    row.stats?.scrapedKeywords + row.stats?.nonScrapedKeywords;
                  const keyword_percent = (
                    (totalKeywords * 100) /
                    row.stats?.number_all_keywords
                  ).toFixed();

                  let generateKeywords = (
                    <Button
                      onClick={() => handleGenerate(row._id, row.keywords)}
                    >
                      Generate - ({isNaN(keyword_percent) ? 0 : keyword_percent}
                      %)
                    </Button>
                  );

                  let loadingKeyword =
                    generatingKeywords === row._id ? (
                      <Button>
                        <CircularProgress size={20} />
                      </Button>
                    ) : null;

                  let keywordsString =
                    totalKeywords === row.stats?.number_all_keywords ? (
                      <Button>
                        <NumberFormat
                          value={totalKeywords}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                        - ({keyword_percent}%)
                      </Button>
                    ) : (
                      generateKeywords
                    );

                  return (
                    <TableRow hover key={`${row.id}-${index}`}>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row.status}
                              onChange={(event) =>
                                handleGroupStatus(row._id, {
                                  status: !status,
                                  scraped: !status ? row.scraped : false,
                                  filtered: !status ? row.filtered : false,
                                  openai: !status ? row.openai : false,
                                })
                              }
                            />
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row.scraped ? true : false}
                              onChange={(event) => {
                                if (row.keywords.length) {
                                  handleGroupStatus(row._id, {
                                    scraped: !row.scraped,
                                  });
                                } else {
                                  setNotificationMessage(
                                    "Add Filtered Keywords"
                                  );
                                }
                              }}
                            />
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row.filtered ? true : false}
                              onChange={(event) => {
                                if (row.keywords.length) {
                                  handleGroupStatus(row._id, {
                                    filtered: !row.filtered,
                                  });
                                } else {
                                  setNotificationMessage(
                                    "Add Filtered Keywords"
                                  );
                                }
                              }}
                            />
                          }
                        />
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "14px" }}
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        <Customer>
                          <Box onClick={() => handleEditGroup(row)}>
                            {row.name}
                          </Box>
                        </Customer>
                      </TableCell>
                      <TableCell style={{ fontSize: "14px" }} align="left">
                        {loadingKeyword ? loadingKeyword : keywordsString}
                        <br />
                        {keywordsStats ? (
                          <Box style={{ display: "flex" }}>
                            {keywordsStats}
                            <Reload
                              tooltip="Reset Group's Keywords"
                              style={{ marginTop: "-7px" }}
                              loading={row.isLoading}
                              action={() => handleResetGroupKeywords(row)}
                            />
                          </Box>
                        ) : (
                          keywordsScrapedPercent + "%"
                        )}
                      </TableCell>
                      <TableCell style={{ fontSize: "14px" }} align="left">
                        <Box style={{ display: "flex" }}>
                          <Box>
                            <Box
                              style={{ cursor: "pointer" }}
                              onClick={() => handleCompaniesByGroup(row._id)}
                            >
                              <NumberFormat
                                value={row.stats?.totalCompanies || "-"}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </Box>
                            <Box>
                              <NumberFormat
                                value={row.stats?.emailsCount || "-"}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </Box>
                            <Box style={{ display: "flex" }}>
                              <Box>
                                <NumberFormat
                                  value={row.stats?.filteredCount || "-"}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </Box>
                              <Reload
                                tooltip="Reset Group's Filtered"
                                style={{ marginTop: "-7px" }}
                                loading={row.isLoading}
                                action={() => handleResetGroupFiltered(row)}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell style={{ display: "flex" }}>
                        <Reload
                          tooltip="Refresh Group data"
                          style={{ marginTop: "8px" }}
                          loading={row.isLoading}
                          action={() => handleRefreshGroup(row)}
                        />
                        <Tooltip title="Merge">
                          <IconButton
                            aria-label="details"
                            color="primary"
                            onClick={() => handleMergeGroup(row)}
                          >
                            <MergeIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                          <IconButton
                            aria-label="details"
                            color="primary"
                            onClick={() => handleEditGroup(row)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" color="primary">
                          <IconButton
                            aria-label="details"
                            color="primary"
                            onClick={() => handleDeleteGroup(row._id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={total_records}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

const GroupsList = () => {
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState(false);
  const { error, success, groupAdded, list, isLoading } = useSelector(
    (state) => state.entities.groups
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (success || error) {
      setNotification(true);
    }
  }, [success, error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNotificationClose = () => {
    setNotification(false);
    if (!groupAdded) dispatch(resetAll());
  };

  const loadGroups = () => {
    dispatch(getGroups());
  };

  return (
    <Fragment>
      {(success || error) && (
        <Snackbar
          open={notification}
          autoHideDuration={5000}
          onClose={handleNotificationClose}
          TransitionComponent={TransitionLeft}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleNotificationClose}
            severity={success ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {success || error}
          </Alert>
        </Snackbar>
      )}

      <Helmet title="Scraping Groups" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Scraping Groups
          </Typography>
        </Grid>
        <Grid item>
          <Box>
            <Box display="flex" align="center">
              <Reload loading={isLoading} action={loadGroups} />

              <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
                style={{ backgroundColor: "#6a74c9", color: "white" }}
              >
                <AddIcon />
                New Group
              </Button>
            </Box>
            {open && (
              <GroupPopup
                open={open}
                setOpen={setOpen}
                project={null}
                handleClose={handleClose}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Divider mt={6} />
      <Grid>
        <Tags />
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            {isLoading && list.length === 0 ? <Loading /> : <EnhancedTable />}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

function Tags() {
  const [tags, setTags] = useState([]);
  const dispatch = useDispatch();

  const { GROUP_SEARCH_TAGS } = useSelector(
    (state) => state.entities.settings.setting
  );

  useEffect(() => {
    setTags(GROUP_SEARCH_TAGS);
  }, [GROUP_SEARCH_TAGS]);

  const removeTag = (e, key) => {
    let result = tags.filter((item) => item !== key);
    setTags(result);
    dispatch(updateSettings({ GROUP_SEARCH_TAGS: result }));
  };

  const searchTag = (e, item) => {
    dispatch(
      getGroups({
        search: item,
      })
    );
  };

  return (
    <Grid item md={12}>
      {tags &&
        tags.map((item) => {
          return (
            <Chip
              style={{
                marginRight: "5px",
                marginTop: "10px",
                cursor: "pointer",
              }}
              key={item}
              label={item}
              onClick={(e) => {
                searchTag(e, item);
              }}
              onDelete={(e) => {
                removeTag(e, item);
              }}
            />
          );
        })}

      {tags && tags.length > 0 && <Divider my={3} />}
    </Grid>
  );
}

export default GroupsList;
