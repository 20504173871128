import React, { useState, useEffect, forwardRef, Fragment } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import {
  updateChannel,
  resetAll,
  getChannelsProjects,
} from "../../redux/reducers/channelReducer";
import { Snackbar, Toolbar } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import NumberFormat from "react-number-format";
import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Slide,
  FormControlLabel,
  Typography,
  Switch,
} from "@material-ui/core";
import {
  getComparator,
  getDateFormate,
  stableSort,
} from "../../helpers/functions";
import { Alert as MuiAlert } from "@material-ui/lab";
import {
  HelpOutline as InfoIcon,
  Add as AddIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import ChannelPopup from "./components/ChannelPopup";
import { getProjects } from "../../redux/reducers/projectReducer";
import Popup from "./components/Popup";
import Loading from "../customComponent/Loading";
import { Reload } from "../customComponent/Reload";
import { Layers } from "react-feather";
import { TableName } from "../customComponent/TableName";
import { ROWS_PER_PAGE_OPTIONS } from "../../constants";
import OrganizationsTable from "./components/Table";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Customer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const headCells = [
  {
    id: "#",
    numeric: true,
    disablePadding: false,
    label: "#",
    sort: false,
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
    sort: false,
  },
  {
    id: "created",
    numeric: true,
    disablePadding: false,
    label: "Created At",
    sort: true,
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Name",
    sort: true,
  },
  {
    id: "campaigns",
    numeric: false,
    disablePadding: false,
    label: "Campaigns",
    sort: true,
  },
  {
    id: "totalCost",
    numeric: true,
    disablePadding: false,
    label: "Total Cost",
    sort: true,
  },
  {
    id: "remainingLinks",
    numeric: true,
    disablePadding: false,
    label: "Remaining Links",
    sort: true,
  },
  {
    id: "successRate",
    numeric: true,
    disablePadding: false,
    label: "Success Rate",
    sort: true,
  },
  {
    id: "cps",
    numeric: true,
    disablePadding: false,
    label: "CPS",
    sort: true,
  },
  {
    id: "leads",
    numeric: true,
    disablePadding: false,
    label: "Leads",
    sort: true,
  },
  {
    id: "cpl",
    numeric: true,
    disablePadding: false,
    label: "CPL",
    sort: true,
  },
  {
    id: "conversionRate",
    numeric: true,
    disablePadding: false,
    label: "Conversion Rate",
    sort: true,
  },
  {
    id: "user",
    numeric: true,
    disablePadding: false,
    label: "Users",
    sort: true,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    sort: false,
  },
];
function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontSize: "16px",
            }}
          >
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {headCell.tooltip ? (
                  <Tooltip title={headCell.tooltip}>
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                ) : (
                  ""
                )}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let EnhancedTableToolbar = () => {
  return (
    <Toolbar>
      <TableName title="Organizations List" Component={Layers} />
    </Toolbar>
  );
};

// eslint-disable-next-line no-unused-vars
const EnhancedTable = () => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("customer");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  // this needed for edit dialog
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [channelToEdit, setChannelToEdit] = useState(null);

  const [openChannelDialog, setOpenChannelDialog] = useState(false);
  let [selectedChannel, setSelectedChannel] = useState(null);

  const { list_project: channelsList, isLoading } = useSelector(
    (state) => state.entities.channels
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, channelsList.length - page * rowsPerPage);

  const handleChannelStatus = (channel_id, status) => {
    dispatch(updateChannel(channel_id, status));
  };

  const handleChannelDialog = (action) => {
    if (!action) setSelectedChannel(null);
    setOpenChannelDialog(action);
  };

  const handleChannel = (channel) => {
    setSelectedChannel(channel);
    setOpenChannelDialog(true);
  };

  const handleEditDialogStatus = (open) => {
    if (!open) {
      setChannelToEdit(null);
    }
    setEditDialogOpen(open);
  };

  const handleEditChannel = (channel) => {
    setChannelToEdit(channel);
    dispatch(resetAll());
    setEditDialogOpen(true);
  };

  return (
    <div>
      {channelToEdit && (
        <ChannelPopup
          open={editDialogOpen}
          setOpen={handleEditDialogStatus}
          channel={channelToEdit}
        />
      )}

      {openChannelDialog && (
        <Popup
          open={openChannelDialog}
          setOpen={handleChannelDialog}
          channel={selectedChannel}
        />
      )}

      {isLoading ? (
        <Loading />
      ) : (
        <Paper>
          <EnhancedTableToolbar />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"small"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={channelsList.length}
              />

              <TableBody>
                {stableSort(channelsList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const { status } = row;
                    let totalSubmission =
                      row.totalCompanies - row.remaining_links;

                    return (
                      <TableRow hover key={index}>
                        <TableCell component="th" id={labelId} scope="row">
                          <Customer>#{row.number}</Customer>
                        </TableCell>

                        <TableCell>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={row.status}
                                onChange={(event) =>
                                  handleChannelStatus(row._id, {
                                    status: !status,
                                  })
                                }
                              />
                            }
                          />
                        </TableCell>

                        <TableCell
                          style={{ fontSize: "14px" }}
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          {getDateFormate(row.createdAt)}
                        </TableCell>

                        <TableCell
                          style={{ fontSize: "14px" }}
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <Customer>
                            <Box onClick={() => handleChannel(row)}>
                              {row.name}
                            </Box>
                          </Customer>
                        </TableCell>

                        <TableCell style={{ fontSize: "14px" }} align="center">
                          <NumberFormat
                            value={row.campaigns}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </TableCell>

                        <TableCell style={{ fontSize: "14px" }} align="center">
                          <NumberFormat
                            value={row.total_cost}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </TableCell>

                        <TableCell style={{ fontSize: "14px" }} align="center">
                          <NumberFormat
                            value={totalSubmission}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                          <Divider />
                          <NumberFormat
                            value={row.remaining_links}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                          <Divider />
                          <NumberFormat
                            value={row.totalCompanies}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </TableCell>

                        <TableCell style={{ fontSize: "14px" }} align="center">
                          <NumberFormat
                            value={row.submissionSuccessPercent}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix="%"
                          />
                        </TableCell>

                        <TableCell style={{ fontSize: "14px" }} align="center">
                          <NumberFormat
                            value={row.cost_per_submit}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix="$"
                          />
                        </TableCell>

                        <TableCell style={{ fontSize: "14px" }} align="center">
                          {row.totalLead && row.totalLead > 0 ? (
                            <div style={{ fontWeight: "700" }}>
                              <span>Total:</span>
                              <NumberFormat
                                value={row.totalLead}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </div>
                          ) : (
                            0
                          )}

                          {row.lead?.call && row.lead?.call > 0 ? (
                            <>
                              <Divider />
                              <span>Calls:</span>
                              <NumberFormat
                                value={row.lead.call}
                                displayType={"text"}
                                thousandSeparator={true}
                                //prefix="Calls: "
                              />
                            </>
                          ) : (
                            ""
                          )}

                          {row.lead?.submit && row.lead?.submit > 0 ? (
                            <>
                              <Divider />
                              <span>Forms:</span>
                              <NumberFormat
                                value={row.lead.submit}
                                displayType={"text"}
                                thousandSeparator={true}
                                //  prefix="Forms: "
                              />{" "}
                            </>
                          ) : (
                            ""
                          )}

                          {row.lead?.mail && row.lead?.mail > 0 ? (
                            <>
                              <Divider />
                              <NumberFormat
                                value={row.lead.mail}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix="Emails: "
                              />
                            </>
                          ) : (
                            ""
                          )}

                          {row.lead?.other && row.lead?.other > 0 ? (
                            <>
                              <Divider />
                              <NumberFormat
                                value={row.lead.other}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix="Others: "
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </TableCell>

                        <TableCell style={{ fontSize: "14px" }} align="center">
                          <NumberFormat
                            value={row.cost_per_lead}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix="$"
                          />
                        </TableCell>

                        <TableCell style={{ fontSize: "14px" }} align="center">
                          <NumberFormat
                            value={row.conversion_rate}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix="$"
                          />
                        </TableCell>

                        <TableCell style={{ fontSize: "14px" }} align="center">
                          <NumberFormat
                            value={row.total_users}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </TableCell>

                        <TableCell align="right">
                          <Tooltip title="Edit">
                            <IconButton
                              aria-label="details"
                              color="primary"
                              onClick={() => handleEditChannel(row)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={10} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={channelsList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  );
};

const ChannelsList = () => {
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState(false);

  const { error, success, isLoading } = useSelector(
    (state) => state.entities.channels
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (success || error) {
      setNotification(true);
    }

    if (success) dispatch(getProjects());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNotificationClose = () => {
    setNotification(false);
    dispatch(resetAll());
  };

  const loadChannels = () => {
    dispatch(getChannelsProjects());
  };

  return (
    <Fragment>
      {notification === true && (
        <Snackbar
          open={notification}
          autoHideDuration={5000}
          onClose={handleNotificationClose}
          TransitionComponent={TransitionLeft}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleNotificationClose}
            severity={success ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {success || error}
          </Alert>
        </Snackbar>
      )}

      <Helmet title="Organizations" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Organizations
          </Typography>
        </Grid>
        <Grid item>
          <Box display="flex" align="center">
            <Reload loading={isLoading} action={loadChannels} />

            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              style={{ backgroundColor: "#6a74c9", color: "white" }}
            >
              <AddIcon />
              New Organizations
            </Button>
            {open && (
              <ChannelPopup
                open={open}
                setOpen={setOpen}
                channel={null}
                handleClose={handleClose}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <OrganizationsTable />
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ChannelsList;
