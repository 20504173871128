import React, { useEffect, useState, Fragment } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import {
  Box,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Slide,
  Snackbar,
  Tab,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";
import GeneraleSettings from "./components/GeneraleSettings";
import { getOneGroup, resetAll } from "../../../redux/reducers/groupsReducer";
import { useParams } from "react-router-dom";
import Services from "./components/Services";
import Keywords from "./components/Keywords";
import Filters from "./components/Filters";
import { getTabNumber, updateTabNumber } from "../../../helpers/functions";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const LabTabs = ({ group }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("1");
  const { isLoading } = useSelector((state) => state.entities.groups);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    updateTabNumber("group_settings_tab", newValue);
  };

  useEffect(() => {
    const tabNumber = getTabNumber("group_settings_tab");
    if (tabNumber) setValue(tabNumber.toString());
  }, []);

  return (
    <Box
      sx={{ typography: "body1" }}
      style={{ position: "relative", height: "100%" }}
    >
      <TabContext value={value} style={{ height: "100%" }}>
        <Box style={{ display: "flex" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              orientation={"vertical"}
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab variant="h6" label="Details" value="1" />
              <Tab variant="h6" label="Keywords" value="2" />
              <Tab variant="h6" label="Filters" value="3" />
              <Tab variant="h6" label="Services" value="4" />
            </TabList>
          </Box>
          <Box style={{ width: "100%" }}>
            <TabPanel value="1">
              <GeneraleSettings
                group={group}
                dispatch={dispatch}
                isLoading={isLoading}
              />
            </TabPanel>
            <TabPanel value="2">
              <Keywords
                group={group}
                dispatch={dispatch}
                isLoading={isLoading}
              />
            </TabPanel>
            <TabPanel value="3">
              <Filters
                group={group}
                dispatch={dispatch}
                isLoading={isLoading}
              />
            </TabPanel>
            <TabPanel value="4">
              <Services
                group={group}
                dispatch={dispatch}
                isLoading={isLoading}
              />
            </TabPanel>
          </Box>
        </Box>
      </TabContext>
    </Box>
  );
};

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const Settings = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const [group, setGroup] = useState(null);
  const [notification, setNotification] = useState(false);
  const { success, error, list } = useSelector(
    (state) => state.entities.groups
  );
  const handleNotificationClose = () => {
    setNotification(false);
    dispatch(resetAll());
  };

  useEffect(() => {
    if (success || error) {
      setNotification(true);
    }
  }, [success, error]);

  useEffect(() => {
    if (id && list.length) {
      const found = list.find((item) => item._id === id);
      if (!found) dispatch(getOneGroup(id));
      else setGroup(found);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, list]);

  return (
    <Fragment>
      {notification === true && (
        <Snackbar
          open={notification}
          autoHideDuration={5000}
          onClose={handleNotificationClose}
          TransitionComponent={TransitionLeft}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleNotificationClose}
            severity={success ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {success || error}
          </Alert>
        </Snackbar>
      )}
      <Helmet title="Group Settings" />
      <Typography variant="h3" gutterBottom display="inline">
        Group Settings {group && `: ${group.name}`}
      </Typography>
      <Divider my={6} />
      <Grid
        container
        spacing={6}
        style={{ height: "96%", position: "relative" }}
      >
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent style={{ height: "100%" }}>
              {group && <LabTabs group={group} />}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Settings;
