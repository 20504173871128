import { createSlice } from "@reduxjs/toolkit";
import { getNumberPerPage } from "../../helpers/functions";
import { apiCallBegan } from "../actions/apiActions";
const url = "websites";

const slice = createSlice({
  name: "websites",
  initialState: {
    list: [],
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
    search: "",
    page: 0,
    per_page: 0,
    total_pages: 0,
    maxPage: 0,
    total_records: 0,
  },
  reducers: {
    resetAll: (websites, action) => {
      websites.isLoading = false;
      websites.error = null;
      websites.success = null;
    },
    requestStarted: (websites, action) => {
      websites.isLoading = true;
      websites.error = null;
      websites.isRequestSent = true;
    },
    requestedFailed: (websites, action) => {
      websites.error = action.payload;
      websites.isLoading = false;
    },
    loadWebsitesList: (websites, action) => {
      websites.page = action.payload.page;
      websites.per_page = action.payload.per_page;
      websites.total_pages = action.payload.total_pages;
      websites.total_records = action.payload.total_records;
      websites.list = websites.list.concat(action.payload.result);
      websites.search = action.payload.search;
      websites.maxPage =
        websites.page > websites.maxPage ? websites.page : websites.maxPage;
      websites.isLoading = false;
    },
    reLoadWebsitesList: (websites, action) => {
      websites.page = action.payload.page;
      websites.per_page = action.payload.per_page;
      websites.total_pages = action.payload.total_pages;
      websites.total_records = action.payload.total_records;
      websites.list = action.payload.result;
      websites.search = action.payload.search;
      websites.maxPage = 0;
      websites.isLoading = false;
    },
    websiteAdded: (websites, action) => {
      websites.success = "New Website Added";
      websites.list.unshift(action.payload);
      // websites.list_all.push({
      //   _id: action.payload._id,
      //   domain: action.payload.domain,
      //   key: action.payload.key,
      // });
      websites.isLoading = false;
    },
    websiteUpdated: (websites, action) => {
      const index = websites.list.findIndex(
        (website) => website._id === action.payload._id
      );
      websites.list[index] = action.payload;

      websites.isLoading = false;
      websites.success = "Website Updated";
    },
    websiteDeleted: (websites, action) => {
      const index = websites.list.findIndex(
        (website) => website._id === action.payload._id
      );
      websites.list.splice(index, 1);
      websites.isLoading = false;
      websites.success = "Website Deleted";
    },
  },
});

export const {
  resetAll,
  websiteAdded,
  websiteUpdated,
  websiteDeleted,
  loadWebsitesList,
  requestStarted,
  requestedFailed,
  reLoadWebsitesList,
} = slice.actions;
export default slice.reducer;

export const getWebsites = (params = {}) => {
  if (!params.per_page) params.per_page = getNumberPerPage();
  if (params.search === "" || params.search === null) delete params.search;
  delete params.channel;

  //Generate a URL query based on the object we got
  let query = new URLSearchParams(params);

  let resultsReceived = params.page
    ? loadWebsitesList.type
    : reLoadWebsitesList.type;

  return apiCallBegan({
    url: `${url}?${query}`,
    onStart: requestStarted.type,
    onSuccess: resultsReceived,
    onError: requestedFailed.type,
  });
};

export const addWebsite = (data) =>
  apiCallBegan({
    url,
    method: "POST",
    data,
    onStart: requestStarted.type,
    onSuccess: websiteAdded.type,
    onError: requestedFailed.type,
  });

export const updateWebsite = (data) =>
  apiCallBegan({
    url,
    method: "PUT",
    data,
    onStart: requestStarted.type,
    onSuccess: websiteUpdated.type,
    onError: requestedFailed.type,
  });

export const deleteWebsite = (_id) =>
  apiCallBegan({
    url,
    method: "DELETE",
    onStart: requestStarted.type,
    onSuccess: websiteDeleted.type,
    onError: requestedFailed.type,
    data: { _id },
  });
