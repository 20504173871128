import React, { useState, useEffect, Fragment, forwardRef } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUser,
  resetAll,
  getUsers,
} from "../../redux/reducers/usersReducer";
import {
  Box,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  FormControlLabel,
  Switch,
  Snackbar,
  InputBase,
  Slide,
} from "@material-ui/core";
import Popup from "./components/Popup";
import Addnew from "./components/Addnew";
import { Alert as MuiAlert } from "@material-ui/lab";
import { darken } from "polished";
import { Search as SearchIcon } from "react-feather";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import NumberFormat from "react-number-format";
import Loading from "../customComponent/Loading";
import { Users } from "react-feather";
import { TableName } from "../customComponent/TableName";
import { getDateTimeFormate } from "../../helpers/functions";
import InvitePopUp from "./components/InvitePopUp";
import Chip from "../customComponent/Chips";
import DeleteUserPopUp from "./components/DeleteUserPopUp";
import { ROWS_PER_PAGE_OPTIONS } from "../../constants";
import Actions from "./components/Actions";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const Spacer = styled.div`
  flex: 1 1 100%;
`;
const ButtonTable = styled.div`
  display: flex;
  align-items: center;
`;
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 100%;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 4px;
  border: 1px solid #6320ee;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  margin-right: 2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  width: 30rem;
  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "status", alignment: "left", label: "Status", sort: false },
  { id: "name", alignment: "left", label: "Name", sort: true },
  { id: "good_submits", alignment: "left", label: "Submits", sort: true },
  {
    id: "success_rate",
    alignment: "center",
    label: "Success",
    sort: true,
  },
  {
    id: "submits_per_hour",
    alignment: "center",
    label: "Submits/h",
    sort: true,
  },
  {
    id: "cost_per_submit",
    alignment: "center",
    label: "Cost Per Submit",
    sort: true,
  },
  { id: "payout", alignment: "center", label: "Total Paid", sort: true },
  {
    id: "last_activity",
    alignment: "center",
    label: "Last seen",
    sort: true,
  },
  {
    id: "current_campaign",
    alignment: "center",
    label: "Current Campaign",
    sort: true,
  },
  { id: "actions", alignment: "center", label: "Actions", sort: false },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontSize: "16px",
            }}
          >
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let EnhancedTableToolbar = (props) => {
  const { search } = useSelector((state) => state.entities.users);
  const [searchUser, setSearchUser] = useState(search);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    let search = e.target.value;

    setSearchUser(search);
    let data = {
      search: search.trim(),
    };

    if (e.keyCode === 13) {
      dispatch(getUsers(data));
    }
  };

  useEffect(() => {
    setSearchUser(search);
  }, [search]);

  return (
    <Toolbar>
      <TableName title="Users List" Component={Users} />
      <Spacer />
      <Grid item>
        <ButtonTable>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <Input
              placeholder="Search Users"
              value={searchUser}
              onChange={handleChange}
              onKeyUp={handleChange}
              inputRef={(input) => input && input.focus()}
            />
          </Search>
        </ButtonTable>
      </Grid>
    </Toolbar>
  );
};

function EnhancedTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("customer");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [UserToEdit, setUserToEdit] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { list: usersList, isLoading } = useSelector(
    (state) => state.entities.users
  );

  const { list: projectList } = useSelector((state) => state.entities.projects);

  const dispatch = useDispatch();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectees = usersList.map((n) => n.id);
      setSelected(newSelectees);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, usersList.length - page * rowsPerPage);

  const handleUserStatus = (user_id, status) => {
    dispatch(updateUser(user_id, status));
  };

  const handleUserUpdate = (project, user_id) => {
    dispatch(updateUser(user_id, { projects: [project._id] }));
  };

  const handleOpenDialog = (user) => {
    setUserToEdit({ ...user });
    dispatch(resetAll());
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteUser = (user) => {
    setUserToDelete(user);
    setDeleteDialogOpen(true);
  };

  return (
    <Box>
      {UserToEdit && (
        <Popup
          open={dialogOpen}
          setOpen={setDialogOpen}
          user={UserToEdit}
          handleClose={handleClose}
        />
      )}
      <Paper>
        {userToDelete && (
          <DeleteUserPopUp
            open={deleteDialogOpen}
            setOpen={handleDeleteUser}
            user={userToDelete}
          />
        )}
        <EnhancedTableToolbar />
        <TableContainer>
          {isLoading && !usersList.length ? (
            <Loading />
          ) : (
            <Table
              aria-labelledby="tableTitle"
              size={"small"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={usersList.length}
              />

              <TableBody>
                {stableSort(usersList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const { status } = row;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`${row.id}-${index}`}
                        selected={isItemSelected}
                        style={{ fontSize: "10px", padding: "5px" }}
                      >
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={row.status}
                                onChange={(event) =>
                                  handleUserStatus(row._id, {
                                    status: !status,
                                  })
                                }
                              />
                            }
                          />
                        </TableCell>

                        <TableCell component="th" id={labelId} scope="row">
                          <Customer>
                            <Box>{row.name}</Box>
                          </Customer>
                        </TableCell>
                        <TableCell align="center">
                          <NumberFormat
                            value={row.total_submitted}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <NumberFormat
                            value={row.success_submits_percent}
                            displayType={"text"}
                            allowLeadingZeros={true}
                            suffix="%"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <NumberFormat
                            thousandsGroupStyle="thousand"
                            value={row.submits_per_hour}
                            displayType={"text"}
                            allowLeadingZeros={true}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <NumberFormat
                            value={row.cost_per_submit}
                            displayType={"text"}
                            allowLeadingZeros={true}
                            suffix=" $"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <NumberFormat
                            value={row.total_amount}
                            displayType={"text"}
                            allowLeadingZeros={true}
                            suffix=" $"
                          />
                        </TableCell>
                        <TableCell align="center">
                          {getDateTimeFormate(row.last_time_logged)}
                        </TableCell>
                        <TableCell
                          style={{
                            minWidth: "250px",
                          }}
                          align="center"
                        >
                          <Chip
                            list={projectList}
                            selected={row.projects[0]}
                            label={"name"}
                            setSelected={(item) =>
                              handleUserUpdate(item, row._id)
                            }
                            multiple={false}
                            disabled={false}
                            size="small"
                          />
                        </TableCell>

                        <TableCell
                          style={{
                            display: "-webkit-box",
                            minWidth: "175px",
                          }}
                          align="right"
                        >
                          <Tooltip title="Edit">
                            <IconButton
                              aria-label="edit"
                              color="primary"
                              onClick={() => handleOpenDialog(row)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Delete">
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              onClick={() => handleDeleteUser(row)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={usersList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

function UsersList() {
  const [open, setOpen] = useState(false);
  const [inviteOpen, setInviteOpen] = useState(false);
  const [notification, setNotification] = useState(false);

  const { error, success } = useSelector((state) => state.entities.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (success || error) {
      setNotification(true);
    }
  }, [success, error]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleNotificationClose = () => {
    setNotification(false);
    dispatch(resetAll());
  };

  return (
    <Fragment>
      {inviteOpen && <InvitePopUp open={true} setOpen={setInviteOpen} />}

      {(success || error) && (
        <Snackbar
          open={notification}
          autoHideDuration={5000}
          onClose={handleNotificationClose}
          TransitionComponent={TransitionLeft}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleNotificationClose}
            severity={success ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {success || error}
          </Alert>
        </Snackbar>
      )}
      <Helmet title="Users" />

      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Users
          </Typography>
        </Grid>
        <Grid item>
          <Box display="flex" align="center">
            <Actions />
            {open && (
              <Addnew open={open} setOpen={setOpen} handleClose={handleClose} />
            )}
          </Box>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default UsersList;
