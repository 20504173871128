import React, { useState, useEffect, Fragment, forwardRef } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { InputBase, Snackbar } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import AlertDialog from "../customComponent/AlertDialog";
import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  Slide,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  FormControlLabel,
  Typography,
  Switch,
} from "@material-ui/core";
import {
  getComparator,
  getNumberPerPage,
  stableSort,
  updateNbrPerPage,
} from "../../helpers/functions";
import { Alert as MuiAlert } from "@material-ui/lab";
import {
  HelpOutline as InfoIcon,
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import { darken } from "polished";
import { spacing } from "@material-ui/system";
import WebsitePopup from "./components/Popup";
import Loading from "../customComponent/Loading";
import { Reload } from "../customComponent/Reload";
import { AtSign, Search as SearchIcon } from "react-feather";
import { TableName } from "../customComponent/TableName";
import { ROWS_PER_PAGE_OPTIONS } from "../../constants";
import {
  deleteWebsite,
  getWebsites,
  resetAll,
  updateWebsite,
} from "../../redux/reducers/websitesReducer";
import NumberFormat from "react-number-format";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const Search = styled.div`
  border-radius: 4px;
  border: 1px solid #6320ee;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  margin-right: 2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  width: 30rem;
  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 100%;
  }
`;

const ButtonTable = styled.div`
  display: flex;
  align-items: center;
`;

const headCells = [
  { id: "status", alignment: "left", label: "Status", sort: false },
  { id: "domain", alignment: "left", label: "Domain", sort: false },
  { id: "key", alignment: "left", label: "License Key", sort: false },
  { id: "actions", alignment: "right", label: "Actions", sort: false },
];
function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontSize: "16px",
            }}
          >
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {headCell.tooltip ? (
                  <Tooltip title={headCell.tooltip}>
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                ) : (
                  ""
                )}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let EnhancedTableToolbar = () => {
  const dispatch = useDispatch();
  const { search, status } = useSelector((state) => state.entities.websites);
  const [searchWebsite, setSearchWebsite] = useState(search);

  const handleChange = (e) => {
    let search = e.target.value;

    setSearchWebsite(search);
    let data = {
      search: search.trim(),
      status,
    };

    if (e.keyCode === 13) {
      dispatch(getWebsites(data));
    }
  };

  useEffect(() => {
    setSearchWebsite(search);
  }, [search]);

  return (
    <Toolbar>
      <TableName title="Websites List" Component={AtSign} />
      <Spacer />
      <Grid item>
        <ButtonTable>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <Input
              placeholder="Search Websites"
              value={searchWebsite}
              onChange={handleChange}
              onKeyUp={handleChange}
              inputRef={(input) => input && input.focus()}
            />
          </Search>
        </ButtonTable>
      </Grid>
    </Toolbar>
  );
};

const EnhancedTable = () => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("customer");
  const [page, setPage] = useState(0);
  const rowsPerPage = getNumberPerPage();
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [websiteToEdit, setWebsiteToEdit] = useState(null);
  const [websiteToDelete, setWebsiteToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const {
    search,
    status,
    page: serverPage,
    total_records,
    maxPage,
    list: websitesList,
    // success,
  } = useSelector((state) => state.entities.websites);

  useEffect(() => {
    if (maxPage === 0) setPage(0);
  }, [maxPage]);

  useEffect(() => {
    setPage(serverPage - 1);
  }, [serverPage]);

  const handleDeleteWebsite = (id) => {
    setWebsiteToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    let data = {
      page: newPage + 1,
      status,
    };

    if (search !== "") data.search = search;

    if (newPage > maxPage - 1) {
      dispatch(getWebsites(data));
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let per_page = event.target.value;
    let data = {
      per_page,
      status,
      search,
    };

    if (search !== "") data.search = search;

    updateNbrPerPage(per_page);
    setPage(0);
    dispatch(getWebsites(data));
  };

  const emptyRows =
    rowsPerPage -
    Math.min(websitesList, websitesList.length - page * rowsPerPage);

  const handleStatus = (id, data) => {
    dispatch(updateWebsite(id, data));
  };

  const handleEditDialogStatus = (open) => {
    if (!open) {
      setWebsiteToEdit(null);
    }
    setEditDialogOpen(open);
  };

  const handleEditWebsite = (website) => {
    setWebsiteToEdit(website);
    dispatch(resetAll());
    setEditDialogOpen(true);
  };

  const handleDeleteDialogStatus = (open) => {
    if (!open) {
      setDeleteDialogOpen(null);
    }
    setWebsiteToDelete(open);
  };

  const handleNotificationMessageClose = () => {
    setNotificationMessage(false);
    dispatch(resetAll());
  };

  return (
    <Box>
      {websiteToDelete && (
        <AlertDialog
          title="Confirm Removing the website?!"
          description="Removing the website."
          id={websiteToDelete}
          setOpen={handleDeleteDialogStatus}
          open={deleteDialogOpen}
          dispatchOnAgree={deleteWebsite}
        />
      )}

      {websiteToEdit && (
        <WebsitePopup
          open={editDialogOpen}
          setOpen={handleEditDialogStatus}
          website={websiteToEdit}
        />
      )}

      {notificationMessage && (
        <Snackbar
          open={notificationMessage}
          autoHideDuration={5000}
          onClose={handleNotificationMessageClose}
          TransitionComponent={TransitionLeft}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleNotificationMessageClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            {notificationMessage}
          </Alert>
        </Snackbar>
      )}
      <Paper>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={websitesList.length}
            />

            <TableBody>
              {stableSort(websitesList, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover key={`${row.id}-${index}`}>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row.status}
                              onChange={(event) =>
                                handleStatus(row._id, {
                                  status: !row.status,
                                })
                              }
                            />
                          }
                        />
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        <a
                          href={`https://${row.domain}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: "none" }}
                        >
                          {row.domain}
                        </a>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Copy to clipboard">
                          <Box
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigator.clipboard.writeText(row.key);
                            }}
                          >
                            {row.key.slice(0, 5) +
                              "********************************" +
                              row.key.slice(-3)}
                          </Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell padding="none" align="right">
                        <Box mr={2}>
                          <Tooltip title="Update Website">
                            <IconButton
                              aria-label="details"
                              color="primary"
                              onClick={() => handleEditWebsite(row)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete Website">
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              onClick={() => {
                                handleDeleteWebsite(row._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={total_records}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

const WebsitesList = () => {
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState(false);
  const { error, success, list, isLoading, total_records } = useSelector(
    (state) => state.entities.websites
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (success || error) {
      setNotification(true);
    }
  }, [success, error]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleNotificationClose = () => {
    setNotification(false);
    dispatch(resetAll());
  };

  const loadWebsites = () => {
    dispatch(getWebsites());
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <Fragment>
      {(success || error) && (
        <Snackbar
          open={notification}
          autoHideDuration={5000}
          onClose={handleNotificationClose}
          TransitionComponent={TransitionLeft}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleNotificationClose}
            severity={success ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {success || error}
          </Alert>
        </Snackbar>
      )}

      <Helmet title="Websites List" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Websites List&nbsp;
            {!isLoading && (
              <NumberFormat
                style={{ fontSize: "15px" }}
                value={total_records}
                displayType={"text"}
                thousandSeparator={true}
              />
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Box>
            <Box display="flex" align="center">
              <Reload loading={isLoading} action={loadWebsites} />
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
                style={{ backgroundColor: "#6a74c9", color: "white" }}
              >
                <AddIcon />
                New Website
              </Button>
            </Box>
            {open && (
              <WebsitePopup
                open={open}
                setOpen={setOpen}
                project={null}
                handleClose={handleClose}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            {isLoading && list.length === 0 ? <Loading /> : <EnhancedTable />}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default WebsitesList;
