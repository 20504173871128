import * as React from "react";
import { Box, FormControl, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  shrink: {
    display: "none",
  },
  input: {
    padding: "8.5px 10px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    backgroundColor: "#f5f6fa",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    backgroundColor: "#dfdcdc",
  },
}));

const Chip = ({
  label,
  value,
  list,
  placeholder,
  selected,
  setSelected,
  multiple,
  disabled,
  error,
  size = "small",
  id = "id",
  shrink = false,
  variant = "outlined",
}) => {
  const classes = useStyles();

  return (
    <Box>
      <FormControl fullWidth style={{ backgroundColor: "#F9F9FC" }}>
        <Autocomplete
          multiple={multiple}
          id={id}
          options={list}
          getOptionLabel={(item) => item[label] || item}
          onChange={(e, item) => setSelected(item[value] || item)}
          defaultValue={selected}
          disabled={disabled ?? false}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              inputlabelprops={{ shrink }}
              InputProps={{ classes: { root: classes.input } }}
              {...params}
              variant={variant}
              label={placeholder}
              size={size}
            />
          )}
        />
      </FormControl>
    </Box>
  );
};

export default Chip;
