import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/apiActions";
const url = "hubstaff";

const slice = createSlice({
  name: "hubstaff",
  initialState: {
    list: [],
    stats: [],
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
  },
  reducers: {
    resetAll: (hubstaffProjects, action) => {
      hubstaffProjects.isLoading = false;
      hubstaffProjects.error = null;
      hubstaffProjects.success = null;
    },
    requestStarted: (hubstaffProjects, action) => {
      hubstaffProjects.isLoading = true;
      hubstaffProjects.error = null;
      hubstaffProjects.isRequestSent = true;
    },
    requestedFailed: (hubstaffProjects, action) => {
      hubstaffProjects.error = action.payload;
      hubstaffProjects.isLoading = false;
    },
    hubstaffProjectsLoaded: (hubstaffProjects, action) => {
      hubstaffProjects.list = action.payload;
      hubstaffProjects.isLoading = false;
    },

    hubstaffStatsLoaded: (hubstaffProjects, action) => {
      hubstaffProjects.stats = action.payload;
      hubstaffProjects.isLoading = false;
    },
  },
});

export const {
  hubstaffProjectsLoaded,
  requestedFailed,
  requestStarted,
  hubstaffStatsLoaded,
} = slice.actions;
export default slice.reducer;

export const getHubstaffProjects = () =>
  apiCallBegan({
    url: url + "/projects",
    onStart: requestStarted.type,
    onSuccess: hubstaffProjectsLoaded.type,
    onError: requestedFailed.type,
  });

export const getHubstaffStats = () =>
  apiCallBegan({
    url: url + "/stats",
    onStart: requestStarted.type,
    onSuccess: hubstaffStatsLoaded.type,
    onError: requestedFailed.type,
  });
