import { useState, useEffect } from "react";
import {
  Button as MuiButton,
  CircularProgress,
  TextField as MuiTextField,
} from "@material-ui/core";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import { updateGroup } from "../../../../redux/reducers/groupsReducer";

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const GeneraleSettings = ({ group, dispatch, isLoading = false }) => {
  const [data, setData] = useState(group || {});

  useEffect(() => {
    setData(group);
  }, [group]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { _id, name, description } = data;
    let UpdateData = {
      name,
      description,
    };
    dispatch(updateGroup(_id, UpdateData));
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <TextField
        id="name"
        label="Name*"
        variant="outlined"
        value={data?.name || ""}
        onChange={(e) => handleChange(e)}
        fullWidth
        height={"60rem"}
        my={2}
      />
      <TextField
        id="description"
        label="Description*"
        variant="outlined"
        value={data?.description || ""}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
        rows={4}
        multiline
      />

      <Button
        variant="contained"
        type="submit"
        style={{
          backgroundColor: "#6a74c9",
          color: "white",
          width: "8vw",
          position: "absolute",
          right: "0",
          bottom: "0",
        }}
      >
        {isLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Save changes"
        )}
      </Button>
    </form>
  );
};

export default GeneraleSettings;
