import { useState, useEffect } from "react";
import {
  Button as MuiButton,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";
import { updateDirectory } from "../../../../../redux/reducers/directoriesReducer";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import Chip from "../../../../customComponent/Chips";
import { useSelector } from "react-redux";
const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const GeneraleSettings = ({ directory, dispatch, isLoading = false }) => {
  const [data, setData] = useState(directory || {});
  const [groups, setGroups] = useState(directory?.groups);
  const { all: groupsList } = useSelector((state) => state.entities.groups);

  useEffect(() => {
    setData(directory);
    setGroups(directory.groups);
  }, [directory]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { _id, name, description, url, username, password } = data;
    let UpdateData = {
      _id,
      name,
      url,
      username,
      password,
      description,
      groups,
    };
    dispatch(updateDirectory(UpdateData));
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <TextField
        id="name"
        label="Name*"
        variant="outlined"
        value={data?.name || ""}
        onChange={(e) => handleChange(e)}
        fullWidth
        height={"60rem"}
        my={2}
      />
      <TextField
        id="description"
        label="Description*"
        variant="outlined"
        value={data?.description || ""}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
        rows={4}
        multiline
      />
      <Divider my={3} />
      <Grid item style={{ padding: "9px 0px" }}>
        <Chip
          list={groupsList}
          selected={groups}
          placeholder={"Groups"}
          label="name"
          setSelected={setGroups}
          multiple={true}
        />
      </Grid>
      <Divider my={3} />
      <TextField
        id="url"
        label="URL*"
        variant="outlined"
        value={data?.url || ""}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
      />
      <Grid style={{ display: "flex" }}>
        <Grid item md={6} style={{ paddingRight: "1rem" }}>
          <TextField
            id="username"
            label="User Name*"
            variant="outlined"
            value={data?.username || ""}
            onChange={(e) => handleChange(e)}
            fullWidth
            height={"60rem"}
            my={2}
          />
        </Grid>
        <Grid item md={6} style={{ paddingLeft: "1rem" }}>
          <TextField
            id="password"
            label="Password*"
            variant="outlined"
            value={data?.password || ""}
            onChange={(e) => handleChange(e)}
            fullWidth
            height={"60rem"}
            my={2}
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        type="submit"
        style={{
          backgroundColor: "#6a74c9",
          color: "white",
          width: "8vw",
          position: "absolute",
          right: "0",
          bottom: "0",
        }}
      >
        {isLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Save changes"
        )}
      </Button>
    </form>
  );
};

export default GeneraleSettings;
