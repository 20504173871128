import React from "react";
import styled from "styled-components/macro";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Divider as MuiDivider,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CardContent,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import { spacing } from "@material-ui/system";
import { spacing } from "@material-ui/system";
import NumberFormat from "react-number-format";

const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },
  dialogwrapper: {
    position: "absolute",
    maxWidth: "40rem",
    // top: theme.spacing(5)
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  startICon: {
    margin: 0,
  },
  chips: {
    height: "20px",
    borderRadius: "0px",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  ButtonApprove: {
    backgroundColor: "#23CC94",
    color: "white",
    width: "6rem",
    margin: "12px 39px",
  },
  description: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  h5: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  controlinput: {
    backgroundColor: "#F9F9FC",
  },
}));

const PopupStats = ({ open, setOpen, handleClose, stats }) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialogwrapper }}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            style={{ color: "#868695" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <CardContent>
          {stats && Object.keys(stats).length
            ? Object.keys(stats).map((stat, index) => {
                const percents = ["CTR Submits", "CR Visits", "CR Submits"];

                return (
                  <Box key={index}>
                    {index !== 0 && <Divider />}
                    <NumberFormat
                      value={stats[stat]}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={`${stat}: `}
                      suffix={percents.includes(stat) ? "%" : ""}
                    />
                  </Box>
                );
              })
            : "-"}
        </CardContent>
      </DialogContent>
    </Dialog>
  );
};

export default PopupStats;
