/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  CardContent,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { updateDirectory } from "../../../../../../redux/reducers/directoriesReducer";
import MySelect from "../../../../../customComponent/MySelect";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },
  dialogWrapper: {
    position: "absolute",
    maxWidth: "50rem",
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  startICon: {
    margin: 0,
  },
  chips: {
    height: "20px",
    borderRadius: "0px",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  ButtonApprove: {
    backgroundColor: "#23CC94",
    color: "white",
    width: "6rem",
    margin: "12px 39px",
  },
  description: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  h5: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
  },
  shrink: {
    display: "none",
  },
  inputSelect: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    backgroundColor: "#f5f6fa",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
}));

const Popup = ({ open, setOpen, directory, email_provider }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [list_id, setList_id] = useState(null);
  const [status, setStatus] = useState(false);
  const [API_KEY, setAPI_KEY] = useState(false);
  const { sendGridList } = useSelector((state) => state.entities.directories);

  useEffect(() => {
    setList_id(email_provider?.list_id);
    setStatus(email_provider?.status);
    setAPI_KEY(email_provider?.API_KEY);
  }, [email_provider]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (directory && email_provider) {
      let email_providers = [...directory.email_providers];
      const providerIndex = email_providers.findIndex(
        (item) => item._id === email_provider._id
      );
      const emailProviderUpdated = {
        id: email_provider._id,
        provider_name: "SendGrid",
        list_id,
        status,
        API_KEY,
      };
      email_providers[providerIndex] = emailProviderUpdated;
      const data = {
        _id: directory._id,
        email_providers,
      };
      dispatch(updateDirectory(data));
      setOpen(false);
    }
  };

  const changeAPI_KEY = (e) => {
    setAPI_KEY(e.target.value);
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogWrapper }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <span style={{ fontSize: "20px" }}>SendGrid Settings</span>
        <Button
          style={{ color: "#868695", position: "absolute", right: 0 }}
          classes={{ root: classes.rootButton }}
          onClick={() => {
            setOpen(false);
          }}
        >
          <CloseIcon />
        </Button>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item md={12} style={{ padding: "9px 0" }}>
                <Grid item md={12} style={{ display: "flex" }}>
                  <Typography variant="h5" classes={{ h5: classes.h5 }}>
                    Status:
                  </Typography>
                  <FormControlLabel
                    style={{
                      marginRight: "2rem",
                      marginTop: "-10px",
                      right: 0,
                      position: "absolute",
                    }}
                    control={
                      <Switch
                        checked={status}
                        onChange={(event) => {
                          setStatus(!status);
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Typography variant="h5" classes={{ h5: classes.h5 }}>
                Send Grid Contact List:
              </Typography>
              <MySelect
                selected={list_id}
                defaultLabel={""}
                defaultValue={""}
                list={sendGridList}
                label="name"
                value="id"
                data={{}}
                func={setList_id}
              />
              <Grid item md={12} style={{ padding: "9px 0" }}>
                <Typography variant="h5" classes={{ h5: classes.h5 }}>
                  API_KEY*:
                </Typography>
                <TextField
                  id="API_KEY"
                  name="API_KEY"
                  placeholder="API_KEY"
                  InputProps={{ classes: { input: classes.input } }}
                  inputlabelprops={{ shrink: false }}
                  value={API_KEY}
                  onChange={changeAPI_KEY}
                  fullWidth
                  type="text"
                  variant="outlined"
                  my={2}
                />
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                style={{
                  marginTop: "10px",
                  backgroundColor: "#6a74c9",
                  color: "white",
                }}
                type="submit"
                variant="contained"
                mt={4}
              >
                {directory ? "Update" : "Add New"}
              </Button>
            </Grid>
          </form>
        </CardContent>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
