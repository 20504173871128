import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, Box } from "@material-ui/core";
import {
  getChannelsProjects,
  getChannelStats,
} from "../../../redux/reducers/channelReducer";
import MySelect from "../../customComponent/MySelect";
import { getProjects } from "../../../redux/reducers/projectReducer";
import DatePicker from "../../customComponent/DateRangePicker";
import { Reload } from "../../customComponent/Reload";

const Actions = () => {
  const dispatch = useDispatch();

  const {
    isLoading,
    selected_date,
    selectedChannel,
    selected_project,
    selected_message,
    selected_profile,
  } = useSelector((state) => state.entities.channels);
  const { allProjects: project_list } = useSelector(
    (state) => state.entities.projects
  );
  const { all: messages_list } = useSelector(
    (state) => state.entities.messages
  );
  const { list_all: profiles_list } = useSelector(
    (state) => state.entities.profiles
  );

  const [projectsList, setProjectsList] = useState([]);
  const [messagesList, setMessagesList] = useState([]);
  const [profilesList, setProfilesList] = useState([]);

  const handleRefresh = () => {
    dispatch(getProjects());
    dispatch(getChannelsProjects());
    dispatch(
      getChannelStats({
        date: selected_date,
        project: selected_project,
        profile: selected_profile,
        message: selected_message,
      })
    );
  };

  useEffect(() => {
    let list = project_list.filter(
      (project) => project.channel.toString() === selectedChannel
    );
    setProjectsList(list);
  }, [project_list, selectedChannel]);

  useEffect(() => {
    let list = messages_list.filter(
      (message) => message.channel.toString() === selectedChannel
    );
    if (selected_project && selected_project !== "all") {
      let project = project_list.find(
        (i) => i._id.toString() === selected_project
      );
      list = list.filter((message) => project?.messages?.includes(message._id));
    }
    setMessagesList(list);
  }, [messages_list, project_list, selectedChannel, selected_project]);

  useEffect(() => {
    let list = profiles_list.filter(
      (profile) => profile.channel.toString() === selectedChannel
    );
    if (selected_message && selected_message !== "all") {
      let message = messages_list.find(
        (i) => i._id.toString() === selected_message
      );
      list = profiles_list.filter((profile) =>
        message?.profiles?.includes(profile._id)
      );
    }
    setProfilesList(list);
  }, [profiles_list, messages_list, selectedChannel, selected_message]);

  return (
    <Fragment>
      <Box display="flex" align="center">
        <Reload loading={isLoading} action={handleRefresh} />
        <FormControl style={{ marginRight: "1rem" }}>
          <MySelect
            selected={selected_project}
            defaultLabel="All Campaigns"
            defaultValue="all"
            list={projectsList}
            label="name"
            value="_id"
            valueHolder="project"
            data={{
              date: selected_date,
              message: selected_message,
              profile: selected_profile,
            }}
            toDispatch={getChannelStats}
          />
        </FormControl>
        <FormControl style={{ marginRight: "1rem" }}>
          <MySelect
            selected={selected_message}
            defaultLabel="All Messages"
            defaultValue="all"
            list={messagesList}
            label="label"
            value="_id"
            valueHolder="message"
            data={{
              date: selected_date,
              project: selected_project,
              profile: selected_profile,
            }}
            toDispatch={getChannelStats}
          />
        </FormControl>
        <FormControl style={{ marginRight: "1rem" }}>
          <MySelect
            selected={selected_profile}
            defaultLabel="All Aliases"
            defaultValue="all"
            list={profilesList}
            label="email"
            value="_id"
            valueHolder="profile"
            data={{
              date: selected_date,
              project: selected_project,
              message: selected_message,
            }}
            toDispatch={getChannelStats}
          />
        </FormControl>
        <FormControl>
          <DatePicker
            data={{
              project: selected_project,
              message: selected_message,
              profile: selected_profile,
            }}
            toDispatch={getChannelStats}
          />
        </FormControl>
      </Box>
    </Fragment>
  );
};

export default Actions;
