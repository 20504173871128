import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  CardContent,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  addProfile,
  updateProfile,
} from "../../../redux/reducers/profilesReducer";
import ChipList from "../../customComponent/Chips";
import { syncChannelFilter } from "../../../helpers/functions";

const Alert = styled(MuiAlert)(spacing);

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },
  dialogWrapper: {
    position: "absolute",
    maxWidth: "40rem",
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  startICon: {
    margin: 0,
  },
  chips: {
    height: "20px",
    borderRadius: "0px",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  ButtonApprove: {
    backgroundColor: "#23CC94",
    color: "white",
    width: "6rem",
    margin: "12px 39px",
  },
  description: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  h5: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
}));

const Popup = ({ open, setOpen, handleClose, profile, duplicate }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [channelId, setChannelId] = useState(syncChannelFilter());
  const [selectApproach, setSelectedApproach] = useState(null);
  const [selectTone, setSelectedTone] = useState(null);
  const profiles = useSelector((state) => state.entities.profiles);
  const { TONE_LIST, APPROACHES_LIST } = useSelector(
    (state) => state.entities.settings.setting
  );

  const initialValues = {
    sender: profile ? profile.sender : "",
    email: profile && !duplicate ? profile.email : "",
    phone: profile ? profile.phone : "",
    address: profile ? profile.address : "",
    url: profile && !duplicate ? profile.url : "",
    city: profile ? profile.city : "",
    state: profile ? profile.state : "",
    zip: profile ? profile.zip : "",
  };

  const validationSchema = Yup.object().shape({
    sender: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    zip: Yup.string().required("Required"),
  });

  useEffect(() => {
    setSelectedApproach(profile?.approach);
    setSelectedTone(profile?.tone);
  }, [profile]);

  const handleSubmit = (values) => {
    const data = {
      sender: values.sender,
      email: values.email,
      phone: values.phone,
      url: values.url,
      address: values.address,
      city: values.city,
      state: values.state,
      zip: values.zip,
      // approach: selectApproach,
      // tone: selectTone,
      channel: channelId,
    };

    if (profile && !duplicate) {
      // dispatch update
      const id = profile._id;
      dispatch(updateProfile(id, data));
      setOpen(false);
    } else {
      // dispatch add
      dispatch(addProfile(data));
      setOpen(false);
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogWrapper }}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" classes={{ h5: classes.h5 }}>
            {profile && !duplicate ? "Update Alias" : "Add New Alias"}
          </Typography>

          <Button
            style={{ color: "#868695" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
          }) => (
            <>
              <CardContent>
                {profiles.success && (
                  <Alert severity="success" my={3}>
                    New profile has been created successfully!
                  </Alert>
                )}
                {profiles.error && (
                  <Alert severity="error" my={3}>
                    Error: {profiles.error}
                  </Alert>
                )}
                {profiles.isLoading ? (
                  <Box display="flex" justifyContent="center" my={6}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <Grid item md={6} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          URL*:
                        </Typography>
                        <TextField
                          inputlabelprops={{ shrink: false }}
                          placeholder="https://domain.com"
                          InputProps={{
                            classes: {
                              input: classes.input,
                            },
                          }}
                          name="url"
                          value={values.url}
                          error={Boolean(touched.url && errors.url)}
                          fullWidth
                          helpertext={touched.url && errors.url}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          type="text"
                        />
                      </Grid>

                      <Grid item md={6} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Email*:
                        </Typography>
                        <TextField
                          inputlabelprops={{ shrink: false }}
                          placeholder="Email"
                          InputProps={{ classes: { input: classes.input } }}
                          name="email"
                          value={values.email}
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helpertext={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>

                      {profile ? (
                        <Grid md={12} style={{ display: "flex" }}>
                          <Grid item md={6} style={{ padding: "9px" }}>
                            <Typography
                              variant="h5"
                              classes={{ h5: classes.h5 }}
                            >
                              Approach*:
                            </Typography>
                            <ChipList
                              list={APPROACHES_LIST || []}
                              selected={selectApproach}
                              setSelected={setSelectedApproach}
                              disabled={true}
                            />
                          </Grid>

                          <Grid item md={6} style={{ padding: "9px" }}>
                            <Typography
                              variant="h5"
                              classes={{ h5: classes.h5 }}
                            >
                              Tone*:
                            </Typography>
                            <ChipList
                              list={TONE_LIST || []}
                              selected={selectTone}
                              setSelected={setSelectedTone}
                              disabled={true}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      )}

                      <Grid item md={12} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Full Name*:
                        </Typography>
                        <TextField
                          inputlabelprops={{ shrink: false }}
                          placeholder="Sender"
                          InputProps={{ classes: { input: classes.input } }}
                          name="sender"
                          value={values.sender}
                          error={Boolean(touched.sender && errors.sender)}
                          fullWidth
                          helpertext={touched.sender && errors.sender}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={12} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Phone*:
                        </Typography>
                        <TextField
                          inputlabelprops={{ shrink: false }}
                          placeholder="Phone"
                          InputProps={{
                            classes: {
                              input: classes.input,
                            },
                          }}
                          name="phone"
                          value={values.phone}
                          error={Boolean(touched.phone && errors.phone)}
                          fullWidth
                          helpertext={touched.phone && errors.phone}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          type="text"
                        />
                      </Grid>
                      <Grid item md={12} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Address*:
                        </Typography>
                        <TextField
                          inputlabelprops={{ shrink: false }}
                          placeholder="Address"
                          InputProps={{
                            classes: {
                              input: classes.input,
                            },
                          }}
                          name="address"
                          value={values.address}
                          error={Boolean(touched.address && errors.address)}
                          fullWidth
                          helpertext={touched.address && errors.address}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          type="text"
                        />
                      </Grid>

                      <Grid item md={4} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          City*:
                        </Typography>
                        <TextField
                          InputProps={{ classes: { input: classes.input } }}
                          inputlabelprops={{ shrink: false }}
                          name="city"
                          placeholder="City"
                          value={values.city}
                          error={Boolean(touched.city && errors.city)}
                          fullWidth
                          helpertext={touched.city && errors.city}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={4} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          State*:
                        </Typography>
                        <TextField
                          InputProps={{ classes: { input: classes.input } }}
                          inputlabelprops={{ shrink: false }}
                          name="state"
                          placeholder="State"
                          value={values.state}
                          error={Boolean(touched.state && errors.state)}
                          fullWidth
                          helpertext={touched.state && errors.state}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={4} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Zip*:
                        </Typography>
                        <TextField
                          InputProps={{ classes: { input: classes.input } }}
                          inputlabelprops={{ shrink: false }}
                          name="zip"
                          placeholder="Zip"
                          value={values.zip}
                          error={Boolean(touched.zip && errors.zip)}
                          fullWidth
                          helpertext={touched.zip && errors.zip}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>

                    <div style={{ float: "right", margin: "40px 0" }}>
                      <Button
                        style={{ marginRight: "15px" }}
                        // type="outlined"
                        variant="outlined"
                        color="primary"
                        mt={3}
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{
                          width: "10rem",
                          backgroundColor: "#6a74c9",
                          color: "white",
                        }}
                        type="submit"
                        variant="contained"
                        color="primary"
                        mt={3}
                      >
                        {profile && !duplicate ? "Update" : "Add New"}
                      </Button>
                    </div>
                  </form>
                )}
              </CardContent>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
