const overrides = {
  MuiCard: {
    root: {
      borderRadius: "10px",
      height: "100%",
      //boxShadow: "rgba(50, 50, 93, 0.025) 0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
      // "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
      //boxShadow: shadows[2],
      // background: "linear-gradient(145deg, #e6e6e6, #ffffff)",
      // boxShadow: "-2px 5px 10px #c9c9c9, -5px -5px 10px #ffffff",
    },
  },
  MuiPaper: {
    root: {
      //boxShadow: "rgba(50, 50, 93, 0.025) 0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
      // "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
      //boxShadow: shadows[2],
      // boxShadow: "2px 2px 8px #c9c9c9, -2px -2px 8px #000",
      webkitBoxShadow: "3px 3px 17px -4px rgba(0,0,0,0.75)",
      mozBoxShadow: "3px 3px 17px -4px rgba(0,0,0,0.75)",
      boxShadow: "3px 3px 8px -2px rgba(0,0,0,0.50)",
    },
  },
  MuiCardHeader: {
    action: {
      marginTop: "-4px",
      marginRight: "-4px",
    },
  },
  MuiPickersDay: {
    day: {
      fontWeight: "300",
    },
  },
  MuiPickersYear: {
    root: {
      height: "64px",
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      marginTop: "6px",
    },
  },
  MuiPickersCalendarHeader: {
    iconButton: {
      backgroundColor: "transparent",
      "& > *": {
        backgroundColor: "transparent",
      },
    },
    switchHeader: {
      marginTop: "2px",
      marginBottom: "4px",
    },
  },
  MuiPickersClock: {
    container: {
      margin: `32px 0 4px`,
    },
  },
  MuiPickersClockNumber: {
    clockNumber: {
      left: `calc(50% - 16px)`,
      width: "32px",
      height: "32px",
    },
  },
  MuiPickerDTHeader: {
    dateHeader: {
      "& h4": {
        fontSize: "2.125rem",
        fontWeight: 400,
      },
    },
    timeHeader: {
      "& h3": {
        fontSize: "3rem",
        fontWeight: 400,
      },
    },
  },
  MuiPickersTimePicker: {
    hourMinuteLabel: {
      "& h2": {
        fontSize: "3.75rem",
        fontWeight: 300,
      },
    },
  },
  MuiPickersToolbar: {
    toolbar: {
      "& h4": {
        fontSize: "2.125rem",
        fontWeight: 400,
      },
    },
  },
  MuiChip: {
    root: {
      borderRadius: "6px",
    },
  },
};

export default overrides;
