import { createSlice } from "@reduxjs/toolkit";
import { getNumberPerPage } from "../../helpers/functions";
import { apiCallBegan } from "../actions/apiActions";
const url = "channels";

const slice = createSlice({
  name: "channels",
  initialState: {
    list: [],
    list_all: [],
    project_stats: [],
    list_project: [],
    stats: [],
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,

    selectedChannel: null,
    selected_channel: null,
    selected_date: null,
    selected_project: null,
    selected_message: null,
    selected_profile: null,
  },

  reducers: {
    resetAll: (channels) => {
      channels.isLoading = false;
      channels.error = null;
      channels.success = null;
    },
    requestStarted: (channels, action) => {
      channels.isLoading = true;
      channels.error = null;
      channels.isRequestSent = true;
    },
    requestedFailed: (channels, action) => {
      channels.error = action.payload;
      channels.isLoading = false;
    },
    channelsProjectLoaded: (channels, action) => {
      channels.list_project = action.payload;
      channels.isLoading = false;
    },
    channelsLoaded: (state, action) => {
      state.page = action.payload.page;
      state.per_page = action.payload.per_page;
      state.total_pages = action.payload.total_pages;
      state.total_records = action.payload.total_records;
      state.list = action.payload.result;
      state.selectedChannel = action.payload.selectedChannel;
      state.maxPage = state.page > state.maxPage ? state.page : state.maxPage;
      state.isLoading = false;
    },
    allChannelsLoaded: (channels, action) => {
      channels.list_all = action.payload;
      channels.isLoading = false;
    },
    channelStatsLoaded: (channels, action) => {
      channels.stats = action.payload.stats;
      channels.selectedChannel = action.payload.selectedChannel;
      channels.selected_project = action.payload.selected_project;
      channels.selected_date = action.payload.selected_date;
      channels.selected_message = action.payload.selected_message;
      channels.selected_profile = action.payload.selected_profile;
      channels.isLoading = false;
    },
    channelAdded: (channels, action) => {
      channels.list_all.push(action.payload);
      channels.list.push(action.payload);
      channels.isLoading = false;
      channels.success = "Organization Added";
    },
    channelUpdated: (channels, action) => {
      const index_of_all = channels.list_all.findIndex(
        (channel) => channel._id === action.payload._id
      );
      const index_of_list = channels.list.findIndex(
        (channel) => channel._id === action.payload._id
      );
      if (index_of_all !== -1) {
        channels.list_all[index_of_all].name = action.payload.name;
        channels.list_all[index_of_all].status = action.payload.status;
      }
      if (index_of_list !== -1) {
        channels.list[index_of_list].name = action.payload;
        channels.list[index_of_list].status = action.payload;
      }

      channels.list = action.payload;

      channels.isLoading = false;
      channels.success = "Organization Updated";
    },
    channelChanged: (messages, action) => {
      messages.selectedChannel = action.payload;
    },
  },
});

export const {
  resetAll,
  channelAdded,
  requestStarted,
  channelsLoaded,
  channelChanged,
  channelUpdated,
  requestedFailed,
  allChannelsLoaded,
  channelStatsLoaded,
  channelsProjectLoaded,
} = slice.actions;
export default slice.reducer;

export const getChannels = (params = {}) => {
  params.per_page = getNumberPerPage();
  let query = new URLSearchParams(params);
  return apiCallBegan({
    url: `${url}?${query}`,
    onStart: requestStarted.type,
    onSuccess: channelsLoaded.type,
    onError: requestedFailed.type,
  });
};

export const getAllChannels = () =>
  apiCallBegan({
    url: `${url}/all`,
    onStart: requestStarted.type,
    onSuccess: allChannelsLoaded.type,
    onError: requestedFailed.type,
  });

export const getChannelsProjects = (data) => {
  return apiCallBegan({
    url: url + "/projects",
    onStart: requestStarted.type,
    onSuccess: channelsProjectLoaded.type,
    onError: requestedFailed.type,
  });
};

export const getChannelStats = (params = {}) => {
  delete params.channel;

  if (params.startDate === null) {
    delete params.date;
  } else {
    if (params.date && params.date.startDate)
      params.startDate = params.date.startDate;
    if (params.date && params.date.endDate)
      params.endDate = params.date.endDate;

    delete params.date;
  }

  if (!params.project || params.project === "all") delete params.project;
  if (!params.message || params.message === "all") delete params.message;
  if (!params.profile || params.profile === "all") delete params.profile;

  let query = new URLSearchParams(params);
  return apiCallBegan({
    url: url + `/stats?${query}`,
    onStart: requestStarted.type,
    onSuccess: channelStatsLoaded.type,
    onError: requestedFailed.type,
  });
};

export const updateChannel = (id, data = {}) =>
  apiCallBegan({
    url: url + "/" + id,
    method: "put",
    data,
    onStart: requestStarted.type,
    onSuccess: channelUpdated.type,
    onError: requestedFailed.type,
  });

export const addChannel = (data) =>
  apiCallBegan({
    url,
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: channelAdded.type,
    onError: requestedFailed.type,
  });

export const archiveChannel = (id) =>
  apiCallBegan({
    url,
    method: "put",
    onStart: requestStarted.type,
    onSuccess: channelAdded.type,
    onError: requestedFailed.type,
  });

export const restoreChannel = (id) =>
  apiCallBegan({
    url,
    method: "put",
    onStart: requestStarted.type,
    onSuccess: channelAdded.type,
    onError: requestedFailed.type,
  });
