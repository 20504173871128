import {
  Box,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import { TableName } from "../../../../customComponent/TableName";
import { Send as iconEmail } from "react-feather";
import SendGridPopUp from "./components/SendGridPopUp";
import { ROWS_PER_PAGE_OPTIONS } from "../../../../../constants";
import {
  resetAll,
  updateDirectory,
} from "../../../../../redux/reducers/directoriesReducer";
import { Edit as EditIcon } from "@material-ui/icons";

const EmailsProviderSettings = ({ directory, dispatch, isLoading = false }) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [providerToEdit, setProviderToEdit] = useState(null);
  const [email_providersToEdit, setEmail_providersToEdit] = useState(null);
  const [data, setData] = useState(directory);
  const [values, setValues] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  useEffect(() => {
    setData(directory);
    setValues(directory?.email_providers);
  }, [directory]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const handleEditDialogStatus = (open) => {
    if (!open) {
      setProviderToEdit(null);
    }
    setEditDialogOpen(open);
  };

  const handleEditProvider = (provider) => {
    setProviderToEdit(data);
    setEmail_providersToEdit(provider);
    dispatch(resetAll());
    setEditDialogOpen(true);
  };

  const handleStatus = ({ email_provider, status }) => {
    let email_providers = [...directory.email_providers];
    const providerIndex = email_providers.findIndex(
      (item) => item._id === email_provider._id
    );
    const emailProviderUpdated = {
      ...email_provider,
      status,
    };
    email_providers[providerIndex] = emailProviderUpdated;
    const data = {
      _id: directory._id,
      email_providers,
    };
    dispatch(updateDirectory(data));
  };

  const headCells = [
    { id: "status", alignment: "left", label: "Status", sort: false },
    { id: "name", alignment: "left", label: "Name", sort: false },
    { id: "actions", alignment: "right", label: "Actions", sort: false },
  ];

  return (
    <Paper>
      {providerToEdit && (
        <SendGridPopUp
          open={editDialogOpen}
          setOpen={handleEditDialogStatus}
          directory={providerToEdit}
          email_provider={email_providersToEdit}
        />
      )}
      <Toolbar>
        <TableName title="Provides List" Component={iconEmail} />
      </Toolbar>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size={"small"}
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.alignment}
                  padding={headCell.disablePadding ? "none" : "default"}
                  style={{
                    fontSize: "16px",
                  }}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {values
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                let status = row.status;
                return (
                  <TableRow hover key={index} style={{ cursor: "pointer" }}>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={row.status}
                            onChange={(event) => {
                              status = !status;
                              handleStatus({
                                email_provider: row,
                                status,
                              });
                            }}
                          />
                        }
                      />
                    </TableCell>
                    <TableCell>Send Grid</TableCell>
                    <TableCell padding="none" align="right">
                      <Box mr={2}>
                        <Tooltip
                          title="Directories"
                          onClick={() => {
                            handleEditProvider(row);
                          }}
                        >
                          <IconButton aria-label="details" color="primary">
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        component="div"
        count={values.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
export default EmailsProviderSettings;
