import { createSlice } from "@reduxjs/toolkit";
import { getNumberPerPage } from "../../helpers/functions";
import { apiCallBegan } from "../actions/apiActions";
const url = "emails";

const slice = createSlice({
  name: "emails",
  initialState: {
    list: [],
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
    selectedChannel: null,
    selectedGroup: null,
    status: true,
    search: "",
    page: 0,
    per_page: 0,
    total_pages: 0,
    maxPage: 0,
    total_records: 0,
  },
  reducers: {
    resetAll: (emails, action) => {
      emails.isLoading = false;
      emails.error = null;
      emails.success = null;
    },
    requestStarted: (emails, action) => {
      emails.isLoading = true;
      emails.error = null;
      emails.isRequestSent = true;
    },
    requestedFailed: (emails, action) => {
      emails.error = action.payload;
      emails.isLoading = false;
    },
    loadEmailsList: (emails, action) => {
      emails.page = action.payload.page;
      emails.per_page = action.payload.per_page;
      emails.total_pages = action.payload.total_pages;
      emails.total_records = action.payload.total_records;
      emails.list = emails.list.concat(action.payload.result);
      emails.selectedChannel = action.payload.selectedChannel;
      emails.selectedGroup = action.payload.selectedGroup;
      emails.status = action.payload.status;
      emails.search = action.payload.search;
      emails.maxPage =
        emails.page > emails.maxPage ? emails.page : emails.maxPage;
      emails.isLoading = false;
    },
    reLoadEmailsList: (emails, action) => {
      emails.page = action.payload.page;
      emails.per_page = action.payload.per_page;
      emails.total_pages = action.payload.total_pages;
      emails.total_records = action.payload.total_records;
      emails.list = action.payload.result;
      emails.selectedChannel = action.payload.selectedChannel;
      emails.selectedGroup = action.payload.selectedGroup;
      emails.status = action.payload.status;
      emails.search = action.payload.search;
      emails.maxPage = 0;
      emails.isLoading = false;
    },
    emailUpdated: (emails, action) => {
      const index = emails.list.findIndex(
        (email) => email._id === action.payload._id
      );
      emails.list[index] = action.payload;

      emails.isLoading = false;
      emails.success = "Email Updated";
    },
    emailDeleted: (emails, action) => {
      const index = emails.list.findIndex(
        (email) => email._id === action.payload._id
      );
      emails.list.splice(index, 1);
      emails.isLoading = false;
      emails.success = "Email Deleted";
    },
    channelChanged: (emails, action) => {
      emails.selectedChannel = action.payload;
    },
  },
});

export const {
  resetAll,
  emailUpdated,
  emailDeleted,
  channelChanged,
  loadEmailsList,
  requestStarted,
  requestedFailed,
  reLoadEmailsList,
} = slice.actions;
export default slice.reducer;

export const getEmails = (params = {}) => {
  if (!params.per_page) params.per_page = getNumberPerPage();
  delete params.channel;

  //Generate a URL query based on the object we got
  let query = new URLSearchParams(params);

  let resultsReceived = params.page
    ? loadEmailsList.type
    : reLoadEmailsList.type;

  return apiCallBegan({
    url: `${url}?${query}`,
    onStart: requestStarted.type,
    onSuccess: resultsReceived,
    onError: requestedFailed.type,
  });
};

export const updateEmail = (id, data) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "PUT",
    data,
    onStart: requestStarted.type,
    onSuccess: emailUpdated.type,
    onError: requestedFailed.type,
  });

export const deleteEmail = (_id) =>
  apiCallBegan({
    url,
    method: "DELETE",
    onStart: requestStarted.type,
    onSuccess: emailDeleted.type,
    onError: requestedFailed.type,
    data: { _id },
  });
