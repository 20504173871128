import React, { Fragment } from "react";
import { AppBar, Container, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "absolute",
    bottom: "0px",
    height: "2rem",
    fontFamily: "Open Sans', sans-serif",
    color: "#FFFFFF",
    padding: "0.4rem",
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <AppBar position="static" color="primary" className={classes.footer}>
        <Container maxWidth="lg">
          <Grid container align="center" justify="space-between">
            <Typography variant="h6" color="inherit">
              Privacy Policy | Terms of Service
            </Typography>
            <Typography variant="h6" color="inherit">
              © 2020 all rights reserved
            </Typography>
          </Grid>
        </Container>
      </AppBar>
    </Fragment>
  );
};

export default Footer;
