import React, { useState, useEffect } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CardContent,
  FormControl,
  Chip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser } from "../../../redux/reducers/usersReducer";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },
  dialogwrapper: {
    position: "absolute",
    maxWidth: "50rem",
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  description: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
  },
}));

const DeleteUserPopUp = ({ open, setOpen, user }) => {
  const [selected_user, setSelected_user] = useState({});
  const users_list = useSelector((state) => state.entities.users.list);
  const [targeted_user, setTargeted_user] = useState(users_list);

  const dispatch = useDispatch();

  useEffect(() => {
    let users = users_list.filter((item) => item._id !== user._id);
    setTargeted_user(users);
  }, [user, users_list]);

  const classes = useStyles();

  const handleSubmit = () => {
    if (selected_user?._id) {
      dispatch(
        deleteUser({
          id: user._id,
          user: selected_user._id,
        })
      );
      setOpen(false);
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogwrapper }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth={true}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography gutterBottom>Merge Users Submits</Typography>
        <div style={{ display: "flex", float: "right" }}>
          <Button
            style={{ color: "#868695", marginTop: "-40px" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <CardContent>
          <Typography variant="body2">
            Selected User (this user will be deleted)
          </Typography>

          <Grid item md={12}>
            <FormControl fullWidth>
              <Chip
                list={users_list}
                selected={user}
                label={"name"}
                setSelected={setSelected_user}
                multiple={false}
                disabled={true}
              />
            </FormControl>
          </Grid>

          <Typography
            variant="body2"
            style={
              selected_user?._id
                ? { marginTop: "20px" }
                : { color: "red", marginTop: "20px" }
            }
          >
            Target User (Everything will be assigned to this User)
          </Typography>

          <Grid item md={12}>
            <FormControl fullWidth>
              <Chip
                list={targeted_user}
                selected={selected_user}
                label={"name"}
                setSelected={setSelected_user}
                multiple={false}
              />
            </FormControl>
          </Grid>

          <Button
            style={
              selected_user?._id
                ? {
                    marginTop: "15px",
                    backgroundColor: "#6a74c9",
                    color: "white",
                  }
                : {
                    marginTop: "15px",
                    disabled: true,
                    backgroundColor: "#999993",
                    cursor: "not-allowed",
                    color: "white",
                  }
            }
            variant="contained"
            mt={4}
            onClick={(e) => handleSubmit()}
          >
            Merge Users Submits
          </Button>
        </CardContent>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteUserPopUp;
