import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CardContent,
  CircularProgress,
  Box,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { useSelector } from "react-redux";
import Chip from "../../customComponent/Chips";

const Alert = styled(MuiAlert)(spacing);

const useStyles = makeStyles((theme) => ({}));

const validationSchema = Yup.object().shape({
  hubstaff: Yup.string(),
});

const Popup = ({ open, setOpen, project }) => {
  const [hubstaff, setHubstaff] = useState();

  let { success, error, isLoading } = useSelector(
    (state) => state.entities.users
  );

  let hubstaffProjects = useSelector((state) => state.entities.hubstaff);

  useEffect(() => {
    if (success) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const initialValues = {
    hubstaff,
  };

  const classes = useStyles();

  const handleSubmit = (values) => {
    // setOpen(false);
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogwrapper }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography
          style={{ fontSize: "15px", fontWeight: "bold" }}
          gutterBottom
        >
          Sync Users
        </Typography>
        <div style={{ display: "flex", float: "right" }}>
          <Button
            style={{ color: "#868695", marginTop: "-40px" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            status,
          }) => (
            <>
              <CardContent>
                <Typography variant="body2" gutterBottom>
                  Create a new Campaign and start connecting with business
                  across the country
                </Typography>

                {error && (
                  <Alert severity="danger" my={3}>
                    {error}
                  </Alert>
                )}

                {isLoading ? (
                  <Box display="flex" justifyContent="center" my={6}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container>
                      <Grid item md={12} style={{ padding: "9px 0px" }}>
                        <FormControl fullWidth>
                          <Chip
                            list={hubstaffProjects.list}
                            selected={values.hubstaff}
                            placeholder={"Campaign in Hubstaff"}
                            label={"name"}
                            setSelected={setHubstaff}
                            multiple={false}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Button
                      style={{
                        marginTop: "10px",
                        backgroundColor: "#6a74c9",
                        color: "white",
                      }}
                      type="submit"
                      variant="contained"
                      mt={4}
                    >
                      Sync users
                    </Button>
                  </form>
                )}
              </CardContent>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
