import React, { useState, useEffect, Fragment, forwardRef } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProject,
  resetAll,
  archiveProject,
  restoreProject,
  getProjects,
} from "../../redux/reducers/projectReducer";
import { Snackbar } from "@material-ui/core";
import { getUsers } from "../../redux/reducers/usersReducer";
import { Helmet } from "react-helmet-async";
import NumberFormat from "react-number-format";
import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Slide,
  FormControlLabel,
  Typography,
  Switch,
} from "@material-ui/core";
import { getComparator, stableSort } from "../../helpers/functions";
import { Alert as MuiAlert } from "@material-ui/lab";
import {
  HelpOutline as InfoIcon,
  Add as AddIcon,
  Archive as ArchiveIcon,
  Edit as EditIcon,
  Restore as RestoreIcon,
} from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import ProjectPopup from "./components/Popup";
import ArchiveDialog from "../customComponent/AlertDialog";
import Loading from "../customComponent/Loading";
import { Reload } from "../customComponent/Reload";
import { Briefcase } from "react-feather";
import { TableName } from "../customComponent/TableName";
import { getHubstaffProjects } from "../../redux/reducers/hubstaffReducer";
import { ROWS_PER_PAGE_OPTIONS } from "../../constants";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const ButtonTable = styled.div`
  display: flex;
  align-items: center;
`;

const headCells = [
  { id: "name", alignment: "left", label: "Name", sort: true },
  { id: "status", alignment: "left", label: "Status", sort: true },
  { id: "organization", alignment: "left", label: "Organization", sort: true },
  {
    id: "links",
    alignment: "center",
    label: "Total Links",
    sort: true,
  },
  {
    id: "submitted",
    alignment: "center",
    label: "Good Submits",
    sort: true,
  },
  {
    id: "cps",
    alignment: "center",
    label: "Cost per Submit",
    sort: true,
  },
  {
    id: "expenses",
    alignment: "left",
    label: "Expenses",
    sort: true,
  },

  { id: "actions", alignment: "right", label: "Actions", sort: false },
];
function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell mr={2}>#</TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontSize: "16px",
            }}
          >
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {headCell.tooltip ? (
                  <Tooltip title={headCell.tooltip}>
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                ) : (
                  ""
                )}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let EnhancedTableToolbar = () => {
  const dispatch = useDispatch();
  const { listType } = useSelector((state) => state.entities.projects);

  const handleClickButton = (status) => {
    dispatch(getProjects(status));
  };

  return (
    <Toolbar>
      <TableName title="Campaigns List" Component={Briefcase} />
      <Spacer />
      <ButtonTable>
        <Grid item>
          <Button
            variant="contained"
            color="default"
            onClick={() => handleClickButton("active")}
            style={{
              marginRight: "1rem",
              backgroundColor: listType === "active" ? "#27ae60" : "",
              color: listType === "active" ? "white" : "",
            }}
          >
            Active
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="default"
            onClick={() => handleClickButton("archived")}
            style={{
              marginRight: "1rem",
              backgroundColor: listType === "archived" ? "#27ae60" : "",
              color: listType === "archived" ? "white" : "",
            }}
          >
            Archived
          </Button>
        </Grid>
      </ButtonTable>
    </Toolbar>
  );
};

const EnhancedTable = () => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("customer");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  // this needed for edit dialog
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [projectToEdit, setProjectToEdit] = useState(null);

  // this needed for archive dialog
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [projectToArchive, setProjectToArchive] = useState(null);

  // this needed for restore dialog
  const [restoreDialogOpen, setRestoreDialogOpen] = useState(false);
  const [projectToRestore, setProjectToRestore] = useState(null);

  const { list: projectsList, isLoading } = useSelector(
    (state) => state.entities.projects
  );

  const hubstaff = useSelector((state) => state.entities.hubstaff);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, projectsList.length - page * rowsPerPage);

  const handleProjectStatus = (project_id, status) => {
    dispatch(updateProject(project_id, status));
  };

  const handleEditDialogStatus = (open) => {
    if (!open) {
      setProjectToEdit(null);
    }
    setEditDialogOpen(open);
  };

  const handleRestoreDialogStatus = (open) => {
    if (!open) setProjectToRestore(null);

    setRestoreDialogOpen(open);
  };
  const handleArchiveDialogStatus = (open) => {
    if (!open) setProjectToArchive(null);

    setArchiveDialogOpen(open);
  };

  const handleEditProject = (project) => {
    setProjectToEdit(project);
    dispatch(resetAll());
    setEditDialogOpen(true);
  };

  const handleArchiveProject = (project_id) => {
    setProjectToArchive(project_id);
    setArchiveDialogOpen(true);
  };

  const handleRestoreProject = (project_id) => {
    setProjectToRestore(project_id);
    setRestoreDialogOpen(true);
  };

  // get the amount spent on a project using hubstaff Activities
  const getAmountSpent = (project_id) => {
    const { stats } = hubstaff;
    return stats.reduce((total, item) => {
      if (item.project_id === project_id) return total + item.amount;
      return total;
    }, 0);
  };

  return (
    <div>
      {projectToRestore && (
        <ArchiveDialog
          title="Confirm Restoring the campaign?!"
          description="You will be able to see this campaign in the list of active Campaigns and users will be able to post on it again.."
          id={projectToRestore}
          setOpen={handleRestoreDialogStatus}
          open={restoreDialogOpen}
          dispatchOnAgree={restoreProject}
        />
      )}
      {projectToArchive && (
        <ArchiveDialog
          title="Confirm Archiving the campaign?!"
          description="Users will not be able to post or to contact companies using its data, 
          but you will be able to see the archived Campaigns by selecting 'Archived' in 
          top right Campaigns page List..."
          id={projectToArchive}
          setOpen={handleArchiveDialogStatus}
          open={archiveDialogOpen}
          dispatchOnAgree={archiveProject}
        />
      )}
      {projectToEdit && (
        <ProjectPopup
          open={editDialogOpen}
          setOpen={handleEditDialogStatus}
          project={projectToEdit}
        />
      )}

      {isLoading ? (
        <Loading />
      ) : (
        <Paper>
          <EnhancedTableToolbar />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"small"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={projectsList.length}
              />
              <TableBody>
                {stableSort(projectsList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const { status } = row;

                    const totalSpent = getAmountSpent(row.hubstaff_project);

                    return (
                      <TableRow hover key={index}>
                        <TableCell
                          style={{ fontSize: "14px" }}
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <Customer>#{row.project_number}</Customer>
                        </TableCell>

                        <TableCell
                          style={{ fontSize: "14px" }}
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <Customer>
                            <Box onClick={() => handleEditProject(row)}>
                              {row.name}
                            </Box>
                          </Customer>
                        </TableCell>

                        <TableCell style={{ fontSize: "14px" }}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={row.status}
                                onChange={(event) =>
                                  handleProjectStatus(row._id, {
                                    status: !status,
                                  })
                                }
                              />
                            }
                          />
                        </TableCell>
                        <TableCell
                          style={{ fontSize: "14px" }}
                          align="left"
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          {row.channel ? row.channel.name : "-"}
                        </TableCell>
                        <TableCell style={{ fontSize: "14px" }} align="center">
                          <NumberFormat
                            value={row.remainingLinks}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix=""
                          />
                          <Divider />
                          <NumberFormat
                            value={row.totalCompanies}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix=""
                          />
                        </TableCell>
                        <TableCell style={{ fontSize: "14px" }} align="center">
                          <NumberFormat
                            value={row.totalSubmissionsSuccess}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix=""
                          />
                          <Divider />
                          <NumberFormat
                            value={row.submissionSuccessPercent}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"%"}
                          />
                        </TableCell>
                        <TableCell style={{ fontSize: "14px" }} align="center">
                          <NumberFormat
                            value={row.cost_per_submit}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix="$"
                          />
                        </TableCell>
                        <TableCell style={{ fontSize: "14px" }} align="left">
                          <NumberFormat
                            value={totalSpent.toFixed()}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix="$"
                          />
                        </TableCell>
                        <TableCell align="right">
                          {row.deletedAt ? (
                            <Tooltip title="Restore">
                              <IconButton
                                aria-label="Restore"
                                color="primary"
                                onClick={() => handleRestoreProject(row._id)}
                              >
                                <RestoreIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Archive">
                              <IconButton
                                aria-label="delete"
                                color="primary"
                                onClick={() => handleArchiveProject(row._id)}
                              >
                                <ArchiveIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title="Edit">
                            <IconButton
                              aria-label="details"
                              color="primary"
                              onClick={() => handleEditProject(row)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={10} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={projectsList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  );
};

const ProjectList = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState(false);
  const { error, success, isLoading, selectedChannel } = useSelector(
    (state) => state.entities.projects
  );

  useEffect(() => {
    if (success || error) {
      setNotification(true);
    }

    if (success) dispatch(getUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNotificationClose = () => {
    setNotification(false);
    dispatch(resetAll());
  };

  const loadProjects = () => {
    dispatch(getProjects({ channel: selectedChannel }));
    dispatch(getHubstaffProjects());
  };

  return (
    <Fragment>
      {notification === true && (
        <Snackbar
          open={notification}
          autoHideDuration={5000}
          onClose={handleNotificationClose}
          TransitionComponent={TransitionLeft}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleNotificationClose}
            severity={success ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {success || error}
          </Alert>
        </Snackbar>
      )}

      <Helmet title="Campaigns" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Campaigns
          </Typography>
        </Grid>
        <Grid item>
          <Box display="flex" align="center">
            <Reload loading={isLoading} action={loadProjects} />
            <Button
              variant="contained"
              onClick={handleClickOpen}
              style={{ backgroundColor: "#23cc94", color: "white" }}
            >
              <AddIcon />
              New Campaign
            </Button>
          </Box>
          {open && (
            <ProjectPopup
              open={open}
              setOpen={setOpen}
              project={null}
              handleClose={handleClose}
            />
          )}
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ProjectList;
