import React, { useState, useEffect, Fragment, forwardRef } from "react";
import Header from "./Header";
import Section from "./Section";
import Footer from "./Footer";
import { Alert as MuiAlert } from "@material-ui/lab";
import { Slide, Snackbar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { isMobile, isTablet } from "react-device-detect";
import PageNotDevice from "../auth/PageNotDevice";
import ProfileEdit from "./ProfileUser";
import { resetAll } from "../../redux/reducers/authReducer";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const MembersArea = () => {
  const [openUserEdit, setOpenUserEdit] = useState(false);
  const { error, success, data } = useSelector((state) => state.entities.auth);
  const [notification, setNotification] = useState(false);

  const dispatch = useDispatch();

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  // };

  const handleCloseUserEdit = () => {
    setOpenUserEdit(false);
  };

  useEffect(() => {
    if (data && !data.password_change) setOpenUserEdit(true);
  }, [data]);

  useEffect(() => {
    if (error || success) setNotification(true);
  }, [error, success]);

  const handleNotificationClose = () => {
    dispatch(resetAll());
    setNotification(false);
  };

  if (isMobile || isTablet) {
    return <PageNotDevice />;
  } else {
    return (
      <Fragment>
        <Header />
        <Section />

        {notification && (
          <Snackbar
            open={notification}
            autoHideDuration={5000}
            onClose={handleNotificationClose}
            TransitionComponent={TransitionLeft}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Alert
              onClose={handleNotificationClose}
              severity={success ? "success" : "error"}
              sx={{ width: "100%" }}
            >
              {success || error}
            </Alert>
          </Snackbar>
        )}

        {!data?.password_change && (
          <ProfileEdit
            open={openUserEdit}
            setOpen={setOpenUserEdit}
            handleClose={handleCloseUserEdit}
          />
        )}
        <Footer />
      </Fragment>
    );
  }
};

export default MembersArea;
