import {
  Box,
  Button as MuiButton,
  Chip,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import { updateDirectory } from "../../../../../redux/reducers/directoriesReducer";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const Features = ({ directory, dispatch, isLoading = false }) => {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState(directory);
  const [feature, setFeature] = useState("");
  const [selectFeatures, setSelectFeatures] = useState(data.features);

  useEffect(() => {
    setData(directory);
    setSelectFeatures(directory.features);
  }, [directory]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let UpdateData = { _id: directory._id, features: selectFeatures };
    dispatch(updateDirectory(UpdateData));
  };

  const handleChange = (e) => {
    setFeature(e.target.value);
  };

  const removeFeature = (e, key) => {
    let result = selectFeatures.filter((item) => item !== key);
    setSelectFeatures(result);
  };

  const addToBlackList = (e, domain) => {
    e.preventDefault();

    if (!selectFeatures.includes(domain))
      setSelectFeatures((selectFeatures) => [...selectFeatures, feature]);

    setFeature("");
  };

  return (
    <Box>
      <form onSubmit={(e) => addToBlackList(e, feature)}>
        <TextField
          id="feature"
          label="Add a feature"
          variant="outlined"
          onChange={handleChange}
          value={feature}
          fullWidth
          my={2}
        />
      </form>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Grid item md={12} style={{ padding: "9px 0px" }}>
          {selectFeatures &&
            selectFeatures.map((item) => {
              return (
                <Chip
                  key={item}
                  style={{
                    marginRight: "5px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  label={item}
                  // onClick={}
                  onDelete={(e) => {
                    removeFeature(e, item);
                  }}
                />
              );
            })}
        </Grid>

        <Button
          variant="contained"
          type="submit"
          style={{
            backgroundColor: "#6a74c9",
            color: "white",
            width: "8vw",
            position: "absolute",
            right: "0",
            bottom: "0",
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Save changes"
          )}
        </Button>
      </form>
    </Box>
  );
};

export default Features;
