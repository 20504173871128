import React, { useState, useEffect, Fragment, useRef } from "react";
import styled from "styled-components/macro";

import {
  Box,
  Grid,
  TextField as MuiTextField,
  Typography,
  Fab,
  Divider as MuiDivider,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import SendIcon from "@material-ui/icons/Send";
import { useDispatch, useSelector } from "react-redux";
import { addNote } from "../../redux/reducers/leadReducer";
import { addNoteToSubmit } from "../../redux/reducers/submissionsReducer";
import Gravatar from "react-gravatar";

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const ChatContainer = styled(Grid)`
  width: 100%;
  height: 68.4vh;
`;

const ChatMain = styled(Grid)`
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
`;

const ChatMessages = styled.div`
  overflow-y: scroll;
  height: calc(65vh - 94px);
`;

const ChatMessage = styled.div`
  margin: 30px;
  text-align: ${(props) => props.position};
`;

const ChatMessageInner = styled.div`
  display: inline-block;
`;

const ChatMessageTime = styled(Typography)`
  text-align: right;
  opacity: 0.8;
`;

const ChatMessageBubble = styled.div`
  display: inline-block;
  margin-right: auto;
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.secondary.main
      : props.theme.palette.action.hover};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.common.white
      : props.theme.palette.text.primary};
  border-radius: 3px;
  padding: ${(props) => props.theme.spacing(2)}px;
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
  ${(props) => props.theme.shadows[1]};
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

const ChatInput = styled(Grid)`
  min-height: 94px;
  padding: ${(props) => props.theme.spacing(5)}px;
`;

// const Online = styled(Badge)`
//   margin-right: ${(props) => props.theme.spacing(1)}px;
//   span {
//     background-color: ${(props) =>
//       props.theme.sidebar.footer.online.background};
//     border: 1.5px solid ${(props) => props.theme.palette.common.white};
//     height: 12px;
//     width: 12px;
//     border-radius: 50%;
//   }
// `;

function ChatMessageComponent({
  name,
  message,
  time,
  avatar,
  position = "right",
  avatarPosition = "right",
}) {
  return (
    <ChatMessage position={position}>
      <ChatMessageInner>
        {avatarPosition === "left" && (
          <Gravatar
            email={avatar || ""}
            size={40}
            mask="rounded"
            default="monsterid"
            protocol="https://"
            style={{ borderRadius: "50%", marginRight: "0.5em" }}
            mx={15}
          />
        )}

        <ChatMessageBubble
          highlighted={position === "right"}
          style={{ minWidth: "10rem", maxWidth: "20rem", textAlign: "left" }}
        >
          <Box>
            <ChatMessageBubbleName variant="body1">
              {name}
            </ChatMessageBubbleName>
          </Box>
          <Typography variant="body2">{message}</Typography>
        </ChatMessageBubble>

        {avatarPosition === "right" && (
          <Gravatar
            email={avatar || ""}
            size={40}
            mask="rounded"
            default="monsterid"
            protocol="https://"
            style={{ borderRadius: "50%", marginLeft: "0.5em" }}
            mx={15}
          />
        )}

        <ChatMessageTime variant="body2">{time}</ChatMessageTime>
      </ChatMessageInner>
    </ChatMessage>
  );
}

function Chat({ lead }) {
  const [message, setMessage] = useState();
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const { user } = useSelector((state) => state.entities.auth.data);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [lead]);

  let _id = lead && lead._id;
  const sendMessage = (e) => {
    e.preventDefault();
    dispatch(
      lead.isFromLead
        ? addNote({ _id, message })
        : addNoteToSubmit({ _id, message })
    );
    setMessage("");
  };

  const Spacer = styled.div(spacing);

  return (
    <Fragment>
      <Spacer />
      <ChatContainer container>
        <ChatMain item xs={12} md={8} lg={9}>
          <ChatMessages>
            {lead && lead.notes
              ? lead.notes.map((item) => (
                  <div>
                    <ChatMessageComponent
                      key={item?._id}
                      name={
                        user?._id === item.user._id ? "You" : item?.user?.name
                      }
                      avatar={item?.user ? item?.user?.email : ""}
                      message={item?.message}
                      time={new Date(item?.date).toUTCString()}
                      position={
                        user?._id === item?.user?._id ? "right" : "left"
                      }
                      avatarPosition={
                        user?._id === item?.user?._id ? "right" : "left"
                      }
                    />
                    <div ref={messagesEndRef} />
                  </div>
                ))
              : " "}
          </ChatMessages>
          <Divider />
          <ChatInput container style={{ bottom: "0" }}>
            <Grid item style={{ flexGrow: 1 }}>
              <TextField
                variant="outlined"
                label="Type your note"
                fullWidth
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    sendMessage(e);
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Box ml={2}>
                <Fab
                  color="primary"
                  aria-label="add"
                  size="medium"
                  onClick={sendMessage}
                  type="submit"
                >
                  <SendIcon />
                </Fab>
              </Box>
            </Grid>
          </ChatInput>
        </ChatMain>
      </ChatContainer>
    </Fragment>
  );
}

export default Chat;
