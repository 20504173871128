/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect, Fragment, forwardRef } from "react";
import Navbar from "./Navbar";
import { Alert as MuiAlert } from "@material-ui/lab";
import { Paper, CircularProgress, Box, Backdrop } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { getSubmission } from "../../../redux/reducers/submissionsReducer";
import PageNotProject from "../../auth/PageNotProject";
import { makeStyles } from "@material-ui/core/styles";
import { isValidURL } from "../../../helpers/functions";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "9999",
    width: "100%",
    height: "100%",
  },
  wrapper: {
    minHeight: "100%",
    position: "relative",
  },
  content: {
    paddingBottom: "140px",
  },
}));

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SubmissionsArea = ({ copySuccess }) => {
  const classes = useStyles();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let { show } = useSelector((state) => state.entities.snack);
  let { data, isLoading, error } = useSelector(
    (state) => state.entities.submissions
  );
  let auth = useSelector((state) => state.entities.auth);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSubmission());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.data]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <Fragment>
      {error ? (
        <PageNotProject />
      ) : (
        <Box className={classes.wrapper}>
          <Navbar />
          {isLoading ? (
            <Box
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                height: "100%",
                width: "100vw",
              }}
              className={classes.content}
            >
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                onClick={handleClose}
              >
                <CircularIndeterminate
                  disableShrink
                  size={40}
                  classes={{ root: classes.spinner }}
                />
                <span
                  style={{
                    justifyContent: "center",
                    position: "fixed",
                    top: "62%",
                  }}
                >
                  Loading...please wait
                </span>
              </Backdrop>
            </Box>
          ) : (
            <Paper
              elevation={1}
              style={{ height: "100%" }}
              flex={1}
              overflow="auto"
            >
              <Iframe
                src={data && data.url}
                // domain={data && data.domain}
                frameborder="0"
                style={{ overflow: "hidden", height: "100%", width: "100%" }}
              />
            </Paper>
          )}
        </Box>
      )}

      {show && (
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Copied!
          </Alert>
        </Snackbar>
      )}
    </Fragment>
  );
};

const Iframe = ({ src, domain, style, frameborder }) => {
  const url = isValidURL(src) ? src : new URL("http://" + domain);
  return <iframe src={url} style={style} frameborder={frameborder} />;
};

export default SubmissionsArea;

export const CircularIndeterminate = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress />
    </Box>
  );
};
