import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components/macro";
import { Bar } from "react-chartjs-2";

import { useSelector } from "react-redux";
import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/styles";
import {
  CardContent,
  Card as MuiCard,
  Typography,
  FormControlLabel,
  Checkbox,
  ThemeProvider,
  FormGroup,
  FormControl,
} from "@material-ui/core";
import MySelect from "../../customComponent/MySelect";
import { getProfilesChartStats } from "../../../redux/reducers/profilesReducer";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const Spacer = styled.div(spacing);

const useStyles = makeStyles({
  traffic: {
    borderBottom: "1px solid #E8EEF1",
  },
  Card: {
    height: "45%",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
});

const BarChart = () => {
  const classes = useStyles();
  const [projectsList, setProjectsList] = useState([]);
  const { chartStats, selectedChannel, selectedProject } = useSelector(
    (state) => state.entities.profiles
  );

  /* Destructuring the allProjects from the state.entities.projects. */
  const { allProjects } = useSelector((state) => state.entities.projects);

  useEffect(() => {
    const list = allProjects.filter(
      (item) =>
        !selectedChannel ||
        selectedChannel === "all" ||
        selectedChannel === item.channel
    );
    setProjectsList(list);
  }, [allProjects, selectedChannel]);

  const [state, setState] = useState({
    checkedAll: true,
    checkedVisit: true,
    checkedConversion: true,
    checkedSubmissions: true,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      checkedAll: Object.values({
        ...state,
        [event.target.name]: event.target.checked,
      })
        .slice(1)
        .every((item) => item),
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeAll = (event) => {
    let status = event.target.checked;
    setState({
      checkedAll: status,
      checkedVisit: status,
      checkedConversion: status,
      checkedSubmissions: status,
    });
  };

  const data = {
    labels: chartStats.labels,
    datasets: [
      {
        type: "line",
        position: "right",
        backgroundColor: "#FF9800",
        borderColor: "#FF9800",
        hoverBackgroundColor: "#FF9800",
        hoverBorderColor: "#FF9800",
        fill: true,
        tension: 0.4,
        id: 1,
        label: "Events",
        data: state.checkedConversion ? chartStats.conversions : [],
        yAxisID: "yR",
      },
      {
        type: "bar",
        position: "right",
        backgroundColor: "#4782DA",
        borderColor: "#4782DA",
        hoverBackgroundColor: "#4782DA",
        hoverBorderColor: "#4782DA",
        barPercentage: 0.75,
        categoryPercentage: 0.5,
        id: 2,
        label: "Submissions",
        data: state.checkedSubmissions ? chartStats.submissions : [],
        yAxisID: "yL",
      },
      {
        type: "bar",
        position: "right",
        backgroundColor: "#9A68FC",
        borderColor: "#9A68FC",
        hoverBackgroundColor: "#9A68FC",
        hoverBorderColor: "#9A68FC",
        barPercentage: 0.75,
        categoryPercentage: 0.5,
        id: 3,
        label: "Visits",
        data: state.checkedVisit ? chartStats.unique_visits : [],
        yAxisID: "yR",
      },
    ],
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    stacked: false,
    scales: {
      yAxes: [
        {
          id: "yL",
          type: "linear",
          display: true,
          position: "left",
        },
        {
          id: "yR",
          type: "linear",
          display: true,
          position: "right",
        },
      ],
    },
  };

  return (
    <Card mb={1} className={classes.Card} style={{ marginBottom: "10%" }}>
      <FormControl style={{ marginTop: "1%", marginLeft: "1%" }}>
        <MySelect
          selected={selectedProject}
          defaultLabel="All Campaigns"
          defaultValue="all"
          list={projectsList}
          label="name"
          value="_id"
          valueHolder="project"
          data={{}}
          toDispatch={getProfilesChartStats}
        />
      </FormControl>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Alias Stats
        </Typography>
        <Spacer mb={6} />
        <ThemeProvider>
          <FormGroup row style={{ marginLeft: "5px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checkedAll}
                  onChange={handleChangeAll}
                  name="checkedAll"
                  color="primary"
                />
              }
              classes={{ label: classes.labelCheck }}
              label="All"
              style={{ marginRight: "72px", fontWeight: "bold" }}
            />
            <FormControlLabel
              control={
                <>
                  <Checkbox
                    checked={state.checkedSubmissions}
                    onChange={handleChange}
                    name="checkedSubmissions"
                    color="primary"
                  />
                  <span
                    style={{
                      backgroundColor: "#4782DA",
                      width: "9px",
                      height: "9px",
                      left: "-4%",
                      position: "relative",
                      borderRadius: "50%",
                      margin: "3px",
                    }}
                  ></span>
                </>
              }
              classes={{ label: classes.labelCheck }}
              label="Submissions"
              style={{ marginRight: "75px" }}
            />
            <FormControlLabel
              control={
                <>
                  <Checkbox
                    checked={state.checkedVisit}
                    onChange={handleChange}
                    name="checkedVisit"
                    color="primary"
                  />
                  <span
                    style={{
                      backgroundColor: "#9A68FC",
                      width: "9px",
                      height: "9px",
                      left: "-4%",
                      position: "relative",
                      borderRadius: "50%",
                      margin: "3px",
                    }}
                  ></span>
                </>
              }
              classes={{ label: classes.labelCheck }}
              label="Visits"
              style={{ marginRight: "72px" }}
            />
            <FormControlLabel
              control={
                <>
                  <Checkbox
                    checked={state.checkedConversion}
                    onChange={handleChange}
                    name="checkedConversion"
                    color="primary"
                  />
                  <span
                    style={{
                      backgroundColor: "#FF9800",
                      width: "19px",
                      height: "3.3px",
                      left: "-4%",
                      position: "relative",
                      margin: "3px",
                    }}
                  ></span>
                </>
              }
              classes={{ label: classes.labelCheck }}
              label="Events"
              style={{ marginRight: "72px", fontWeight: "bold" }}
            />
          </FormGroup>
        </ThemeProvider>
        <Spacer mb={6} />
        <ChartWrapper>
          <Bar datasetIdKey="id" data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default BarChart;
