import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import reducer from "./combinedReducers";
import api from "../middleware/api";

const store = configureStore({
  reducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    api,
  ],
});
export default store;
