import React from "react";
import styled from "styled-components/macro";

import {
  Button as MuiButton,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Loop as LoopIcon } from "@material-ui/icons";

const Button = styled(MuiButton)(spacing);

const SmallButton = styled(Button)`
  padding: 0.3rem;
  min-width: 0;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const Reload = ({ loading, action, style, tooltip }) => {
  const handleRefresh = () => {
    action();
  };

  return (
    <div style={style || {}}>
      {loading ? (
        <CircularProgress
          style={{ marginRight: "1rem", marginTop: "0.4rem" }}
          size={20}
        />
      ) : tooltip ? (
        <Tooltip title={tooltip}>
          <SmallButton size="small" mr={2} color={"primary"}>
            <LoopIcon onClick={handleRefresh} />
          </SmallButton>
        </Tooltip>
      ) : (
        <SmallButton size="small" mr={2} color={"primary"}>
          <LoopIcon onClick={handleRefresh} />
        </SmallButton>
      )}
    </div>
  );
};
