import React from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import { Button as MuiButton, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useDispatch } from "react-redux";
import { signOut } from "../../redux/reducers/authReducer";

const Button = styled(MuiButton)(spacing);
const Spacer = styled.div(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function Page404() {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(signOut());
  };

  return (
    <Wrapper>
      <Helmet title="404 Error" />
      <center>
        <img src="/static/img/logo/noDivice.svg" alt="notDivice" width="40%" />
      </center>
      <Spacer mb={18} />
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Page not working.
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        This content is unavailable on mobile and tablet.
      </Typography>

      <Button
        style={{ backgroundColor: "#6C63FF" }}
        variant="contained"
        color="secondary"
        mt={2}
        onClick={handleClick}
      >
        Return to website
      </Button>
    </Wrapper>
  );
}

export default Page404;
