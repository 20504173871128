import { createSlice } from "@reduxjs/toolkit";
import { getNumberPerPage } from "../../helpers/functions";
import { apiCallBegan } from "../actions/apiActions";
const url = "directories";

const slice = createSlice({
  name: "Directories",
  initialState: {
    list: [],
    sendGridList: [],
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
    selectedChannel: null,
    addedDirectory: null,
    page: 1,
    per_page: 0,
    total_pages: 0,
    maxPage: 0,
    total_records: 0,
  },
  reducers: {
    resetAll: (directories, action) => {
      directories.isLoading = false;
      directories.error = null;
      directories.success = null;
    },
    requestStarted: (directories, action) => {
      directories.isLoading = true;
      directories.error = null;
      directories.isRequestSent = true;
    },
    requestedFailed: (directories, action) => {
      directories.error = action.payload;
      directories.isLoading = false;
    },
    loadDirectory: (directories, action) => {
      directories.list.push(action.payload.result);
      ++directories.total_pages;
      directories.isLoading = false;
    },
    loadDirectoriesList: (directories, action) => {
      directories.page = action.payload.page;
      directories.per_page = action.payload.per_page;
      directories.total_pages = action.payload.total_pages;
      directories.total_records = action.payload.total_records;
      directories.list = directories.list.concat(action.payload.result);
      directories.selectedChannel = action.payload.selectedChannel;
      directories.maxPage =
        directories.page > directories.maxPage
          ? directories.page
          : directories.maxPage;
      directories.isLoading = false;
    },
    reLoadDirectoriesList: (directories, action) => {
      directories.page = action.payload.page;
      directories.per_page = action.payload.per_page;
      directories.total_pages = action.payload.total_pages;
      directories.total_records = action.payload.total_records;
      directories.list = action.payload.result;
      directories.selectedChannel = action.payload.selectedChannel;
      directories.maxPage = 0;
      directories.isLoading = false;
    },
    directoryAdded: (directories, action) => {
      directories.addedDirectory = action.payload;
      directories.list.push(action.payload);
      directories.isLoading = false;
      directories.success = "Directories Added";
    },
    directoryUpdated: (directories, action) => {
      const index = directories.list.findIndex(
        (directory) => directory._id === action.payload._id
      );
      directories.list[index] = action.payload;
      directories.isLoading = false;
      directories.success = "Directories Updated";
    },
    directoryDeleted: (directories, action) => {
      const index = directories.list.findIndex(
        (directory) => directory._id === action.payload._id
      );
      directories.list.splice(index, 1);
      directories.isLoading = false;
      directories.success = "Directories Deleted";
    },
    channelChanged: (directories, action) => {
      directories.selectedChannel = action.payload;
    },
    cleanAddedDirectory: (directories, action) => {
      directories.addedDirectory = null;
    },
    loadSendGridList: (directories, action) => {
      directories.sendGridList = action.payload;
      directories.isLoading = false;
    },
    DirectoryPostSended: (directories, action) => {
      directories.isLoading = false;
      directories.success = "Listing Added";
    },
  },
});

export const {
  resetAll,
  loadDirectory,
  directoryAdded,
  channelChanged,
  requestStarted,
  requestedFailed,
  loadSendGridList,
  directoryUpdated,
  directoryDeleted,
  loadDirectoriesList,
  cleanAddedDirectory,
  DirectoryPostSended,
  reLoadDirectoriesList,
} = slice.actions;
export default slice.reducer;

export const getDirectories = (params = {}) => {
  if (!params.per_page) params.per_page = getNumberPerPage();
  delete params.channel;

  //Generate a URL query based on the object we got
  let query = new URLSearchParams(params);

  let resultsReceived = params.page
    ? loadDirectoriesList.type
    : reLoadDirectoriesList.type;

  return apiCallBegan({
    url: `${url}?${query}`,
    onStart: requestStarted.type,
    onSuccess: resultsReceived,
    onError: requestedFailed.type,
  });
};

export const getOneDirectory = (id) =>
  apiCallBegan({
    url: `${url}/${id}`,
    onStart: requestStarted.type,
    onSuccess: loadDirectory,
    onError: requestedFailed.type,
  });

export const addDirectory = (data) =>
  apiCallBegan({
    url,
    method: "POST",
    data,
    onStart: requestStarted.type,
    onSuccess: directoryAdded.type,
    onError: requestedFailed.type,
  });

export const updateDirectory = (data) =>
  apiCallBegan({
    url,
    method: "PUT",
    data,
    onStart: requestStarted.type,
    onSuccess: directoryUpdated.type,
    onError: requestedFailed.type,
  });

export const deleteDirectory = (id) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "DELETE",
    onStart: requestStarted.type,
    onSuccess: directoryDeleted.type,
    onError: requestedFailed.type,
  });

export const sendDirectoryPost = (id) => {
  return apiCallBegan({
    url: `openai?_id=${id}`,
    onStart: requestStarted.type,
    onSuccess: DirectoryPostSended.type,
    onError: requestedFailed.type,
  });
};

export const getSendGridList = () =>
  apiCallBegan({
    url: `${url}/sendgrid-list`,
    onStart: requestStarted.type,
    onSuccess: loadSendGridList.type,
    onError: requestedFailed.type,
  });
