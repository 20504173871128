import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { getToken } from "../helpers/functions";
import LoadingPage from "../pages/auth/LoadingPage";
import { getUserByToken } from "../redux/reducers/authReducer";

// For routes that can only be accessed by authenticated users
function UserGuard({ children }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.entities.auth.data);

  let token = getToken();

  if (!token) {
    return <Redirect to="/" />;
  } else {
    if (!user) {
      dispatch(getUserByToken());
      return <LoadingPage />;
    }
  }
  return children;
}

export default UserGuard;
