import { createSlice } from "@reduxjs/toolkit";
import { getNumberPerPage } from "../../helpers/functions";
import { apiCallBegan } from "../actions/apiActions";
const url = "users";

const slice = createSlice({
  name: "users",
  initialState: {
    list: [],
    selectedChannel: null,
    selectedProject: null,
    selectedDate: null,
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
  },
  reducers: {
    resetAll: (users, action) => {
      users.isLoading = false;
      users.error = null;
      users.success = null;
    },
    requestStarted: (users, action) => {
      users.isLoading = true;
      users.error = null;
      users.isRequestSent = true;
    },
    requestedFailed: (users, action) => {
      users.error = action.payload;
      users.isLoading = false;
    },
    loadUsersList: (users, action) => {
      users.list = action.payload.list;
      users.selectedChannel = action.payload.selectedChannel;
      users.selectedProject = action.payload.selectedProject;
      users.selectedDate = action.payload.selectedDate;
      users.isLoading = false;
    },
    hubsatffUsersSynced: (users, action) => {
      const results = action.payload;
      let oldNbr = users.list.length;
      users.list = results;
      let addedNumber = users.list.length - oldNbr;
      users.isLoading = false;
      users.success = addedNumber + " Users Synced";
    },
    userAdded: (users, action) => {
      users.success = "New User Added";
      users.list.push(action.payload);
      users.isLoading = false;
    },
    userInvited: (users, action) => {
      users.success = "New User Invited";
      users.isLoading = false;
    },
    userDeleted: (users, action) => {
      users.list = users.list.filter((user) => user._id !== action.payload.id);
      const index = users.list.findIndex(
        (user) => user._id === action.payload.user._id
      );
      users.list[index] = action.payload.user;
      users.success = "User Deleted";
      users.isLoading = false;
    },
    userUpdated: (users, action) => {
      const index = users.list.findIndex(
        (user) => user._id === action.payload.user._id
      );
      users.list[index].status = action.payload.user.status;
      users.list[index].email = action.payload.user.email;
      users.list[index].name = action.payload.user.name;
      users.list[index].membership_role = action.payload.user.membership_role;
      users.list[index].projects = action.payload.user.projects;
      users.success = "User Updated";
      users.isLoading = false;
    },
  },
});

export const {
  userAdded,
  loadUsersList,
  requestedFailed,
  requestStarted,
  userUpdated,
  resetAll,
  hubsatffUsersSynced,
  userInvited,
  userDeleted,
} = slice.actions;
export default slice.reducer;

export const getUsers = (params = {}) => {
  params.per_page = getNumberPerPage();
  delete params.channel;
  if (params.search === "" || params.search === null) delete params.search;
  if (!params.project || params.project === "all") delete params.project;
  if (params.startDate === null) {
    delete params.date;
  } else {
    if (params.date && params.date.startDate)
      params.startDate = params.date.startDate;
    if (params.date && params.date.endDate)
      params.endDate = params.date.endDate;
    delete params.date;
  }

  //Generate a URL query based on the object we got
  let query = new URLSearchParams(params);
  return apiCallBegan({
    url: `${url}?${query}`,
    onStart: requestStarted.type,
    onSuccess: loadUsersList.type,
    onError: requestedFailed.type,
  });
};

export const syncHubstaffUsers = () =>
  apiCallBegan({
    url: "hubstaff/sync/projects/users",
    onStart: requestStarted.type,
    onSuccess: hubsatffUsersSynced.type,
    onError: requestedFailed.type,
  });

export const addUser = (data) =>
  apiCallBegan({
    url,
    method: "POST",
    data,
    onStart: requestStarted.type,
    onSuccess: userAdded.type,
    onError: requestedFailed.type,
  });

export const updateUser = (id, data) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "PUT",
    data,
    onStart: requestStarted.type,
    onSuccess: userUpdated.type,
    onError: requestedFailed.type,
  });

export const inviteUser = (data) =>
  apiCallBegan({
    url: `${url}/invite`,
    method: "POST",
    data,
    onStart: requestStarted.type,
    onSuccess: userInvited.type,
    onError: requestedFailed.type,
  });

export const deleteUser = (data) =>
  apiCallBegan({
    url,
    method: "DELETE",
    data,
    onStart: requestStarted.type,
    onSuccess: userDeleted.type,
    onError: requestedFailed.type,
  });
