import React, { useState, useEffect, Fragment, forwardRef } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { InputBase, Snackbar } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import AlertDialog from "../customComponent/AlertDialog";
import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  Slide,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  FormControlLabel,
  Typography,
  Switch,
} from "@material-ui/core";
import {
  getComparator,
  getNumberPerPage,
  stableSort,
  updateNbrPerPage,
} from "../../helpers/functions";
import { Alert as MuiAlert } from "@material-ui/lab";
import {
  HelpOutline as InfoIcon,
  Add as AddIcon,
  Visibility as EyeIcon,
  PostAdd as PostAddIcon,
} from "@material-ui/icons";
import { darken } from "polished";
import { spacing } from "@material-ui/system";
import DirectoryPopup from "./components/Popup";
import Loading from "../customComponent/Loading";
import { Reload } from "../customComponent/Reload";
import { Box as iconBox, Search as SearchIcon } from "react-feather";
import { TableName } from "../customComponent/TableName";
import { ROWS_PER_PAGE_OPTIONS } from "../../constants";
import {
  cleanAddedDirectory,
  deleteDirectory,
  getDirectories,
  resetAll,
  sendDirectoryPost,
  updateDirectory,
} from "../../redux/reducers/directoriesReducer";
import { useHistory } from "react-router-dom";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const Search = styled.div`
  border-radius: 4px;
  border: 1px solid #6320ee;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  margin-right: 2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, 
  width: 30rem;
  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 100%;
  }
`;

const ButtonTable = styled.div`
  display: flex;
  align-items: center;
`;

const headCells = [
  { id: "status", alignment: "left", label: "Status", sort: false },
  { id: "name", alignment: "left", label: "Name", sort: false },
  // { id: "link", alignment: "left", label: "Link", sort: false },
  { id: "actions", alignment: "right", label: "Actions", sort: false },
];

const TransitionLeft = (props) => {
  return <Slide {...props} direction="left" />;
};

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontSize: "16px",
            }}
          >
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {headCell.tooltip ? (
                  <Tooltip title={headCell.tooltip}>
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                ) : (
                  ""
                )}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

let EnhancedTableToolbar = () => {
  const dispatch = useDispatch();
  const { search } = useSelector((state) => state.entities.directories);
  const [searchDirectory, setSearchDirectory] = useState(search);

  const handleChange = (e) => {
    let search = e.target.value;

    setSearchDirectory(search);
    let data = {
      search: search.trim(),
    };

    if (e.keyCode === 13) {
      dispatch(getDirectories(data));
    }
  };

  useEffect(() => {
    setSearchDirectory(search);
  }, [search]);

  return (
    <Toolbar>
      <TableName title="Directories List" Component={iconBox} />
      <Spacer />
      <Grid item>
        <ButtonTable>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <Input
              placeholder="Search Directories"
              value={searchDirectory}
              onChange={handleChange}
              onKeyUp={handleChange}
              inputRef={(input) => input && input.focus()}
            />
          </Search>
        </ButtonTable>
      </Grid>
    </Toolbar>
  );
};

const EnhancedTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("customer");
  const [page, setPage] = useState(0);
  const rowsPerPage = getNumberPerPage();
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [directoryToEdit, setDirectoryToEdit] = useState(null);
  const [directoryToDelete, setDirectoryToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const {
    search,
    page: serverPage,
    total_records,
    maxPage,
    addedDirectory,
    list: directoriesList,
    // success,
  } = useSelector((state) => state.entities.directories);

  useEffect(() => {
    if (maxPage === 0) setPage(0);
  }, [maxPage]);

  useEffect(() => {
    setPage(serverPage - 1);
  }, [serverPage]);

  useEffect(() => {
    if (addedDirectory) {
      if (addedDirectory !== null) {
        handleEditDirectories(addedDirectory);
        dispatch(cleanAddedDirectory());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedDirectory]);

  // eslint-disable-next-line no-unused-vars
  const handleDeleteDirectories = (id) => {
    setDirectoryToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    let data = {
      page: newPage + 1,
    };

    if (search !== "") data.search = search;

    if (newPage > maxPage - 1) {
      dispatch(getDirectories(data));
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let per_page = event.target.value;
    let data = {
      per_page,
    };

    if (search !== "") data.search = search;

    updateNbrPerPage(per_page);
    setPage(0);
    dispatch(getDirectories(data));
  };

  const emptyRows =
    rowsPerPage -
    Math.min(directoriesList, directoriesList.length - page * rowsPerPage);

  const handleStatus = (data) => {
    dispatch(updateDirectory(data));
  };

  const handleEditDialogStatus = (open) => {
    if (!open) {
      setDirectoryToEdit(null);
    }
    setEditDialogOpen(open);
  };

  const handleEditDirectories = (directory) => {
    setDirectoryToEdit(directory);
    history.push(`/directories/${directory._id}`);
    dispatch(resetAll());
    // setEditDialogOpen(true);
  };

  const handleDeleteDialogStatus = (open) => {
    if (!open) {
      setDirectoryToDelete(null);
    }
    setDeleteDialogOpen(open);
  };

  const handleNotificationMessageClose = () => {
    setNotificationMessage(false);
    dispatch(resetAll());
  };

  const handleDirectoryPost = (id) => {
    dispatch(sendDirectoryPost(id));
  };

  return (
    <Box>
      {directoryToDelete && (
        <AlertDialog
          title="Confirm Removing the directory?!"
          description="Removing the directory."
          id={directoryToDelete}
          setOpen={handleDeleteDialogStatus}
          open={deleteDialogOpen}
          dispatchOnAgree={deleteDirectory}
        />
      )}

      {directoryToEdit && (
        <DirectoryPopup
          open={editDialogOpen}
          setOpen={handleEditDialogStatus}
          directory={directoryToEdit}
        />
      )}

      {notificationMessage && (
        <Snackbar
          open={notificationMessage}
          autoHideDuration={5000}
          onClose={handleNotificationMessageClose}
          TransitionComponent={TransitionLeft}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleNotificationMessageClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            {notificationMessage}
          </Alert>
        </Snackbar>
      )}
      <Paper>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={directoriesList.length}
            />

            <TableBody>
              {stableSort(directoriesList, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover key={`${row.id}-${index}`}>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row.status}
                              onChange={(event) =>
                                handleStatus({
                                  _id: row._id,
                                  status: !row.status,
                                })
                              }
                            />
                          }
                        />
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer" }}
                        onClick={() => handleEditDirectories(row)}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell padding="none" align="right">
                        <Box mr={2}>
                          <Tooltip title="Send Post">
                            <IconButton
                              aria-label="Post Listings"
                              color="primary"
                              onClick={() => {
                                handleDirectoryPost(row._id);
                              }}
                            >
                              <PostAddIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Directory Website">
                            <IconButton aria-label="details" color="primary">
                              <a
                                href={row.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <EyeIcon />
                              </a>
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={3} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={total_records}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

const DirectoriesList = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState(false);
  const { error, success, list, isLoading } = useSelector(
    (state) => state.entities.directories
  );

  useEffect(() => {
    if (success || error) {
      setNotification(true);
    }
  }, [success, error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNotificationClose = () => {
    setNotification(false);
    dispatch(resetAll());
  };

  const loadDirectory = () => {
    dispatch(getDirectories());
  };

  return (
    <Fragment>
      {(success || error) && (
        <Snackbar
          open={notification}
          autoHideDuration={5000}
          onClose={handleNotificationClose}
          TransitionComponent={TransitionLeft}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleNotificationClose}
            severity={success ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {success || error}
          </Alert>
        </Snackbar>
      )}
      <Helmet title="Directories List" />
      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Directories List
          </Typography>
        </Grid>
        <Grid item>
          <Box>
            <Box display="flex" align="center">
              <Reload loading={isLoading} action={loadDirectory} />

              <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
                style={{ backgroundColor: "#6a74c9", color: "white" }}
              >
                <AddIcon />
                New Directories
              </Button>
            </Box>
            {open && (
              <DirectoryPopup
                open={open}
                setOpen={setOpen}
                project={null}
                handleClose={handleClose}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            {isLoading && list.length === 0 ? <Loading /> : <EnhancedTable />}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default DirectoriesList;
