import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import "../../vendor/roundedBarCharts";

import {
  Box,
  Card as MuiCard,
  Grid as MuiGrid,
  Typography,
  Tab,
  Divider as MuiDivider,
  IconButton,
} from "@material-ui/core";

import {
  Archive as ArchiveIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Link as LinkUrl,
  Delete as DeleteIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import Chat from "./Chat";
import { getMessageWithVariable } from "../../constants";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { getDateFormate } from "../../helpers/functions";

const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);

const SubmissionSection = styled.div`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
  height: 100%;
`;

const Grid = styled(MuiGrid)(spacing);

const Company = ({ submit }) => {
  return (
    <Box>
      <LabelValue label="Company" value={submit && submit.company?.title} />

      <LabelValue
        label="Domain"
        url={submit && submit.company?.url}
        value={submit && submit.company?.domain}
      />

      {/* <LabelValue
        label="Phone"
        value={submit && submit.company?.phone}
        url={submit && submit.company?.phone}
        typeUrl="tel"
      />
      <LabelValue label="Category" value={submit && submit.company?.category} />
      {submit && submit.company?.rating ? (
        <>
          <LabelValue label="Rating" value={submit.company.rating.value} />
          <LabelValue label="Votes" value={submit.company.rating.votes_count} />
        </>
      ) : (
        <></>
      )} */}
    </Box>
  );
};

const Group = ({ submit }) => {
  return (
    <Box>
      <LabelValue label="Name" value={submit && submit.group?.name} />
      <LabelValue
        label="Description"
        value={submit && submit.group?.description}
      />
      <LabelValue
        label="Status"
        value={submit && submit.group?.status === true ? "active" : "inactive"}
      />
      <LabelValue label="Keyword" value={submit && submit.group?.keyword} />
    </Box>
  );
};

const Project = ({ submit }) => {
  return (
    <Box>
      <LabelValue
        label="Campaign Name"
        value={submit && submit.project?.name}
      />
      <LabelValue
        label="Description"
        value={submit && submit.project?.description}
      />
      <LabelValue
        label="Status"
        value={
          submit && submit.project?.status === true ? "active" : "inactive"
        }
      />
      <LabelValue
        label="Hubstaff ID"
        value={submit && submit.project?.hubstaff_project}
      />
      <LabelValue
        label="Hubstaff name"
        value={submit && submit.project?.hubstaff_project_name}
      />
    </Box>
  );
};

const Profile = ({ submit }) => {
  return (
    <Box>
      <LabelValue label="Name" value={submit && submit.profile?.sender} />
      <LabelValue label="Email" value={submit && submit.profile?.email} />
      <LabelValue
        label="Status"
        value={
          submit && submit.profile?.status === true ? "active" : "inactive"
        }
      />
      <LabelValue label="Domain" value={submit && submit.profile?.url} />
    </Box>
  );
};

const User = ({ user }) => {
  return (
    <Box pl={10} pt={3}>
      <Typography variant="h6" m={3} style={{ marginBottom: ".4rem" }}>
        User Information
      </Typography>
      <Divider m={3} />
      <Box style={{ display: "flex" }}>
        <Box>
          <LabelValue label="User Name" value={user && user.name} />
          <LabelValue label="User Email" value={user && user.email} />
        </Box>
        <Box>
          <LabelValue
            label="Status"
            value={user && user.status === true ? "active" : "inactive"}
          />
          <LabelValue label="Rule" value={user && user.membership_role} />
        </Box>
      </Box>
    </Box>
  );
};

const Message = ({ message }) => {
  const [messageText, setMessageText] = useState("");

  useEffect(() => {
    if (message.body) setMessageText(message.body);
  }, [message]);

  return (
    <Box pl={10}>
      <Typography variant="h6" m={3} style={{ marginBottom: ".4rem" }}>
        Message
      </Typography>
      <Divider my={6} />
      <Box display="block">
        <Box style={{ display: "flex" }}>
          <LabelValue label="Subject" value={message && message.subject} />
          <LabelValue label="Date" value={message && message.date} />
        </Box>
        <Divider m={4} />
        <LabelValue label="message" />
        <Box mr={10} my={4}>
          <Typography>{messageText}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const LabelValue = ({ label, value, url, typeUrl }) => {
  return (
    <Box
      style={{
        display: "flex",
        minWidth: "30rem",
        marginTop: "-1.5rem",
        marginBottom: "-1.5rem",
        fontSize: ".9rem",
      }}
    >
      <h4 style={{ minWidth: "8em" }}> {label} : </h4>
      {url ? (
        <a
          href={typeUrl ? `${typeUrl}:${url}` : url}
          target="_blank"
          rel="noreferrer"
        >
          <h5 style={{ minWidth: "5em" }}> {value} </h5>
        </a>
      ) : (
        <h5 style={{ minWidth: "5em" }}> {value} </h5>
      )}
    </Box>
  );
};

const Actions = () => {
  return (
    <Box
      style={{
        display: "flex",
        minWidth: "30rem",
        textAlign: "center",
        textJustify: "center",
      }}
    >
      <IconButton
        aria-label="delete"
        // onClick={() => window.open(row.company.url)}
      >
        <ArchiveIcon />
      </IconButton>
      <IconButton
        aria-label="delete"
        // onClick={() => window.open(row.company.url)}
      >
        <LinkUrl />
      </IconButton>
      <IconButton
        aria-label="delete"
        // onClick={() => window.open(row.company.url)}
      >
        <RemoveRedEyeIcon />
      </IconButton>
      <IconButton
        aria-label="delete"
        // onClick={() => window.open(row.company.url)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

const Submission = ({ submit }) => {
  let [user, setUser] = useState({});
  let [message, setMessage] = useState({});

  useEffect(() => {
    if (submit) {
      if (submit.user) setUser(submit.user);
      if (submit.message) {
        let newUpdatedMessage = getMessageWithVariable(submit.message.body, {
          ...submit,
          url: submit.company.url,
          category: submit.company.category,
        });

        setMessage({
          ...submit.message,
          body: newUpdatedMessage,
          date: getDateFormate(submit.createdAt),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  return (
    <Fragment>
      <Helmet title="Submission Info" />

      <Card style={{ width: "100%", height: "100%" }}>
        <Grid container md={12}>
          <Grid md={8}>
            <SubmissionSection>
              <LabTabs submit={submit} />
              <Divider mt={4} />
              <User user={user} />
              <Divider my={4} />
              <Message message={message} />
              <Divider my={4} />
              <Box pl={7}>
                <Actions my={4} />
              </Box>
            </SubmissionSection>
          </Grid>
          <Grid md={4}>
            <Box>
              <Chat lead={submit} />
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  );
};

const LabTabs = ({ submit }) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab variant="h6" label="Company" value="1" />
            <Tab variant="h6" label="Campaign" value="2" />
            <Tab variant="h6" label="Group" value="3" />
            <Tab variant="h6" label="Alias" value="4" />
          </TabList>
        </Box>
        <Box pl={7} pt={3}>
          <TabPanel value="1">
            <Company submit={submit} />
          </TabPanel>
          <TabPanel value="2">
            <Project submit={submit} />
          </TabPanel>
          <TabPanel value="3">
            <Group submit={submit} />
          </TabPanel>
          <TabPanel value="4">
            <Profile submit={submit} />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
};

export default Submission;
