import React from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";

const ArchiveDialog = ({
  title,
  description,
  open,
  id = null,
  setOpen,
  dispatchOnAgree,
}) => {
  const dispatch = useDispatch();

  const handleAgree = () => {
    dispatch(dispatchOnAgree(id));
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title || "Please Confirm?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description || "Please Confirm the action you just asked for?!"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Disagree
        </Button>
        <Button onClick={handleAgree} color="primary" autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ArchiveDialog;
