import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  CardContent,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { updateEmail } from "../../../redux/reducers/emailsReducer";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },
  dialogwrapper: {
    position: "absolute",
    maxWidth: "50rem",
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  startICon: {
    margin: 0,
  },
  chips: {
    height: "20px",
    borderRadius: "0px",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  ButtonApprove: {
    backgroundColor: "#23CC94",
    color: "white",
    width: "6rem",
    margin: "12px 39px",
  },
  description: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  h5: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
  },
  shrink: {
    display: "none",
  },
  inputSelect: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    backgroundColor: "#f5f6fa",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
}));

const validationSchema = Yup.object().shape({
  email: Yup.string().required("email is required"),
  company: Yup.string().required("company is required"),
});

const Popup = ({ open, setOpen, email }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const initialValues = {
    email: email ? email.email : "",
  };

  const handleSubmit = ({ email, company }) => {
    const data = {
      email,
      company,
    };

    if (email) {
      dispatch(updateEmail(email._id, data));
      setOpen(false);
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogwrapper }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography gutterBottom>
          {email ? "Update Email" : "New Email"}
        </Typography>
        <Box style={{ display: "flex", float: "right" }}>
          <Button
            style={{ color: "#868695", marginTop: "-40px" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </Box>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            status,
            disabled,
          }) => (
            <CardContent>
              <Typography variant="body2" gutterBottom>
                Create a new Email
              </Typography>

              <form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item md={12} style={{ padding: "9px 0" }}>
                    <Typography variant="h5" classes={{ h5: classes.h5 }}>
                      Email*:
                    </Typography>
                    <TextField
                      name="email"
                      placeholder="example@webdev.com"
                      InputProps={{ classes: { input: classes.input } }}
                      inputlabelprops={{ shrink: false }}
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helpertext={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Button
                  style={{
                    marginTop: "10px",
                    backgroundColor: "#6a74c9",
                    color: "white",
                  }}
                  type="submit"
                  variant="contained"
                  mt={4}
                >
                  {email ? "Update" : "Add New"}
                </Button>
              </form>
            </CardContent>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
