function createShadow() {
  return `box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05);`;
}

const shadows = [
  "none",
  createShadow(),
  `box-shadow: rgba(50, 50, 93, 0.5) 0px 0px 6px -2.5px rgba(0, 0, 0, 0.05)`,
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
];

export default shadows;
