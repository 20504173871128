import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, Box } from "@material-ui/core";
import { getMessages } from "../../../redux/reducers/messagesReducer";
import DatePicker from "../../customComponent/DateRangePicker";
import MySelect from "../../customComponent/MySelect";
import { Reload } from "../../customComponent/Reload";

function Actions() {
  const dispatch = useDispatch();
  const [projectsList, setProjectsList] = useState([]);

  const {
    isLoading,
    selectedDate,
    selectedTone,
    selectedChannel,
    selectedProject,
    selectedApproach,
  } = useSelector((state) => state.entities.messages);
  const { TONE_LIST, APPROACHES_LIST } = useSelector(
    (state) => state.entities.settings.setting
  );
  const { allProjects } = useSelector((state) => state.entities.projects);

  useEffect(() => {
    const list = allProjects.filter(
      (item) =>
        !selectedChannel ||
        selectedChannel === "all" ||
        selectedChannel === item.channel
    );
    setProjectsList(list);
  }, [allProjects, selectedChannel]);

  const loadMessages = () => {
    dispatch(
      getMessages({
        tone: selectedTone,
        approach: selectedApproach,
        channel: selectedChannel,
        project: selectedProject,
        date: selectedDate,
      })
    );
  };
  return (
    <Box display="flex" align="center">
      <Reload loading={isLoading} action={loadMessages} />
      <FormControl style={{ marginRight: ".5rem" }}>
        <MySelect
          selected={selectedProject}
          defaultLabel="All Campaigns"
          defaultValue="all"
          list={projectsList}
          valueHolder="project"
          label="name"
          value="_id"
          data={{
            channel: selectedChannel,
            approach: selectedApproach,
            tone: selectedTone,
            date: selectedDate,
          }}
          toDispatch={getMessages}
        />
      </FormControl>
      <FormControl style={{ marginRight: ".5rem" }}>
        <MySelect
          selected={selectedApproach}
          defaultLabel="All Approaches"
          defaultValue="all"
          list={APPROACHES_LIST || []}
          valueHolder="approach"
          data={{
            channel: selectedChannel,
            project: selectedProject,
            tone: selectedTone,
            date: selectedDate,
          }}
          toDispatch={getMessages}
        />
      </FormControl>
      <FormControl style={{ marginRight: ".5rem" }}>
        <MySelect
          selected={selectedTone}
          defaultLabel="All Tones"
          defaultValue="all"
          list={TONE_LIST || []}
          valueHolder="tone"
          data={{
            channel: selectedChannel,
            project: selectedProject,
            approach: selectedApproach,
            date: selectedDate,
          }}
          toDispatch={getMessages}
        />
      </FormControl>
      <FormControl style={{ marginRight: ".5rem" }}>
        <DatePicker
          data={{
            channel: selectedChannel,
            project: selectedProject,
            approach: selectedApproach,
            tone: selectedTone,
          }}
          toDispatch={getMessages}
        />
      </FormControl>
    </Box>
  );
}

export default Actions;
