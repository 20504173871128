import React, { useState, Fragment } from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updateMessage } from "../../../redux/reducers/messagesReducer";

function Message({ message }) {
  const dispatch = useDispatch();
  const { subject, body, _id } = message;
  const [subjectValue, setSubjectValue] = useState(subject);
  const [bodyValue, setBodyValue] = useState(body);
  const [editSubject, setEditSubject] = useState(false);
  const [editBody, setEditBody] = useState(false);

  const handleChange = (e, type) => {
    if (type === "subject") setSubjectValue(e.target.value);
    else setBodyValue(e.target.value);
  };

  const handle = (data) => {
    setEditBody(false);
    dispatch(updateMessage(_id, data));
  };

  return (
    <Fragment>
      <Card style={{ backgroundColor: "#F7F7F7" }}>
        <CardContent style={{ position: "relative" }}>
          <Box
            style={{ display: "flex" }}
            onClick={() => {
              setEditSubject(true);
            }}
          >
            <span
              style={
                !subjectValue.length ? { color: "red", fontWeight: 900 } : {}
              }
            >
              SUBJECT{!subjectValue.length ? "*" : ""}
            </span>
            {editSubject ? (
              <TextField
                id={_id + "_subject"}
                value={subjectValue}
                onChange={(e) => {
                  handleChange(e, "subject");
                }}
                onBlur={() => {
                  setEditSubject(false);
                }}
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    handle({ subject: subjectValue });
                  }
                }}
                fullWidth
                required
                placeholder="This is a Subject"
                style={{
                  marginTop: "-7px",
                  marginLeft: "1rem",
                  border: "none",
                  fontSize: "10px",
                }}
              />
            ) : (
              <Typography variant="h6" mb={6} style={{ fontSize: "15px" }}>
                : {subjectValue}
              </Typography>
            )}
          </Box>
          <Divider my={5} />
          <Box
            onClick={() => {
              setEditBody(true);
            }}
          >
            {editBody ? (
              <TextField
                id={_id + "_body"}
                variant="outlined"
                value={bodyValue}
                onChange={(e) => handleChange(e, "body")}
                onBlur={() => {
                  setEditBody(false);
                }}
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    handle({ body: bodyValue });
                  }
                }}
                fullWidth
                multiline
                rowsMax={8}
                style={{ marginBottom: "1em", fontSize: "8px" }}
              />
            ) : (
              <Typography variant="h5" mb={6} style={{ fontSize: "15px" }}>
                {bodyValue}
              </Typography>
            )}
          </Box>
          <span
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              marginRight: "1rem",
              marginBottom: "5px",
            }}
          >
            {body.length}/300
          </span>
        </CardContent>
      </Card>
    </Fragment>
  );
}

export default Message;
