import React, { useState, useEffect, Fragment } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  CardContent,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { updateUser, addUser } from "../../../redux/reducers/usersReducer";
import {
  loginIntoUser,
  sendResetPassword,
} from "../../../redux/reducers/authReducer";
import ArchiveDialog from "../../customComponent/AlertDialog";
import { removeCurrentSubmit } from "../../../redux/reducers/submissionsReducer";

const Alert = styled(MuiAlert)(spacing);

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },
  // paper: {
  //     borderRadius: "12px",
  //     boxShadow: "0px 0px 12px -5px #000000",
  // },
  dialogwrapper: {
    position: "absolute",
    maxWidth: "40rem",
    // top: theme.spacing(5)
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  startICon: {
    margin: 0,
  },
  chips: {
    height: "20px",
    borderRadius: "0px",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  ButtonApprove: {
    backgroundColor: "#23CC94",
    color: "white",
    width: "6rem",
    margin: "12px 39px",
  },
  description: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  h5: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
    // backgroundColor: "white"
  },
  shrink: {
    display: "none",
  },
  inputSelect: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    backgroundColor: "#f5f6fa",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
}));

const Popup = ({ open, setOpen, handleClose, user }) => {
  const [project, setProject] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [userToReset, setUserToReset] = useState(null);
  const [resetDialogOpen, setResetDialogOpen] = useState(false);

  const { error, success, isLoading } = useSelector(
    (state) => state.entities.users
  );

  const { allProjects } = useSelector((state) => state.entities.projects);

  useEffect(() => {
    if (success && !isLoading) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    setProject(user.projects[0] ? user.projects[0]._id : false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user._id]);

  const initialValues = {
    user_name: user && user.name,
    user_email: user && user.email,
    user_password: "",
    repeatPassword: "",
    membership_role: user && user.membership_role,
    project: project,
  };

  const validationSchema = Yup.object().shape({
    user_name: Yup.string().required("Required"),
    user_email: Yup.string().required("Required"),
    project: Yup.string(),
    user_password: Yup.string()
      .min(8, "Must be at least 8 characters")
      .max(255),
    repeatPassword: Yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
  });

  const handleSubmit = async (values) => {
    const data = {
      name: values.user_name,
      email: values.user_email,
      password: values.user_password,
      membership_role: values.membership_role,
      projects: values.project ? [values.project] : [],
    };

    if (user) {
      let id = user._id;
      // dispatch update
      dispatch(updateUser(id, data));
    } else {
      dispatch(addUser(data));
    }
  };

  // open the dialog
  const handleResetPassword = () => {
    if (user) {
      setUserToReset(user);
    }
    setResetDialogOpen(true);
  };

  // HANDLE reset Dialog
  const openReset = (action) => {
    setResetDialogOpen(action);
    setUserToReset(null);
  };

  const logInToUser = () => {
    dispatch(loginIntoUser(user._id));
  };

  const handleRemoveCurrentSubmit = () => {
    dispatch(removeCurrentSubmit(user._id));
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogwrapper }}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      {userToReset && (
        <ArchiveDialog
          title="Reset User Password"
          description="This will send a link to reset the password for the user, are you sure you want to proceed?"
          open={resetDialogOpen}
          id={{ email: userToReset?.email }}
          setOpen={openReset}
          dispatchOnAgree={sendResetPassword}
        />
      )}
      <DialogTitle className={classes.dialogTitle}>
        <Box style={{ display: "flex", float: "right" }}>
          <Button
            style={{ color: "#868695" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </Box>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
          }) => (
            <>
              <CardContent>
                {error && (
                  <Alert severity="danger" my={3}>
                    {error}
                  </Alert>
                )}
                {isLoading ? (
                  <Box display="flex" justifyContent="center" my={6}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <Grid item md={12} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Name*:
                        </Typography>
                        <TextField
                          InputProps={{ classes: { input: classes.input } }}
                          inputlabelprops={{ shrink: false }}
                          name="user_name"
                          placeholder="Name"
                          value={values.user_name}
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helpertext={touched.name && errors.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item md={12} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Email*:
                        </Typography>
                        <TextField
                          inputlabelprops={{ shrink: false }}
                          placeholder="Email"
                          InputProps={{ classes: { input: classes.input } }}
                          name="user_email"
                          value={values.user_email}
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helpertext={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item md={12} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Password*:
                        </Typography>
                        <TextField
                          inputlabelprops={{ shrink: false }}
                          placeholder="Password"
                          InputProps={{ classes: { input: classes.input } }}
                          name="user_password"
                          value={values.user_password}
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          helpertext={touched.password && errors.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="password"
                          variant="outlined"
                          my={2}
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item md={12} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Repeat Password*:
                        </Typography>
                        <TextField
                          inputlabelprops={{ shrink: false }}
                          placeholder="Repeat Password"
                          InputProps={{ classes: { input: classes.input } }}
                          name="repeatPassword"
                          value={values.repeatPassword}
                          error={Boolean(
                            touched.repeatPassword && errors.repeatPassword
                          )}
                          fullWidth
                          helpertext={
                            touched.repeatPassword && errors.repeatPassword
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="password"
                          variant="outlined"
                          my={2}
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item md={12} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Type*:
                        </Typography>
                        <FormControl fullWidth>
                          <InputLabel
                            // shrink={false}
                            classes={{ shrink: classes.shrink }}
                            style={{ margin: "-7px 10px", zIndex: 1 }}
                            id="demo-simple-select-label"
                          >
                            Access Level
                          </InputLabel>
                          <Select
                            classes={{ root: classes.inputSelect }}
                            MenuProps={{
                              classes: { paper: classes.paperSelect },
                              variant: "menu",
                            }}
                            inputlabelprops={{ shrink: false }}
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.membership_role}
                            error={Boolean(
                              touched.membership_role && errors.membership_role
                            )}
                            fullWidth
                            helpertext={
                              touched.membership_role && errors.membership_role
                            }
                            onBlur={handleBlur}
                            onChange={handleChange("membership_role")}
                            my={2}
                          >
                            <MenuItem value={"owner"}>Owner</MenuItem>
                            <MenuItem value={"manager"}>Manager</MenuItem>
                            <MenuItem value={"admin"}>Admin</MenuItem>
                            <MenuItem value={"user"}>User</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={12} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Campaign:
                        </Typography>
                        <FormControl fullWidth>
                          <InputLabel
                            // shrink={false}
                            classes={{ shrink: classes.shrink }}
                            style={{ margin: "-7px 10px", zIndex: 1 }}
                            id="demo-simple-select-label-campaign"
                          >
                            Select Campaign
                          </InputLabel>
                          <Select
                            classes={{ root: classes.inputSelect }}
                            MenuProps={{
                              classes: { paper: classes.paperSelect },
                              variant: "menu",
                            }}
                            inputlabelprops={{ shrink: false }}
                            variant="outlined"
                            labelId="demo-simple-select-label-campaign"
                            id="demo-simple-select-campaign"
                            name="demo-simple-select-campaign"
                            value={values.project}
                            fullWidth
                            helpertext={touched.project && errors.project}
                            onBlur={handleBlur}
                            onChange={handleChange("project")}
                            my={2}
                          >
                            <MenuItem value={false}>No Campaign</MenuItem>
                            {allProjects.map((project, index) => (
                              <MenuItem value={project._id} key={index}>
                                {project.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Box
                      style={{
                        display: "grid",
                        gridAutoFlow: "column",
                        gridColumnGap: "5px",
                        marginTop: "2rem",
                      }}
                    >
                      <Button
                        style={{
                          marginRight: "15px",
                          backgroundColor: "#FF0000",
                          color: "#fff",
                        }}
                        variant="outlined"
                        mt={3}
                        onClick={handleRemoveCurrentSubmit}
                      >
                        Remove Current Submission
                      </Button>
                      {/* {user?.membership_role === "user" && ( */}
                      <Button
                        style={{ marginRight: "15px" }}
                        variant="outlined"
                        color="primary"
                        mt={3}
                        onClick={logInToUser}
                      >
                        Login As User
                      </Button>
                      {/* )} */}
                      <Button
                        style={{ marginRight: "15px" }}
                        variant="outlined"
                        color="primary"
                        mt={3}
                        onClick={handleResetPassword}
                      >
                        Send Reset Email to User
                      </Button>
                      <Button
                        style={{
                          width: "10rem",
                          backgroundColor: "#6a74c9",
                          color: "white",
                        }}
                        type="submit"
                        variant="contained"
                        mt={3}
                      >
                        Save changes
                      </Button>
                    </Box>
                  </form>
                )}
              </CardContent>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
