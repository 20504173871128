import { getAllGroups, getGroups } from "../redux/reducers/groupsReducer";
import {
  getAllProfiles,
  getProfiles,
  getProfilesChartStats,
} from "../redux/reducers/profilesReducer";
import { getMessages, getAllMessages } from "../redux/reducers/messagesReducer";
import { getUsers } from "../redux/reducers/usersReducer";
import {
  getProjects,
  getAllProjects,
  getProjectsStats,
} from "../redux/reducers/projectReducer";
import { getLeads } from "../redux/reducers/leadReducer";
import { getSubmissions } from "../redux/reducers/submissionsReducer";
import { getCompanies } from "../redux/reducers/companiesReducer";
import { getSettings } from "../redux/reducers/settingsReducer";
import * as globals from "../constants/index";

import {
  getAllChannels,
  getChannels,
  getChannelsProjects,
  getChannelStats,
} from "../redux/reducers/channelReducer";

import {
  getHubstaffProjects,
  getHubstaffStats,
} from "../redux/reducers/hubstaffReducer";
import {
  getConversions,
  getEventsList,
} from "../redux/reducers/conversionsReducer";
import { getEmails } from "../redux/reducers/emailsReducer";
import {
  getSendGridList,
  getDirectories,
} from "../redux/reducers/directoriesReducer";
import { getWebsites } from "../redux/reducers/websitesReducer";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const dispatchAdminModules = (dispatch, state, refresh = false) => {
  if (state) {
    if (state.channels.isRequestSent === null) {
      dispatch(getChannels());
      dispatch(getChannelsProjects());
      dispatch(getChannelStats());
      dispatch(getAllChannels());
    }

    if (state.companies.isRequestSent === null) {
      dispatch(getCompanies());
    }

    if (state.settings.isRequestSent === null) {
      dispatch(getSettings());
    }

    if (state.groups.isRequestSent === null) {
      dispatch(getGroups());
      dispatch(getAllGroups());
    }

    if (state.hubstaff.isRequestSent === null) {
      dispatch(getHubstaffProjects());
      dispatch(getHubstaffStats());
    }

    if (!syncChannelFilter()) return;

    if (state.users.isRequestSent === null || refresh) {
      dispatch(getUsers());
    }

    if (state.channels.isRequestSent === null || refresh) {
      dispatch(getChannelStats());
    }

    if (state.projects.isRequestSent === null || refresh) {
      dispatch(getProjects());
      dispatch(getAllProjects());
      dispatch(getProjectsStats());
    }

    if (state.profiles.isRequestSent === null || refresh) {
      dispatch(getProfilesChartStats());
      dispatch(getAllProfiles());
      dispatch(getProfiles());
    }

    if (state.messages.isRequestSent === null || refresh) {
      dispatch(getMessages());
      dispatch(getAllMessages());
    }

    if (state.leads.isRequestSent === null || refresh) {
      dispatch(getLeads());
    }

    if (state.submissions.isRequestSent === null || refresh) {
      dispatch(getSubmissions());
    }

    if (state.conversions.isRequestSent === null || refresh) {
      dispatch(getConversions());
      dispatch(getEventsList());
    }
    if (state.emails.isRequestSent === null || refresh) {
      dispatch(getEmails());
    }
    if (state.websites.isRequestSent === null || refresh) {
      dispatch(getWebsites());
    }
    if (state.directories.isRequestSent === null || refresh) {
      dispatch(getDirectories());
      dispatch(getSendGridList());
    }
  }

  getNumberPerPage();
};

const getLocalStorageItem = (item) => {
  return localStorage.getItem(item);
};

const setLocalStorageItem = (item, value) => {
  return localStorage.setItem(item, value);
};

const getNumberPerPage = () => {
  const nbr = parseInt(getLocalStorageItem("PerPage"));
  if (nbr) return nbr;
  else setLocalStorageItem("PerPage", 25);
  return 25;
};

const updateNbrPerPage = (nbr) => {
  // update  local storage
  setLocalStorageItem("PerPage", nbr);
};

const getToken = () => {
  const token = getLocalStorageItem(globals.USER_TOKEN);
  return token ? JSON.parse(token) : null;
};

const updateToken = (token) => {
  setLocalStorageItem(globals.USER_TOKEN, JSON.stringify(token));
  return token;
};

const getTabNumber = (tabs) => {
  const value = getLocalStorageItem(tabs);
  return value ? JSON.parse(value) : null;
};

const updateTabNumber = (tabs, number) => {
  setLocalStorageItem(tabs, number);
  return number;
};

const showNumber = (number) => {
  return number !== undefined && number !== null ? number.toString() : number;
};

const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || "";

const getDateFormate = (d) => {
  let date = new Date(d);
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  date = mm + "-" + dd + "-" + yyyy;
  return date;
};

const getShortDateFormate = (d) => {
  let date = new Date(d);
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  date = parseInt(mm) + "-" + parseInt(dd) + "-" + yyyy.toString().substr(-2);
  return date;
};

const getDateTimeFormate = (date) => {
  date = new Date(date);

  // // Make a fuzzy time
  let delta = Math.round((+new Date() - date) / 1000);

  let minute = 60,
    hour = minute * 60,
    day = hour * 24,
    week = day * 7,
    month = day * 30;

  let fuzzy = getDateFormate(date) + " " + date.toString().substr(16, 5);

  if (delta < 30) {
    fuzzy = "a moment ago";
  } else if (delta < minute) {
    fuzzy = delta + " seconds ago";
  } else if (delta < 2 * minute) {
    fuzzy = "a minute ago";
  } else if (delta < hour) {
    fuzzy = Math.floor(delta / minute) + " minutes ago";
  } else if (Math.floor(delta / hour) === 1) {
    fuzzy = "1 hour ago";
  } else if (delta < day) {
    fuzzy = Math.floor(delta / hour) + " hours ago";
  } else if (delta < day * 2) {
    fuzzy = "yesterday";
  } else if (delta < day * 7) {
    fuzzy = Math.floor(delta / day) + " days ago";
  } else if (delta < week * 4) {
    fuzzy = Math.floor(delta / week) + " week ago";
  } else if (delta < month * 12) {
    let count = Math.floor(delta / month);
    fuzzy = count === 1 ? count + " month ago" : count + " months ago";
  }

  return fuzzy;
};

const getDomainFromURL = (url = "") => {
  try {
    let domain = new URL(url);
    return capitalize(domain.hostname.replace("www.", ""));
  } catch {
    return capitalize(url);
  }
};

const syncChannelFilter = (newOrganization = false) => {
  const key = "organization";
  if (newOrganization) {
    setLocalStorageItem(key, newOrganization);
    return newOrganization;
  } else {
    let organization = getLocalStorageItem(key);
    return organization;
  }
};

const isFunction = (functionToCheck) => {
  return (
    (functionToCheck &&
      {}.toString.call(functionToCheck) === "[object Function]") ||
    (functionToCheck && typeof functionToCheck === "function")
  );
};

const isValidURL = (urlString) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export {
  getToken,
  stableSort,
  capitalize,
  showNumber,
  isFunction,
  isValidURL,
  updateToken,
  getTabNumber,
  getComparator,
  getDateFormate,
  updateTabNumber,
  getNumberPerPage,
  updateNbrPerPage,
  getDomainFromURL,
  syncChannelFilter,
  getDateTimeFormate,
  getShortDateFormate,
  getLocalStorageItem,
  setLocalStorageItem,
  dispatchAdminModules,
};
