import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip,
  Divider as MuiDivider,
  Grid,
  Slide,
  Snackbar,
  Tab,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { resetAll, updateSettings } from "../../redux/reducers/settingsReducer";
import { Alert } from "@material-ui/lab";
import Loading from "../customComponent/Loading";
import { getTabNumber, updateTabNumber } from "../../helpers/functions";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const HubstaffTokens = ({ settings, dispatch }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(settings);
  }, [settings]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { HUBSTAFF_REFRESH_TOKEN, hubstaffOrganization } = data;
    let UpdateData = {
      HUBSTAFF_REFRESH_TOKEN,
      hubstaffOrganization,
    };
    dispatch(updateSettings(UpdateData));
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <TextField
        id="HUBSTAFF_REFRESH_TOKEN"
        label="Refresh Token"
        variant="outlined"
        value={data.HUBSTAFF_REFRESH_TOKEN || ""}
        onChange={(e) => handleChange(e)}
        fullWidth
        height={"60rem"}
        my={2}
      />

      <TextField
        id="hubstaffOrganization"
        label="hubstaff Organization"
        variant="outlined"
        value={data.hubstaffOrganization || ""}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
      />

      <Button
        variant="contained"
        type="submit"
        style={{
          backgroundColor: "#6a74c9",
          color: "white",
          position: "absolute",
          right: "0",
          bottom: "0",
        }}
      >
        Save changes
      </Button>
    </form>
  );
};

const ScrapingServices = ({ settings, dispatch }) => {
  const [data, setData] = useState(settings);

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      USER,
      PASS,
      DATAFORSEO_BASE_URL,
      DATAFORSEO_API_URL,
      NBR_TASKS_PER_MINUTES,
      nbrKeywordsToFetch,
      NBR_COMPANY_FILTERED_PER_MINUTES,
    } = data;
    let UpdateData = {
      USER,
      PASS,
      DATAFORSEO_BASE_URL,
      DATAFORSEO_API_URL,
      NBR_TASKS_PER_MINUTES,
      nbrKeywordsToFetch,
      NBR_COMPANY_FILTERED_PER_MINUTES,
    };
    dispatch(updateSettings(UpdateData));
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <TextField
        id="USER"
        label="User"
        variant="outlined"
        value={data.USER}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
      />

      <TextField
        id="PASS"
        label="Password"
        variant="outlined"
        value={data.PASS}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
      />

      <TextField
        id="DATAFORSEO_BASE_URL"
        label="DATA FOR SEO BASE URL"
        variant="outlined"
        value={data.DATAFORSEO_BASE_URL}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
      />

      <TextField
        id="DATAFORSEO_API_URL"
        label="DATA FOR SEO API URL"
        variant="outlined"
        value={data.DATAFORSEO_API_URL}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
      />

      <TextField
        id="NBR_TASKS_PER_MINUTES"
        label="Number Tasks per minutes"
        variant="outlined"
        value={data.NBR_TASKS_PER_MINUTES}
        onChange={(e) => handleChange(e)}
        defaultValue=""
        fullWidth
        my={2}
      />

      <TextField
        id="nbrKeywordsToFetch"
        label="Number Keywords To Fetch"
        variant="outlined"
        value={data.nbrKeywordsToFetch}
        onChange={(e) => handleChange(e)}
        defaultValue=""
        fullWidth
        my={2}
      />

      <TextField
        id="NBR_COMPANY_FILTERED_PER_MINUTES"
        label="Number Company To Filter"
        variant="outlined"
        value={data.NBR_COMPANY_FILTERED_PER_MINUTES}
        onChange={(e) => handleChange(e)}
        defaultValue=""
        fullWidth
        my={2}
      />

      <Button
        variant="contained"
        type="submit"
        style={{
          backgroundColor: "#6a74c9",
          color: "white",
          position: "absolute",
          right: "0",
          bottom: "0",
        }}
      >
        Save changes
      </Button>
    </form>
  );
};

const CLOSEIO = ({ settings, dispatch }) => {
  const [data, setData] = useState(settings);

  const handleSubmit = (e) => {
    e.preventDefault();

    const { CLOSEIO_API_KEY } = data;
    let UpdateData = {
      CLOSEIO_API_KEY,
    };
    dispatch(updateSettings(UpdateData));
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <TextField
        id="CLOSEIO_API_KEY"
        label="CLOSEIO API KEY"
        variant="outlined"
        value={data.CLOSEIO_API_KEY}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
      />

      <Button
        variant="contained"
        type="submit"
        style={{
          backgroundColor: "#6a74c9",
          color: "white",
          position: "absolute",
          right: "0",
          bottom: "0",
        }}
      >
        Save changes
      </Button>
    </form>
  );
};

const WordPress = ({ settings, dispatch }) => {
  const [data, setData] = useState(settings);

  const handleSubmit = (e) => {
    e.preventDefault();

    const { wordPressBusinessLogo } = data;
    let UpdateData = {
      wordPressBusinessLogo,
    };
    dispatch(updateSettings(UpdateData));
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <TextField
        id="wordPressBusinessLogo"
        label="Business Logo"
        variant="outlined"
        value={data.wordPressBusinessLogo}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
      />

      <Button
        variant="contained"
        type="submit"
        style={{
          backgroundColor: "#6a74c9",
          color: "white",
          position: "absolute",
          right: "0",
          bottom: "0",
        }}
      >
        Save changes
      </Button>
    </form>
  );
};

const SendGrid = ({ settings, dispatch }) => {
  const [data, setData] = useState(settings);

  const handleSubmit = (e) => {
    e.preventDefault();

    const { SENDGRID_API_KEY, Forget_Password_Template_Id } = data;
    let UpdateData = {
      SENDGRID_API_KEY,
      Forget_Password_Template_Id,
    };
    dispatch(updateSettings(UpdateData));
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <TextField
        id="SENDGRID_API_KEY"
        label="SEND GRID API KEY"
        variant="outlined"
        value={data.SENDGRID_API_KEY}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
      />
      <TextField
        id="Forget_Password_Template_Id"
        label="Forget Password Template Id"
        variant="outlined"
        value={data.Forget_Password_Template_Id}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
      />
      <Button
        variant="contained"
        type="submit"
        style={{
          backgroundColor: "#6a74c9",
          color: "white",
          position: "absolute",
          right: "0",
          bottom: "0",
        }}
      >
        Save changes
      </Button>
    </form>
  );
};

const CallRail = ({ settings, dispatch }) => {
  const [data, setData] = useState(settings);

  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      Call_tracking_api,
      Call_tracking_Account_ID,
      CALL_RAIL_API_URL,
    } = data;
    let UpdateData = {
      Call_tracking_api,
      Call_tracking_Account_ID,
      CALL_RAIL_API_URL,
    };
    dispatch(updateSettings(UpdateData));
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <TextField
        id="Call_tracking_api"
        label="Call Tracking API"
        variant="outlined"
        value={data.Call_tracking_api}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
      />
      <TextField
        id="Call_tracking_Account_ID"
        label="Call tracking Account ID"
        variant="outlined"
        value={data.Call_tracking_Account_ID}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
      />
      <TextField
        id="CALL_RAIL_API_URL"
        label="CALL RAIL API URL"
        variant="outlined"
        value={data.CALL_RAIL_API_URL}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
      />

      <Button
        variant="contained"
        type="submit"
        style={{
          backgroundColor: "#6a74c9",
          color: "white",
          position: "absolute",
          right: "0",
          bottom: "0",
        }}
      >
        Save changes
      </Button>
    </form>
  );
};

const BlacklistedDomains = ({ settings, dispatch }) => {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState(settings);
  const [domain, setDomain] = useState("");
  const [selectDomains, setSelectDomains] = useState(data.blacklistedDomains);

  const handleSubmit = (e) => {
    e.preventDefault();

    // const { blacklistedDomains } = data;

    let UpdateData = { blacklistedDomains: selectDomains };
    dispatch(updateSettings(UpdateData));
  };

  const handleChange = (e) => {
    // setData({ ...data, [e.target.id]: e.target.value });
    setDomain(e.target.value);
  };

  const removeDomain = (e, key) => {
    let result = selectDomains.filter((item) => item !== key);
    setSelectDomains(result);
  };

  const addToBlackList = (e, domain) => {
    e.preventDefault();

    if (!selectDomains.includes(domain))
      setSelectDomains((selectDomains) => [...selectDomains, domain]);

    setDomain("");
  };

  return (
    <Box>
      <form onSubmit={(e) => addToBlackList(e, domain)}>
        <TextField
          id="add_to_black_list"
          label="Add to black list"
          variant="outlined"
          onChange={handleChange}
          value={domain}
          fullWidth
          my={2}
        />
      </form>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Grid item md={12} style={{ padding: "9px 0px" }}>
          {selectDomains &&
            selectDomains.map((item) => {
              return (
                <Chip
                  key={item}
                  style={{
                    marginRight: "5px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  label={item}
                  // onClick={}
                  onDelete={(e) => {
                    removeDomain(e, item);
                  }}
                />
              );
            })}
        </Grid>

        <Button
          variant="contained"
          type="submit"
          style={{
            backgroundColor: "#6a74c9",
            color: "white",
            position: "absolute",
            right: "0",
            bottom: "0",
          }}
        >
          Save changes
        </Button>
      </form>
    </Box>
  );
};

const ApproachedPanel = ({ settings, dispatch }) => {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState(settings);
  const [ApproachesUse, setApproachesUse] = useState([]);
  const [approach, setApproach] = useState("");
  const [selectApproach, setSelectApproach] = useState(data.APPROACHES_LIST);
  const { list_all } = useSelector((state) => state.entities.profiles);
  const { all } = useSelector((state) => state.entities.messages);

  useEffect(() => {
    let profileUseProfile = list_all.map((item) => item.approach);
    let profileUseMessage = all.map((item) => item.approach);
    const list = [...profileUseProfile, ...profileUseMessage];
    setApproachesUse(list);
  }, [list_all, all]);

  const isApproachUse = (approach) => {
    return ApproachesUse.includes(approach);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let UpdateData = { APPROACHES_LIST: selectApproach };
    dispatch(updateSettings(UpdateData));
  };

  const handleChange = (e) => {
    setApproach(e.target.value);
  };

  const removeApproach = (e, key) => {
    let result = selectApproach.filter((item) => item !== key);
    setSelectApproach(result);
  };

  const addToApproachedList = (e, approach) => {
    e.preventDefault();

    if (!selectApproach.includes(approach))
      setSelectApproach((selectApproach) => [...selectApproach, approach]);

    setApproach("");
  };

  return (
    <Box>
      <form onSubmit={(e) => addToApproachedList(e, approach)}>
        <TextField
          id="add_to_approached_list"
          label="Add to approached list"
          variant="outlined"
          onChange={handleChange}
          value={approach}
          fullWidth
          my={2}
        />
      </form>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Grid item md={12} style={{ padding: "9px 0px" }}>
          {selectApproach &&
            selectApproach.map((item) => {
              return (
                <Chip
                  key={item}
                  style={{
                    marginRight: "5px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  label={item}
                  // onClick={}
                  onDelete={
                    isApproachUse(item)
                      ? undefined
                      : (e) => {
                          removeApproach(e, item);
                        }
                  }
                />
              );
            })}
        </Grid>

        <Button
          variant="contained"
          type="submit"
          style={{
            backgroundColor: "#6a74c9",
            color: "white",
            position: "absolute",
            right: "0",
            bottom: "0",
          }}
        >
          Save changes
        </Button>
      </form>
    </Box>
  );
};

const TonesPanel = ({ settings, dispatch }) => {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState(settings);
  const [tone, setTone] = useState("");
  const [tonesUse, setTonesUse] = useState([]);
  const [selectTone, setSelectTone] = useState(data.TONE_LIST);
  const { list_all } = useSelector((state) => state.entities.profiles);
  const { all } = useSelector((state) => state.entities.messages);

  useEffect(() => {
    let profileUseProfile = list_all.map((item) => item.tone);
    let profileUseMessage = all.map((item) => item.tone);
    const list = [...profileUseProfile, ...profileUseMessage];
    setTonesUse(list);
  }, [list_all, all]);

  const isToneUse = (tone) => {
    return tonesUse.includes(tone);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let UpdateData = { TONE_LIST: selectTone };
    dispatch(updateSettings(UpdateData));
  };

  const handleChange = (e) => {
    setTone(e.target.value);
  };

  const removeTone = (e, key) => {
    let result = selectTone.filter((item) => item !== key);
    setSelectTone(result);
  };

  const addToTonesList = (e, tone) => {
    e.preventDefault();

    if (!selectTone.includes(tone))
      setSelectTone((selectTone) => [...selectTone, tone]);

    setTone("");
  };

  return (
    <Box>
      <form onSubmit={(e) => addToTonesList(e, tone)}>
        <TextField
          id="add_to_tones_list"
          label="Add to tones list"
          variant="outlined"
          onChange={handleChange}
          value={tone}
          fullWidth
          my={2}
        />
      </form>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Grid item md={12} style={{ padding: "9px 0px" }}>
          {selectTone &&
            selectTone.map((item) => {
              return (
                <Chip
                  key={item}
                  style={{
                    marginRight: "5px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  label={item}
                  onDelete={
                    isToneUse(item)
                      ? undefined
                      : (e) => {
                          removeTone(e, item);
                        }
                  }
                />
              );
            })}
        </Grid>

        <Button
          variant="contained"
          type="submit"
          style={{
            backgroundColor: "#6a74c9",
            color: "white",
            position: "absolute",
            right: "0",
            bottom: "0",
          }}
        >
          Save changes
        </Button>
      </form>
    </Box>
  );
};

const ConversionPanel = ({ settings, dispatch }) => {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState(settings);
  const [conversion, setConversion] = useState("");
  // const [conversionsUse, setConversionsUse] =useState([]);
  const [selectConversion, setSelectConversion] = useState(data.CONVERSIONS);
  // const { list } = useSelector((state) => state.entities.channels);

  // useEffect(() => {
  //   let listFiltered = [];
  //   listFiltered = listFiltered.concat(list.map((item) => item.conversions));
  //   setConversionsUse(listFiltered);
  // }, [list, all]);

  // const isConversionUse = (conversion) => {
  //   return conversionsUse.includes(conversion);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();

    let UpdateData = { CONVERSIONS: selectConversion };
    dispatch(updateSettings(UpdateData));
  };

  const handleChange = (e) => {
    setConversion(e.target.value);
  };

  const removeConversion = (e, key) => {
    let result = selectConversion.filter((item) => item !== key);
    setSelectConversion(result);
  };

  const addToConversionsList = (e, conversion) => {
    e.preventDefault();

    if (!selectConversion.includes(conversion))
      setSelectConversion((selectConversion) => [
        ...selectConversion,
        conversion,
      ]);

    setConversion("");
  };

  return (
    <Box>
      <form onSubmit={(e) => addToConversionsList(e, conversion)}>
        <TextField
          id="add_to_events_list"
          label="Add to events list"
          variant="outlined"
          onChange={handleChange}
          value={conversion}
          fullWidth
          my={2}
        />
      </form>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Grid item md={12} style={{ padding: "9px 0px" }}>
          {selectConversion &&
            selectConversion.map((item) => {
              return (
                <Chip
                  key={item}
                  style={{
                    marginRight: "5px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  label={item}
                  onDelete={(e) => {
                    removeConversion(e, item);
                  }}
                />
              );
            })}
        </Grid>

        <Button
          variant="contained"
          type="submit"
          style={{
            backgroundColor: "#6a74c9",
            color: "white",
            position: "absolute",
            right: "0",
            bottom: "0",
          }}
        >
          Save changes
        </Button>
      </form>
    </Box>
  );
};

const FailedSubmitMessage = ({ settings, dispatch }) => {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState(settings);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState(data.FAILED_SUBMIT_MESSAGES);

  const handleSubmit = (e) => {
    e.preventDefault();

    let UpdateData = { FAILED_SUBMIT_MESSAGES: messages };
    dispatch(updateSettings(UpdateData));
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const removeDomain = (e, key) => {
    let result = messages.filter((item) => item !== key);
    setMessages(result);
  };

  const addToMessageList = (e, message) => {
    e.preventDefault();

    if (!messages.includes(message)) setMessages([...messages, message]);

    setMessage("");
  };

  return (
    <Box>
      <form onSubmit={(e) => addToMessageList(e, message)}>
        <TextField
          id="add_to_messages_list"
          label="Add to messages list"
          variant="outlined"
          onChange={handleChange}
          value={message}
          fullWidth
          my={2}
        />
      </form>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Grid item md={12} style={{ padding: "9px 0px" }}>
          {messages &&
            messages.map((item) => {
              return (
                <Chip
                  key={item}
                  style={{
                    marginRight: "5px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  label={item}
                  // onClick={}
                  onDelete={(e) => {
                    removeDomain(e, item);
                  }}
                />
              );
            })}
        </Grid>

        <Button
          variant="contained"
          type="submit"
          style={{
            backgroundColor: "#6a74c9",
            color: "white",
            position: "absolute",
            right: "0",
            bottom: "0",
          }}
        >
          Save changes
        </Button>
      </form>
    </Box>
  );
};

const OpenAiOptions = ({ settings, dispatch }) => {
  const [openAIOptions, setValues] = useState(settings.openAIOptions || {});

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateSettings({
        openAIOptions: {
          max_tokens: parseInt(openAIOptions.max_tokens),
          top_p: parseInt(openAIOptions.top_p),
          frequency_penalty: parseFloat(openAIOptions.frequency_penalty),
          presence_penalty: parseFloat(openAIOptions.presence_penalty),
          stop: openAIOptions.stop,
        },
      })
    );
  };

  const handleChange = (e) => {
    setValues({ ...openAIOptions, [e.target.id]: e.target.value });
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <TextField
        id="max_tokens"
        label="Max Tokens"
        variant="outlined"
        value={openAIOptions.max_tokens}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
      />
      <TextField
        id="top_p"
        label="top_p"
        variant="outlined"
        value={openAIOptions.top_p}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
      />
      <TextField
        id="frequency_penalty"
        label="Frequency Penalty"
        variant="outlined"
        value={openAIOptions.frequency_penalty}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
      />
      <TextField
        id="presence_penalty"
        label="Presence Penalty"
        variant="outlined"
        value={openAIOptions.presence_penalty}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
      />
      <TextField
        id="stop"
        label="stop"
        variant="outlined"
        value={openAIOptions.stop}
        onChange={(e) => handleChange(e)}
        fullWidth
        my={2}
      />

      <Button
        variant="contained"
        type="submit"
        style={{
          backgroundColor: "#6a74c9",
          color: "white",
          position: "absolute",
          right: "0",
          bottom: "0",
        }}
      >
        Save changes
      </Button>
    </form>
  );
};

const LabTabs = () => {
  const [value, setValue] = useState("1");
  const { setting: settings, isLoading } = useSelector(
    (state) => state.entities.settings
  );

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    updateTabNumber("settings_tab", newValue);
  };

  useEffect(() => {
    const tabNumber = getTabNumber("settings_tab");
    if (tabNumber) setValue(tabNumber.toString());
  }, []);

  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
      style={{ position: "relative", height: "100%" }}
    >
      <TabContext value={value} style={{ height: "100%" }}>
        <Box style={{ display: "flex" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              orientation={"vertical"}
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab variant="h6" label="Hubstaff" value="1" />
              <Tab variant="h6" label="Data For SEO" value="2" />
              <Tab variant="h6" label="WordPress" value="3" />
              <Tab variant="h6" label="Call Rail" value="4" />
              <Tab variant="h6" label="Close API" value="5" />
              <Tab variant="h6" label="Send Grid" value="6" />
              <Tab variant="h6" label="Black Listed" value="7" />
              <Tab variant="h6" label="Message of Failed Submit" value="8" />
              <Tab variant="h6" label="Approaches" value="9" />
              <Tab variant="h6" label="Tones" value="10" />
              <Tab variant="h6" label="Events" value="11" />
              <Tab variant="h6" label="OpenAI Options" value="12" />
            </TabList>
          </Box>

          {isLoading ? (
            <Loading />
          ) : (
            <Box style={{ width: "100%" }}>
              <TabPanel value="1">
                <HubstaffTokens settings={settings} dispatch={dispatch} />
              </TabPanel>
              <TabPanel value="2">
                <ScrapingServices settings={settings} dispatch={dispatch} />
              </TabPanel>
              <TabPanel value="3">
                <WordPress settings={settings} dispatch={dispatch} />
              </TabPanel>
              <TabPanel value="4">
                <CallRail settings={settings} dispatch={dispatch} />
              </TabPanel>
              <TabPanel value="5">
                <CLOSEIO settings={settings} dispatch={dispatch} />
              </TabPanel>
              <TabPanel value="6">
                <SendGrid settings={settings} dispatch={dispatch} />
              </TabPanel>
              <TabPanel value="7">
                <BlacklistedDomains settings={settings} dispatch={dispatch} />
              </TabPanel>
              <TabPanel value="8">
                <FailedSubmitMessage settings={settings} dispatch={dispatch} />
              </TabPanel>
              <TabPanel value="9">
                <ApproachedPanel settings={settings} dispatch={dispatch} />
              </TabPanel>
              <TabPanel value="10">
                <TonesPanel settings={settings} dispatch={dispatch} />
              </TabPanel>
              <TabPanel value="11">
                <ConversionPanel settings={settings} dispatch={dispatch} />
              </TabPanel>
              <TabPanel value="12">
                <OpenAiOptions settings={settings} dispatch={dispatch} />
              </TabPanel>
            </Box>
          )}
        </Box>
      </TabContext>
    </Box>
  );
};

const TransitionLeft = (props) => {
  return <Slide {...props} direction="left" />;
};

const Settings = () => {
  const [notification, setNotification] = useState(false);
  const { success, error } = useSelector((state) => state.entities.settings);
  const dispatch = useDispatch();
  const handleNotificationClose = () => {
    setNotification(false);
    dispatch(resetAll());
  };

  useEffect(() => {
    if (success || error) {
      setNotification(true);
    }
  }, [success, error]);

  return (
    <Fragment>
      {notification === true && (
        <Snackbar
          open={notification}
          autoHideDuration={5000}
          onClose={handleNotificationClose}
          TransitionComponent={TransitionLeft}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleNotificationClose}
            severity={success ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {success || error}
          </Alert>
        </Snackbar>
      )}
      <Helmet title="Settings" />
      <Typography variant="h3" gutterBottom display="inline">
        Settings
      </Typography>
      <Divider my={6} />
      <Grid container spacing={6} style={{ height: "96%" }}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <LabTabs />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Settings;
