import React, { useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Menu as MenuRight,
  PersonSharp,
  ExitToAppSharp,
  ArrowBack,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { getUserByToken, signOut } from "../../redux/reducers/authReducer";
import { useHistory } from "react-router-dom";
import ProfileUser from "./ProfileUser";
import { updateToken } from "../../helpers/functions";

const useStyles = makeStyles({
  logo: {
    margin: "auto",
    textAlign: "center",
  },

  logoHorizontallyCenter: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  menuButton: {
    color: "white",
  },
  paperMenu: {
    boxShadow:
      " rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
  },
  buttonGoBack: {
    position: "absolute",
    left: "20%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "15px",
    fontWeight: "700",
    borderRadius: "5px",
    boxShadow:
      " rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
    "&:hover": {
      // background: "#ecf0f1",
    },
    backgroundColor: "#6320ee",
    color: "white",
  },
});
const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();

  let { membership_role } = useSelector(
    (state) => state.entities.auth.data.user
  );
  let { admin_area } = useSelector((state) => state.entities.auth.data);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const show = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleLogout = () => {
    if (admin_area && admin_area.token) {
      updateToken(admin_area.token);
      dispatch(getUserByToken());
      setTimeout(() => {
        history.push("/users");
      }, 3000);
    } else dispatch(signOut());
  };

  const goToDashboard = () => {
    history.push("/dashboard");
  };

  const isAdmin = () => {
    return (
      membership_role === "admin" ||
      membership_role === "owner" ||
      membership_role === "manager"
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky">
        <Toolbar className={classes.toolbar}>
          {isAdmin() && (
            <div>
              <Button
                className={classes.buttonGoBack}
                size="large"
                alt="Go To Dashboard"
                color="primary"
                variant="contained"
                onClick={goToDashboard}
              >
                <ArrowBack />
                Dashboard
              </Button>
            </div>
          )}
          <div className={classes.logoHorizontallyCenter}>
            <img
              className={classes.logo}
              src="https://webdevgroup.com/leads/templates/assets/images/logo2.png"
              alt="logo"
              height="40px"
            />
          </div>
          <div>
            <Button
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={show ? "true" : undefined}
              onClick={handleClick}
            >
              <MenuRight className={classes.menuButton} />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={show}
              onClose={handleCloseMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              classes={{ paper: classes.paperMenu }}
            >
              <MenuItem onClick={handleClickOpen} disableRipple>
                <PersonSharp style={{ marginRight: "10px" }} /> Update Password
              </MenuItem>
              {open && (
                <ProfileUser
                  open={open}
                  setOpen={setOpen}
                  project={null}
                  handleClose={handleCloseMenu}
                />
              )}

              <MenuItem onClick={handleLogout} disableRipple>
                <ExitToAppSharp style={{ marginRight: "10px" }} />
                Log Out
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
