import React from "react";
import {
  Layers,
  Briefcase,
  Link,
  Layout,
  Users,
  MessageSquare,
  Framer,
  Globe,
  Settings,
  BarChart2,
  AtSign,
  Box,
  Activity,
} from "react-feather";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Guards
import AuthGuard from "../components/AuthGuard";
import UserGuard from "../components/UserGuard";

// Auth components
import SignIn from "../pages/auth/SignIn";
import SendResetPassword from "../pages/auth/SendResetPassword";
import ResetPassword from "../pages/auth/ResetPassword";

// user components
import MembersArea from "../pages/members/MembersArea";
import SubmissionsArea from "../pages/members/iframe/SubmissionsArea";

// Protected routes
import dashboard from "../pages/dashboards";
import ChannelsList from "../pages/channels";
import GroupsList from "../pages/groups";
import MessagesList from "../pages/messages";
import Profiles from "../pages/profiles";
import Conversions from "../pages/conversions";
import UsersList from "../pages/users";
// import Leads from "../pages/leads";
// import LeadsInfo from "../pages/leads/InfoLeads";
import SubmissionsInfo from "../pages/submissions/InfoSubmission";
import LoginGuard from "../components/LoginGuard";
import GroupSettings from "../pages/groups/settings";
import SettingsPage from "../pages/settings";
import CompaniesList from "../pages/companies";
import EmailsList from "../pages/emails";
import DirectoriesList from "../pages/directories";
import DirectorySettings from "../pages/directories/settings";
import WebsitesList from "../pages/websites";

// new pages design
import ProjectsList from "../pages/projects";
import SubmissionsList from "../pages/submissions";

// eslint-disable-next-line no-unused-vars
const analytics = {
  id: "Analytics",
  path: "/",
  // icon: <Dashboard />,
  children: [
    {
      name: "Dashboard",
      path: "/dashboard",
      icon: <BarChart2 />,
      component: dashboard,
      guard: AuthGuard,
    },
    {
      name: "Users",
      path: "/users",
      icon: <Users />,
      component: UsersList,
      guard: AuthGuard,
    },
  ],
  component: null,
};

const Campaigns = {
  id: "Overview",
  path: "/",
  // icon: <Layers />,
  children: [
    {
      name: "Dashboard",
      path: "/dashboard",
      icon: <BarChart2 />,
      component: dashboard,
      guard: AuthGuard,
    },
    {
      name: "Campaigns",
      path: "/campaigns",
      icon: <Briefcase />,
      component: ProjectsList,
      guard: AuthGuard,
    },
    {
      name: "Messages",
      path: "/message",
      icon: <MessageSquare />,
      component: MessagesList,
      guard: AuthGuard,
    },
    {
      name: "Aliases",
      path: "/aliases",
      icon: <Layout />,
      component: Profiles,
      guard: AuthGuard,
    },
    {
      name: "Events",
      path: "/events",
      icon: <Activity />,
      component: Conversions,
      guard: AuthGuard,
    },
    {
      name: "Submits",
      path: "/submits/list",
      icon: <Framer />,
      component: SubmissionsList,
      guard: AuthGuard,
    },
  ],
  component: null,
};

const Scraping = {
  id: "Scraping",
  path: "/",
  // icon: <Globe />,
  children: [
    {
      path: "/groups",
      name: "Scraping Groups",
      icon: <Globe />,
      component: GroupsList,
      guard: AuthGuard,
    },
    {
      name: "Links (Companies)",
      path: "/companies",
      icon: <Link />,
      component: CompaniesList,
      guard: AuthGuard,
    },
    {
      name: "Emails",
      path: "/emails",
      icon: <AtSign />,
      component: EmailsList,
      guard: AuthGuard,
    },
    {
      name: "Directories",
      path: "/directories",
      icon: <Box />,
      component: DirectoriesList,
      guard: AuthGuard,
    },
  ],
  component: null,
};

const Misc = {
  id: "Misc",
  path: "/",
  // icon: <Settings />,
  children: [
    {
      name: "Settings",
      path: "/settings",
      icon: <Settings />,
      component: SettingsPage,
      guard: AuthGuard,
    },
    {
      name: "Organizations",
      path: "/organizations",
      icon: <Layers />,
      component: ChannelsList,
      guard: AuthGuard,
    },
    {
      name: "Users",
      path: "/users",
      icon: <Users />,
      component: UsersList,
      guard: AuthGuard,
      badge: "5",
    },
  ],
  component: null,
};

const AI_Platform = {
  id: "AI Platform",
  path: "/",
  children: [
    {
      name: "Websites",
      path: "/websites",
      icon: <Settings />,
      component: WebsitesList,
      guard: AuthGuard,
    },
  ],
  component: null,
};

const directorySettingRoute = {
  id: "Directory Settings",
  path: "/directories/:id",
  name: "Directory Settings",
  component: DirectorySettings,
  guard: AuthGuard,
  hideInMenu: true,
};

const groupSettings = {
  id: "Group Settings",
  path: "/groups/:id/settings",
  name: "Group Settings",
  component: GroupSettings,
  guard: AuthGuard,
  hideInMenu: true,
};

const submissionInfoRoute = {
  id: "Company Details",
  path: "/companies/:company_id/:id",
  name: "Company Details",
  component: SubmissionsInfo,
  guard: AuthGuard,
  hideInMenu: true,
};

const companySubmissionInfoRoute = {
  id: "Company Details",
  path: "/companies/:company_id",
  name: "Company Details",
  component: SubmissionsInfo,
  guard: AuthGuard,
  hideInMenu: true,
};

const loginRoute = {
  path: "/",
  name: "Sign In",
  component: SignIn,
  guard: LoginGuard,
};

const sendResetThePasswordRoute = {
  path: "/auth/forgot-password",
  name: "Forgot Password",
  component: SendResetPassword,
};

const resetThePasswordRoute = {
  path: "/auth/reset-password/:_id/:token",
  name: "Reset Password",
  component: ResetPassword,
  props: "this.props.match.params._id",
};

const membersRoutes = {
  id: "Members Area",
  path: "/members-area",
  icon: <Globe />,
  children: [
    {
      path: "/members-area",
      name: "Members Area",
      component: MembersArea,
      guard: UserGuard,
    },
    {
      path: "/members-area/submitting",
      name: "Submissions Area",
      component: SubmissionsArea,
      guard: UserGuard,
    },
  ],
  component: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  Campaigns,
  Scraping,
  Misc,
  AI_Platform,
  submissionInfoRoute,
  companySubmissionInfoRoute,
  groupSettings,
  directorySettingRoute,
];

// Routes that are not protected
export const authLayoutRoutes = [
  loginRoute,
  sendResetThePasswordRoute,
  resetThePasswordRoute,
];

// Routes using the user layout
export const membersLayoutRoutes = [membersRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [Campaigns, Scraping, Misc, AI_Platform];
