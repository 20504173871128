import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { dispatchAdminModules, getToken } from "../helpers/functions";
import { getUserByToken } from "../redux/reducers/authReducer";

// For routes that can only be accessed by authenticated users
const AuthGuard = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.entities.auth.data);
  const state = useSelector((state) => state.entities);

  let token = getToken();

  if (!token) {
    return <Redirect to="/" />;
  } else {
    if (!user) dispatch(getUserByToken());
    else {
      if (user.membership_role === "user")
        return <Redirect to="/members-area" />;
      dispatchAdminModules(dispatch, state);
    }
  }
  return children;
};

export default AuthGuard;
