import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/apiActions";
const url = "projects";

const slice = createSlice({
  name: "projects",
  initialState: {
    list: [],
    allProjects: [],
    project_stats: [],
    listType: "active",
    isLoading: false,
    error: null,
    success: null,
    isUpdated: false,
    isRequestSent: null,
    selectedChannel: null,
  },
  reducers: {
    resetAll: (projects) => {
      projects.isLoading = false;
      projects.error = null;
      projects.success = null;
    },
    requestStarted: (projects, action) => {
      projects.isLoading = true;
      projects.error = null;
      projects.success = null;
      projects.isRequestSent = true;
    },
    requestedFailed: (projects, action) => {
      projects.error = action.payload;
      projects.isLoading = false;
    },
    projectsLoaded: (projects, action) => {
      projects.list = action.payload.list;
      projects.selectedChannel = action.payload.selectedChannel;
      projects.isLoading = false;
      // set the type of the list
      if (projects.list.length > 0) {
        projects.listType = projects.list[0].deletedAt ? "archived" : "active";
      }
    },
    allProjectsLoaded: (projects, action) => {
      projects.allProjects = action.payload;
    },
    projectStatsLoaded: (projects, action) => {
      projects.project_stats = action.payload;
      projects.isLoading = false;
    },
    projectAdded: (projects, action) => {
      projects.success = "New campaign Added";
      projects.list.push(action.payload);
      projects.isLoading = false;
    },
    projectUpdated: (projects, action) => {
      const index = projects.list.findIndex(
        (project) => project._id === action.payload._id
      );
      projects.list[index] = action.payload;

      // update allProjects
      const indexAll = projects.allProjects.findIndex(
        (project) => project._id === action.payload._id
      );
      if (indexAll > -1)
        projects.allProjects[indexAll].name = action.payload.name;

      projects.isLoading = false;

      projects.success = "Campaign Updated";
      projects.isUpdated = true;
    },
    projectArchived: (projects, action) => {
      const newList = projects.list.filter(
        (project) => project._id !== action.payload._id
      );
      projects.list = newList;
      projects.isLoading = false;
      projects.success = "Campaign Archived";
    },
    projectRestored: (projects, action) => {
      const newList = projects.list.filter(
        (project) => project._id !== action.payload._id
      );
      projects.list = newList;
      projects.isLoading = false;
      projects.success = "Campaign Restored";
    },
    setProjectsListType: (projects, action) => {
      projects.listType = action.payload;
    },
    channelChanged: (messages, action) => {
      messages.selectedChannel = action.payload;
    },
  },
});

export const {
  resetAll,
  projectAdded,
  requestStarted,
  projectsLoaded,
  projectUpdated,
  channelChanged,
  requestedFailed,
  projectArchived,
  projectRestored,
  allProjectsLoaded,
  projectStatsLoaded,
  setProjectsListType,
} = slice.actions;
export default slice.reducer;

export const getProjects = (params = {}) => {
  delete params.channel;

  if (params.status === "archived") params.archived = 1;

  //Generate a URL query based on the object we got
  let query = new URLSearchParams(params);
  return apiCallBegan({
    url: `${url}?${query}`,
    onStart: requestStarted.type,
    onSuccess: projectsLoaded.type,
    onError: requestedFailed.type,
  });
};

export const getAllProjects = (status = "active") =>
  apiCallBegan({
    url: url + "/all",
    onStart: requestStarted.type,
    onSuccess: allProjectsLoaded.type,
    onError: requestedFailed.type,
  });

export const getProjectsStats = (params = {}) => {
  delete params.channel;
  if (params.startDate === null) {
    delete params.date;
  } else {
    if (params.date && params.date.startDate)
      params.startDate = params.date.startDate;
    if (params.date && params.date.endDate)
      params.endDate = params.date.endDate;

    delete params.date;
  }

  let query = new URLSearchParams(params);
  return apiCallBegan({
    url: url + `/stats?${query}`,
    onStart: requestStarted.type,
    onSuccess: projectStatsLoaded.type,
    onError: requestedFailed.type,
  });
};

export const archiveProject = (id) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "put",
    data: { deletedAt: new Date(), status: false },
    onStart: requestStarted.type,
    onSuccess: projectArchived.type,
    onError: requestedFailed.type,
  });

export const restoreProject = (id) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "put",
    data: { deletedAt: null },
    onStart: requestStarted.type,
    onSuccess: projectRestored.type,
    onError: requestedFailed.type,
  });

export const addProject = (data) =>
  apiCallBegan({
    url,
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: projectAdded.type,
    onError: requestedFailed.type,
  });

export const updateProject = (id, data) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "put",
    data,
    onStart: requestStarted.type,
    onSuccess: projectUpdated.type,
    onError: requestedFailed.type,
  });
