import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/apiActions";
const url = "submissions";

const slice = createSlice({
  name: "leads",
  initialState: {
    list: [],
    selectedLeads: [],
    notes: [],
    searchResults: [],
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
  },
  reducers: {
    resetAll: (leads, action) => {
      leads.isLoading = false;
      leads.error = null;
      leads.success = null;
    },
    requestStarted: (leads, action) => {
      leads.isLoading = true;
      leads.error = null;
      leads.isRequestSent = true;
    },
    requestedFailed: (leads, action) => {
      leads.error = action.payload;
      leads.isLoading = false;
    },
    leadsListLoaded: (leads, action) => {
      leads.list = action.payload;
      leads.isLoading = false;
    },
    loadSelectedLeads: (leads, action) => {
      leads.selectedLeads.push(action.payload);
      leads.isLoading = false;
    },
    noteAdded: (leads, action) => {
      let lead_id = action.payload._id;
      const index = leads.selectedLeads.findIndex(
        (lead) => lead._id === lead_id
      );
      leads.selectedLeads[index].notes.push(action.payload.note);
      leads.isLoading = false;
    },
    leadAdded: (leads, action) => {
      leads.success = "New Lead Added";
      leads.list.result.push(action.payload);
      leads.isLoading = false;
    },
    userUpdated: (leads, action) => {
      const index = leads.list.findIndex(
        (user) => user._id === action.payload._id
      );
      leads.list[index] = action.payload;
      leads.success = "leads Updated";
      leads.isLoading = false;
    },
    searchResultsLoaded: (leads, action) => {
      leads.searchResults = action.payload;
      leads.isLoading = false;
    },
  },
});

export const {
  userAdded,
  leadsListLoaded,
  requestedFailed,
  requestStarted,
  userUpdated,
  leadAdded,
  loadSelectedLeads,
  resetAll,
  noteAdded,
  searchResultsLoaded,
} = slice.actions;
export default slice.reducer;

export const getLeads = () =>
  apiCallBegan({
    url: url + "/leads",
    onStart: requestStarted.type,
    onSuccess: leadsListLoaded.type,
    onError: requestedFailed.type,
  });

export const updateLead = (id, data) =>
  apiCallBegan({
    url: `${url}/${id}/lead`,
    method: "put",
    data,
    onStart: requestStarted.type,
    onSuccess: userUpdated.type,
    onError: requestedFailed.type,
  });

export const getLead = (id) =>
  apiCallBegan({
    url: `${url}/${id}/lead`,
    onStart: requestStarted.type,
    onSuccess: loadSelectedLeads.type,
    onError: requestedFailed.type,
  });

export const addNote = (data) =>
  apiCallBegan({
    url: `${url}/note`,
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: noteAdded.type,
    onError: requestedFailed.type,
  });

export const searchSubmissionForLeads = (keyword, id) =>
  apiCallBegan({
    url: `${url}/last?search=${keyword}&project=${id}`,
    onStart: requestStarted.type,
    onSuccess: searchResultsLoaded.type,
    onError: requestedFailed.type,
  });

export const addLead = (data) =>
  apiCallBegan({
    url: `${url}/leads`,
    data,
    method: "put",
    onStart: requestStarted.type,
    onSuccess: leadAdded.type,
    onError: requestedFailed.type,
  });
