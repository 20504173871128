import React, { useState } from "react";
import { DateRangePicker } from "materialui-daterange-picker";
import { useDispatch } from "react-redux";
import { Box, Button, makeStyles } from "@material-ui/core";

import { DateRangeOutlined, ArrowDropDown } from "@material-ui/icons";
import { getDateFormate } from "../../helpers/functions";

const useStyles = makeStyles((theme) => ({
  button: {
    borderColor: "#BEBFC2",
    border: "solid 1px",
    color: "#6A74C9",
    fontWeight: "600",
    backgroundColor: "Transparent",
    borderRadius: "4px",
    padding: "4px 14px",
  },
}));

const DatePicker = ({
  toDispatch,
  data,
  labelDefault,
  initialDateRange,
  minDate,
  maxDate,
  style,
}) => {
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState(labelDefault || "All Times");
  const classes = useStyles();

  let date = new Date();

  const dispatch = useDispatch();

  const toggle = () => setOpen(!open);

  const handleChange = (range) => {
    setOpen(false);

    const start = getDateFormate(range.startDate);
    const end = getDateFormate(range.endDate);

    let labelText = `${start} to ${end}`;

    setLabel(range.label ? range.label : labelText);
    if (!data) data = {};
    data.date = {
      startDate: range.startDate,
      endDate: range.endDate,
    };

    if (toDispatch) dispatch(toDispatch(data));
  };

  const getThisWeek = (date) => {
    const curr = new Date();
    const startDate = new Date(curr.setDate(curr.getDate() - curr.getDay()));
    const endDate = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));
    return { startDate, endDate };
  };

  let definedRanges = [
    {
      startDate: new Date(),
      endDate: new Date(new Date()),
      label: "Today",
    },
    {
      startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
      endDate: new Date(new Date().setDate(new Date().getDate() - 1)),
      label: "Yesterday",
    },
    {
      ...getThisWeek(date),
      label: "This Week",
    },
    // {
    //   startDate: new Date(
    //     date.setTime(
    //       date.getTime() -
    //         (date.getDay() ? date.getDay() : 7) * 24 * 60 * 60 * 1000
    //     )
    //   ),
    //   endDate: new Date(date.setTime(date.getTime() - 6 * 24 * 60 * 60 * 1000)),
    //   label: "Last Week",
    // },
    {
      startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
      endDate: new Date(new Date()),
      label: "Last 7 days",
    },
    {
      startDate: new Date(new Date().setDate(new Date().getDate() - 14)),
      endDate: new Date(new Date()),
      label: "Last 14 days",
    },
    {
      startDate: new Date(
        new Date(`${date.getFullYear()}-${date.getMonth() + 1}-01`)
      ),
      endDate: new Date(date.getFullYear(), date.getMonth() + 1, 1),
      label: "This Month",
    },
    {
      startDate: new Date(
        new Date(`${date.getFullYear()}-${date.getMonth()}-01`)
      ),
      endDate: new Date(date.getFullYear(), date.getMonth(), 0),
      label: "Last Month",
    },
  ];

  return (
    <Box>
      <Button
        variant="contained"
        className={classes.button}
        onClick={() => {
          setOpen(true);
        }}
      >
        <DateRangeOutlined style={{ marginRight: "1rem" }} />
        {label}
        <ArrowDropDown style={{ marginLeft: "1rem" }} />
      </Button>
      <Box
        style={
          style
            ? style
            : {
                zIndex: "5",
                position: "absolute",
                top: "100%",
                right: "4%",
              }
        }
      >
        <DateRangePicker
          open={open}
          toggle={toggle}
          minDate={maxDate ? maxDate : "01-01-2020"}
          definedRanges={definedRanges}
          initialDateRange={initialDateRange || {}}
          startText="Check-in"
          endText="Check-out"
          maxDate={
            maxDate
              ? maxDate
              : new Date(new Date().setDate(new Date().getDate() + 1))
                  .toISOString()
                  .split("T")[0]
          }
          onChange={(range) => handleChange(range)}
        />
      </Box>
    </Box>
  );
};

export default DatePicker;
