import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  CardContent,
  CircularProgress,
  Box,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { inviteUser } from "../../../redux/reducers/usersReducer";
import Chip from "../../customComponent/Chips";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },
  dialogWrapper: {
    position: "absolute",
    maxWidth: "40rem",
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  startICon: {
    margin: 0,
  },
  chips: {
    height: "20px",
    borderRadius: "0px",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  ButtonApprove: {
    backgroundColor: "#23CC94",
    color: "white",
    width: "6rem",
    margin: "12px 39px",
  },
  description: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  h5: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
    // backgroundColor: "white"
  },
  shrink: {
    display: "none",
  },
  inputSelect: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    backgroundColor: "#f5f6fa",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
}));

const InvitePopUp = ({ open, setOpen }) => {
  const [hubstaff, setHubstaff] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();

  let { list, isLoading } = useSelector((state) => state.entities.hubstaff);

  const initialValues = {
    user_email: "",
    project: "",
    hourly_rate: 1,
  };

  const validationSchema = Yup.object().shape({
    user_email: Yup.string().email("Invalid email format").required("Required"),
    project: Yup.string(),
    hourly_rate: Yup.string().required("Required"),
  });

  const handleSubmit = async (values) => {
    const data = {
      email: values.user_email,
      hourly_rate: values.hourly_rate,
      projects: [hubstaff?.id],
    };

    if (data) {
      // dispatch invite User
      dispatch(inviteUser(data));
      setOpen(false);
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogWrapper }}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" classes={{ h5: classes.h5 }}>
            Invite User
          </Typography>

          <Button
            style={{ color: "#868695" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
          }) => (
            <>
              <CardContent>
                {isLoading ? (
                  <Box display="flex" justifyContent="center" my={6}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <Grid item md={12} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Email*:
                        </Typography>
                        <TextField
                          inputlabelprops={{ shrink: true }}
                          placeholder="Email"
                          name="user_email"
                          value={values.user_email}
                          error={Boolean(
                            touched.user_email && errors.user_email
                          )}
                          fullWidth
                          helpertext={touched.user_email && errors.user_email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                          autoComplete="off"
                        />

                        {touched.user_email && errors.user_email && (
                          <div className="error">{errors.user_email}</div>
                        )}
                      </Grid>

                      <Grid item md={12} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Hourly Rate:
                        </Typography>
                        <TextField
                          inputlabelprops={{ shrink: false }}
                          placeholder="Hourly Rate"
                          name="hourly_rate"
                          value={values.hourly_rate}
                          error={Boolean(
                            touched.hourly_rate && errors.hourly_rate
                          )}
                          fullWidth
                          helpertext={touched.hourly_rate && errors.hourly_rate}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="number"
                          variant="outlined"
                          my={2}
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item md={12}>
                        <FormControl fullWidth>
                          <Typography variant="h5" classes={{ h5: classes.h5 }}>
                            Hubstaff Campaign:
                          </Typography>
                          <Chip
                            list={list}
                            selected={values.hubstaff}
                            label={"name"}
                            setSelected={setHubstaff}
                            multiple={false}
                            disabled={false}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <div style={{ float: "right", margin: "40px 0" }}>
                      <Button
                        style={{
                          width: "10rem",
                          backgroundColor: "#6a74c9",
                          color: "white",
                        }}
                        type="submit"
                        variant="contained"
                        mt={3}
                      >
                        Send Invite
                      </Button>
                    </div>
                  </form>
                )}
              </CardContent>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default InvitePopUp;
