import React, { useState, Fragment } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  FormControlLabel,
  Switch,
  Box,
} from "@material-ui/core";

import { Check } from "react-feather";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import NumberFormat from "react-number-format";
import { getDateFormate } from "../../../helpers/functions";

const Divider = styled(MuiDivider)(spacing);

const StyledTableRow = withStyles({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F9F9FC",
      opacity: 2,
    },
    "& .MuiTableCell-root": {
      border: "1px solid rgba(224, 224, 224, 1)",
      borderLeft: "none",
      borderTop: "none",
      whiteSpace: "nowrap",
      padding: "6px 15px",
      height: "51px",
    },
  },
})(TableRow);

const useStyles = makeStyles((theme) => ({
  dialogwrapper: {
    position: "absolute",
    width: "100%",
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  dialogContent: {
    padding: "0px",
  },
  iconLabel: {
    color: "#6320EE !important",
  },
  goodLeads: {
    fontWeight: "bold",
    marginRight: "50px",
    width: "212px",
  },
  label: {
    fontWeight: "700",
  },
  table: {
    minWidth: 700,
    boxShadow: "0px 0px 12px -5px #000000",
    whiteSpace: "nowrap",
  },
  paper: {
    boxShadow: "0px 0px 12px -5px #000000",
    border: "1px solid #E0E0E0",
    marginTop: "-7px",
  },
  fontcells: {
    fontWeight: 600,
  },
  maxWidthSm: {
    maxWidth: "81rem",
  },
  cellTotal: {
    width: "25rem",
    cursor: "pointer",
  },
  cellInvoices: {
    width: "14rem",
  },
  cellAction: {
    width: "9rem",
  },
  checkIcon: {
    width: "20px",
    color: "#50b432",
  },
  tableContainer: {
    overflow: "hidden",
  },
  tableHead: {
    borderRight: "1px solid  #E0E0E0",
  },
  archiveIcons: {
    width: "18px",
    color: "#6A74C9",
  },
  editIcons: {
    width: "18px",
    color: "#6A74C9",
  },
  deleteIcons: {
    width: "18px",
    color: "#6A74C9",
  },
}));

const Popup = ({ open, setOpen, channel }) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialogwrapper }}
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="xl"
      fullWidth={true}
      disableEnforceFocus
      scroll="paper"
    >
      <Helmet title={channel.name} />
      <DialogTitle className={classes.dialogTitle}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            id="tableTitle"
            className={classes.goodLeads}
          >
            {channel.name}
          </Typography>
          <Button
            style={{ color: "#868695" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Fragment>
          <Grid container>
            <Grid item xs={12}>
              <EnhancedTable channel={channel} />
            </Grid>
          </Grid>
        </Fragment>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "#",
    numeric: true,
    disablePadding: false,
    label: "#",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "created",
    numeric: true,
    disablePadding: false,
    label: "Created At",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "totalCost",
    numeric: true,
    disablePadding: false,
    label: "Total Cost",
  },
  {
    id: "remainingLinks",
    numeric: true,
    disablePadding: false,
    label: "Remaining Links",
  },
  {
    id: "successRate",
    numeric: true,
    disablePadding: false,
    label: "Success Rate",
  },
  {
    id: "cps",
    numeric: true,
    disablePadding: false,
    label: "CPS",
  },
  {
    id: "leads",
    numeric: true,
    disablePadding: false,
    label: "Leads",
  },
  {
    id: "cpl",
    numeric: true,
    disablePadding: false,
    label: "CPL",
  },
  {
    id: "conversionRate",
    numeric: true,
    disablePadding: false,
    label: "Conversion Rate",
  },
  {
    id: "user",
    numeric: true,
    disablePadding: false,
    label: "Users",
  },
];

function EnhancedTableHead(props) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            classes={{ head: classes.tableHead }}
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              classes={{ root: classes.label, icon: classes.iconlabel }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTable({ channel }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("source");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const classes = useStyles();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // eslint-disable-next-line no-unused-vars
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // eslint-disable-next-line no-unused-vars
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <Divider />
      <TableContainer classes={{ root: classes.tableContainer }}>
        <Table
          classes={{ root: classes.table }}
          aria-labelledby="tableTitle"
          size={"small"}
          aria-label="customized table"
          stickyHeader
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={channel.projects?.length}
          />
          <TableBody>
            <StyledTableRow
              tabIndex={-1}
              key={-1}
              hover
              style={{
                whiteSpace: "nowrap",
                position: "sticky",
                backgroundColor: "cyan",
              }}
            >
              <TableCell style={{ fontSize: "14px" }} padding="checkbox">
                <Check className={classes.checkIcon} />
              </TableCell>
              <TableCell style={{ fontSize: "14px" }}>
                <FormControlLabel
                  control={<Switch checked={channel.status} />}
                />
              </TableCell>
              <TableCell
                style={{ fontSize: "14px" }}
                component="th"
                // id={labelId}
                classes={{ root: classes.cellInvoices }}
                className={classes.fontcells}
                //  onClick={}
              >
                {getDateFormate(channel.createdAt)}
              </TableCell>
              <TableCell
                style={{ fontSize: "14px" }}
                align="left"
                className={classes.fontcells}
                classes={{ root: classes.cellTotal }}
              >
                {channel.name}
              </TableCell>
              <TableCell
                style={{ fontSize: "14px" }}
                align="left"
                className={classes.fontcells}
              >
                <NumberFormat
                  value={channel.total_cost}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix="$"
                />
              </TableCell>
              <TableCell
                style={{ fontSize: "14px" }}
                align="left"
                className={classes.fontcells}
              >
                <NumberFormat
                  value={channel.remaining_links}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </TableCell>
              <TableCell
                style={{ fontSize: "14px" }}
                align="left"
                className={classes.fontcells}
              >
                <NumberFormat
                  value={channel.submissionSuccessPercent}
                  displayType={"text"}
                  thousandSeparator={true}
                  suffix="%"
                />
              </TableCell>
              <TableCell
                style={{ fontSize: "14px" }}
                align="left"
                className={classes.fontcells}
              >
                <NumberFormat
                  value={channel.cost_per_submit}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix="$"
                />
              </TableCell>

              <TableCell style={{ fontSize: "14px" }} align="left">
                {channel.totalLead && channel.totalLead > 0 ? (
                  <Box style={{ fontWeight: "700" }}>
                    <span className={classes.total}>Total:</span>
                    <NumberFormat
                      value={channel.totalLead}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </Box>
                ) : (
                  0
                )}

                {channel.lead.call > 0 ? (
                  <>
                    <Divider />
                    <span className={classes.calls}>Calls:</span>
                    <NumberFormat
                      value={channel.lead.call}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </>
                ) : (
                  ""
                )}

                {channel.lead.submit > 0 ? (
                  <>
                    <Divider />
                    <span className={classes.forms}>Forms:</span>
                    <NumberFormat
                      value={channel.lead.submit}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </>
                ) : (
                  ""
                )}

                {channel.lead.mail > 0 ? (
                  <>
                    <Divider />
                    <NumberFormat
                      value={channel.lead.mail}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix="Emails: "
                    />
                  </>
                ) : (
                  ""
                )}
              </TableCell>

              <TableCell
                style={{ fontSize: "14px" }}
                align="left"
                className={classes.fontcells}
              >
                <NumberFormat
                  value={channel.cost_per_lead}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix="$"
                />
              </TableCell>
              <TableCell
                style={{ fontSize: "14px" }}
                align="left"
                className={classes.fontcells}
              >
                <NumberFormat
                  value={channel.conversion_rate}
                  displayType={"text"}
                  thousandSeparator={true}
                  suffix="%"
                />
              </TableCell>
              <TableCell
                style={{ fontSize: "14px" }}
                align="left"
                className={classes.fontcells}
              >
                <NumberFormat
                  value={channel.total_users}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </TableCell>
            </StyledTableRow>

            {stableSort(channel.projects, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <StyledTableRow
                    tabIndex={-1}
                    key={index}
                    hover
                    style={{ whiteSpace: "nowrap", position: "sticky" }}
                  >
                    <TableCell padding="checkbox">
                      <Check className={classes.checkIcon} />
                    </TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={row.status}
                            // onChange={(event) =>
                            //   handleProjectStatus(row._id, {
                            //     status: !status,
                            //   })
                            // }
                          />
                        }
                      />
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "14px" }}
                      component="th"
                      id={labelId}
                      classes={{ root: classes.cellsInvoices }}
                      className={classes.fontcells}
                      //  onClick={}
                    >
                      {getDateFormate(row.createdAt)}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "14px" }}
                      align="left"
                      className={classes.fontcells}
                      classes={{ root: classes.cellsTotal }}
                    >
                      {row.name}
                    </TableCell>

                    <TableCell
                      style={{ fontSize: "14px" }}
                      align="left"
                      className={classes.fontcells}
                    >
                      <NumberFormat
                        value={row.total_cost}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix="$"
                      />
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "14px" }}
                      align="left"
                      className={classes.fontcells}
                    >
                      <NumberFormat
                        value={row.remaining_links}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "14px" }}
                      align="left"
                      className={classes.fontcells}
                    >
                      <NumberFormat
                        value={row.submissionSuccessPercent}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix="%"
                      />
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "14px" }}
                      align="left"
                      className={classes.fontcells}
                    >
                      <NumberFormat
                        value={row.cost_per_submit}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix="$"
                      />
                    </TableCell>

                    <TableCell style={{ fontSize: "14px" }} align="left">
                      {row.totalLead && row.totalLead > 0 ? (
                        <Box style={{ fontWeight: "700" }}>
                          <span className={classes.total}>Total:</span>
                          <NumberFormat
                            value={row.totalLead}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </Box>
                      ) : (
                        0
                      )}

                      {row.lead.call > 0 ? (
                        <>
                          <Divider />
                          <span className={classes.calls}>Calls:</span>
                          <NumberFormat
                            value={row.lead.call}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </>
                      ) : (
                        ""
                      )}

                      {row.lead.submit > 0 ? (
                        <>
                          <Divider />
                          <span className={classes.forms}>Forms:</span>
                          <NumberFormat
                            value={row.lead.submit}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </>
                      ) : (
                        ""
                      )}

                      {row.lead.mail > 0 ? (
                        <>
                          <Divider />
                          <NumberFormat
                            value={row.lead.mail}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix="Emails: "
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </TableCell>

                    <TableCell
                      style={{ fontSize: "14px" }}
                      align="left"
                      className={classes.fontcells}
                    >
                      <NumberFormat
                        value={row.cost_per_lead}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix="$"
                      />
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "14px" }}
                      align="left"
                      className={classes.fontcells}
                    >
                      <NumberFormat
                        value={row.conversion_rate}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix="%"
                      />
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "14px" }}
                      align="left"
                      className={classes.fontcells}
                    >
                      {row.totalUsers}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
