import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  CardContent,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import Chip from "../../customComponent/Chips";
import {
  addProject,
  updateProject,
} from "../../../redux/reducers/projectReducer";
import { syncChannelFilter } from "../../../helpers/functions";

const Alert = styled(MuiAlert)(spacing);

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },
  dialogwrapper: {
    position: "absolute",
    maxWidth: "50rem",
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  startICon: {
    margin: 0,
  },
  chips: {
    height: "20px",
    borderRadius: "0px",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  h5: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  controlInput: {
    backgroundColor: "#F9F9FC",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
  },
  shrink: {
    display: "none",
  },
  inputSelect: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    backgroundColor: "#f5f6fa",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
}));

const validationSchema = Yup.object().shape({
  project_number: Yup.number().required("Campaign Number is required.."),
  name: Yup.string().required("Campaign name is required"),
  description: Yup.string().required("Required"),
});

const Popup = ({ open, setOpen, project }) => {
  const [selectUsers, setSelectUsers] = useState([]);
  const [selectGroups, setSelectGroups] = useState([]);
  const [selectMessages, setSelectMessages] = useState();
  const [messageList, setMessageList] = useState([]);
  const [hubstaff, setHubstaff] = useState();
  // eslint-disable-next-line no-unused-vars
  const [channelId, setChannelId] = useState(syncChannelFilter());

  const [localError, setLocalError] = useState(null);

  const dispatch = useDispatch();

  const projects = useSelector((state) => state.entities.projects);
  const users = useSelector((state) => state.entities.users);
  const groups = useSelector((state) => state.entities.groups);
  const messages = useSelector((state) => state.entities.messages);
  const { list, isLoading } = useSelector((state) => state.entities.hubstaff);

  const [groupsList, setGroupList] = useState(groups.all);

  useEffect(() => {
    if (project) {
      // in update
      const selectedGroups = groups.all.filter((item) =>
        project.groups.includes(item._id)
      );

      let projectUsers = users.list.filter(
        ({ projects }) => projects[0]?._id === project._id
      );

      let projectMessages = messages.all.filter((item) =>
        project.messages.includes(item._id)
      );

      let projectHubstaff = list.find(
        (item) => item.id === project.hubstaff_project
      );

      setHubstaff(projectHubstaff);
      setSelectMessages(projectMessages);
      setSelectUsers(projectUsers);
      setSelectGroups(selectedGroups);
      setGroupList(
        groups.all.filter(
          (item) => item.status || selectedGroups.includes(item._id)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, groups.all, users.list, list, messages.all]);

  const initialValues = {
    project_number: project ? project.project_number : "",
    name: project ? project.name : "",
    description: project ? project.description : "",
    hubstaff,
    selectMessages,
    selectGroups,
    selectUsers,
  };

  const classes = useStyles();

  const handleSubmit = ({ project_number, name, description }) => {
    let listOfGroups = selectGroups.map((item) => item._id);
    let listOfUsers = selectUsers.map((item) => item._id);

    const data = {
      project_number,
      name,
      description,
      messages: selectMessages,
      groups: listOfGroups,
      users: listOfUsers,
      hubstaff_project: hubstaff.id,
      hubstaff_project_name: hubstaff.name,
    };

    if (project) {
      // dispatch update
      dispatch(updateProject(project._id, data));
      setOpen(false);
      setLocalError(null);
    } else {
      // dispatch add
      dispatch(addProject(data));
      setOpen(false);
      setLocalError(null);
    }
  };

  useEffect(() => {
    const list = messages.all.filter((item) => channelId === item.channel);
    setMessageList(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId, messages.all]);

  return (
    <Dialog
      classes={{ paper: classes.dialogwrapper }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h6" gutterBottom>
          {project ? "Update Campaign" : "New Campaign"}
        </Typography>
        <div style={{ display: "flex", float: "right" }}>
          <Button
            style={{ color: "#868695", marginTop: "-40px" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            status,
          }) => (
            <>
              <CardContent>
                <Typography variant="body2" gutterBottom>
                  {project ? "Update " : "Create a new "}
                  Campaign and start connecting with business across the country
                </Typography>
                {projects.success && (
                  <Alert severity="success" my={3}>
                    New Campaign has been created successfully!
                  </Alert>
                )}
                {projects.error ||
                  (localError && (
                    <Alert severity="error" my={3}>
                      Error: {projects.error || localError}
                    </Alert>
                  ))}

                <form onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid item md={12} style={{ padding: "9px 0" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Campaign Number*:
                      </Typography>
                      <TextField
                        name="project_number"
                        placeholder="Campaign Number"
                        InputProps={{ classes: { input: classes.input } }}
                        inputlabelprops={{ shrink: false }}
                        value={values.project_number}
                        error={Boolean(
                          touched.project_number && errors.project_number
                        )}
                        fullWidth
                        helpertext={
                          touched.project_number && errors.project_number
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>

                    <Grid item md={12} style={{ padding: "9px 0" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Name*:
                      </Typography>
                      <TextField
                        name="name"
                        placeholder="Name"
                        InputProps={{ classes: { input: classes.input } }}
                        inputlabelprops={{ shrink: false }}
                        value={values.name}
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helpertext={touched.name && errors.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>

                    <Grid item md={12} style={{ padding: "9px 0px" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Description*:
                      </Typography>
                      <TextField
                        name="description"
                        placeholder="Description"
                        InputProps={{
                          classes: {
                            input: classes.inputTextarea,
                            root: classes.controlInput,
                          },
                        }}
                        inputlabelprops={{ shrink: false }}
                        value={values.description}
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        fullWidth
                        rows={3}
                        rowsMax={4}
                        multiline={true}
                        helpertext={touched.description && errors.description}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={12} style={{ padding: "9px 0px" }}>
                      <Chip
                        list={users.list}
                        selected={values.selectUsers}
                        placeholder={"Users"}
                        label={"name"}
                        setSelected={setSelectUsers}
                        multiple={true}
                      />
                    </Grid>
                    <Grid item md={12} style={{ padding: "9px 0px" }}>
                      <Chip
                        list={groupsList}
                        selected={values.selectGroups}
                        placeholder={"Groups"}
                        label={"name"}
                        setSelected={setSelectGroups}
                        multiple={true}
                      />
                    </Grid>
                    <Grid item md={12} style={{ padding: "9px 0px" }}>
                      <Chip
                        list={messageList}
                        selected={values.selectMessages}
                        placeholder={"Messages"}
                        label={"label"}
                        setSelected={setSelectMessages}
                        multiple={true}
                      />
                    </Grid>
                    <Grid item md={12} style={{ padding: "9px 0px" }}>
                      <FormControl fullWidth>
                        <Chip
                          list={list}
                          selected={values.hubstaff}
                          placeholder={"Campaign in Hubstaff"}
                          label={"name"}
                          setSelected={setHubstaff}
                          multiple={false}
                          disabled={project ? true : false}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Button
                    style={{
                      marginTop: "10px",
                      backgroundColor: isLoading ? "red" : "#6a74c9",
                      color: "white",
                    }}
                    type="submit"
                    variant="contained"
                    mt={4}
                    disabled={isLoading}
                  >
                    {project ? "Update" : "Add New"}
                  </Button>
                </form>
              </CardContent>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
