import { Box, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components/macro";

const ToolbarTitle = styled.div`
  min-width: 150px;
`;
export const TableName = ({ title, Component }) => {
  return (
    <ToolbarTitle>
      <Box style={{ display: "flex" }}>
        <Component style={{ color: "#6320ee", marginRight: ".5rem" }} />
        <Typography
          variant="h6"
          style={{ fontWeight: "bold", marginTop: ".25rem", width: "10em" }}
          id="tableTitle"
        >
          {title}
        </Typography>
      </Box>
    </ToolbarTitle>
  );
};
