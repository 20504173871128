import React, { useState, useEffect, Fragment } from "react";
import styled, { withTheme } from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import {
  capitalize,
  getDateTimeFormate,
  getDomainFromURL,
} from "../../helpers/functions.js";
import {
  Box,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  ThumbUp as ThumbUpIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Link as LinkUrl,
} from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { spacing } from "@material-ui/system";
import {
  updateNbrPerPage,
  getNumberPerPage,
  showNumber,
} from "../../helpers/functions";
import {
  getSubmissions,
  setCompanyHasContactToTrue,
} from "../../redux/reducers/submissionsReducer";
import { updateCompany } from "../../redux/reducers/companiesReducer";
import MyStats from "../customComponent/MyStats";
import Loading from "../customComponent/Loading";
import { Framer } from "react-feather";
import { TableName } from "../customComponent/TableName.js";
import { ROWS_PER_PAGE_OPTIONS } from "../../constants/index.js";
import Actions from "./components/Actions.js";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "company", alignment: "left", label: "Company", sort: true },
  { id: "createdAt", alignment: "left", label: "Date", sort: true },
  {
    id: "campaign_name",
    alignment: "left",
    label: "Campaign Name",
    sort: true,
  },
  { id: "group", alignment: "left", label: "Group", sort: true },
  { id: "user", alignment: "left", label: "User", sort: true },
  { id: "alias", alignment: "left", label: "Alias", sort: true },
  { id: "type", alignment: "left", label: "Status", sort: true },
  { id: "actions", alignment: "right", label: "Actions", sort: false },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontSize: "16px",
            }}
          >
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let EnhancedTableToolbar = () => {
  return (
    <Toolbar>
      <TableName title="Submissions List" Component={Framer} />
      <Spacer />
    </Toolbar>
  );
};

function EnhancedTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("customer");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();

  const {
    list,
    page: serverPage,
    total_records,
    maxPage,
    selectedProject,
  } = useSelector((state) => state.entities.submissions);

  useEffect(() => {
    if (maxPage === 0) setPage(0);
  }, [maxPage]);

  useEffect(() => {
    setPage(serverPage - 1);
  }, [serverPage]);

  const rowsPerPage = getNumberPerPage();

  const handleChangePage = (event, newPage) => {
    if (newPage > maxPage - 1) {
      dispatch(
        getSubmissions({
          page: newPage + 1,
          project: selectedProject,
        })
      );
    }
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectees = list.map((n) => n.id);
      setSelected(newSelectees);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    let per_page = event.target.value;
    updateNbrPerPage(per_page);
    setPage(0);
    dispatch(
      getSubmissions({
        project: selectedProject,
        per_page,
      })
    );
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(list, list.length - page * rowsPerPage);

  let history = useHistory();

  const showDetails = (company_id, submit_id) => {
    history.push(`/companies/${company_id}/${submit_id}`);
  };

  const TurnHasContactToTrue = (submission_id, company_id) => {
    dispatch(updateCompany(company_id, { hasContact: true }));
    dispatch(setCompanyHasContactToTrue({ _id: submission_id }));
  };

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={list.length}
            />
            <TableBody>
              {stableSort(list, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  let status = capitalize(
                    row.lead ? row.lead : row.success ? "Success" : "Failed"
                  );

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell
                        style={{ fontSize: "14px", width: "10vw" }}
                        align="left"
                      >
                        {row.company.title}
                      </TableCell>
                      <TableCell style={{ fontSize: "14px" }} align="left">
                        {getDateTimeFormate(row.createdAt)}
                      </TableCell>
                      <TableCell style={{ fontSize: "14px" }} align="left">
                        {row.project.name}
                      </TableCell>
                      <TableCell style={{ fontSize: "14px" }} align="left">
                        {row.group.name}
                      </TableCell>
                      <TableCell style={{ fontSize: "14px" }} align="left">
                        {row.user.name}
                      </TableCell>
                      <TableCell style={{ fontSize: "14px" }} align="left">
                        <a
                          href={row.profile.url}
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: "none" }}
                        >
                          {getDomainFromURL(row.profile.url)}
                        </a>
                      </TableCell>
                      <TableCell style={{ fontSize: "14px" }} align="left">
                        {status}
                      </TableCell>
                      <TableCell padding="none" align="right">
                        <Box mr={2}>
                          {!row.success && row.company.hasContact === false && (
                            <Tooltip title="Turn company Has Contact To True">
                              <IconButton
                                aria-label="ThumbUpIcon"
                                onClick={() =>
                                  TurnHasContactToTrue(row._id, row.company._id)
                                }
                              >
                                <ThumbUpIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title="open">
                            <a
                              href={row.company.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <IconButton aria-label="delete" color="primary">
                                <LinkUrl />
                              </IconButton>
                            </a>
                          </Tooltip>
                          <Tooltip title="Detail">
                            <IconButton
                              aria-label="Show"
                              color="primary"
                              onClick={() =>
                                showDetails(row.company._id, row._id)
                              }
                            >
                              <RemoveRedEyeIcon />
                            </IconButton>
                          </Tooltip>
                          {/* <Tooltip title="Delete">
                            <IconButton
                              aria-label="delete"
                              onClick={() => window.open(row.company.url)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip> */}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={total_records}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

const SubmissionsList = ({ theme }) => {
  const { submissionsStats, isLoading } = useSelector(
    (state) => state.entities.submissions
  );

  return (
    <Fragment>
      <Helmet title="Submits" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Submits
          </Typography>
        </Grid>
        <Actions />
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Stats stats={submissionsStats} theme={theme} />
        </Grid>
        <Grid item xs={12}>
          {isLoading ? <Loading /> : <EnhancedTable />}
        </Grid>
      </Grid>
    </Fragment>
  );
};

const Stats = ({ stats, theme }) => {
  return (
    <>
      <Grid container spacing={6}>
        <MyStats
          xs={12}
          sm={12}
          md={4}
          title="Total Submissions:"
          titleRight="Success Rate"
          amount={showNumber(stats.totalSubmissions)}
          amountRightPrefix="%"
          amountColor={theme.palette.primary.main}
          amountRight={stats.successRate}
          amountRightColor={theme.palette.primary.main}
          numberBottomLeft={showNumber(stats.totalSubmissionsSuccess)}
          textBottomLeft="(Success)"
          numberBottomRight={showNumber(stats.totalSubmissionsFailed)}
          textBottomRight="(Failed)"
          titleVariant=""
          amountVariant=""
          amountRightVariant=""
          chipVariant=""
          numberBottomLeftVariant=""
          textBottomLeftVariant=""
          tooltip="total of submissions"
          tooltipRight="success Rate of submissions"
          numberBottomRightColor="#e01e1e"
        />

        <MyStats
          xs={12}
          sm={6}
          md={4}
          title="Total Cost:"
          titleRight="Cost Per Submit"
          amount={showNumber(stats.totalCost)}
          amountColor={theme.palette.primary.main}
          amountRightColor={theme.palette.primary.main}
          prefix="$"
          amountRight={stats.costPerSubmit}
          numberBottomLeft={showNumber(stats.costPerSubmitSuccess)}
          textBottomLeft="(Per Valid Submit)"
        />

        <MyStats
          xs={12}
          sm={6}
          md={4}
          title="Leads:"
          titleRight="Cost Per Lead"
          amount={showNumber(stats.totalLead)}
          amountColor={theme.palette.primary.main}
          amountRightColor={theme.palette.primary.main}
          amountRight={showNumber(stats.costPerLead)}
          amountRightPrefix="$"
        />
      </Grid>
    </>
  );
};

export default withTheme(SubmissionsList);
