import { createSlice } from "@reduxjs/toolkit";
import { getNumberPerPage } from "../../helpers/functions";
import { apiCallBegan } from "../actions/apiActions";
const url = "messages";

const slice = createSlice({
  name: "messages",
  initialState: {
    list: [],
    all: [],
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
    selectedTone: null,
    selectedApproach: null,
    selectedChannel: null,
    selectedProject: null,
    selectedDate: null,
    page: 0,
    per_page: 0,
    total_pages: 0,
    maxPage: 0,
    total_records: 0,
  },
  reducers: {
    resetAll: (messages, action) => {
      messages.isLoading = false;
      messages.error = null;
      messages.success = null;
    },
    requestStarted: (messages, action) => {
      messages.isLoading = true;
      messages.error = null;
      messages.isRequestSent = true;
    },
    requestedFailed: (messages, action) => {
      messages.error = action.payload;
      messages.isLoading = false;
    },
    messagesLoaded: (state, action) => {
      state.isLoading = false;
      state.list = state.list.concat(action.payload.result);
      state.selectedApproach = action.payload.selectedApproach;
      state.selectedTone = action.payload.selectedTone;
      state.selectedChannel = action.payload.selectedChannel;
      state.selectedProject = action.payload.selectedProject;
      state.selectedDate = action.payload.selectedDate;
      state.page = action.payload.page;
      state.per_page = action.payload.per_page;
      state.total_pages = action.payload.total_pages;
      state.total_records = action.payload.total_records;
      state.maxPage = state.page > state.maxPage ? state.page : state.maxPage;
    },
    reLoadMessages: (state, action) => {
      state.page = action.payload.page;
      state.list = action.payload.result;
      state.per_page = action.payload.per_page;
      state.selectedApproach = action.payload.selectedApproach;
      state.selectedTone = action.payload.selectedTone;
      state.total_pages = action.payload.total_pages;
      state.total_records = action.payload.total_records;
      state.selectedProject = action.payload.selectedProject;
      state.selectedChannel = action.payload.selectedChannel;
      state.selectedDate = action.payload.selectedDate;
      state.maxPage = 0;
      state.isLoading = false;
    },
    allMessagesLoaded: (messages, action) => {
      messages.all = action.payload;
      messages.isLoading = false;
    },
    messageAdded: (messages, action) => {
      messages.success = "New message Added";
      messages.list.push(action.payload);
      messages.all.push({
        _id: action.payload._id,
        label: action.payload.label,
        subject: action.payload.subject,
        channel: action.payload.channel,
        tone: action.payload.tone,
        approach: action.payload.approach,
      });
      messages.isLoading = false;
    },
    messageUpdated: (messages, action) => {
      const index = messages.list.findIndex(
        (message) => message._id === action.payload._id
      );
      messages.list[index] = action.payload;
      messages.success = "Message Updated";
      messages.isLoading = false;
    },
    messageDeleted: (messages, action) => {
      const index = messages.list.findIndex(
        (message) => message._id === action.payload._id
      );
      const indexAll = messages.all.findIndex(
        (message) => message._id === action.payload._id
      );
      messages.list.splice(index, 1);
      messages.all.splice(indexAll, 1);
      messages.isLoading = false;
      messages.success = "Message Deleted";
    },
    channelChanged: (messages, action) => {
      messages.selectedChannel = action.payload;
    },
  },
});

export const {
  messageAdded,
  messagesLoaded,
  reLoadMessages,
  requestedFailed,
  requestStarted,
  messageUpdated,
  messageDeleted,
  allMessagesLoaded,
  resetAll,
  channelChanged,
} = slice.actions;
export default slice.reducer;

export const getMessages = (params = {}) => {
  params.per_page = getNumberPerPage();

  delete params.channel;

  if (params.approach === null || params.approach === "all")
    delete params.approach;
  if (params.tone === null || params.tone === "all") delete params.tone;
  if (params.project === null || params.project === "all")
    delete params.project;
  if (params.startDate === null) {
    delete params.date;
  } else {
    if (params.date && params.date.startDate)
      params.startDate = params.date.startDate;
    if (params.date && params.date.endDate)
      params.endDate = params.date.endDate;
    delete params.date;
  }

  //Generate a URL query based on the object we got
  let query = new URLSearchParams(params);

  let resultsReceived = params.page ? messagesLoaded.type : reLoadMessages.type;

  return apiCallBegan({
    url: `${url}?${query}`,
    onStart: requestStarted.type,
    onSuccess: resultsReceived,
    onError: requestedFailed.type,
  });
};

export const getAllMessages = () =>
  apiCallBegan({
    url: url + "/all",
    onStart: requestStarted.type,
    onSuccess: allMessagesLoaded.type,
    onError: requestedFailed.type,
  });

export const addMessage = (data) =>
  apiCallBegan({
    url,
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: messageAdded.type,
    onError: requestedFailed.type,
  });

export const updateMessage = (id, data) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "put",
    data,
    onStart: requestStarted.type,
    onSuccess: messageUpdated.type,
    onError: requestedFailed.type,
  });

export const deleteMessage = (id) => {
  return apiCallBegan({
    url: `${url}/${id}`,
    method: "delete",
    onStart: requestStarted.type,
    onSuccess: messageDeleted.type,
    onError: requestedFailed.type,
  });
};
