import {
  Button as MuiButton,
  CircularProgress,
  TextField as MuiTextField,
} from "@material-ui/core";
import { useState, useEffect, useRef } from "react";
import { updateDirectory } from "../../../../../redux/reducers/directoriesReducer";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const ListingStructureSettings = ({
  directory,
  dispatch,
  isLoading = false,
}) => {
  const [data, setData] = useState({});
  const serviceRef = useRef();
  const faqRef = useRef();
  const featureRef = useRef();

  useEffect(() => {
    setData(directory);
    serviceRef.current.value = directory?.prompts?.service;
    faqRef.current.value = directory?.prompts?.faq;
    featureRef.current.value = directory?.prompts?.feature;
  }, [directory]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let UpdateData = {
      _id: data._id,
      prompts: {
        service: serviceRef.current.value,
        faq: faqRef.current.value,
        feature: featureRef.current.value,
      },
    };
    dispatch(updateDirectory(UpdateData));
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <TextField
        id="service"
        inputlabelprops={{ shrink: false }}
        placeholder="Services Prompt"
        label="Services Prompt"
        name="service"
        inputRef={serviceRef}
        fullWidth
        variant="outlined"
        type="text"
        multiline
        rows="4"
        my={2}
      />
      <TextField
        id="feature"
        inputlabelprops={{ shrink: false }}
        placeholder="Features Prompt"
        label="Features Prompt"
        name="features"
        inputRef={featureRef}
        fullWidth
        variant="outlined"
        type="text"
        multiline
        rows="4"
        my={2}
      />
      <TextField
        id="faq"
        inputlabelprops={{ shrink: false }}
        placeholder="FAQ Prompt"
        label="FAQ Prompt"
        name="faq"
        inputRef={faqRef}
        fullWidth
        variant="outlined"
        type="text"
        multiline
        rows="16"
        my={2}
      />

      <Button
        variant="contained"
        type="submit"
        style={{
          backgroundColor: "#6a74c9",
          color: "white",
          position: "absolute",
          right: "0",
          bottom: "0",
        }}
      >
        {isLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Save changes"
        )}
      </Button>
    </form>
  );
};

export default ListingStructureSettings;
