import { createSlice } from "@reduxjs/toolkit";
import { getNumberPerPage } from "../../helpers/functions";
import { apiCallBegan } from "../actions/apiActions";

const url = "submissions";

const submissionSlice = createSlice({
  name: "submissions",
  initialState: {
    list: [],
    selectedSubmission: [],
    submissionsStats: {
      totalSubmissions: null,
      successRate: null,
      totalSubmissionsSuccess: null,
      totalSubmissionsFailed: null,
      totalCost: null,
      costPerSubmit: null,
      costPerSubmitSuccess: null,
      totalLead: null,
      costPerLead: null,
    },
    submission: null,
    selectedProject: null,
    selectedChannel: null,
    selectedDate: null,
    selectedUser: null,
    selectedGroup: null,
    typeOfSubmissions: null,
    data: null,
    isLoading: false,
    error: null,
    success: null,
    page: 0,
    per_page: 0,
    total_pages: 0,
    maxPage: 0,
    total_records: 0,
    isRequestSent: null,
  },
  reducers: {
    submittedSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    submissionsLoaded: (state, action) => {
      state.list = state.list.concat(action.payload.result);
      state.isLoading = false;
      state.page = action.payload.page;
      state.per_page = action.payload.per_page;
      state.total_pages = action.payload.total_pages;
      state.total_records = action.payload.total_records;
      state.submissionsStats = action.payload.submissionsStats;
      state.selectedProject = action.payload.selectedProject;
      state.selectedChannel = action.payload.selectedChannel;
      state.selectedDate = action.payload.selectedDate;
      state.selectedUser = action.payload.selectedUser;
      state.selectedGroup = action.payload.selectedGroup;
      state.typeOfSubmissions = action.payload.typeOfSubmissions;
      state.maxPage = state.page > state.maxPage ? state.page : state.maxPage;
    },
    submissionsReLoaded: (state, action) => {
      state.list = action.payload.result;
      state.isLoading = false;
      state.page = action.payload.page;
      state.per_page = action.payload.per_page;
      state.total_pages = action.payload.total_pages;
      state.total_records = action.payload.total_records;
      state.submissionsStats = action.payload.submissionsStats;
      state.selectedProject = action.payload.selectedProject;
      state.selectedChannel = action.payload.selectedChannel;
      state.selectedDate = action.payload.selectedDate;
      state.selectedUser = action.payload.selectedUser;
      state.selectedGroup = action.payload.selectedGroup;
      state.typeOfSubmissions = action.payload.typeOfSubmissions;
      state.maxPage = 0;
    },

    loadSelectedSubmission: (state, action) => {
      state.selectedSubmission.push(action.payload);
      state.submission = action.payload;
      state.isLoading = false;
    },
    backSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    notWorkingSuccess: (state, action) => {
      state.isLoading = false;
      // state.data = action.payload;
    },
    requestStarted: (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.isRequestSent = true;
    },
    userRequestStarted: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    updateSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },

    requestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    noteAdded: (state, action) => {
      let submit_id = action.payload._id;
      const index = state.list.findIndex((submit) => submit._id === submit_id);
      state.list[index].notes.push(action.payload.note);
      state.isLoading = false;
      state.loadingOver = true;
    },
    setCompanyHasContactToTrue: (state, action) => {
      let submit_id = action.payload._id;
      const index = state.list.findIndex((submit) => submit._id === submit_id);
      state.list[index].company.hasContact = true;
    },
    setToLead: (state, action) => {
      let submit_id = action.payload._id;
      const index = state.list.findIndex((submit) => submit._id === submit_id);
      if (index >= 0) state.list[index].lead = action.payload.lead;
      state.success = "set Lead";
    },
    currentSubmitRemoved: (state, action) => {
      state.success = "Reported as spam";
    },
  },
});

export default submissionSlice.reducer;
export const {
  setToLead,
  noteAdded,
  backSuccess,
  requestFailed,
  updateSuccess,
  requestStarted,
  submittedSuccess,
  notWorkingSuccess,
  submissionsLoaded,
  userRequestStarted,
  submissionsReLoaded,
  currentSubmitRemoved,
  loadSelectedSubmission,
  setCompanyHasContactToTrue,
} = submissionSlice.actions;

export const getSubmission = () =>
  apiCallBegan({
    url,
    method: "GET",
    onStart: userRequestStarted.type,
    onSuccess: submittedSuccess.type,
    onError: requestFailed.type,
  });

export const getSubmissions = (params = {}) => {
  params.per_page = getNumberPerPage();
  if (params.project === null || params.project === "all")
    delete params.project;
  if (params.user === null || params.user === "all") delete params.user;
  if (params.type === null || params.type === "all") delete params.type;
  if (params.group === null || params.group === "all") delete params.group;

  if (params.startDate === null) {
    delete params.date;
  } else {
    if (params.date && params.date.startDate)
      params.startDate = params.date.startDate;
    if (params.date && params.date.endDate)
      params.endDate = params.date.endDate;
    delete params.date;
  }

  //Generate a URL query based on the object we got
  let query = new URLSearchParams(params);

  // Reload the data if no page is provided
  let resultsReceived = params.page
    ? submissionsLoaded.type
    : submissionsReLoaded.type;

  return apiCallBegan({
    url: `${url}/all?${query}`,
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: resultsReceived,
    onError: requestFailed.type,
  });
};

export const getSubmissionSelected = (id) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: loadSelectedSubmission.type,
    onError: requestFailed.type,
  });

export const updateSubmission = (data) =>
  apiCallBegan({
    url,
    method: "PUT",
    data,
    onStart: requestStarted.type,
    onSuccess: updateSuccess.type,
    onError: requestFailed.type,
  });

export const setToLeadSubmission = (data) =>
  apiCallBegan({
    url: `${url}/setToLead`,
    method: "PUT",
    data,
    onStart: requestStarted.type,
    onSuccess: setToLead.type,
    onError: requestFailed.type,
  });

export const getBack = () =>
  apiCallBegan({
    url: url + "/Back",
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: backSuccess.type,
    onError: requestFailed.type,
  });

export const sendNotWorking = (data) =>
  apiCallBegan({
    url,
    method: "PUT",
    data,
    onStart: requestStarted.type,
    onSuccess: updateSuccess.type,
    onError: requestFailed.type,
  });

export const addNoteToSubmit = (data) =>
  apiCallBegan({
    url: `${url}/note`,
    method: "POST",
    data,
    onStart: requestStarted.type,
    onSuccess: noteAdded.type,
    onError: requestFailed.type,
  });

export const removeCurrentSubmit = (user_id) =>
  apiCallBegan({
    url: `${url}/current/${user_id}`,
    method: "DELETE",
    onStart: requestStarted.type,
    onSuccess: currentSubmitRemoved.type,
    onError: requestFailed.type,
  });
