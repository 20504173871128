import React, { useState, useEffect, Fragment, forwardRef } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Snackbar,
  Slide,
  FormControl,
  FormControlLabel,
  Switch,
  Button,
  Collapse,
  Chip,
} from "@material-ui/core";
import { updateCompany, resetAll } from "../../redux/reducers/companiesReducer";
import {
  Add as AddIcon,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import MySelect from "../customComponent/MySelect";
import { getCompanies } from "../../redux/reducers/companiesReducer";
import {
  capitalize,
  getDateFormate,
  getNumberPerPage,
  updateNbrPerPage,
} from "../../helpers/functions";
import Loading from "../customComponent/Loading";
import FilterPopup from "./FilterPopUp";
import { Reload } from "../customComponent/Reload";
import { TableName } from "../customComponent/TableName";
import { Link } from "react-feather";
import { useHistory } from "react-router-dom";
import { ROWS_PER_PAGE_OPTIONS } from "../../constants";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "button", alignment: "left", label: "", sort: false },
  { id: "working", alignment: "left", label: "Working", sort: false },
  { id: "company", alignment: "left", label: "Company", sort: true },
  { id: "domain", alignment: "left", label: "Domain", sort: true },
  // { id: "phone", alignment: "left", label: "Phone", sort: true },
  { id: "actions", alignment: "right", label: "Actions", sort: false },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontWeight: "bold",
            }}
          >
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => {
                  createSortHandler(headCell.id);
                }}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let EnhancedTableToolbar = () => {
  return (
    <Toolbar>
      <TableName title="Companies List" Component={Link} />
      <Spacer />
    </Toolbar>
  );
};

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  let history = useHistory();

  const showDetails = (company_id, submit_id) => {
    history.push(`/companies/${company_id}/${submit_id}`);
  };

  const handleCompanyStatus = (company_id, status) => {
    dispatch(updateCompany(company_id, status));
  };

  return (
    <Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => {
          if (row.submissions.length > 0) setOpen(!open);
        }}
      >
        <TableCell>
          {row.submissions.length > 0 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          <FormControlLabel
            control={
              <Switch
                checked={row.status}
                onChange={(event) =>
                  handleCompanyStatus(row._id, {
                    status: !row.status,
                  })
                }
              />
            }
          />
        </TableCell>

        <TableCell style={{ fontSize: "14px" }} align="left">
          {row.title}
        </TableCell>
        <TableCell style={{ fontSize: "14px" }} align="left">
          <a href={row.url} target="_blank" rel="noreferrer">
            {row.domain}
          </a>
        </TableCell>
        <TableCell style={{ fontSize: "14px" }} padding="none" align="right">
          <Box mr={2}>
            <Tooltip title="Open Google My Business">
              <a
                href={`https://google.com/maps/?cid=${row.cid}`}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Chip
                  label="GMB"
                  color="primary"
                  style={{ cursor: "pointer" }}
                />
              </a>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow
        style={{
          marginLeft: "0",
          marginRight: "0",
          padding: "1em",
          backgroundColor: "#edeff0",
        }}
      >
        <TableCell
          style={{ fontSize: "14px", paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{ margin: 1 }}
              style={{
                marginTop: "1em",
                marginBottom: "1em",
              }}
            >
              <Box>
                <Typography variant="h4" gutterBottom component="div">
                  Submissions list
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>Date</TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="left">
                        Campaign Name
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="left">
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.submissions.map((submit, key) => (
                      <TableRow
                        key={key}
                        onClick={(e) => {
                          showDetails(row._id, submit.submission?._id);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell style={{ fontSize: "14px" }}>
                          {getDateFormate(submit.submission?.createdAt)}
                        </TableCell>
                        <TableCell style={{ fontSize: "14px" }} align="left">
                          {submit.project?.name}
                        </TableCell>
                        <TableCell style={{ fontSize: "14px" }} align="left">
                          {capitalize(
                            submit.submission?.lead
                              ? submit.submission?.lead
                              : submit.submission?.success
                              ? "Success"
                              : "Failed"
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

function EnhancedTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("customer");
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const rowsPerPage = getNumberPerPage();

  const {
    selectedGroup,
    typeOfCompany,
    search,
    list,
    page: serverPage,
    total_records,
    maxPage,
  } = useSelector((state) => state.entities.companies);

  useEffect(() => {
    if (maxPage === 0) setPage(0);
  }, [maxPage]);

  useEffect(() => {
    setPage(serverPage - 1);
  }, [serverPage]);

  const handleChangePage = (event, newPage) => {
    let data = {
      search,
      page: newPage + 1,
      group: selectedGroup || undefined,
      type: typeOfCompany || undefined,
    };

    if (newPage > maxPage - 1) {
      dispatch(getCompanies(data));
    }
    setPage(newPage + 1);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event) => {
    let per_page = event.target.value;
    let data = {
      search,
      per_page,
      group: selectedGroup || undefined,
      type: typeOfCompany || undefined,
    };

    updateNbrPerPage(per_page);
    setPage(0);
    dispatch(getCompanies(data));
  };

  const emptyRows =
    rowsPerPage - Math.min(list, list.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={list.length}
            />
            <TableBody>
              {stableSort(list, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return <Row key={row.domain} row={row} />;
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={total_records}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function CompaniesList() {
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState(false);
  const { list: groupList } = useSelector((state) => state.entities.groups);
  const {
    success,
    error,
    search,
    isLoading,
    deActivated,
    selectedGroup,
    typeOfCompany,
  } = useSelector((state) => state.entities.companies);

  const companyType = [
    { value: "all", label: "All Types" },
    { value: "bookmark", label: "Bookmarks" },
    { value: "disable", label: "Disable" },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    if (success || error) {
      setNotification(true);
    }
  }, [success, error]);

  useEffect(() => {
    if (deActivated) dispatch(getCompanies({ search: deActivated }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deActivated]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNotificationClose = () => {
    setNotification(false);
    dispatch(resetAll());
  };

  const loadCompanies = () => {
    dispatch(
      getCompanies({ search, group: selectedGroup, type: typeOfCompany })
    );
  };

  return (
    <Fragment>
      {(success || error) && (
        <Snackbar
          open={notification}
          autoHideDuration={5000}
          onClose={handleNotificationClose}
          TransitionComponent={TransitionLeft}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleNotificationClose}
            severity={success ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {success || error}
          </Alert>
        </Snackbar>
      )}
      <Helmet title="Companies" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Companies
          </Typography>
        </Grid>
        <Grid item>
          <Box style={{ display: "flex" }}>
            <Reload loading={isLoading} action={loadCompanies} />
            <FormControl style={{ minWidth: "15rem", marginRight: "1rem" }}>
              <MySelect
                selected={selectedGroup}
                defaultLabel="All Group"
                defaultValue="all"
                list={groupList}
                label="name"
                value="_id"
                valueHolder="group"
                data={{ search, type: typeOfCompany }}
                toDispatch={getCompanies}
              />
            </FormControl>

            <FormControl style={{ minWidth: "15rem", marginRight: "1rem" }}>
              <MySelect
                selected={typeOfCompany}
                defaultLabel="All Type"
                defaultValue="all"
                list={companyType}
                label="label"
                value="value"
                valueHolder="type"
                data={{ search, group: selectedGroup }}
                toDispatch={getCompanies}
              />
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleClickOpen();
              }}
              style={{ backgroundColor: "#6a74c9", color: "white" }}
            >
              <AddIcon />
              Filter
            </Button>
            {open && (
              <FilterPopup
                open={open}
                setOpen={setOpen}
                handleClose={handleClose}
                word
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {isLoading ? <Loading /> : <EnhancedTable />}
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default CompaniesList;
