export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const USER_TOKEN = "spider_forms_token";

export const SETTINGS = [
  "blacklistedDomains",
  "hubstaffOrganization",
  "DATAFORSEO_BASE_URL",
  "DATAFORSEO_API_URL",
  "USER",
  "PASS",
  "NBR_TASKS_PER_MINUTES",
  "CLOSEIO_API_KEY",
  "Call_tracking_api",
  "Call_tracking_Account_ID",
  "CALL_RAIL_API_URL",
  "SENDGRID_API_KEY",
  "HUBSTAFF_REFRESH_TOKEN",
  "nbrKeywordsToFetch",
  "Forget_Password_Template_Id",
  "costPercentage",
  "NBR_COMPANY_FILTERED_PER_MINUTES",
  "FAILED_SUBMIT_MESSAGES",
  "GROUP_SEARCH_TAGS",
  "APPROACHES_LIST",
  "TONE_LIST",
  "CONVERSIONS",
  "wordPressBusinessLogo",
  "openAIOptions",
];

export const getMessageWithVariable = (message, data) => {
  message = message.replaceAll("[URL]", data.profile.url || "");
  message = message.replaceAll("[NUMBER]", data.profile.phone || "");
  message = message.replaceAll("[PHONE]", data.profile.phone || "");
  message = message.replaceAll("[EMAIL]", data.profile.email || "");
  message = message.replaceAll("[NAME]", data.profile.sender || "");
  message = message.replaceAll(
    "[COMPANY_NAME]",
    data.profile.company_name || ""
  );
  message = message.replaceAll("[COMPANY_URL]", data.url || "");
  message = message.replaceAll("[CATEGORY]", data.category || "");
  message = message.replaceAll(
    "[COMPANY_DOMAIN]",
    new URL(data.url).hostname || ""
  );
  message = message.replaceAll("[WP_SLUG]", data.wp_slug || "");
  return message;
};

export const MESSAGES_VARIABLE = [
  "[URL]",
  "[NUMBER]",
  "[EMAIL]",
  "[NAME]",
  "[COMPANY_NAME]",
  "[COMPANY_URL]",
  "[COMPANY_DOMAIN]",
  "[CATEGORY]",
  "[WP_SLUG]",
];

export const MESSAGES_TAGS = {
  "Our Website": "[URL]",
  "Phone Number": "[NUMBER]",
  "My Email": "[EMAIL]",
  "My Name": "[NAME]",
  "Company Name": "[COMPANY_NAME]",
  "Company Url": "[COMPANY_URL]",
  "Company Domain": "[COMPANY_DOMAIN]",
  Category: "[CATEGORY]",
  Permalink: "[WP_SLUG]",
};

export const ROWS_PER_PAGE_OPTIONS = [15, 25, 45, 50, 75, 100];

export const getTags = [
  "Complicate",
  "HelpFull",
  "Aggressive",
  "Complicate/HelpFull",
  "Complicate/Aggressive",
  "HelpFull/Aggressive",
];
