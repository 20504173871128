import React, { useEffect, useState, Fragment } from "react";
import styled, { withTheme } from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import ProjectPopup from "../projects/components/Popup";
import ChannelPopup from "./components/ChannelPopup";
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Box,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import Actions from "./components/Actions";
import { Plus } from "react-feather";
import MyStats from "../customComponent/MyStats";
import { showNumber } from "../../helpers/functions";
import Loading from "../customComponent/Loading";

const useStyles = makeStyles({
  campaign: {
    marginRight: "12px",
    height: "40px",
    backgroundColor: "#6a74c9",
    color: "white",
    "&:hover": {
      backgroundColor: "#424c96",
    },
  },
  icon: {
    width: "20px",
    color: "white",
  },
  newProject: {
    color: "white",
    height: "40px",
    backgroundColor: "#6a74c9",
    "&:hover": {
      backgroundColor: "#424c96",
    },
  },
});

const Divider = styled(MuiDivider)(spacing);
const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

const Dashboard = ({ theme }) => {
  const classes = useStyles();
  const [openAddProject, setOpenAddProject] = useState(false);
  const [openAddChannel, setOpenAddChannel] = useState(false);

  const { stats, selectedChannel } = useSelector(
    (state) => state.entities.channels
  );
  const [channelName, setChannelName] = useState("");
  const { list_all } = useSelector((state) => state.entities.channels);
  useEffect(() => {
    let channel = list_all.find(
      (channel) => channel._id.toString() === selectedChannel?.toString()
    );
    if (channel) setChannelName(channel.name);
    else setChannelName("");
  }, [list_all, selectedChannel]);

  const { user } = useSelector((state) => state.entities.auth.data);
  const first_name = user ? user.name.split(" ")[0] : "";

  const handleClickOpenAddProject = () => {
    setOpenAddProject(true);
  };

  const handleClose = () => {
    setOpenAddProject(false);
  };

  return (
    <Fragment>
      <Helmet title="Dashboard" />
      <Grid justify="space-between" container spacing={2}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Dashboard
          </Typography>
          <Typography variant="subtitle2">
            Welcome back, <span>{first_name ? first_name : ""}!</span> We've
            missed you.
            <span role="img" aria-label="Waving Hand Sign">
              👋
            </span>
          </Typography>
        </Grid>

        <Grid item>
          <Box display="flex">
            <Button
              variant="contained"
              startIcon={<Plus className={classes.icon} />}
              className={classes.newProject}
              onClick={handleClickOpenAddProject}
            >
              New Campaign
            </Button>
            {openAddProject && (
              <ProjectPopup
                open={openAddProject}
                setOpen={setOpenAddProject}
                project={null}
                handleClose={handleClose}
              />
            )}
            {openAddChannel && (
              <ChannelPopup
                open={openAddChannel}
                setOpen={setOpenAddChannel}
                channel={null}
                handleClose={handleClose}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid justify="space-between" container spacing={2}>
        <Grid item>
          <Typography variant="h4" gutterBottom>
            {channelName}
          </Typography>
        </Grid>

        <Grid item>
          <Actions />
        </Grid>
      </Grid>

      {stats.length ? (
        <Box>
          <Divider my={6} />
          <Grid container spacing={6}>
            {stats
              .filter((item) => item)
              .map((item, index) => {
                return (
                  <MyStats
                    key={index}
                    xs={12}
                    sm={6}
                    md={3}
                    title={item.title}
                    amount={item.amount ? showNumber(item.amount) : 0}
                    titleRight={item.titleRight ? item.titleRight : ""}
                    amountRight={
                      item.amountRight ? showNumber(item.amountRight) : 0
                    }
                    numberBottomLeft={
                      item.NBL ? showNumber(item.NBL) : undefined
                    }
                    textBottomLeft={item.NBLTitle ? item.NBLTitle : null}
                    numberBottomRight={
                      item.NBR ? showNumber(item.NBR) : undefined
                    }
                    textBottomRight={item.NBRTitle ? item.NBRTitle : null}
                    prefix={item.prefix}
                    suffix={item.suffix}
                    NBLSuffix={item.NBLSuffix}
                    NBRSuffix={item.NBRSuffix}
                    NBLPrefix={item.NBLPrefix}
                    NBRPrefix={item.NBRPrefix}
                  />
                );
              })}

            <Divider my={6} />
            <Spacer mt={20} />
          </Grid>
        </Box>
      ) : (
        <Box>
          <Loading />
        </Box>
      )}

      {/* <Divider my={6} />
      <Grid item md={12}>
        <Table />
      </Grid> */}

      <Spacer mb={6} />
    </Fragment>
  );
};

export default withTheme(Dashboard);
