import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "theme",
  initialState: { currentTheme: "BLUE" },
  reducers: {
    setTheme: (theme, action) => ({
      ...theme,
      currentTheme: action.payload,
    }),
  },
});

export const { setTheme } = slice.actions;
export default slice.reducer;
