import React, { useEffect, useState, Fragment } from "react";
import styled, { withTheme } from "styled-components/macro";
import { darken } from "polished";
import { Search as SearchIcon } from "react-feather";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Hidden,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  FormControl,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";

// import NotificationsDropdown from "./NotificationsDropdown";
// import MessagesDropdown from "./MessagesDropdown";
// import LanguagesDropdown from "./LanguagesDropdown";
import UserDropdown from "./UserDropdown";
import { getCompanies } from "../redux/reducers/companiesReducer";
import { useDispatch, useSelector } from "react-redux";
import MySelect from "../pages/customComponent/MySelect";
import { dispatchAdminModules, syncChannelFilter } from "../helpers/functions";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 4px;
  border: 1px solid #6320ee;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  width: 30rem;
  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 100%;
  }
`;

const AppBarComponent = ({ onDrawerToggle }) => {
  const { selectedGroup, typeOfCompany } = useSelector(
    (state) => state.entities.companies
  );

  const { list_all: channelsList } = useSelector(
    (state) => state.entities.channels
  );
  const state = useSelector((state) => state.entities);

  const [selected, setSelected] = useState(syncChannelFilter());
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchLinks, setSearchLinks] = useState("");

  // useEffect(() => {
  //   // if (search && searchLinks === "") setSearchLinks(search);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [search]);

  useEffect(() => {
    if (!syncChannelFilter() && channelsList.length) {
      setSelected(syncChannelFilter(channelsList.at(-1)?._id));
    } else {
      setSelected(syncChannelFilter());
    }
  }, [channelsList]);

  const handleOrganizationChange = (item) => {
    syncChannelFilter(item);
    dispatchAdminModules(dispatch, state, true);
  };

  const handleChange = (e) => {
    //e.preventDefault();
    setSearchLinks(e.target.value);
    let data = {
      search: e.target.value,
    };

    if (selectedGroup !== null) data.group = selectedGroup;
    if (typeOfCompany !== null) data.type = typeOfCompany;

    if (e.keyCode === 13) dispatch(getCompanies(data));
  };
  const handleSearch = (e) => {
    e.preventDefault();
    history.push("/companies");
  };
  return (
    <Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input
                  placeholder="Search Links"
                  value={searchLinks}
                  onChange={handleChange}
                  onClick={handleSearch}
                  // onMouseEnter={handleSearch}
                  onKeyUp={handleChange}
                  inputRef={(input) => input && input.focus()}
                />
              </Search>
            </Grid>
            <Grid item xs />
            <Grid item>
              <FormControl style={{ minWidth: "15rem", marginRight: "1rem" }}>
                <MySelect
                  selected={selected}
                  defaultLabel="All Organizations"
                  defaultValue="all"
                  list={channelsList}
                  label="name"
                  value="_id"
                  valueHolder="channel"
                  data={{}}
                  func={handleOrganizationChange}
                  // toDispatch={}
                  // dispatchAdminModules(dispatch);
                />
              </FormControl>
            </Grid>
            <Grid item>
              {/* <MessagesDropdown />
            <NotificationsDropdown />
            <LanguagesDropdown /> */}
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

export default withTheme(AppBarComponent);
