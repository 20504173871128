import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  makeStyles,
  Grid,
  Button,
} from "@material-ui/core";
import { ArrowBack, ArrowForward, Email, Star } from "@material-ui/icons";
import Popup from "./Popup";
import DialogNotWorking from "./DialogNotWorking";
import { useDispatch, useSelector } from "react-redux";
import { getUserByToken, signOut } from "../../../redux/reducers/authReducer";
import { useHistory } from "react-router-dom";
import { setSnack } from "../../../redux/reducers/snackbarReducer";
import {
  getBack,
  updateSubmission,
} from "../../../redux/reducers/submissionsReducer";

import { updateCompany } from "../../../redux/reducers/companiesReducer";
import { Phone, User } from "react-feather";
import { updateToken } from "../../../helpers/functions";

const useStyles = makeStyles((theme) => ({
  leftBar: {
    display: "flex",
    alignItems: "center",
  },
  success: {
    color: "#28A745",
    borderColor: "#28A745",
    marginRight: "1rem",
    "&:hover": {
      backgroundColor: "#28A745",
      color: "white",
    },
  },
  primary: {
    color: "#00B5FF",
    borderColor: "#00B5FF",
    marginRight: "1rem",
    "&:hover": {
      backgroundColor: "#00B5FF",
      color: "white",
    },
  },
  error: {
    color: "#DC3545",
    borderColor: "#DC3545",
    marginRight: "1rem",
    "&:hover": {
      backgroundColor: "#DC3545",
      color: "white",
    },
  },
  back: {
    backgroundColor: "#00B5FF",
    color: "white",
    marginRight: "1rem",
    "&:hover": {
      backgroundColor: "#17A2B8",
      color: "white",
    },
  },
  refresh: {
    backgroundColor: "#FFC107",
    color: "white",
    marginRight: "1rem",
    "&:hover": {
      backgroundColor: "#b58d17",
      color: "white",
    },
  },
  notWorking: {
    backgroundColor: "#DC3545",
    color: "white",
    marginRight: "1rem",
    "&:hover": {
      backgroundColor: "#a01c27",
      color: "white",
    },
  },
  submitted: {
    backgroundColor: "#1E7D34",
    color: "white",
    "&:hover": {
      backgroundColor: "#0f591f",
      color: "white",
    },
  },
  buttonGoBack: {
    fontSize: "15px",
    fontWeight: "700",
    borderRadius: "5px",
    marginRight: "15px",
    boxShadow:
      " rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
    "&:hover": {
      // background: "#ecf0f1",
    },
    backgroundColor: "#6320ee",
    color: "white",
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [clickNiceSite, SetClickNiceSite] = useState(false);
  const [openWorking, setOpenWorking] = useState(false);
  const [visible, setVisible] = useState(false);

  let { data, isLoading } = useSelector((state) => state.entities.submissions);
  let { membership_role } = useSelector(
    (state) => state.entities.auth.data.user
  );
  let { admin_area } = useSelector((state) => state.entities.auth.data);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenWorking = () => {
    setOpenWorking(true);
  };
  const handleCloseWorking = () => {
    setOpenWorking(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSignOut = () => {
    if (admin_area && admin_area.token) {
      updateToken(admin_area.token);
      dispatch(getUserByToken());
      setTimeout(() => {
        history.push("/users");
      }, 3000);
    } else dispatch(signOut());
  };

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    setOpen(false);
    dispatch(setSnack(true));
    setTimeout(() => dispatch(setSnack(false)), 1500);
  };
  const handleClickBack = () => {
    dispatch(getBack());
  };
  const handleClickSubmitted = () => {
    dispatch(
      updateSubmission({
        _id: data.submission_id,
        success: true,
      })
    );
    setVisible(true);
  };

  const handleClickNiceSite = () => {
    SetClickNiceSite(true);
    dispatch(
      updateCompany(data.company_id, {
        nice_site: true,
      })
    );
  };

  const goToDashboard = () => {
    const path = isAdmin() ? "/dashboard" : "/members-area";
    history.push(path);
  };

  const isAdmin = () => {
    return (
      membership_role === "admin" ||
      membership_role === "owner" ||
      membership_role === "manager"
    );
  };

  useEffect(() => {
    if (data?.message?.body) navigator.clipboard.writeText(data.message.body);
  }, [data]);

  return (
    <AppBar position="static" style={{ backgroundColor: "#343A40" }}>
      <CssBaseline />
      <Toolbar>
        <Grid container direction="row" align="center" justify="space-between">
          <Grid item className={classes.leftBar}>
            <Grid item>
              <Button
                className={classes.buttonGoBack}
                size="large"
                alt="Go To Dashboard"
                color="primary"
                variant="contained"
                onClick={goToDashboard}
              >
                <ArrowBack />
                Dashboard
              </Button>
              <Button
                variant="outlined"
                className={classes.success}
                onClick={() => copyToClipboard(data?.message?.body)}
                disabled={isLoading}
              >
                Message
              </Button>
              <Button
                variant="outlined"
                className={classes.success}
                onClick={() => copyToClipboard(data?.message?.subject)}
                disabled={isLoading}
              >
                Subject
              </Button>
              <Button
                variant="outlined"
                className={classes.success}
                onClick={() => copyToClipboard(data.profile.email)}
                disabled={isLoading}
              >
                <Email />
              </Button>
              <Button
                variant="outlined"
                className={classes.success}
                onClick={() => copyToClipboard(data.profile.phone)}
                disabled={isLoading}
              >
                <Phone />
              </Button>
              <Button
                variant="outlined"
                className={classes.success}
                onClick={() => copyToClipboard(data.profile.sender)}
                disabled={isLoading}
              >
                <User />
              </Button>
            </Grid>
            <Grid item style={{ marginLeft: "1rem" }}>
              <Button
                variant="outlined"
                className={classes.success}
                onClick={handleClickOpen}
                disabled={isLoading}
              >
                Campaign Info
              </Button>
              {open && (
                <Popup
                  open={open}
                  setOpen={setOpen}
                  handleClose={handleClose}
                />
              )}
              <Button
                variant="outlined"
                onClick={() => window.open(data.url, "_blank")}
                className={classes.primary}
                disabled={isLoading}
              >
                Open in new window
              </Button>
              <Button
                onClick={handleSignOut}
                variant="outlined"
                className={classes.error}
              >
                Log Out
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            {visible && (
              <Button
                variant="contained"
                className={classes.back}
                startIcon={<ArrowBack />}
                onClick={handleClickBack}
                disabled={isLoading}
              >
                Back
              </Button>
            )}

            <Button
              variant="contained"
              onClick={handleClickNiceSite}
              className={classes.refresh}
              style={clickNiceSite ? { backgroundColor: "#00e288" } : {}}
            >
              <Star />
            </Button>
            <Button
              variant="contained"
              onClick={() => window.location.reload(false)}
              className={classes.refresh}
            >
              Refresh
            </Button>
            <Button
              onClick={handleClickOpenWorking}
              variant="contained"
              className={classes.notWorking}
              disabled={isLoading}
            >
              Not working
            </Button>
            {openWorking && (
              <DialogNotWorking
                open={openWorking}
                setOpen={setOpenWorking}
                handleClose={handleCloseWorking}
              />
            )}
            <Button
              variant="contained"
              className={classes.submitted}
              endIcon={<ArrowForward />}
              onClick={handleClickSubmitted}
              disabled={isLoading}
            >
              Submitted
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
