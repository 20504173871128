import {
  Button as MuiButton,
  CircularProgress,
  TextField as MuiTextField,
} from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import { updateDirectory } from "../../../../../redux/reducers/directoriesReducer";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const ListingStructureSettings = ({
  directory,
  dispatch,
  isLoading = false,
}) => {
  const [data, setData] = useState({});
  const [value, setValue] = useState("");

  useEffect(() => {
    setData(directory);
    setValue(directory?.listing_structure);
  }, [directory]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let UpdateData = {
      _id: data._id,
      listing_structure: value,
    };
    dispatch(updateDirectory(UpdateData));
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <TextField
        id="listing_structure"
        inputlabelprops={{ shrink: false }}
        placeholder="Listing Structure"
        name="listing _structure"
        value={value}
        fullWidth
        onChange={(e) => handleChange(e)}
        variant="outlined"
        type="text"
        multiline
        rows="30"
        my={2}
      />
      <Button
        variant="contained"
        type="submit"
        style={{
          backgroundColor: "#6a74c9",
          color: "white",
          position: "absolute",
          right: "0",
          bottom: "0",
        }}
      >
        {isLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Save changes"
        )}
      </Button>
    </form>
  );
};

export default ListingStructureSettings;
