import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Box, CircularProgress } from "@material-ui/core";
const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;
function LoadingPage() {
  return (
    <Wrapper>
      <Helmet title="Loading..." />
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    </Wrapper>
  );
}
export default LoadingPage;
