import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CardContent,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { deActivateCompanies } from "../../redux/reducers/companiesReducer";

const Alert = styled(MuiAlert)(spacing);

const useStyles = makeStyles((theme) => ({}));

const FilterPopup = ({ open, setOpen, handleClose }) => {
  const dispatch = useDispatch();
  const [active, seActive] = useState(false);
  const [data, setData] = useState({ keyword: "", pin: "" });

  const { success, isLoading, error } = useSelector(
    (state) => state.entities.companies
  );

  useEffect(() => {
    if (success) setOpen(false);

    if (isLoading) seActive(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, error]);

  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.keyword && data.keyword.length > 2 && active)
      dispatch(deActivateCompanies(data.keyword));
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
    seActive(data.pin === "1986");
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogwrapper }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography
          style={{ fontSize: "15px", fontWeight: "bold" }}
          gutterBottom
        >
          Filter Keywords
        </Typography>
        <div style={{ display: "flex", float: "right" }}>
          <Button
            style={{ color: "#868695", marginTop: "-40px" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <>
          <CardContent>
            {error && (
              <Alert severity="danger" my={3}>
                {error}
              </Alert>
            )}

            <form onSubmit={(e) => handleSubmit(e)}>
              <TextField
                id="keyword"
                label="Keyword to filter"
                variant="outlined"
                value={data.keyword}
                onChange={(e) => handleChange(e)}
                fullWidth
                style={{ marginBottom: "1em" }}
              />

              <TextField
                id="pin"
                label="Enter your PIN"
                type="password"
                variant="outlined"
                value={data.pin}
                onChange={(e) => handleChange(e)}
                onKeyUp={(e) => handleChange(e)}
                fullWidth
                style={{ marginBottom: "1em" }}
              />

              <Button
                variant="contained"
                type="submit"
                mt={3}
                style={{
                  backgroundColor: !active ? "#dbdef9" : "#6a74c9",
                  color: "white",
                }}
                disabled={!active}
              >
                De-Activate
              </Button>
            </form>
          </CardContent>
        </>
      </DialogContent>
    </Dialog>
  );
};

export default FilterPopup;
