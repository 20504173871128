import {
  Box,
  Button as MuiButton,
  Chip,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import { updateGroup } from "../../../../redux/reducers/groupsReducer";

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const Filters = ({ group, dispatch, isLoading = false }) => {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState(group);
  const [value, setValue] = useState("");
  const [selectValues, setSelectValues] = useState(data.filters);

  useEffect(() => {
    setData(group);
    setSelectValues(group.filters);
  }, [group]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let UpdateData = { filters: selectValues };
    dispatch(updateGroup(group._id, UpdateData));
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const removeFeature = (e, key) => {
    let result = selectValues.filter((item) => item !== key);
    setSelectValues(result);
  };

  const addToList = (e, item) => {
    e.preventDefault();

    if (!selectValues.includes(item))
      setSelectValues((selectValues) => [...selectValues, value]);

    setValue("");
  };

  return (
    <Box>
      <form onSubmit={(e) => addToList(e, value)}>
        <TextField
          id="filter"
          label="Add a Filter"
          variant="outlined"
          onChange={handleChange}
          value={value}
          fullWidth
          my={2}
        />
      </form>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Grid item md={12} style={{ padding: "9px 0px" }}>
          {selectValues &&
            selectValues.map((item) => {
              return (
                <Chip
                  key={item}
                  style={{
                    marginRight: "5px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  label={item}
                  // onClick={}
                  onDelete={(e) => {
                    removeFeature(e, item);
                  }}
                />
              );
            })}
        </Grid>

        <Button
          variant="contained"
          type="submit"
          style={{
            backgroundColor: "#6a74c9",
            color: "white",
            width: "8vw",
            position: "absolute",
            right: "0",
            bottom: "0",
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Save changes"
          )}
        </Button>
      </form>
    </Box>
  );
};

export default Filters;
