import React, { useState, useEffect, Fragment, forwardRef } from "react";
import styled from "styled-components/macro";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Slide,
  Tooltip,
  FormControlLabel,
  Switch,
  Box,
  IconButton,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import { Snackbar } from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import Popup from "./Popup";
import ChannelPopup from "./ChannelPopup";
import { Edit as EditIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  updateChannel,
  resetAll,
  getChannelStats,
  getChannelsProjects,
} from "../../../redux/reducers/channelReducer";
import { getProjects } from "./../../../redux/reducers/projectReducer";
import shadows from "@material-ui/core/styles/shadows";
import { getDateFormate } from "../../../helpers/functions";
import { ROWS_PER_PAGE_OPTIONS } from "../../../constants";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Paper = styled(MuiPaper)(spacing);

const StyledTableRow = withStyles({
  root: {
    "&:nth-of-type(odd)": {
      opacity: 2,
    },
    "& .MuiTableCell-root": {
      border: "1px solid rgba(224, 224, 224, 1)",
      borderLeft: "none",
      borderTop: "none",
      whiteSpace: "nowrap",
      padding: "6px 6px",
      height: "45px",
    },
  },
})(TableRow);
function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
    // width: "19px",
  },

  iconLabel: {
    color: "#6320EE !important",
  },
  goodLeads: {
    fontWeight: "bold",
    // marginRight: "50px",
    // width: "90px",
  },

  label: {
    // fontWeight: "700",
  },
  popper: {
    //  backgroundColor: "#6A74C9",
    color: "white",
  },
  table: {
    // minWidth: 700,
    boxShadow: "0px 0px 12px -5px #000000",
    whiteSpace: "nowrap",
  },
  paper: {
    marginTop: "-8px",
    boxShadow: shadows[2],
    border: "1px solid #E0E0E0",
  },
  fontcells: {
    fontWeight: 600,
  },
  maxWidthSm: {
    // maxWidth: "81rem",
  },
  toolbar: {
    minHeight: "48px",
  },
  cellsTotal: {
    // width: "25rem",
    cursor: "pointer",
  },
  cellsInvoices: {
    // width: "14rem",
  },
  cellsAction: {
    // width: "9rem",
  },
  checkIcon: {
    // width: "20px",
    color: "#50b432",
  },
  tableContainer: {
    overflow: "hidden",
  },
  tableHead: {
    borderRight: "1px solid  #E0E0E0",
  },
  archiveIcons: {
    // width: "18px",
    color: "#6A74C9",
  },
  editIcons: {
    // width: "18px",
    color: "#6A74C9",
  },
  deleteIcons: {
    // width: "18px",
    color: "#6A74C9",
  },
  total: {
    // marginRight: "3px",
  },
  calls: {
    fontSize: "12px",
  },
  forms: {
    fontSize: "12px",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // {
  //   id: "#",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "#",
  //   sort: false,
  // },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sort: false,
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    sort: false,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sort: false,
  },
  {
    id: "campaigns",
    numeric: false,
    disablePadding: false,
    label: "Campaigns",
    sort: false,
  },
  {
    id: "totalCost",
    numeric: true,
    disablePadding: false,
    label: "Total Cost",
    sort: false,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    sort: false,
  },
];

function EnhancedTableHead(props) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              classes={{ head: classes.tableHead }}
              key={headCell.id}
              align={"center"}
              // align={headCell.numeric ? "center" : "left"}
              // padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.sort ? (
                <TableSortLabel
                  classes={{ root: classes.label, icon: classes.iconlabel }}
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

function EnhancedTable() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("source");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [openChannelDialog, setOpenChannelDialog] = useState(false);
  let [selectedChannel, setSelectedChannel] = useState(null);

  const { list_project: channelsList } = useSelector(
    (state) => state.entities.channels
  );

  const handleChannelDialog = (action) => {
    if (!action) setSelectedChannel(null);
    setOpenChannelDialog(action);
  };
  const handleChannel = (channel) => {
    setSelectedChannel(channel);
    setOpenChannelDialog(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleProjectStatus = (channel_id, data) => {
    dispatch(updateChannel(channel_id, data));
  };

  return (
    <div>
      {openChannelDialog && (
        <Popup
          open={openChannelDialog}
          setOpen={handleChannelDialog}
          channel={selectedChannel}
        />
      )}

      <Paper>
        <TableContainer classes={{ root: classes.tableContainer }}>
          <Table
            classes={{ root: classes.table }}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="customized table"
            stickyHeader
            // aria-label="sticky table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={channelsList.length}
            />
            <TableBody>
              {stableSort(
                channelsList.length > 0 ? channelsList : [],
                getComparator(order, orderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow
                      tabIndex={-1}
                      key={index}
                      hover
                      style={{ whiteSpace: "nowrap", position: "sticky" }}
                    >
                      <TableCell align="center">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row.status}
                              onChange={(event) =>
                                handleProjectStatus(row._id, {
                                  status: !row.status,
                                })
                              }
                            />
                          }
                        />
                      </TableCell>

                      <TableCell
                        style={{ fontSize: "14px", maxWidth: "3rem" }}
                        id={labelId}
                        classes={{ root: classes.cellsInvoices }}
                        className={classes.fontcells}
                        align="center"
                      >
                        {getDateFormate(row.createdAt)}
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "14px" }}
                        align="center"
                        className={classes.fontcells}
                        classes={{ root: classes.cellsTotal }}
                        onClick={() => handleChannel(row)}
                      >
                        {row.name}
                      </TableCell>

                      <TableCell
                        style={{ fontSize: "14px" }}
                        align="center"
                        className={classes.fontcells}
                      >
                        <NumberFormat
                          value={row.campaigns}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix=""
                        />
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "14px" }}
                        align="center"
                        className={classes.fontcells}
                      >
                        <NumberFormat
                          value={row.total_cost || 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix="$"
                        />
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "14px" }}
                        align="center"
                        classes={{ root: classes.cellsAction }}
                      >
                        {<RowOptions channel={row} />}
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={channelsList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function RowOptions({ channel }) {
  const [channelToEdit, setChannelToEdit] = useState(null);
  const [openEditChannel, setOpenEditChannel] = useState(false);
  // const handleClickOpen = () => {
  //   setOpenEditChannel(true);
  // };

  // const handleClose = () => {
  //   setOpenEditChannel(false);
  // };

  const handleClickOpenEditChannel = (channel) => {
    setOpenEditChannel(true);
    setChannelToEdit(channel);
  };
  return (
    <Box>
      {openEditChannel && (
        <ChannelPopup
          open={channelToEdit}
          setOpen={setOpenEditChannel}
          channel={channelToEdit}
        />
      )}
      <Tooltip title="Edit">
        <IconButton
          aria-label="Edit"
          onClick={() => handleClickOpenEditChannel(channel)}
        >
          <EditIcon color="primary" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

const AdvancedTable = () => {
  const [notification, setNotification] = useState(false);

  const { error, success } = useSelector((state) => state.entities.channels);
  const dispatch = useDispatch();
  useEffect(() => {
    if (success || error) {
      setNotification(true);
    }

    if (success) {
      dispatch(getChannelStats());
      dispatch(getChannelsProjects());
      dispatch(getProjects());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, error]);

  const handleNotificationClose = () => {
    setNotification(false);
    dispatch(resetAll());
  };

  return (
    <Fragment>
      {notification === true && (
        <Snackbar
          open={notification}
          autoHideDuration={5000}
          onClose={handleNotificationClose}
          TransitionComponent={TransitionLeft}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleNotificationClose}
            severity={success ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {success || error}
          </Alert>
        </Snackbar>
      )}

      <Grid item md={12}>
        <EnhancedTable />
      </Grid>
    </Fragment>
  );
};

export default AdvancedTable;
