/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import styled from "styled-components/macro";

import {
  Box,
  CircularProgress,
  Divider as MuiDivider,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { getUserStats } from "../../redux/reducers/authReducer.js";
import DatePicker from "../customComponent/DateRangePicker.js";
import { ArrowBack } from "@material-ui/icons";
import { useEffect } from "react";

const styles = {
  card: {
    maxWidth: "40rem",
    justifyContent: "center",
    padding: "50px 0 ",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    border: "2px solid",
    borderColor: "#6320ee",
    borderRadius: "4%",
    width: "80%",
  },

  cardsContent: {
    textAlign: "center",
  },
  root: {
    justifyContent: "center",
  },
  p1: {
    fontWeight: "800",
    fontSize: "30px",
    lineHeight: "4rem",
    color: "#6320ee",
  },
  p2: {
    width: "22rem",
    fontSize: "16px",
    marginLeft: "1rem",
    fontWeight: "bold",
  },
  button: {
    fontSize: "20px",
    fontWeight: "700",
    borderRadius: "50px",
    paddingLeft: "38px",
    paddingRight: "38px",
    background: "#23cc94",
    color: "#fff",
  },
  button2: {
    fontSize: "20px",
    fontWeight: "700",
    borderRadius: "50px",
    paddingLeft: "38px",
    paddingRight: "38px",
    background: "#6a74c9",
    color: "#fff",
    boxShadow:
      " rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
    "&:hover": {},
  },
  media: {
    // height: "150px !important",
    // padding: "20px 20px 20px 20px",
    width: "50rem",
    objectFit: "cover",
  },
  normal: {
    width: "12rem",
  },
  stat: {
    // position: "absolute",
    // left: "15%",
    // top: "20%",
    // alignItems: "center",
  },
  statHeader: {
    textAlign: "center",
    fontWeight: "bold",
  },
  statValue: {
    marginTop: "15px",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "25px",
  },
  divider: {
    marginTop: "2em",
    marginBottom: "2em",
  },
};

const Divider = styled(MuiDivider)(spacing);

const StatChild = ({ classes, headerText, valueText, isLoading }) => {
  return (
    <>
      <Typography gutterBottom variant="h5" className={classes.statHeader}>
        {headerText}
      </Typography>
      <Typography gutterBottom variant="h4" className={classes.statValue}>
        {isLoading ? <CircularProgress size="18px" /> : valueText}
      </Typography>
    </>
  );
};

const section = ({ classes }) => {
  const [stats, setStats] = useState({});
  const history = useHistory();

  function handleClick() {
    history.push("/members-area/submitting");
  }

  const { isLoading, data } = useSelector((state) => state.entities.auth);

  const dispatch = useDispatch();

  dispatch(getUserStats);

  useEffect(() => {
    setStats(data.stats);
  }, [data]);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="center"
      style={{
        marginTop: "auto",
        marginBottom: "auto",
      }}
      spacing={3}
      // className={classes.container}
    >
      <Grid
        item
        md={3}
        lg={3}
        style={{
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <Box>
          <DatePicker
            toDispatch={getUserStats}
            labelDefault="Last 14 Days"
            initialDateRange={{
              startDate: new Date(
                new Date().setDate(new Date().getDate() - 14)
              ),
              endDate: new Date(new Date()),
              label: "Last 14 days",
            }}
            style={{
              zIndex: "5",
              position: "fixed",
              marginLeft: "15rem",
            }}
          />
          <Box style={{ marginTop: "2rem" }}>
            <StatChild
              classes={classes}
              headerText={"Total Earnings:"}
              valueText={"$" + stats?.total_amount}
              isLoading={isLoading}
            />
            <Divider className={classes.divider} />
            <StatChild
              classes={classes}
              headerText={"Total Hours Worked:"}
              valueText={stats?.full_time}
              isLoading={isLoading}
            />
            <Divider className={classes.divider} />
            <StatChild
              classes={classes}
              headerText={"Total Submits:"}
              valueText={stats?.total_submitted}
              isLoading={isLoading}
            />
            <Divider className={classes.divider} />
            <StatChild
              classes={classes}
              headerText={"Success Rate:"}
              valueText={stats?.success_submits_percent + "%"}
              isLoading={isLoading}
            />
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        style={{
          textAlign: "-webkit-center",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <Card className={classes.card}>
          <center>
            <img
              src="/static/spider/normal.png"
              alt="Contemplative Reptile"
              className={classes.normal}
              style={
                {
                  // filter:
                  //   "invert(50%) sepia(57%) saturate(488%) hue-rotate(196deg) brightness(83%) contrast(90%)",
                }
              }
            />
            <CardContent className={classes.cardsContent}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.p1}
              >
                Normal Work - Submits
              </Typography>
              <center>
                <Typography component="p" className={classes.p2}>
                  Reach out to new companies that meet our criteria. These
                  companies have never seen our company or heard from us before.
                </Typography>
              </center>
            </CardContent>
            <CardActions classes={{ root: classes.root }}>
              <Button
                size="large"
                variant="contained"
                className={classes.button}
                onClick={handleClick}
              >
                Start Working
                <ArrowBack style={{ transform: "rotate(180deg)" }} />
              </Button>
            </CardActions>
          </center>
        </Card>
      </Grid>
      <Grid item md={3} lg={3}></Grid>
      {/* <Grid item>
        <Card className={classes.card}>
          <center>
            <img
              src="/static/spider/normalUp.png"
              alt="follow up"
              className={classes.normal}
              // style={{
              //   filter:
              //     "invert(50%) sepia(57%) saturate(488%) hue-rotate(196deg) brightness(83%) contrast(90%)",
              // }}
            />
            <CardContent className={classes.cardsContent}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.p1}
              >
                Follow Up - Submits
              </Typography>
              <Typography component="p" className={classes.p2}>
                Send a second message to companies we have already sent a
                message to. This will be a different message.
              </Typography>
            </CardContent>

            <CardActions classes={{ root: classes.root }}>
              <Button
                size="large"
                variant="contained"
                className={classes.button2}
              >
                Start Working
              </Button>
            </CardActions>
          </center>
        </Card>
      </Grid> */}
    </Grid>
  );
};

export default withStyles(styles)(section);
