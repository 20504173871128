import React, { useState, useEffect } from "react";
import { FormControl, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getSubmissions } from "../../../redux/reducers/submissionsReducer";
import DatePicker from "../../customComponent/DateRangePicker";
import MySelect from "../../customComponent/MySelect";
import { Reload } from "../../customComponent/Reload";

const Actions = () => {
  const dispatch = useDispatch();
  const { allProjects } = useSelector((state) => state.entities.projects);
  const { list: usersList } = useSelector((state) => state.entities.users);
  const { all: allGroups } = useSelector((state) => state.entities.groups);
  const {
    selectedProject,
    selectedChannel,
    selectedUser,
    selectedDate,
    selectedGroup,
    typeOfSubmissions,
    isLoading,
  } = useSelector((state) => state.entities.submissions);
  const [projects_list, setProjectsList] = useState(allProjects);
  const [groups_list, setGroupsList] = useState(allGroups);

  useEffect(() => {
    const list = allProjects.filter(
      (item) =>
        !selectedChannel ||
        selectedChannel === "all" ||
        selectedChannel === item.channel
    );
    setProjectsList(list);
  }, [allProjects, selectedChannel]);

  useEffect(() => {
    const groupsIds = [];
    projects_list.map((item) => {
      groupsIds.push(...item.groups);
      return null;
    });

    const list = allGroups.filter((item) =>
      groupsIds.includes(item._id.toString())
    );
    setGroupsList(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGroups, projects_list]);

  const submissionTypes = [
    { value: "lead", label: "Lead" },
    { value: "success", label: "Success" },
    { value: "failed", label: "Failed" },
  ];

  const loadSubmissions = () => {
    dispatch(
      getSubmissions({
        project: selectedProject,
        user: selectedUser,
        type: typeOfSubmissions,
        group: selectedGroup,
        date: selectedDate,
      })
    );
  };
  return (
    <Grid item>
      <FormControl style={{ marginRight: ".1rem", marginTop: ".1rem" }}>
        <Reload loading={isLoading} action={loadSubmissions} />
      </FormControl>
      <FormControl style={{ marginRight: ".5rem" }}>
        <DatePicker
          data={{
            project: selectedProject,
            user: selectedUser,
            type: typeOfSubmissions,
            group: selectedGroup,
          }}
          toDispatch={getSubmissions}
        />
      </FormControl>
      <FormControl style={{ marginRight: ".5rem" }}>
        <MySelect
          selected={selectedUser}
          defaultLabel="All Users"
          defaultValue="all"
          list={usersList}
          label="name"
          value="_id"
          valueHolder="user"
          data={{
            project: selectedProject,
            date: selectedDate,
            type: typeOfSubmissions,
            group: selectedGroup,
          }}
          toDispatch={getSubmissions}
        />
      </FormControl>
      <FormControl style={{ marginRight: ".5rem" }}>
        <MySelect
          selected={selectedGroup}
          defaultLabel="All Groups"
          defaultValue="all"
          list={groups_list}
          label="name"
          value="_id"
          valueHolder="group"
          data={{
            project: selectedProject,
            date: selectedDate,
            user: selectedUser,
            type: typeOfSubmissions,
          }}
          toDispatch={getSubmissions}
        />
      </FormControl>
      <FormControl style={{ marginRight: ".5rem" }}>
        <MySelect
          selected={selectedProject}
          defaultLabel="All Campaigns"
          defaultValue="all"
          list={projects_list}
          label="name"
          value="_id"
          valueHolder="project"
          data={{
            date: selectedDate,
            user: selectedUser,
            type: typeOfSubmissions,
            group: selectedGroup,
          }}
          toDispatch={getSubmissions}
        />
      </FormControl>
      <FormControl style={{ marginRight: ".5rem" }}>
        <MySelect
          selected={typeOfSubmissions}
          defaultValue="all"
          defaultLabel="All Submissions"
          list={submissionTypes}
          label="label"
          value="value"
          valueHolder="type"
          data={{
            project: selectedProject,
            user: selectedUser,
            date: selectedDate,
            group: selectedGroup,
          }}
          toDispatch={getSubmissions}
        />
      </FormControl>
    </Grid>
  );
};
export default Actions;
