import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "snackbar",
  initialState: { show: false },
  reducers: {
    setSnack: (snack, action) => ({
      ...snack,
      show: action.payload,
    }),
  },
});

export const { setSnack } = slice.actions;
export default slice.reducer;
