import { Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  loading: {
    marginTop: "10%",
    marginLeft: "50%",
    paddingBottom: "3rem",
  },
}));

const CircularIndeterminate = ({ size }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress size={size} />
    </Box>
  );
};

const Loading = ({ size = 40 }) => {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <CircularIndeterminate disableShrink size={size} />
      <span
        style={{
          justifyContent: "center",
          position: "fixed",
          marginLeft: "-2.5rem",
          marginTop: "1rem",
        }}
      >
        Loading...please wait
      </span>
    </div>
  );
};

export default Loading;
