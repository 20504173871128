import { combineReducers } from "redux";
import themeReducer from "../reducers/themeReducer";
import authReducer from "../reducers/authReducer";
import projectReducer from "../reducers/projectReducer";
import groupsReducer from "../reducers/groupsReducer";
import snackbarReducer from "../reducers/snackbarReducer";
import usersReducer from "../reducers/usersReducer";
import profilesReducer from "../reducers/profilesReducer";
import messagesReducer from "../reducers/messagesReducer";
import submissionsReducer from "../reducers/submissionsReducer";
import hubstaffReducer from "../reducers/hubstaffReducer";
import leadReducer from "../reducers/leadReducer";
import companiesReducer from "../reducers/companiesReducer";
import channelReducer from "../reducers/channelReducer";
import settingReducer from "../reducers/settingsReducer";
import conversionReducer from "../reducers/conversionsReducer";
import emailsReducer from "../reducers/emailsReducer";
import directoriesReducer from "../reducers/directoriesReducer";
import websitesReducer from "../reducers/websitesReducer";

const listOfReducers = combineReducers({
  theme: themeReducer,
  auth: authReducer,
  projects: projectReducer,
  groups: groupsReducer,
  snack: snackbarReducer,
  users: usersReducer,
  profiles: profilesReducer,
  messages: messagesReducer,
  submissions: submissionsReducer,
  hubstaff: hubstaffReducer,
  leads: leadReducer,
  companies: companiesReducer,
  channels: channelReducer,
  settings: settingReducer,
  conversions: conversionReducer,
  emails: emailsReducer,
  directories: directoriesReducer,
  websites: websitesReducer,
});

export default combineReducers({
  entities: listOfReducers,
});
