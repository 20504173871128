import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, Box, Button } from "@material-ui/core";
import DatePicker from "../../customComponent/DateRangePicker";
import MySelect from "../../customComponent/MySelect";
import { Reload } from "../../customComponent/Reload";
import {
  getUsers,
  syncHubstaffUsers,
} from "../../../redux/reducers/usersReducer";
import { Add as AddIcon } from "@material-ui/icons";

function Actions({ message }) {
  const dispatch = useDispatch();
  const [projectsList, setProjectsList] = useState([]);

  const {
    isLoading,
    selectedDate,
    selectedChannel,
    selectedProject,
  } = useSelector((state) => state.entities.users);

  const { allProjects } = useSelector((state) => state.entities.projects);

  useEffect(() => {
    const list = allProjects.filter(
      (item) =>
        !selectedChannel ||
        selectedChannel === "all" ||
        selectedChannel === item.channel
    );
    setProjectsList(list);
  }, [allProjects, selectedChannel]);

  const loadUsers = () => {
    dispatch(
      getUsers({
        channel: selectedChannel,
        project: selectedProject,
        date: selectedDate,
      })
    );
  };

  const handleClickOpen = () => {
    dispatch(syncHubstaffUsers());
  };

  return (
    <Box display="flex" align="center">
      <Reload loading={isLoading} action={loadUsers} />
      <FormControl style={{ marginRight: ".5rem" }}>
        <MySelect
          selected={selectedProject}
          defaultLabel="All Campaigns"
          defaultValue="all"
          list={projectsList}
          valueHolder="project"
          label="name"
          value="_id"
          data={{
            channel: selectedChannel,
            date: selectedDate,
          }}
          toDispatch={getUsers}
        />
      </FormControl>
      <FormControl style={{ marginRight: ".5rem" }}>
        <DatePicker
          data={{
            channel: selectedChannel,
            project: selectedProject,
          }}
          toDispatch={getUsers}
        />
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        style={{ backgroundColor: "#6a74c9", color: "white" }}
      >
        <AddIcon />
        Sync Users
      </Button>
    </Box>
  );
}

export default Actions;
