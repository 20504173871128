import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/apiActions";
import * as globals from "./../../constants/index";
const url = "settings";

const slice = createSlice({
  name: "settings",
  initialState: {
    setting: {},
    spinText: null,
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
  },
  reducers: {
    resetAll: (settings, action) => {
      settings.isLoading = false;
      settings.error = null;
      settings.success = null;
    },
    requestStarted: (settings, action) => {
      settings.isLoading = true;
      settings.error = null;
      settings.isRequestSent = true;
    },
    requestedFailed: (settings, action) => {
      settings.error = action.payload;
      settings.isLoading = false;
    },
    settingsLoaded: (settings, action) => {
      settings.setting = action.payload;
      settings.isLoading = false;
    },
    settingUpdated: (settings, { payload }) => {
      settings.success = "Settings Updated";
      settings.setting = { ...settings.setting, ...payload };
      settings.isLoading = false;
    },
    spinGutted: (settings, { payload }) => {
      settings.success = "Spin Gutted";
      settings.spinText = payload;
      settings.isLoading = false;
    },
  },
});

export const {
  settingUpdated,
  settingsLoaded,
  spinGutted,
  requestedFailed,
  requestStarted,
  resetAll,
} = slice.actions;
export default slice.reducer;

export const getSettings = () =>
  apiCallBegan({
    url,
    method: "POST",
    data: globals.SETTINGS,
    onStart: requestStarted.type,
    onSuccess: settingsLoaded.type,
    onError: requestedFailed.type,
  });

export const updateSettings = (data) =>
  apiCallBegan({
    url,
    method: "PUT",
    data,
    onStart: requestStarted.type,
    onSuccess: settingUpdated.type,
    onError: requestedFailed.type,
  });

export const getSpinFromText = (data) =>
  apiCallBegan({
    url: `${url}/spin`,
    method: "POST",
    data,
    onStart: requestStarted.type,
    onSuccess: spinGutted.type,
    onError: requestedFailed.type,
  });
