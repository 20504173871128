import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
// import AddContacts from "./AddContacts";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Submission from "../pages/Submission";
import { spacing } from "@material-ui/system";
import {
  getSubmissionSelected,
  setToLeadSubmission,
} from "../../redux/reducers/submissionsReducer";
import { capitalize, getDateFormate } from "../../helpers/functions";
import { getOneCompany } from "../../redux/reducers/companiesReducer";

const Divider = styled(MuiDivider)(spacing);

function InfoLeads() {
  const [submit, setSubmit] = useState(null);
  const [open, setOpen] = useState(false);
  let [submissions, setSubmissions] = useState([]);

  const dispatch = useDispatch();
  let { id, company_id } = useParams();
  const { selectedSubmission, list } = useSelector(
    (state) => state.entities.submissions
  );
  const { selectedCompany, list: companiesList } = useSelector(
    (state) => state.entities.companies
  );

  useEffect(() => {
    if (id) {
      const found = selectedSubmission.find((item) => item._id === id);
      const foundInList = list.find((item) => item._id === id);

      if (!found && !foundInList) dispatch(getSubmissionSelected(id));
      else setSubmit({ ...(found || foundInList), isFromLead: false });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedSubmission, list]);

  useEffect(() => {
    const companyFound = selectedCompany.find(
      (item) => item._id === company_id
    );
    const foundInCompanyList = companiesList.find(
      (item) => item._id === company_id
    );

    if (!companyFound && !foundInCompanyList) {
      dispatch(getOneCompany(company_id));
    } else {
      let company = {
        ...(companyFound || foundInCompanyList),
      };
      setSubmissions(company.submissions);
      if (!id && company.submissions[0]?.submission?._id) {
        history.push(
          `/companies/${company_id}/${company.submissions[0]?.submission?._id}`
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, selectedCompany, companiesList, submissions]);

  const handleOpenTypeLead = () => {
    setOpen(true);
  };

  const handelClose = () => {
    setOpen(false);
  };

  let history = useHistory();
  const showDetails = (company_id, submit_id) => {
    history.push(`/companies/${company_id}/${submit_id}`);
  };

  return (
    <Fragment>
      <Helmet title="Company Details" />
      <Grid
        justify="space-between"
        direction="row"
        align="center"
        container
        spacing={6}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Company Details
          </Typography>
        </Grid>
        <Grid item>
          <Box>
            <Button
              variant="contained"
              style={{ backgroundColor: "#6a74c9", color: "white" }}
              onClick={handleOpenTypeLead}
            >
              {/* <AddIcon /> */}
              Set Lead
            </Button>
            {open && (
              <LeadType
                open={open}
                setOpen={setOpen}
                handelClose={handelClose}
                submit={submit}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Box style={{ maxHeight: "20em", overflow: "auto" }}>
        <Table size="small" aria-label="purchases" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Submission Date
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="left">
                Campaign Name
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="left">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {submissions.map((submit, key) => (
              <TableRow
                key={key}
                onClick={(e) => {
                  showDetails(company_id, submit.submission?._id);
                }}
                style={{
                  cursor: "pointer",
                  backgroundColor:
                    id === submit.submission?._id ? "#6a74c9" : "#d6d6d6",
                }}
              >
                <TableCell style={{ fontSize: "14px" }}>
                  {getDateFormate(submit.submission?.createdAt)}
                </TableCell>
                <TableCell style={{ fontSize: "14px" }} align="left">
                  {submit.project?.name}
                </TableCell>
                <TableCell style={{ fontSize: "14px" }} align="left">
                  {capitalize(
                    submit.submission?.lead
                      ? submit.submission?.lead
                      : submit.submission?.success
                      ? "Success"
                      : "Failed"
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      <Grid container spacing={6} style={{ marginTop: "2em" }}>
        <Submission submit={submit} />
      </Grid>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  h5: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  table: {
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
  },
  formControl: {
    width: "8rem",
    marginTop: "-5px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  },
  select: {
    border: "1px solid #6A74C9",
    borderRadius: "4px",
    color: "#6A74C9",
    fontWeight: "600",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  InputLabel: {
    color: "#6A74C9",
    fontWeight: "600",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  animated: {
    display: "none",
  },
  label: {
    marginLeft: "8px",
  },
  loading: {
    marginTop: "2rem",
    marginLeft: "26rem",
    paddingBottom: "3rem",
  },
}));

const LeadType = ({
  open,
  setOpen,
  handelClose,
  submit,
  company,
  company_id,
}) => {
  const classes = useStyles();
  let [type, setType] = useState(submit.lead || "call");
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setType(e.target.value);
  };

  const setToLead = () => {
    dispatch(
      setToLeadSubmission({
        _id: submit._id,
        lead: type,
        company_id: submit.company._id,
      })
    );
    handelClose();
  };

  useEffect(() => {
    if (submit.lead) setType(submit.lead);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      onClose={handelClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h6" gutterBottom>
          Lead Type
        </Typography>
        <Box style={{ display: "flex", float: "right" }}>
          <Button
            style={{ color: "#868695", marginTop: "-40px" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </Box>
      </DialogTitle>

      <DialogContent classes={{ root: classes.dialogContent }}>
        <Grid container>
          <Grid item style={{ padding: "9px 0", width: "100%" }}>
            <Select
              onChange={(event) => handleChange(event)}
              disableUnderline
              defaultValue={type}
              MenuProps={{
                classes: { paper: classes.paperSelect },
              }}
              className={classes.select}
              style={{ paddingTop: "2px ", width: "100%", textAlign: "center" }}
            >
              <MenuItem value={"call"}>Call</MenuItem>
              <MenuItem value={"submit"}>Submission</MenuItem>
              <MenuItem value={"email"}>Email</MenuItem>
            </Select>
          </Grid>
          {/* <Grid item md={4} style={{ padding: "9px 0" }}></Grid> */}
          <Grid item style={{ padding: "9px 0" }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                MarginLeft: "100px",
                backgroundColor: "#6a74c9",
                color: "white",
              }}
              onClick={setToLead}
            >
              Set Lead
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default InfoLeads;
