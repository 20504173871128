import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/apiActions";
import * as globals from "../../constants/index";
import { updateToken } from "../../helpers/functions";
const url = "auth";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    data: { user: null, stats: {} },
    isLoading: false,
    error: null,
    success: null,
  },
  reducers: {
    resetAll: (state) => {
      state.isLoading = false;
      state.error = null;
      state.success = null;
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.success = "Login success";
    },
    loginToUser: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      updateToken(action.payload.token);
      state.success = "Login success";
    },

    getStats: (state, action) => {
      state.isLoading = false;
      state.data.stats = action.payload;
      state.success = "stats loaded";
    },

    requestStarted: (state, action) => {
      state.isLoading = true;
    },
    requestSuccess: (state, action) => {
      state.success = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    requestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    profileUpdated: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.success = "Alias Updated";
    },
    resetSent: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.success = "Password Reset URL Sent successfully.";
    },
    resetErrorAndSuccess: (state) => {
      state.error = null;
      state.isLoading = false;
      state.success = null;
    },
    signOut: (state) => {
      localStorage.removeItem(globals.USER_TOKEN);
      state.error = null;
      state.isLoading = false;
      state.success = null;
      state.data = { user: null };
    },
  },
});
export default authSlice.reducer;
export const {
  signOut,
  resetAll,
  getStats,
  resetSent,
  loginToUser,
  loginSuccess,
  requestFailed,
  requestStarted,
  profileUpdated,
  requestSuccess,
  resetErrorAndSuccess,
} = authSlice.actions;

export const login = (data) =>
  apiCallBegan({
    url: url + "/login",
    data,
    method: "POST",
    onStart: requestStarted.type,
    onSuccess: loginSuccess.type,
    onError: requestFailed.type,
  });

export const updateMyProfile = (data) =>
  apiCallBegan({
    url: "users/me",
    data,
    method: "PUT",
    onStart: requestStarted.type,
    onSuccess: profileUpdated.type,
    onError: requestFailed.type,
  });

export const sendResetPassword = (data) =>
  apiCallBegan({
    url: url + "/forgot-password",
    data,
    method: "POST",
    onStart: requestStarted.type,
    onSuccess: resetSent.type,
    onError: requestFailed.type,
  });

export const resetPassword = (data) =>
  apiCallBegan({
    url: url + "/reset-password",
    data,
    method: "POST",
    onStart: requestStarted.type,
    onSuccess: requestSuccess.type,
    onError: requestFailed.type,
  });

export const getUserByToken = () => {
  return apiCallBegan({
    url: url + `/token`,
    method: "POST",
    onStart: requestStarted.type,
    onSuccess: loginSuccess.type,
    onError: signOut.type,
  });
};

export const loginIntoUser = (_id) => {
  return apiCallBegan({
    url: url + `/user`,
    method: "POST",
    data: { _id },
    onStart: requestStarted.type,
    onSuccess: loginToUser.type,
    onError: signOut.type,
  });
};

export const getUserStats = (params = {}) => {
  if (params.startDate === null) {
    delete params.date;
  } else {
    if (params.date && params.date.startDate)
      params.startDate = params.date.startDate;
    if (params.date && params.date.endDate)
      params.endDate = params.date.endDate;

    delete params.date;
  }

  let query = new URLSearchParams(params);

  return apiCallBegan({
    url: `/users/stats?${query}`,
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: getStats.type,
    onError: signOut.type,
  });
};
