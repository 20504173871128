import { createSlice } from "@reduxjs/toolkit";
import { getNumberPerPage } from "../../helpers/functions";
import { apiCallBegan } from "../actions/apiActions";
const url = "groups";

const slice = createSlice({
  name: "groups",
  initialState: {
    list: [],
    all: [],
    selectedGroup: null,
    isLoading: false,
    error: null,
    success: null,
    groupAdded: null,
    isRequestSent: null,
    page: 1,
    per_page: 0,
    total_pages: 0,
    maxPage: 0,
    total_records: 0,
    search: "",
    listType: "active",
  },
  reducers: {
    resetAll: (groups) => {
      groups.isLoading = false;
      groups.error = null;
      groups.success = null;
      groups.group = null;
    },
    requestStarted: (groups) => {
      groups.isLoading = true;
      groups.error = null;
      groups.isRequestSent = true;
    },
    requestedFailed: (groups, action) => {
      groups.error = action.payload;
      groups.isLoading = false;
    },
    groupsLoaded: (groups, action) => {
      groups.list.push(...action.payload.result);
      groups.page = action.payload.page;
      groups.per_page = action.payload.per_page;
      groups.total_pages = action.payload.total_pages;
      groups.total_records = action.payload.total_records;
      groups.statusType = action.payload.statusType;
      groups.maxPage =
        groups.page > groups.maxPage ? groups.page : groups.maxPage;
      groups.isLoading = false;
    },
    oneGroupLoaded: (groups, action) => {
      const index = groups.list.findIndex(
        (group) => group._id === action.payload._id
      );
      groups.list[index] = action.payload;
      groups.isLoading = false;
      groups.success = "Group Loaded";
    },
    groupsReLoaded: (groups, action) => {
      groups.list = action.payload.result;
      groups.search = action.payload.search;
      groups.page = action.payload.page;
      groups.per_page = action.payload.per_page;
      groups.total_pages = action.payload.total_pages;
      groups.total_records = action.payload.total_records;
      groups.statusType = action.payload.statusType;
      groups.maxPage = 0;
      groups.isLoading = false;
    },
    allGroupsLoaded: (groups, action) => {
      groups.all = action.payload;
      groups.isLoading = false;
    },
    groupAdded: (groups, action) => {
      groups.success = "Group Added";
      groups.groupAdded = action.payload;

      const index = groups.list.findIndex(
        (group) => group._id === action.payload._id
      );
      groups.list[index] = action.payload;
      // all
      const indexOfAll = groups.all.findIndex(
        (group) => group._id === action.payload._id
      );
      groups.all[indexOfAll] = action.payload;
      groups.isLoading = false;
    },
    keywordsGenerated: (groups, action) => {
      const index = groups.list.findIndex(
        (group) => group._id === action.payload._id
      );
      groups.list[index] = action.payload;
      groups.isLoading = false;
      groups.success = "Keywords generated";
      groups.groupAdded = null;
    },
    groupUpdated: (groups, action) => {
      const index = groups.list.findIndex(
        (group) => group._id === action.payload._id
      );
      groups.list[index] = action.payload;
      // all
      const indexOfAll = groups.all.findIndex(
        (group) => group._id === action.payload._id
      );
      groups.all[indexOfAll] = action.payload;
      groups.isLoading = false;
      groups.success = "Group Updated";
    },
    groupDeleted: (groups, action) => {
      const index = groups.list.findIndex(
        (group) => group._id === action.payload._id
      );
      groups.list.splice(index, 1);
      // all
      const indexOfAll = groups.all.findIndex(
        (group) => group._id === action.payload._id
      );
      groups.all.splice(indexOfAll, 1);
      groups.isLoading = false;
      groups.success = "Group Deleted";
    },
    groupIsLoading: (groups, action) => {
      const index = groups.list.findIndex(
        (group) => group._id === action.payload._id
      );
      groups.list[index].isLoading = true;
    },
    groupMerged: (groups, action) => {
      const index = groups.list.findIndex(
        (group) => group._id === action.payload.origin._id
      );
      groups.list[index] = action.payload.origin;

      // delete merged groups
      // list
      groups.list = groups.list.filter(
        (group) => !action.payload.merged.includes(group._id)
      );
      // all
      groups.all = groups.all.filter(
        (group) => !action.payload.merged.includes(group._id)
      );

      groups.isLoading = false;
      groups.success = "Groups Merged";
    },
  },
});

export const {
  resetAll,
  groupAdded,
  groupsLoaded,
  groupsReLoaded,
  oneGroupLoaded,
  requestedFailed,
  requestStarted,
  groupUpdated,
  allGroupsLoaded,
  keywordsGenerated,
  groupDeleted,
  groupMerged,
  groupIsLoading,
} = slice.actions;
export default slice.reducer;

export const getGroups = (params = {}) => {
  params.per_page = getNumberPerPage();
  if (params.search === "" || params.search === null) delete params.search;
  if (!params.statusType) params.statusType = "active";
  //Generate a URL query based on the object we got
  let query = new URLSearchParams(params);

  let resultsReceived = params.page ? groupsLoaded.type : groupsReLoaded.type;

  return apiCallBegan({
    url: `${url}?${query}`,
    onStart: requestStarted.type,
    onSuccess: resultsReceived,
    onError: requestedFailed.type,
  });
};

export const getOneGroup = (id) => {
  return apiCallBegan({
    url: `${url}/${id}`,
    onStart: requestStarted.type,
    onSuccess: oneGroupLoaded.type,
    onError: requestedFailed.type,
  });
};

export const getAllGroups = () =>
  apiCallBegan({
    url: url + "/list",
    onStart: requestStarted.type,
    onSuccess: allGroupsLoaded.type,
    onError: requestedFailed.type,
  });

export const addGroup = (data) =>
  apiCallBegan({
    url,
    method: "POST",
    data,
    onStart: requestStarted.type,
    onSuccess: groupAdded.type,
    onError: requestedFailed.type,
  });

export const updateGroup = (id, data) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "put",
    data,
    onStart: requestStarted.type,
    onSuccess: groupUpdated.type,
    onError: requestedFailed.type,
  });

export const deleteGroup = (id) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "delete",
    onStart: requestStarted.type,
    onSuccess: groupDeleted.type,
    onError: requestedFailed.type,
  });

export const generateKeywords = (data) =>
  apiCallBegan({
    url: `keywords`,
    method: "POST",
    data,
    onStart: requestStarted.type,
    onSuccess: keywordsGenerated.type,
    onError: requestedFailed.type,
  });

export const mergeGroups = (data) =>
  apiCallBegan({
    url: `${url}/merge`,
    method: "POST",
    data,
    onStart: requestStarted.type,
    onSuccess: groupMerged.type,
    onError: requestedFailed.type,
  });

export const resetGroupKeywords = (id) =>
  apiCallBegan({
    url: `${url}/reset-group-keyword/${id}`,
    method: "POST",
    onStart: requestStarted.type,
    onSuccess: oneGroupLoaded.type,
    onError: requestedFailed.type,
  });

export const resetGroupFiltered = (id) =>
  apiCallBegan({
    url: `${url}/reset-group-filtered/${id}`,
    method: "POST",
    onStart: requestStarted.type,
    onSuccess: oneGroupLoaded.type,
    onError: requestedFailed.type,
  });
