import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  CardContent,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import { spacing } from "@material-ui/system";
import ChipInput from "material-ui-chip-input";
import { addGroup, updateGroup } from "./../../../redux/reducers/groupsReducer";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },
  dialogWrapper: {
    position: "absolute",
    maxWidth: "50rem",
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  startICon: {
    margin: 0,
  },
  chips: {
    height: "20px",
    borderRadius: "0px",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  ButtonApprove: {
    backgroundColor: "#23CC94",
    color: "white",
    width: "6rem",
    margin: "12px 39px",
  },
  description: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  h5: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  controlInput: {
    backgroundColor: "#F9F9FC",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
  },
  shrink: {
    display: "none",
  },
  inputSelect: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    backgroundColor: "#f5f6fa",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
}));

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Group name is required"),
  description: Yup.string().required("Description is required"),
});

const Popup = ({ open, setOpen, group }) => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({ code: "All", name: "All States" });
  const [filters, setFilters] = useState(group?.filters ? group.filters : []);
  const [keywords, setKeywords] = useState(
    group?.keywords ? group.keywords : []
  );
  const [Services, setServices] = useState(
    group?.services ? group.services : []
  );

  const initialValues = {
    name: group ? group.name : "",
    description: group ? group.description : "",
    // state: [{ code: "All", name: "All States" }],
    filters: filters,
    keywords: keywords,
    services: Services,
  };

  const dispatch = useDispatch();

  const classes = useStyles();

  const handleSubmit = ({ name, description }) => {
    const data = {
      name,
      description,
      filters: filters,
      keywords: keywords,
      services: Services,
    };

    if (group) {
      dispatch(updateGroup(group._id, data));
      setOpen(false);
    } else {
      dispatch(addGroup(data));
      setOpen(false);
    }
  };

  const handleAddChip = (filter) => {
    filter = filter.trim();
    if (!filters.includes(filter)) setFilters([...filters, filter]);
  };

  const handleDeleteChip = (filter) => {
    setFilters(filters.filter((item) => item !== filter.trim()));
  };

  const keywordHandleAddChip = (keyword) => {
    keyword = keyword.trim();
    if (!keywords.includes(keyword)) setKeywords([...keywords, keyword]);
  };

  const keywordHandleDeleteChip = (keyword) => {
    setKeywords(keywords.filter((item) => item !== keyword.trim()));
  };

  const ServicesHandleAddChip = (keyword) => {
    keyword = keyword.trim();
    if (!Services.includes(keyword)) setServices([...Services, keyword]);
  };

  const ServicesHandleDeleteChip = (keyword) => {
    setServices(Services.filter((item) => item !== keyword.trim()));
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogWrapper }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography gutterBottom>
          {group ? "Update Group" : "New Group"}
        </Typography>
        <div style={{ display: "flex", float: "right" }}>
          <Button
            style={{ color: "#868695", marginTop: "-40px" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            status,
            disabled,
          }) => (
            <CardContent>
              <Typography variant="body2" gutterBottom>
                Create a new Scraping group and start collecting business
                information
              </Typography>

              <form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item md={12} style={{ padding: "9px 0" }}>
                    <Typography variant="h5" classes={{ h5: classes.h5 }}>
                      Name*:
                    </Typography>
                    <TextField
                      name="name"
                      placeholder="Name"
                      InputProps={{ classes: { input: classes.input } }}
                      inputlabelprops={{ shrink: false }}
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helpertext={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>

                  <Grid item md={12} style={{ padding: "9px 0px" }}>
                    <Typography variant="h5" classes={{ h5: classes.h5 }}>
                      Description*:
                    </Typography>
                    <TextField
                      name="description"
                      placeholder="Description"
                      InputProps={{
                        classes: {
                          input: classes.inputTextarea,
                          root: classes.controlInput,
                        },
                      }}
                      inputlabelprops={{ shrink: false }}
                      value={values.description}
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      rows={3}
                      rowsMax={4}
                      multiline={true}
                      helpertext={touched.description && errors.description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>

                  {/* <Grid item md={12} style={{ padding: "9px 0px" }}>
                    <FormControl fullWidth>
                      <Chip
                        list={states}
                        selected={values.state}
                        placeholder={"State"}
                        label={"name"}
                        setSelected={setState}
                        multiple={true}
                        disabled={true}
                      />
                    </FormControl>
                  </Grid> */}

                  <Grid item md={12} style={{ padding: "9px 0px" }}>
                    <Typography variant="h5" classes={{ h5: classes.h5 }}>
                      Keywords*:
                    </Typography>
                    <Paper className={classes.input}>
                      <ChipInput
                        disableUnderline={true}
                        classes={{
                          input: classes.input,
                          chipContainer: classes.chipInput,
                          inputRoot: classes.rootChips,
                          chip: classes.chip,
                        }}
                        value={keywords}
                        onAdd={(chip) => keywordHandleAddChip(chip)}
                        onDelete={(chip, index) =>
                          keywordHandleDeleteChip(chip, index)
                        }
                      />
                    </Paper>
                  </Grid>

                  <Grid item md={12} style={{ padding: "9px 0px" }}>
                    <Typography variant="h5" classes={{ h5: classes.h5 }}>
                      Filters*:
                    </Typography>
                    <Paper className={classes.input}>
                      <ChipInput
                        disableUnderline={true}
                        classes={{
                          input: classes.input,
                          chipContainer: classes.chipInput,
                          inputRoot: classes.rootChips,
                          chip: classes.chip,
                        }}
                        value={filters}
                        onAdd={(chip) => handleAddChip(chip)}
                        onDelete={(chip, index) =>
                          handleDeleteChip(chip, index)
                        }
                      />
                    </Paper>
                  </Grid>

                  <Grid item md={12} style={{ padding: "9px 0px" }}>
                    <Typography variant="h5" classes={{ h5: classes.h5 }}>
                      Services*:
                    </Typography>
                    <Paper className={classes.input}>
                      <ChipInput
                        disableUnderline={true}
                        classes={{
                          input: classes.input,
                          chipContainer: classes.chipInput,
                          inputRoot: classes.rootChips,
                          chip: classes.chip,
                        }}
                        value={Services}
                        onAdd={(chip) => ServicesHandleAddChip(chip)}
                        onDelete={(chip, index) =>
                          ServicesHandleDeleteChip(chip, index)
                        }
                      />
                    </Paper>
                  </Grid>
                </Grid>

                <Button
                  style={{
                    marginTop: "10px",
                    backgroundColor: "#6a74c9",
                    color: "white",
                  }}
                  // onClick={() => {
                  //   handleSubmit();
                  // }}
                  type="submit"
                  variant="contained"
                  mt={4}
                >
                  {group ? "Update" : "Add New"}
                </Button>
              </form>
            </CardContent>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
